import request from '@/utils/request'


// 查询短视频评论列表
export function listVideoComment(query) {
  return request({
    url: '/video/video-video-comment/list',
    method: 'get',
    params: query
  })
}

// 查询短视频评论分页
export function pageVideoComment(query) {
  return request({
    url: '/video/video-video-comment/getDetailsCommentList',
    method: 'get',
    params: query
  })
}

// 查询短视频评论详细
export function getVideoComment(data) {
  return request({
    url: '/video/video-video-comment/detail',
    method: 'get',
    params: data
  })
}

// 新增短视频评论
export function addVideoComment(data) {
  return request({
    url: '/video/video-video-comment/add',
    method: 'post',
    data: data
  })
}

// 修改短视频评论
export function updateVideoComment(data) {
  return request({
    url: '/video/video-video-comment/edit',
    method: 'post',
    data: data
  })
}

// 删除短视频评论
export function delVideoComment(data) {
  return request({
    url: '/video/video-video-comment/delete',
    method: 'post',
    data: data
  })
}
