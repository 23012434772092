<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户id" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入用户id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="提现金额" prop="money">
                <a-input v-model="queryParam.money" placeholder="请输入提现金额" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-dropdown v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="batch(1)">
              批量审核通过
            </a-menu-item>
            <a-menu-item key="1" @click="batch(2)">
              批量审核拒绝
            </a-menu-item>
            <a-menu-item key="1" @click="batch(3)">
              批量打款
            </a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> 批量操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:withdraw:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:withdraw:remove']">-->
        <!--          <a-icon type="delete" />删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:withdraw:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <!--        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!--          <a-divider type="vertical" v-hasPermi="['user:withdraw:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.status == 0">
            <!--            <a-icon type="edit" />修改-->
            审核
          </a>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.status == 2">
            <!--            <a-icon type="edit" />修改-->
            打款
          </a>
          <!--          <a-divider type="vertical" v-hasPermi="['user:withdraw:remove']" />-->
          <!--          <a @click="handleDelete(record)" v-hasPermi="['user:withdraw:remove']">-->
          <!--            <a-icon type="delete" />删除-->
          <!--          </a>-->
        </span>
        <img
          style="width: 48px;height: 48px;"
          v-image-preview
          :src="record.payCode"
          slot="payCode"
          slot-scope="text, record">
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageWithdraw, listWithdraw, delWithdraw } from '@/api/user/withdraw'
import { getAction } from '@/api/manage'
import CreateForm from './modules/CreateModalForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Withdraw',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        money: null,
        status: null,
        withdrawType: null,
        withdrawUserType: null,
        orderNo: null,
        failureReason: null,
        auditOpinion: null,
        cateName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          customRender: function (text, record, index) {
            return (index + 1)
          }
        },
        {
          title: '用户id',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '提现金额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '提现方式',
          dataIndex: 'withdrawType',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 1) {
              return '支付宝'
            } else if (text == 2) {
              return '微信'
            }
          }
        },
        {
          title: '审核意见',
          dataIndex: 'auditOpinion',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (!text) { return '无' }
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '未审核'
            } else if (text == 1) {
              return '审核未通过'
            } else if (text == 2) {
              return '审核已通过'
            } else if (text == 3) {
              return '已打款'
            } else if (text == 4) {
              return '打款失败'
            }
          }
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '失败原因',
          dataIndex: 'failureReason',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (!text) { return '无' }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户提现记录列表 */
    getList () {
      this.loading = true
      pageWithdraw(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        money: undefined,
        status: undefined,
        withdrawType: undefined,
        withdrawUserType: undefined,
        orderNo: undefined,
        failureReason: undefined,
        auditOpinion: undefined,
        cateName: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delWithdraw(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/withdraw/export', {
            ...that.queryParam
          }, `用户提现记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    batch(type) {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
      } else {
        var ids = this.ids
        var that = this
        var title = ''
        var content = ''
        var url = ''

        if (type == 1) {
          title = '批量审核通过'
          content = '是否审核通过选中数据？'
          url = '/user/user-withdraw/check/2'
        }

        if (type == 2) {
          title = '批量审核拒绝'
          content = '是否审核拒绝选中数据？'
          url = '/user/user-withdraw/check/1'
        }

        if (type == 3) {
          title = '批量打款'
          content = '是否打款选中数据？'
          url = '/user/user-withdraw/check/3'
        }

        this.$confirm({
          title: title,
          content: content,
          onOk: function () {
            getAction(url, { ids: ids }).then((res) => {
              if (res.success) {
                that.$message.success(res.message)
                this.getList()
              } else {
                that.$message.warning(res.message)
              }
            })
          }
        })
      }
    }
  }
}
</script>
