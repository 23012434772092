import request from '@/utils/request'


// 查询交友魅力等级列表
export function listFriendCharm(query) {
  return request({
    url: '/user/friend-charm-grade/list',
    method: 'get',
    params: query
  })
}

// 查询交友魅力等级分页
export function pageFriendCharm(query) {
  return request({
    url: '/user/friend-charm-grade/page',
    method: 'get',
    params: query
  })
}

// 查询交友魅力等级详细
export function getFriendCharm(data) {
  return request({
    url: '/user/friend-charm-grade/detail',
    method: 'get',
    params: data
  })
}

// 新增交友魅力等级
export function addFriendCharm(data) {
  return request({
    url: '/user/friend-charm-grade/add',
    method: 'post',
    data: data
  })
}

// 修改交友魅力等级
export function updateFriendCharm(data) {
  return request({
    url: '/user/friend-charm-grade/edit',
    method: 'post',
    data: data
  })
}

// 删除交友魅力等级
export function delFriendCharm(data) {
  return request({
    url: '/user/friend-charm-grade/delete',
    method: 'post',
    data: data
  })
}
