<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-tabs>
        <a-tab-pane key="1" tab="订单配置">
          <a-form-model ref="form" :model="form" :rules="rules">
            <a-form-model-item label="确认收货后多少天后无法退款/冻结金额恢复的时间（单位：天）" prop="noRefund" >
              <a-input-number v-model="form.noRefund" placeholder="请输入天数" :min="0" :step="1" style="width: 80%;" />
            </a-form-model-item>
            <a-form-model-item label="自动确认收货时间（单位：天）" prop="affirmDate">
              <a-input-number v-model="form.affirmDate" placeholder="请输入天数" :min="0" :step="1" style="width: 80%;"/>
            </a-form-model-item>
          </a-form-model>
          <a-button type="primary" :loading="oneLoading" @click="submitForm(1)">
            保存
          </a-button>
        </a-tab-pane>

        <a-tab-pane key="2" tab="金额配置">
          <a-form-model ref="priceform" :model="priceform" :rules="pricerules">
<!--            <a-form-model-item label="嗨豆订单抽成比例" prop="orderRatio">-->
<!--              <a-input-number v-model="priceform.orderRatio" :max="1" placeholder="请输入嗨豆订单抽成比例" :min="0" :step="0.01" style="width: 80%;"/>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="1嗨币=#人民币" prop="goldRatioZero">
              <a-input-number v-model="priceform.goldRatioZero" placeholder="请输入嗨币价值" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="1嗨宝=#人民币" prop="gemRatioZero">
              <a-input-number v-model="priceform.gemRatioZero" placeholder="请输入嗨宝价值" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="1人民币=#嗨宝(充值-安卓)" prop="gemRatio" >
              <a-input-number v-model="priceform.gemRatio" placeholder="请输入嗨宝兑换比例" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="1人民币=#嗨币(充值-安卓)" prop="goldRatio" >
              <a-input-number v-model="priceform.goldRatio" placeholder="请输入嗨币兑换比例" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
<!--            <a-form-model-item label="股权分红上限" prop="stockRightsLimit" >-->
<!--              <a-input-number v-model="priceform.stockRightsLimit" placeholder="请输入股权分红上限" :min="0" :step="0.01" style="width: 80%;"/>-->
<!--            </a-form-model-item>-->

            <a-form-model-item label="1人民币=#嗨宝(充值-IOS)" prop="gemRatioIos">
              <a-input-number v-model="priceform.gemRatioIos" placeholder="请输入嗨宝兑换ios" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="1人民币=#嗨币(充值-IOS)" prop="goldRatioIos">
              <a-input-number v-model="priceform.goldRatioIos" placeholder="请输入嗨币兑换ios" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="嗨宝转赠手续费比例" prop="donationGem">
              <a-input-number v-model="priceform.donationGem" :max="1" placeholder="请输入嗨宝转赠手续费" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="交友达人收益比例" prop="friendsterIncomeRatio">
              <a-input-number v-model="priceform.friendsterIncomeRatio" :max="1" placeholder="请输入交友达人收益比例" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="交友达人直销比例" prop="friendsterIncomeRatioTwo" >
              <a-input-number v-model="priceform.friendsterIncomeRatioTwo" :max="1" placeholder="请输入交友达人直销比例" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="观看者点赞消费数量（嗨币）" prop="likeWatchSendNum">
              <a-input-number v-model="priceform.likeWatchSendNum"  placeholder="请输入观看者点赞消费数量（嗨币）" :min="0" :step="1" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="观看者点赞收入数量（嗨豆）" prop="likeWatchReapNum" >
              <a-input-number v-model="priceform.likeWatchReapNum" placeholder="请输入观看者点赞收入数量（嗨豆）" :min="0" :step="1" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="观看者评论消费数量（嗨币）"  prop="commentWatchSendNum" >
              <a-input-number v-model="priceform.commentWatchSendNum" placeholder="请输入观看者评论消费数量（嗨币）" :min="0" :step="1" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="观看者评论收入数量（嗨豆）" prop="commentWatchReapNum"  >
              <a-input-number v-model="priceform.commentWatchReapNum" placeholder="请输入观看者评论收入数量（嗨豆）" :min="0" :step="1" style="width: 80%;"/>
            </a-form-model-item>

            <a-form-model-item label="短视频收益比例" prop="videoRatio" >
              <a-input-number v-model="priceform.videoRatio" :max="1" placeholder="请输入观看者评论收入数量（嗨豆）" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="主播申请人数限制" prop="anchorLimit" >
              <a-input-number v-model="priceform.anchorLimit"  placeholder="请输入主播申请人数限制" :min="0" :step="1" style="width: 80%;"/>
            </a-form-model-item>
          </a-form-model>
          <a-button type="primary" :loading="twoLoading" @click="submitForm(2)">
            保存
          </a-button>
        </a-tab-pane>
        <a-tab-pane key="3" tab="账户配置">
          <a-form-model ref="accountform" :model="accountform" :rules="accountrules">
            <a-form-model-item label="银行账号" prop="bankNo">
              <a-input v-model="accountform.bankNo"  placeholder="请输入银行账号" style="width: 80%;" />
            </a-form-model-item>

            <a-form-model-item label="银行开户名"  prop="bankName">
              <a-input v-model="accountform.bankName"  placeholder="请输入银行开户名"  style="width: 80%;" />
            </a-form-model-item>
            <a-form-model-item label="开户行"  prop="openBank">
              <a-input v-model="accountform.openBank"  placeholder="请输入开户行"  style="width: 80%;" />
            </a-form-model-item>
            <a-form-model-item label="开户行所在地"  prop="bankAddress">
              <a-input v-model="accountform.bankAddress"  placeholder="请输入开户行所在地"  style="width: 80%;" />
            </a-form-model-item>
            <a-form-model-item label="打款金额"  prop="remitMoney">
              <a-input-number v-model="accountform.remitMoney" placeholder="请输入打款金额" :min="0" :step="0.01" style="width: 80%;"/>

            </a-form-model-item>
          </a-form-model>
          <a-button type="primary" :loading="threeLoading" @click="submitForm(3)">
            保存
          </a-button>
        </a-tab-pane>

        <a-tab-pane key="4" tab="分销配置">
          <a-form-model ref="distributionform" :model="distributionform" :rules="distributionrules">
            <a-form-model-item label="直销-现金奖" prop="oneCash" >
              <a-input-number v-model="distributionform.oneCash" placeholder="请输入直销-现金奖" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="直销-嗨宝" prop="oneGem" >
              <a-input-number v-model="distributionform.oneGem" placeholder="请输入直销-嗨宝" :min="0" :step="0.01" :max="1"  style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="直销-嗨币" prop="oneGold" >
              <a-input-number v-model="distributionform.oneGold" placeholder="请输入直销-嗨宝" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="直销-日分红" prop="oneBonus" >
              <a-input-number v-model="distributionform.oneBonus" placeholder="请输入直销-日分红" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="间销-日分红" prop="twoBonus" >
              <a-input-number v-model="distributionform.twoBonus" placeholder="请输入间销-日分红" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="间销-嗨宝" prop="twoGem" >
              <a-input-number v-model="distributionform.twoGem" placeholder="请输入间销-嗨宝" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="间销-嗨币" prop="twoGold" >
              <a-input-number v-model="distributionform.twoGold" placeholder="请输入间销-嗨宝" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分销-县级股东分成" prop="distributionCounty" >
              <a-input-number v-model="distributionform.distributionCounty" placeholder="请输入分销-县级股东分成" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分销-市级股东分成" prop="distributionCity" >
              <a-input-number v-model="distributionform.distributionCity" placeholder="请输入分销-市级股东分成" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分销-省级股东分成" prop="distributionProvince" >
              <a-input-number v-model="distributionform.distributionProvince" placeholder="请输入分销-省级股东分成" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分销-股权分成" prop="distributionStockRights" >
              <a-input-number v-model="distributionform.distributionStockRights" placeholder="请输入分销-股权分成" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分销-股权分红分成" prop="distributionBonus" >
              <a-input-number v-model="distributionform.distributionBonus" placeholder="请输入分销-股权分红分成" :min="0" :step="0.01" :max="1" style="width: 80%;"/>
            </a-form-model-item>
          </a-form-model>
          <a-button type="primary" :loading="fourLoading" @click="submitForm(4)">
            保存
          </a-button>
        </a-tab-pane>
        <a-tab-pane key="5" tab="利润配置">
          <a-form-model ref="profitform" :model="profitform" :rules="profitrules">
            <a-form-model-item label="股权占比" prop="allocationOne" >
              <a-input-number v-model="profitform.allocationOne" :max="1" placeholder="请输入股权占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="股权分红占比" prop="allocationTwo" >
              <a-input-number v-model="profitform.allocationTwo" :max="1" placeholder="请输入股权分红占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="县级股东占比" prop="allocationThree" >
              <a-input-number v-model="profitform.allocationThree" :max="1" placeholder="请输入县级股东占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="市级股东占比" prop="allocationFour" >
              <a-input-number v-model="profitform.allocationFour" :max="1" placeholder="请输入市级股东占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="省级股东占比" prop="allocationFive" >
              <a-input-number v-model="profitform.allocationFive" :max="1" placeholder="请输入省级股东占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分红天数" prop="bonusDays" >
              <a-input-number v-model="profitform.bonusDays" placeholder="请输入分红天数" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="省市区分红时间间隔（天）" prop="day" >
              <a-input-number v-model="profitform.day" placeholder="省市区分红时间间隔（天）" :min="1" :step="1" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="上次省市区股分红日期" prop="lastDate" >
              {{profitform.lastDate}}
            </a-form-model-item>
            <a-form-model-item label="每日能量分红上限" prop="bonusLimit" >
              <a-input-number v-model="profitform.bonusLimit" placeholder="请输入每日能量分红上限" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分红现金占比" prop="bonusMoneyRatio" >
              <a-input-number v-model="profitform.bonusMoneyRatio" :max="1" placeholder="请输入分红现金占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分红嗨宝占比" prop="bonusGemRatio" >
              <a-input-number v-model="profitform.bonusGemRatio" :max="1" placeholder="请输入分红嗨宝占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="分红嗨币占比" prop="bonusGoldRatio" >
              <a-input-number v-model="profitform.bonusGoldRatio" :max="1" placeholder="请输入分红嗨币占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="县级平级奖" prop="countyBonus" >
              <a-input-number v-model="profitform.countyBonus" :max="1" placeholder="请输入县级平级奖占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="市级平级奖" prop="cityBonus" >
              <a-input-number v-model="profitform.cityBonus" :max="1" placeholder="请输入市级平级奖占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
            <a-form-model-item label="省级平级奖" prop="provinceBonus" >
              <a-input-number v-model="profitform.provinceBonus" :max="1" placeholder="请输入省级平级奖占比" :min="0" :step="0.01" style="width: 80%;"/>
            </a-form-model-item>
          </a-form-model>
          <a-button type="primary" :loading="fourLoading" @click="submitForm(5)">
            保存
          </a-button>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </page-header-wrapper>

</template>

<script>
import {
  getConfigByOrder,
  getConfigByDistribution,
  getConfigByProfit,
  getConfigByAccount,
  getConfigByPrice,
  editConfigByProfit,
  editConfigByOrder,
  editConfigByPrice,
  editConfigByAccount,
  editConfigByDistribution
} from "@/api/config/config";

export default {
  name: "platformIndex",
  data() {
    return {


      form:{
        noRefund: 0, //确认收货后多少天后无法退款、也是冻结金额恢复的时间（单位：天）
        affirmDate: 0,//自动确认收货时间
      },
      rules: {
        noRefund: { required: true, message: '天数不能为空' },
        affirmDate: { required: true, message: '天数不能为空' }
      },
      oneLoading: false,
      threeLoading: false,
      twoLoading: false,
      fourLoading: false,
      priceform: {
        orderRatio: 0,//嗨豆订单抽成比例
        stockRightsLimit:0,
        goldRatioZero: 0,//1嗨币=#人民币/嗨币价值
        gemRatioZero: 0,//1嗨宝=#人民币/嗨宝价值
        gemRatio: 0,//1人民币=#嗨宝/嗨宝兑换比例
        goldRatio: 0,//1人民币=#嗨币/嗨币兑换比例
        gemRatioIos: 0,//1人民币=#嗨宝/嗨宝兑换ios
        goldRatioIos: 0,//1人民币=#嗨币/嗨币兑换ios
        donationGem: 0,//嗨宝转赠手续费
        friendsterIncomeRatio: 0,//交友达人收益比例
        friendsterIncomeRatioTwo: 0,//交友达人直销比例
        likeWatchSendNum: 0,//观看者点赞消费数量（嗨币）
        likeWatchReapNum: 0,//观看者点赞收入数量（嗨豆）
        commentWatchSendNum: 0,//观看者评论消费数量（嗨币）
        commentWatchReapNum: 0,//观看者评论收入数量（嗨豆）
        videoRatio: 0,//短视频收益比例
        anchorLimit:0,//
      },
      pricerules: {
        orderRatio: { required: true, message: '嗨豆订单抽成比例不能为空' },
        stockRightsLimit: {required: true, message: '股权分红上限不能为空' },
        goldRatioZero: { required: true, message: '嗨币价值不能为空' },
        gemRatioZero: { required: true, message: '嗨宝价值不能为空' },
        gemRatio: { required: true, message: '嗨宝兑换比例不能为空' },
        goldRatio: { required: true, message: '嗨币兑换比例不能为空' },
        gemRatioIos: { required: true, message: '嗨宝兑换ios不能为空' },
        goldRatioIos: { required: true, message: '嗨币兑换ios不能为空' },
        donationGem: { required: true, message: '嗨宝转赠手续费不能为空' },
        friendsterIncomeRatio: { required: true, message: '交友达人收益比例不能为空' },
        friendsterIncomeRatioTwo: { required: true, message: '交友达人收益比例不能为空' },
        likeWatchSendNum: { required: true, message: '观看者点赞消费数量（嗨币）不能为空' },
        likeWatchReapNum: { required: true, message: '观看者点赞收入数量（嗨豆）不能为空' },
        commentWatchSendNum: { required: true, message: '观看者评论消费数量（嗨币）不能为空' },
        commentWatchReapNum: { required: true, message: '观看者评论收入数量（嗨豆）不能为空' },
        videoRatio: { required: true, message: '短视频收益比例不能为空' },
        anchorLimit: { required: true, message: '主播申请限制比例不能为空' },
      },
      accountform: {
        bankNo: '',//银行账号
        bankName: '',//银行开户名
        bankAddress: '',//开户行所在地
        openBank: '',//开户行
        remitMoney: 0,//打款金额
      },
      accountrules: {
        bankNo: { required: true, message: '银行账号不能为空' },//银行账号
        bankName: { required: true, message: '银行开户名不能为空' },//银行开户名
        bankAddress: { required: true, message: '开户行所在地不能为空' },//开户行所在地
        openBank: { required: true, message: '开户行不能为空' },//开户行
        remitMoney: { required: true, message: '打款金额不能为空' },//打款金额
      },
      distributionform: {
        oneCash: 0,//直销-现金奖
        oneGem: 0,//直销-嗨宝-嗨币
        oneBonus: 0,//直销-分红
        twoBonus: 0,//间销-分红
        twoGem: 0,//间销-嗨宝-嗨豆
        oneGold: 0,
        twoGold: 0,
        distributionCounty: 0,
        distributionCity: 0,
        distributionProvince: 0,
        distributionStockRights: 0,
        distributionBonus: 0
      },
      distributionrules: {
        oneCash: { required: true, message: '直销-现金奖不能为空' },//直销-现金奖
        oneGem: { required: true, message: '直销-嗨宝不能为空' },//直销-嗨宝-嗨币
        oneBonus: { required: true, message: '直销-日分红不能为空' },//直销-分红
        twoBonus: { required: true, message: '间销-日分红不能为空' },//间销-分红
        twoGem: { required: true, message: '间销-嗨宝不能为空' },//间销-嗨宝-嗨豆
        oneGold: { required: true, message: '直销-嗨币不能为空' },
        twoGold: { required: true, message: '间销-嗨币不能为空' },
        distributionCounty: { required: true, message: '分销-县级股东分成不能为空' },
        distributionCity: { required: true, message: '分销-市级股东分成不能为空' },
        distributionProvince: { required: true, message: '分销-省级股东分成不能为空' },
        distributionStockRights: { required: true, message: '分销-股权分成不能为空' },
        distributionBonus: { required: true, message: '分销-股权分红分成不能为空' }
      },
      profitform: {
        bonusDays: 0,//分红天数
        allocationOne: 0,//股权占比
        allocationTwo: 0,//股权分红占比
        allocationThree: 0,//县级股东占比
        allocationFour: 0,//市级股东占比
        allocationFive: 0,//省级股东占比
        anchorRatio: 0,//娱乐主播分成
        bonusLimit: 0,//分红上限
        bonusMoneyRatio: 0,//分红现金占比
        bonusGemRatio: 0,//分红嗨宝占比
        bonusGoldRatio: 0,//分红嗨币占比
        day: 0,
        lastDate: '',
        countyBonus: 0,
        cityBonus: 0,
        provinceBonus: 0,
      },
      profitrules: {
        bonusDays: { required: true, message: '分红天数不能为空' },//分红天数
        allocationOne:  { required: true, message: '股权占比不能为空' },//股权占比
        allocationTwo:  { required: true, message: '股权分红占比不能为空' },//股权分红占比
        allocationThree:  { required: true, message: '县级股东占比不能为空' },//县级股东占比
        allocationFour:  { required: true, message: '市级股东占比不能为空' },//市级股东占比
        allocationFive: { required: true, message: '省级股东占比不能为空' },//省级股东占比
        bonusLimit: { required: true, message: '每日能量分红上限不能为空' },//分红上限
        bonusMoneyRatio: { required: true, message: '分红现金占比不能为空' },//分红现金占比
        bonusGemRatio:  { required: true, message: '分红嗨宝占比不能为空' },//分红嗨宝占比
        bonusGoldRatio:  { required: true, message: '分红嗨币占比不能为空' },//分红嗨币占比
        day:  { required: true, message: '省市区分红时间间隔（天）不能为空' },
        lastDate:  { required: true, message: '上次省市区股分红日期占比不能为空' },
        countyBonus:  { required: true, message: '县级平级奖占比不能为空' },
        cityBonus:  { required: true, message: '市级平级奖占比不能为空' },
        provinceBonus:  { required: true, message: '省级平级奖占比不能为空' },
      }
    }
  },
  created() {
    this.getConfigByOrder();
    this.getConfigByPrice();
    this.getConfigByAccount();
    this.getConfigByDistribution();
    this.getConfigByProfit();
  },
  methods: {
    addNumIsQualified(num) {
      if(num == 1) {
        let num = 0;
        let allocationOne = parseFloat(this.distributionform.allocationOne);
        let allocationTwo = parseFloat(this.distributionform.allocationTwo);
        let allocationThree = parseFloat(this.distributionform.allocationThree);
        let allocationFour = parseFloat(this.distributionform.allocationFour);
        let allocationFive = parseFloat(this.distributionform.allocationFive);
        let resultNum =  num + allocationOne + allocationTwo + allocationThree + allocationFour + allocationFive;
        let result = parseFloat(resultNum);
        if(result <= 1) {
          return false
        } else {
          return true
        }
      }
    },
    getConfigByOrder() {
      getConfigByOrder().then(res => {
        if(res.success) {
          this.form = res.data;
        }
      })
    },
    getConfigByPrice() {
      getConfigByPrice().then(res => {
        if(res.success) {
          this.priceform = res.data;
        }
      })
    },
    getConfigByAccount() {
      getConfigByAccount().then(res => {
        if(res.success) {
          this.accountform = res.data;
        }
      })
    },
    getConfigByDistribution() {
      getConfigByDistribution().then(res => {
        if (res.success) {
          this.distributionform = res.data;
        }
      })
    },
    getConfigByProfit() {
      getConfigByProfit().then(res => {
        if (res.success) {
          this.profitform = res.data;
        }
      })
    },
    submitForm(num) {
      if(num == 1) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.oneLoading = true
            editConfigByOrder(this.form).then(res => {
              this.getConfigByOrder();
              this.oneLoading = false
            })
          } else {
            return false
          }
        })
      }

      if(num == 2) {
        this.$refs.priceform.validate(valid => {
          if (valid) {
            this.twoLoading = true
            editConfigByPrice(this.priceform).then(res => {
              this.getConfigByPrice();
              this.twoLoading = false

            })
          } else {
            return false
          }
        })
      }

      if(num == 3) {
        this.$refs.accountform.validate(valid => {
          if (valid) {
            this.threeLoading = true
            editConfigByAccount(this.accountform).then(res => {
              this.getConfigByAccount();
              this.threeLoading = false

            })
          } else {
            return false
          }
        })
      }

      if(num == 4) {
        // if(this.addNumIsQualified) {
        //   this.$message.warning("股权占比、股权分红占比、县级股东占比、市级股东占比、省级股东占比合起来的价格不能大于1")
        //   return;
        // }
        this.$refs.distributionform.validate(valid => {
          if (valid) {
            this.fourLoading = true
            editConfigByDistribution(this.distributionform).then(res => {
              this.getConfigByDistribution();
              this.fourLoading = false
            })
          } else {
            return false
          }
        })
      }

      if(num == 5) {
        // if(this.addNumIsQualified) {
        //   this.$message.warning("股权占比、股权分红占比、县级股东占比、市级股东占比、省级股东占比合起来的价格不能大于1")
        //   return;
        // }
        this.$refs.profitform.validate(valid => {
          if (valid) {
            this.fourLoading = true
            editConfigByProfit(this.profitform).then(res => {
              this.getConfigByProfit();
              this.fourLoading = false
            })
          } else {
            return false
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>