<template>
  <a-modal title="评论列表" :visible="visible" @cancel="visible = fasle" width="50%">
<!--    <page-header-wrapper>-->
      <a-card :bordered="false">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="12" :sm="24">
                <a-form-item label="昵称" prop="nickname">
                  <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="动态内容" prop="content">
                  <a-input v-model="queryParam.content" placeholder="请输入动态内容" allow-clear/>
                </a-form-item>
              </a-col>
              <template v-if="advanced">
<!--                <a-col :md="8" :sm="24">-->
<!--                  <a-form-item label="顶级评论id" prop="topId">-->
<!--                    <a-input v-model="queryParam.topId" placeholder="请输入顶级评论id" allow-clear/>-->
<!--                  </a-form-item>-->
<!--                </a-col>-->
<!--                <a-col :md="8" :sm="24">-->
<!--                  <a-form-item label="父级id，外键" prop="parentId">-->
<!--                    <a-input v-model="queryParam.parentId" placeholder="请输入父级id，外键" allow-clear/>-->
<!--                  </a-form-item>-->
<!--                </a-col>-->
<!--                <a-col :md="8" :sm="24">-->
<!--                  <a-form-item label="分类名称" prop="cateName">-->
<!--                    <a-input v-model="queryParam.cateName" placeholder="请输入分类名称" allow-clear/>-->
<!--                  </a-form-item>-->
<!--                </a-col>-->
              </template>

            </a-row>
            <a-row :gutter="48">
              <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a-button type="danger" :disabled="multiple" style="margin-left: 8px"  @click="handleDelete" v-hasPermi="['video:video/comment:remove']">
            <a-icon type="delete" />删除
          </a-button>
              </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <div class="table-operations">
<!--          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['video:video/comment:add']">-->
<!--            <a-icon type="plus" />新增-->
<!--          </a-button>-->
<!--          <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['video:video/comment:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a-button>-->

<!--          <a-button type="primary" @click="handleExport" v-hasPermi="['video:video/comment:export']">-->
<!--            <a-icon type="download" />导出-->
<!--          </a-button>-->
<!--          <table-setting-->
<!--            :style="{float: 'right'}"-->
<!--            :table-size.sync="tableSize"-->
<!--            v-model="columns"-->
<!--            :refresh-loading="loading"-->
<!--            @refresh="getList" />-->
        </div>
        <!-- 增加修改 -->
        <!--      <create-form-->
        <!--        ref="createForm"-->
        <!--        @ok="getList"-->
        <!--      />-->
        <!-- 数据展示 -->
        <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
          :bordered="tableBordered"
        >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
          <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['video:video/comment:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['video:video/comment:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['video:video/comment:remove']" />-->
          <a @click="handleDelete(record)" v-hasPermi="['video:video/comment:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </a-card>
<!--    </page-header-wrapper>-->
  </a-modal>


</template>

<script>
import { pageVideoComment,listVideoComment, delVideoComment } from '@/api/video/videoComment'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'VideoComment',
  components: {
    // CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      visible:false,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        // userId: null,
        // videoId: null,
        content: null,
        // topId: null,
        // parentId: null,
        // cateName: null,
        nickname:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '用户id，外键',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '标题',
        //   dataIndex: 'title',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '分类名称',
        //   dataIndex: 'cateName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '评论人',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '被回复人',
          dataIndex: 'topName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '评论内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '顶级评论id',
        //   dataIndex: 'topId',
        //   ellipsis: true,
        //   align: 'center'
        // },


        {
          title: '评论时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    open(rowData) {
      this.queryParam.videoId = rowData.id;
      this.getList();
      this.visible = true;

    },
    /** 查询短视频评论列表 */
    getList () {
      this.loading = true
      pageVideoComment(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam.nickname = null;
      this.queryParam.content = null;
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVideoComment(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('video/video/comment/export', {
            ...that.queryParam
          }, `短视频评论_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
/deep/ .ant-card-body {
  padding: 0!important;
}
</style>
