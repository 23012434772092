<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @cancel="onClose" cancelText="关闭" @ok="submitForm">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="驳回原因" prop="auditOpinion" >
        <a-input v-model="form.auditOpinion" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
// import Editor from '@/components/Editor'
import { updateApply } from "@/api/live/apply";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    // Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        targetUserId: null,

        dataType: null,

        dataId: null,

        reportType: null,

        reportImg: null,

        reportContent: null,

        auditStatus: 0,

        auditOpinion: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // userId: [
        //   { required: true, message: '举报人用户id, 外键{zb_user.id}不能为空', trigger: 'blur' }
        // ],
        // targetUserId: [
        //   { required: true, message: '被举报用户id, 外键{zb_user.id}不能为空', trigger: 'blur' }
        // ],
        // dataType: [
        //   { required: true, message: '数据类型, 1-举报用户, 2-举报动态, 3-举报直播, 4-举报短视频不能为空', trigger: 'change' }
        // ],
        // dataId: [
        //   { required: true, message: '数据id, 以 data_type 为准, 1-用户id, 2-动态id, 3-直播id, 4-短视频id不能为空', trigger: 'blur' }
        // ],
        // reportType: [
        //   { required: true, message: '举报类型, 外键{zb_report_type.id}不能为空', trigger: 'change' }
        // ],
        // auditStatus: [
        //   { required: true, message: '审核状态, 0-未审核, 1-审核成功, 2-审核失败, 默认-0不能为空', trigger: 'blur' }
        // ],
        auditOpinion: [
          { required: true, message: '拒绝原因不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        userId: null,
        targetUserId: null,
        dataType: null,
        dataId: null,
        reportType: null,
        reportImg: null,
        reportContent: null,
        auditStatus: 0,
        auditOpinion: null
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.formTitle = '审核原因'
      this.form.id = id;
      this.open = true

      // getReport({"id":id}).then(response => {
      //   this.form = response.data
      //   this.open = true
      //   this.formTitle = '修改'
      // })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log('进来了')
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            debugger
            let formData = {
              id: this.form.id ,
              auditOpinion: this.form.auditOpinion ,
              status:3
            }
            updateApply(formData).then(response => {
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // addReport(this.form).then(response => {
            //   this.$message.success(
            //     '新增成功',
            //     3
            //   )
            //   this.open = false
            //   this.$emit('ok')
            // }).finally(() => {
            //   this.submitLoading = false
            // })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
