<template>
  <div>
    <crud :config="config" @comment="comment"></crud>
<!--    <zb-dynamic-comment-list ref="commentList"></zb-dynamic-comment-list>-->
    <VideoCommentList ref="commentList"></VideoCommentList>
  </div>
</template>
<script>
import Crud from "@/components/crud/Crud.vue";
import ZbDynamicCommentList from "./ZbDynamicCommentList.vue";
import VideoCommentList from "./VideoCommentList.vue";
export default {
  components: {
    Crud,
    // ZbDynamicCommentList,
    VideoCommentList
  },
  data() {
    return {
      config: {
        button:[{name:'删除' ,auth:'',size:'',color:'danger',icon:'delete',event:'inner-delete'}],
        search: [
          { field: 'nickname', name: '昵称' },
          { field: 'mobile', name: '手机号' },
          { field: 'content', name: '动态内容' },
          // {
          //   field: 'type', name: '类型', type: 'select', option: [
          //     { id: 0, name: '纯文字' },
          //     { id: 1, name: '图文' },
          //     { id: 2, name: '视文' }
          //   ]
          // },
          // {
          //   field: 'auditStatus', name: '审核状态', type: 'select', option: [
          //     { id: 0, name: '未审核' },
          //     { id: 1, name: '已审核' },
          //     { id: 2, name: '审核失败' }
          //   ]
          // },
        ],
        table: {
          api: '/video/video-video/pageList',
          deleteApi: '/video/video-video/delete',
          col: [
            { field: 'avatar', name: '头像', type: 'avatar' },
            { field: 'nickname', name: '昵称' },
            { field: 'mobile', name: '手机号' },

            // {
            //   field: 'type', name: '类型', type: 'tag', width: 50, tags: [
            //     { name: '纯文字' },
            //     { name: '图文', color: 'blue' },
            //     { name: '视文', color: 'green' }
            //   ]
            // },
            { field: 'title', name: '标题' },
            { field: 'content', name: '动态内容' },

            { field: 'coverUrl', name: '图片', type: 'imgs' },
            { field: 'videoUrl', name: '视频', type: 'video' },

            // { field: 'positionName', name: '定位' },
            // {
            //   field: 'auditStatus', name: '审核状态', type: 'tag', tags: [
            //     { name: '未审核' },
            //     { name: '已审核', color: 'green' },
            //     { name: '审核失败', color: 'red' }
            //   ]
            // },
            { field: 'commentNum', name: '评论数' },
            { field: 'likeNum', name: '点赞数' },
            { field: 'createTime', name: '发布时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '查看评论', event: 'comment' },
                // { name: '审核', event: 'inner-edit' },
                { name: '删除', event: 'inner-delete' }
              ]
            }
          ]
        },
        form: {
          editApi: '/video/video-video/edit',
          item: [
            {
              field: 'auditStatus', name: '审核状态', type: 'select', option: [
                { id: 0, name: '未审核' },
                { id: 1, name: '已审核' },
                { id: 2, name: '审核失败' }
              ]
            },
          ]
        }
      }
    }
  },
  methods: {
    audit(button, rowData) {
      console.log(rowData);
    },
    comment(button, rowData) {
      this.$refs.commentList.open(rowData);
    },
  }
}
</script>