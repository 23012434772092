<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="评价等级" prop="explainType">-->
<!--                <a-input v-model="queryParam.explainType" placeholder="请输入评价等级" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->

<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="会员" prop="explainType">-->
<!--                <a-input v-model="queryParam.explainType" placeholder="请输入会员等级" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->

            <a-col :md="8" :sm="24">
              <a-form-item label="商品名称" prop="goodsName">
                <a-input v-model="queryParam.goodsName" placeholder="请输入商品名称" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="评价内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入评价内容" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:goods/evaluate:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:goods/evaluate:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:goods/evaluate:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:goods/evaluate:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      > <span  slot="isDeleted" slot-scope="text, record">
          <a-switch @click="updateGoodsState(record,5)" v-model="record.isDeleted == 1">
             <a-icon type="check" slot="checkedChildren"/>
             <a-icon type="close" slot="unCheckedChildren"/>
           </a-switch>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="goodsInfo" slot-scope="text, record">
            <div>
          <div style="float: left;">
            <img style="width: 80px;height: 80px;"
                 :src="record.goodsImage"
                 v-image-preview
                 preview="商品主图"
            />
          </div>
          <div style="float: left;margin-left: 10px;">
            <div class="ellipsis" style="width: 170px;margin-bottom: 15px;text-align: left;">{{record.goodsName}}</div>
            <div class="ellipsis" style="width: 170px;text-align: left;">{{record.skuName}}</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        </span>
        <span slot="cccc" slot-scope="text, record">
          {{record.content?record.content:'暂无评价内容'}}
          <br/>
          <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.image"
               v-for="(img,imgIndex) in record.image.split(',')"
               v-image-preview
               :src="img"
               preview="评价图片"
          />
          <br/>
          <p v-if="record.createTime!=''" style="color: #9d9d9d;margin-top: 5px;">[{{record.createTime}}]</p>
        </span>
        <span slot="grade" slot-scope="text, record">
          商品评价
          <span>
            <a-rate disabled v-model='record.evaluateScores'/>
            <span class="ant-rate-text">{{record.evaluateScores}} 分</span>
          </span>
          <br>
          物流服务
          <span>
            <a-rate disabled v-model='record.qualityScores'/>
            <span class="ant-rate-text">{{record.qualityScores}} 分</span>
          </span>
          <br>
          服务评分
          <span>
            <a-rate disabled v-model='record.serviceScores'/>
            <span class="ant-rate-text">{{record.serviceScores}} 分</span>
          </span>
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['shop:goods/evaluate:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:goods/evaluate:edit']">-->
<!--            <a-icon type="edit" />-->
<!--          </a>-->

<!--            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.isShow == 1">-->
<!--           禁用-->
<!--          </a>-->
<!--            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-else>-->
<!--           启用-->
<!--          </a>-->
          <a  @click="reply(record)" v-hasPermi="['shop:goods/evaluate:reply']" v-if="!record.replyContent && shopInfo && shopInfo.id">回复</a>
          <a-divider type="vertical"  v-hasPermi="['shop:goods/evaluate:reply']"  v-if="!record.replyContent  && shopInfo && shopInfo.id" />
          <a @click="handleDelete(record)" v-hasPermi="['shop:goods/evaluate:remove']">
<!--            <a-icon type="delete" />-->
            删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <a-modal v-model="visible" title="回复" @ok="handleOk">
        <a-textarea maxLength="64" v-model="shopReply" :auto-size="{ minRows: 3, maxRows: 5 }"/>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageGoodsEvaluate, listGoodsEvaluate, delGoodsEvaluate, shopReply} from '@/api/shop/goodsEvaluate'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'GoodsEvaluate',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      visible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      shopReply: '',
      evaluateId: '',
      // 查询参数
      queryParam: {
        orderId: null,
        orderNo: null,
        orderGoodsId: null,
        goodsId: null,
        goodsCode: null,
        goodsName: null,
        goodsPrice: null,
        goodsImage: null,
        skuName: null,
        shopId: null,
        shopName: null,
        goodsType: null,
        content: null,
        image: null,
        explainFirst: null,
        memberName: null,
        number: null,
        userId: null,
        avatar: null,
        isAnonymous: null,
        evaluateScores: null,
        qualityScores: null,
        serviceScores: null,
        againContent: null,
        againImage: null,
        againExplain: null,
        explainType: null,
        isShow: null,
        againAddtime: null,
        videoUrl: null,
        type: null,
        coverImage: null,
        faceTo: null,
        pushTop: null,
        inventEvaluate: null,
        replyContent: null,
        replyTime: null,
        replyFlag: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '会员昵称',
        //   dataIndex: 'memberName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '手机号',
        //   dataIndex: 'mobile',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '评价人昵称',
          dataIndex: 'memberName',
          align: 'center',
          width: 100
        },
        {
          title: '评价内容',
          dataIndex: 'content',
          align: 'center',
          width: 280,
          scopedSlots: {customRender: 'cccc'}
        },
        {
          title: '评价分',
          align: "center",
          dataIndex: 'evaluateScores',
          scopedSlots: {customRender: 'grade'},
          width: 250
        },
        {
          title: '商家回复信息',
          align: "left",
          dataIndex: 'replyContent',
          width: 280,
          customRender: function (v) {
            if (!v) {
              return "-";
            }
            return v;
          }
        },
        {
          title: '商品信息',
          align: "center",
          dataIndex: 'goodsName',
          width: 280,
          scopedSlots: {customRender: 'goodsInfo'}
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  watch: {
  },
  methods: {
    reply(record) {
      this.shopReply = record.shopReply;
      this.evaluateId = record.id;
      this.visible = true;
    },
    handleOk() {
      var that = this;
      if (!that.shopReply) {
        that.$message.error("请填写回复内容");
        return;
      }
      shopReply({id: that.evaluateId, msg: that.shopReply}).then( res => {
        if (res.success) {
          this.visible = false;
          that.$message.success("操作成功");
          that.getList();
        } else {
          that.$message.error(res.message);
        }
      })

    },
    /** 查询商城-商品评价列表 */
    getList () {
      this.loading = true
     pageGoodsEvaluate(this.queryParam).then(response => {

       this.loading = false
       if(response.success) {

         this.list = response.data.records;
         this.total = response.data.total
       }

      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        orderId: undefined,
        orderNo: undefined,
        orderGoodsId: undefined,
        goodsId: undefined,
        goodsCode: undefined,
        goodsName: undefined,
        goodsPrice: undefined,
        goodsImage: undefined,
        skuName: undefined,
        shopId: undefined,
        shopName: undefined,
        goodsType: undefined,
        content: undefined,
        image: undefined,
        explainFirst: undefined,
        memberName: undefined,
        number: undefined,
        userId: undefined,
        avatar: undefined,
        isAnonymous: undefined,
        evaluateScores: undefined,
        qualityScores: undefined,
        serviceScores: undefined,
        againContent: undefined,
        againImage: undefined,
        againExplain: undefined,
        explainType: undefined,
        isShow: undefined,
        againAddtime: undefined,
        videoUrl: undefined,
        type: undefined,
        coverImage: undefined,
        faceTo: undefined,
        pushTop: undefined,
        inventEvaluate: undefined,
        replyContent: undefined,
        replyTime: undefined,
        replyFlag: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGoodsEvaluate(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('shop/goods/evaluate/export', {
            ...that.queryParam
          }, `商城-商品评价_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
