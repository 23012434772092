import request from '@/utils/request'


// 查询主播申请列表
export function listApply(query) {
  return request({
    url: '/live/live-apply/list',
    method: 'get',
    params: query
  })
}

// 查询主播申请分页
export function pageApply(query) {
  return request({
    url: '/live/live-apply/page',
    method: 'get',
    params: query
  })
}

// 查询主播申请详细
export function getApply(data) {
  return request({
    url: '/live/live-apply/detail',
    method: 'get',
    params: data
  })
}

// 新增主播申请
export function addApply(data) {
  return request({
    url: '/live/live-apply/add',
    method: 'post',
    data: data
  })
}

// 修改主播申请
export function updateApply(data) {
  return request({
    url: '/live/live-apply/editAudit',
    method: 'post',
    data: data
  })
}

// 删除主播申请
export function delApply(data) {
  return request({
    url: '/live/live-apply/delete',
    method: 'post',
    data: data
  })
}
