import request from '@/utils/request'


// 查询创业身份列表
export function listRank(query) {
  return request({
    url: '/distribution/distribution-rank/list',
    method: 'get',
    params: query
  })
}

// 查询创业身份分页
export function pageRank(query) {
  return request({
    url: '/distribution/distribution-rank/page',
    method: 'get',
    params: query
  })
}

// 查询创业身份详细
export function getRank(data) {
  return request({
    url: '/distribution/distribution-rank/detail',
    method: 'get',
    params: data
  })
}

// 新增创业身份
export function addRank(data) {
  return request({
    url: '/distribution/distribution-rank/add',
    method: 'post',
    data: data
  })
}

// 修改创业身份
export function updateRank(data) {
  return request({
    url: '/distribution/distribution-rank/edit',
    method: 'post',
    data: data
  })
}

// 删除创业身份
export function delRank(data) {
  return request({
    url: '/distribution/distribution-rank/delete',
    method: 'post',
    data: data
  })
}
