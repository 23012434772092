import request from '@/utils/request'


// 查询商城-物流公司列表
export function listDelivery(query) {
  return request({
    url: '/shop/shop-express/list',
    method: 'get',
    params: query
  })
}

// 查询商城-物流公司分页
export function pageDelivery(query) {
  return request({
    url: '/shop/shop-express/page',
    method: 'get',
    params: query
  })
}

// 查询商城-物流公司详细
export function getDelivery(data) {
  return request({
    url: '/shop/shop-express/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-物流公司
export function addDelivery(data) {
  return request({
    url: '/shop/shop-express/add',
    method: 'post',
    data: data
  })
}

// 修改商城-物流公司
export function updateDelivery(data) {
  return request({
    url: '/shop/shop-express//edit',
    method: 'post',
    data: data
  })
}

// 删除商城-物流公司
export function delDelivery(data) {
  return request({
    url: '/shop/shop-express/delete',
    method: 'post',
    data: data
  })
}

