<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="店铺id 申请成功之前为0" prop="shopId" >
        <a-input v-model="form.shopId" placeholder="请输入店铺id 申请成功之前为0" />
      </a-form-model-item>
      <a-form-model-item label="申请类型1.平台直营 2.商家自营" prop="applyType" >
      </a-form-model-item>
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="联系人姓名" prop="contactsName" >
        <a-input v-model="form.contactsName" placeholder="请输入联系人姓名" />
      </a-form-model-item>
      <a-form-model-item label="手机号区号" prop="areaCode" >
        <a-input v-model="form.areaCode" placeholder="请输入手机号区号" />
      </a-form-model-item>
      <a-form-model-item label="联系人电话" prop="contactsPhone" >
        <a-input v-model="form.contactsPhone" placeholder="请输入联系人电话" />
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName" >
        <a-input v-model="form.shopName" placeholder="请输入店铺名称" />
      </a-form-model-item>
      <a-form-model-item label="申请状态 1-申请中 2-审核失败 3-待支付 4-待财务审核 5-未收到打款 6-开店成功" prop="applyState" >
        <a-input v-model="form.applyState" placeholder="请输入申请状态 1-申请中 2-审核失败 3-待支付 4-待财务审核 5-未收到打款 6-开店成功" />
      </a-form-model-item>
      <a-form-model-item label="管理员审核信息" prop="applyMessage" >
        <a-input v-model="form.applyMessage" placeholder="请输入管理员审核信息" />
      </a-form-model-item>
      <a-form-model-item label="公司名称" prop="companyName" >
        <a-input v-model="form.companyName" placeholder="请输入公司名称" />
      </a-form-model-item>
      <a-form-model-item label="公司所在省" prop="companyAreap" >
        <a-input v-model="form.companyAreap" placeholder="请输入公司所在省" />
      </a-form-model-item>
      <a-form-model-item label="公司所在市" prop="companyAreac" >
        <a-input v-model="form.companyAreac" placeholder="请输入公司所在市" />
      </a-form-model-item>
      <a-form-model-item label="公司所在县" prop="companyAreax" >
        <a-input v-model="form.companyAreax" placeholder="请输入公司所在县" />
      </a-form-model-item>
      <a-form-model-item label="公司详细地址" prop="conpanyAddress" >
        <a-input v-model="form.conpanyAddress" placeholder="请输入公司详细地址" />
      </a-form-model-item>
      <a-form-model-item label="统一社会信用代码" prop="creditCode" >
        <a-input v-model="form.creditCode" placeholder="请输入统一社会信用代码" />
      </a-form-model-item>
      <a-form-model-item label="营业执照" prop="businessLicense" >
        <a-input v-model="form.businessLicense" placeholder="请输入营业执照" />
      </a-form-model-item>
      <a-form-model-item label="经营范围" prop="businessScope" >
        <a-input v-model="form.businessScope" placeholder="请输入经营范围" />
      </a-form-model-item>
      <a-form-model-item label="法人名称" prop="legalPersonName" >
        <a-input v-model="form.legalPersonName" placeholder="请输入法人名称" />
      </a-form-model-item>
      <a-form-model-item label="法人电话" prop="legalPersonPhone" >
        <a-input v-model="form.legalPersonPhone" placeholder="请输入法人电话" />
      </a-form-model-item>
      <a-form-model-item label="法人身份证" prop="legalPersonIdCard" >
        <a-input v-model="form.legalPersonIdCard" placeholder="请输入法人身份证" />
      </a-form-model-item>
      <a-form-model-item label="法人身份证正面" prop="legalPersonIdCardFront" >
        <a-input v-model="form.legalPersonIdCardFront" placeholder="请输入法人身份证正面" />
      </a-form-model-item>
      <a-form-model-item label="法人身份证反面" prop="legalPersonIdCardReverse" >
        <a-input v-model="form.legalPersonIdCardReverse" placeholder="请输入法人身份证反面" />
      </a-form-model-item>
      <a-form-model-item label="账户类型 1-借记卡 2-企业户 3-二类户 4-三类户" prop="bankType" >
      </a-form-model-item>
      <a-form-model-item label="真实姓名" prop="bankRealName" >
        <a-input v-model="form.bankRealName" placeholder="请输入真实姓名" />
      </a-form-model-item>
      <a-form-model-item label="结算账户" prop="bankNo" >
        <a-input v-model="form.bankNo" placeholder="请输入结算账户" />
      </a-form-model-item>
      <a-form-model-item label="账户预留手机号" prop="bankPhone" >
        <a-input v-model="form.bankPhone" placeholder="请输入账户预留手机号" />
      </a-form-model-item>
      <a-form-model-item label="开户名" prop="bankAccountName" >
        <a-input v-model="form.bankAccountName" placeholder="请输入开户名" />
      </a-form-model-item>
      <a-form-model-item label="开户行名称" prop="bankName" >
        <a-input v-model="form.bankName" placeholder="请输入开户行名称" />
      </a-form-model-item>
      <a-form-model-item label="开户行所在地" prop="bankAddress" >
        <a-input v-model="form.bankAddress" placeholder="请输入开户行所在地" />
      </a-form-model-item>
      <a-form-model-item label="店铺所在省份名称" prop="shopAreap" >
        <a-input v-model="form.shopAreap" placeholder="请输入店铺所在省份名称" />
      </a-form-model-item>
      <a-form-model-item label="店铺所在省份" prop="shopAreapId" >
        <a-input v-model="form.shopAreapId" placeholder="请输入店铺所在省份" />
      </a-form-model-item>
      <a-form-model-item label="店铺所在城市名称" prop="shopAreac" >
        <a-input v-model="form.shopAreac" placeholder="请输入店铺所在城市名称" />
      </a-form-model-item>
      <a-form-model-item label="店铺所在城市" prop="shopAreacId" >
        <a-input v-model="form.shopAreacId" placeholder="请输入店铺所在城市" />
      </a-form-model-item>
      <a-form-model-item label="店铺所在县" prop="shopAreax" >
        <a-input v-model="form.shopAreax" placeholder="请输入店铺所在县" />
      </a-form-model-item>
      <a-form-model-item label="店铺所在县" prop="shopAreaxId" >
        <a-input v-model="form.shopAreaxId" placeholder="请输入店铺所在县" />
      </a-form-model-item>
      <a-form-model-item label="店铺详细地址" prop="shopAddressDetail" >
        <a-input v-model="form.shopAddressDetail" placeholder="请输入店铺详细地址" />
      </a-form-model-item>
      <a-form-model-item label="店铺LOGO" prop="shopLogo" >
        <a-input v-model="form.shopLogo" placeholder="请输入店铺LOGO" />
      </a-form-model-item>
      <a-form-model-item label="店铺背景" prop="shopBackdrop" >
        <a-input v-model="form.shopBackdrop" placeholder="请输入店铺背景" />
      </a-form-model-item>
      <a-form-model-item label="店铺搜索词" prop="shopKeywords" >
        <a-input v-model="form.shopKeywords" placeholder="请输入店铺搜索词" />
      </a-form-model-item>
      <a-form-model-item label="行业id" prop="tradeId" >
        <a-input v-model="form.tradeId" placeholder="请输入行业id" />
      </a-form-model-item>
      <a-form-model-item label="所卖商品" prop="goodsName" >
        <a-input v-model="form.goodsName" placeholder="请输入所卖商品" />
      </a-form-model-item>
      <a-form-model-item label="入驻时间id" prop="enterId" >
        <a-input v-model="form.enterId" placeholder="请输入入驻时间id" />
      </a-form-model-item>
      <a-form-model-item label="店铺简介" prop="shopDescription" >
        <a-input v-model="form.shopDescription" placeholder="请输入店铺简介" />
      </a-form-model-item>
      <a-form-model-item label="客服电话" prop="kfPhone" >
        <a-input v-model="form.kfPhone" placeholder="请输入客服电话" />
      </a-form-model-item>
      <a-form-model-item label="开通费用" prop="openMoney" >
        <a-input v-model="form.openMoney" placeholder="请输入开通费用" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="note" >
        <a-input v-model="form.note" placeholder="请输入备注" />
      </a-form-model-item>
      <a-form-model-item label="经度" prop="lng" >
        <a-input v-model="form.lng" placeholder="请输入经度" />
      </a-form-model-item>
      <a-form-model-item label="纬度" prop="lat" >
        <a-input v-model="form.lat" placeholder="请输入纬度" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getShopApply, addShopApply, updateShopApply } from '@/api/shop/shopApply'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        shopId: null,

        applyType: null,

        userId: null,

        contactsName: null,

        areaCode: null,

        contactsPhone: null,

        shopName: null,

        applyState: null,

        applyMessage: null,

        companyName: null,

        companyAreap: null,

        companyAreac: null,

        companyAreax: null,

        conpanyAddress: null,

        creditCode: null,

        businessLicense: null,

        businessScope: null,

        legalPersonName: null,

        legalPersonPhone: null,

        legalPersonIdCard: null,

        legalPersonIdCardFront: null,

        legalPersonIdCardReverse: null,

        bankType: null,

        bankRealName: null,

        bankNo: null,

        bankPhone: null,

        bankAccountName: null,

        bankName: null,

        bankAddress: null,

        shopAreap: null,

        shopAreapId: null,

        shopAreac: null,

        shopAreacId: null,

        shopAreax: null,

        shopAreaxId: null,

        shopAddressDetail: null,

        shopLogo: null,

        shopBackdrop: null,

        shopKeywords: null,

        tradeId: null,

        goodsName: null,

        enterId: null,

        shopDescription: null,

        kfPhone: null,

        openMoney: null,

        note: null,

        lng: null,

        lat: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopId: [
          { required: true, message: '店铺id 申请成功之前为0不能为空', trigger: 'blur' }
        ],
        applyType: [
          { required: true, message: '申请类型1.平台直营 2.商家自营不能为空', trigger: 'change' }
        ],
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        contactsName: [
          { required: true, message: '联系人姓名不能为空', trigger: 'blur' }
        ],
        contactsPhone: [
          { required: true, message: '联系人电话不能为空', trigger: 'blur' }
        ],
        shopName: [
          { required: true, message: '店铺名称不能为空', trigger: 'blur' }
        ],
        applyState: [
          { required: true, message: '申请状态 1-申请中 2-审核失败 3-待支付 4-待财务审核 5-未收到打款 6-开店成功不能为空', trigger: 'blur' }
        ],
        applyMessage: [
          { required: true, message: '管理员审核信息不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        shopId: null,
        applyType: null,
        userId: null,
        contactsName: null,
        areaCode: null,
        contactsPhone: null,
        shopName: null,
        applyState: null,
        applyMessage: null,
        companyName: null,
        companyAreap: null,
        companyAreac: null,
        companyAreax: null,
        conpanyAddress: null,
        creditCode: null,
        businessLicense: null,
        businessScope: null,
        legalPersonName: null,
        legalPersonPhone: null,
        legalPersonIdCard: null,
        legalPersonIdCardFront: null,
        legalPersonIdCardReverse: null,
        bankType: null,
        bankRealName: null,
        bankNo: null,
        bankPhone: null,
        bankAccountName: null,
        bankName: null,
        bankAddress: null,
        shopAreap: null,
        shopAreapId: null,
        shopAreac: null,
        shopAreacId: null,
        shopAreax: null,
        shopAreaxId: null,
        shopAddressDetail: null,
        shopLogo: null,
        shopBackdrop: null,
        shopKeywords: null,
        tradeId: null,
        goodsName: null,
        enterId: null,
        shopDescription: null,
        kfPhone: null,
        openMoney: null,
        note: null,
        lng: null,
        lat: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopApply({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShopApply(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShopApply(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
