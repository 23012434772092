<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel">
    <a-card :bordered="false">
      <template v-if="record">
        <div class="div-bg bg-white">
          <!--物流跟踪-->
          <div style="padding-bottom: 0.5rem;">
            <div class="bg-white"
                 style="width: 92%; margin-left: 4%;margin: auto;padding-left: 15px;padding-right: 15px;">
              <div>
                <div class="track-rcol">
                  <div class="track-list">
                    <ul>
                      <div v-for="(item,index) in record" :key="index">
                        <li class="first" v-if="index===0">
                          <div></div>
                          <i class="node-icon"></i>
                          <span class="txt">{{ item.time }}</span>
                          <span class="time">{{ item.context }}</span>
                        </li>
                        <li v-if="index > 0 && index !== record.length-1">
                          <i class="node-icon"></i>
                          <span class="txt">{{ item.time }}</span>
                          <span class="time">{{ item.context }}</span>
                        </li>
                        <li v-if="index === record.length-1" class="finall">
                          <i class="div-spilander"></i>
                          <i class="node-icon"></i>
                          <span class="txt">{{ item.time }}</span>
                          <span class="time">{{ item.context }}</span>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div v-if="!record||record.length==0"
           style="width: 170px;margin: 0 auto;text-align: center;">
        <img src="@/assets/shipping.png" style="width: 170px;">
        <div style="color: rgba(164,164,164,0.84);">暂无物流信息</div>
      </div>
    </a-card>
  </a-modal>
</template>

<script>
// import {getAction} from '@/api/manage'

import {pageOrderShippingList} from "@/api/order/ordershipping";

export default {
  name: "OrderShippingInfoModal",

  data() {
    return {
      visible: false,
      title: '物流详情',
      url: {
        detail: "/order/order/detail"
      },
      record:{}
    }
  },
  methods: {
    info(id) {
      // getAction(this.url.detail, {id: id}).then((res) => {
      //   if (res.success) {
      //     this.record = res.result;
      //     this.visible = true;
      //   }
      // })
      pageOrderShippingList({
        id:id
      }).then(res => {
        if(res.code == 20001) {
          this.record = res.data;
          this.visible = true
        } else {
          this.$message.warning(res.msg)
        }
      })


    },
    handleCancel() {
      this.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}

.addressshow2 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.addressshow1 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}
</style>