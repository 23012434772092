import request from '@/utils/request'
const userApi = {
  add: '/shop/shop-goods/add',
  del: '/shop/shop-goods/delete',
  update: '/goods/update',
  editStatus: '/goods/update-status',
  edit: '/shop/shop-goods/edit',
  detail: '/shop/shop-goods/detail',
  list: '/shop/shop-goods/page',
  updateGoodsState: '/shop/shop-goods/updateGoodsState',
  auditGoods: '/shop/shop-goods/auditGoods'

}
export function detail (query) {
  return request({
    url: userApi.detail,
    method: 'get',
    params: query
  })
}
// 新增商品
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}

export function edit(data) {
  return request({
    url: userApi.edit,
    method: 'post',
    data: data
  })
}

export function auditGoods(data) {
  return request({
    url: userApi.auditGoods,
    method: 'post',
    data: data
  })
}

export function updateGoodsState (data) {
  return request({
    url: userApi.updateGoodsState,
    method: 'post',
    data: data
  })
}
// 删除商品
export function del (data) {
  return request({
    url: userApi.del,
    method: 'post',
    data: data
  })
}
// 查询商品列表
export function list (query) {
  return request({
    url: userApi.list,
    method: 'get',
    params: query
  })
}
// 修改商品
export function update (data) {
  return request({
    url: userApi.update,
    method: 'put',
    data: data
  })
}
// 改 状态 开启和关闭  微信
export function editStatus (data) {
  return request({
    url: userApi.editStatus,
    method: 'post',
    data: data
  })
}
