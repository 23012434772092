import request from '@/utils/request'


// 查询亲密度等级(24个等级)列表
export function listFriendIntimacyLevel(query) {
  return request({
    url: '/user/friend-intimacy-level/list',
    method: 'get',
    params: query
  })
}

// 查询亲密度等级(24个等级)分页
export function pageFriendIntimacyLevel(query) {
  return request({
    url: '/user/friend-intimacy-level/page',
    method: 'get',
    params: query
  })
}

// 查询亲密度等级(24个等级)详细
export function getFriendIntimacyLevel(data) {
  return request({
    url: '/user/friend-intimacy-level/detail',
    method: 'get',
    params: data
  })
}

// 新增亲密度等级(24个等级)
export function addFriendIntimacyLevel(data) {
  return request({
    url: '/user/friend-intimacy-level/add',
    method: 'post',
    data: data
  })
}

// 修改亲密度等级(24个等级)
export function updateFriendIntimacyLevel(data) {
  return request({
    url: '/user/friend-intimacy-level/edit',
    method: 'post',
    data: data
  })
}

// 删除亲密度等级(24个等级)
export function delFriendIntimacyLevel(data) {
  return request({
    url: '/user/friend-intimacy-level/delete',
    method: 'post',
    data: data
  })
}
