<template>
  <a-modal :title="formTitle" :width="800" :visible="open"
           @cancel="onClose" cancelText="关闭" @ok="submitForm">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="请选择物流公司" prop="expressId">
        <a-select showSearch v-model="form.expressId"
                  placeholder="请输入物流公司" style="width: 200px" :defaultActiveFirstOption="false" :showArrow="false"
                  :filterOption="false" @search="handleSearch" @change="handleChange" :notFoundContent="null">
          <a-select-option v-for="d in data" :key="d.value">{{ d.text }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="快递单号" prop="shippingNumber">
        <a-input v-model="form.shippingNumber" placeholder="请输入快递单号" allow-clear />

      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

import {listDelivery} from "@/api/shop/delivery";
import {deliverGoods} from "@/api/order/order";
import {getSearchKeywordList} from "@/api/express/express";

let timeout;
let currentValue;
function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    const keyword = value;
    if (keyword == undefined || keyword == '') {
      return;
    }

    getSearchKeywordList(keyword)
      .then(d => {
        if (currentValue === value) {
          const result = d.data;
          const data = result.map(i => ({
            value:i.id,
            text:i.name
          }));
          callback(data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}
export default {
  name: "ShipMentsModal",
  data() {
    return {
      data: [],
      formTitle: '备注',
      open: false,
      form: {
        id: null,
        shippingNumber: null,
        shippingCompanyCode: null,
        expressId:null,
      },
      rules: {
        shippingCompanyCode: [
          { required: true, message: '请选择物流公司', trigger: 'blur' }
        ],
        expressId: [
          { required: true, message: '请输入物流公司', trigger: 'blur' }
        ],
        shippingNumber: [
          { required: true, message: '请输入快递单号', trigger: 'blur' }

        ]
      }
    }
  },
  created() {
    // this.getlistDelivery();
  },
  methods: {
    // getlistDelivery() {
    //   listDelivery().then(res => {
    //     if(res.code == 20001) {
    //       this.data = res.data;
    //     } else {
    //       this.$message.info(res.msg)
    //     }
    //   })
    // },
    handleChange(value) {
      this.form.shippingCompanyCode = value;
      fetch(value, data => (this.data = data));
    },
    /*
    搜索
     */
    handleSearch(value) {
      fetch(value, data => (this.data = data));
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    reset() {

    },
    openModal(order) {
      this.form = order
      this.open = true
    },
    /**
     * ok
     */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          deliverGoods({
            id: this.form.id,
              shippingNumber: this.form.shippingNumber,
              expressId: this.form.expressId
          }).then(res => {
            if(res.code == 20001) {
              this.open = false
              this.$emit('ok')
            } else {
              this.$message.info(res.msg)
            }
          })

        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>