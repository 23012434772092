<template>
  <page-header-wrapper @back="back">
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <template v-slot:content>
    </template>
    <template v-slot:extraContent>
    </template>
    <freightTemplateModal ref="freightTemplateModal" @getList="getFreightTemplateList()"></freightTemplateModal>

    <div class="bg-white padding-lr">
      <a-tabs tab-position="top" v-model="tabIndex" @change="changeTopTabs">
        <a-tab-pane :key="1" tab="基础信息">
          <a-row :gutter="12">
            <a-col :span="24">
              <a-form-model ref="oneForm" :model="form" :rules="baseRules" :label-col="{ span: 2 }"
                            :wrapper-col="{ span: 10 }"
                            class="padding-lr">

                <a-form-model-item label="商品分类" prop="categoryId" class="margin-bottom-10">
                  <a-tree-select v-model="form.categoryId" style="width: 100%"
                                 :replaceFields="replaceFields"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                 showCheckedStrategy="SHOW_ALL"
                                 :tree-data="goodsCategoryList" placeholder="请选择商品分类" @change="changeCategorySelect" tree-default-expand-all>
                  </a-tree-select>
                </a-form-model-item>
                <a-form-model-item label="商品店铺分类" prop="shopCategoryId" class="margin-bottom-10">
                  <a-tree-select v-model="form.shopCategoryId" style="width: 100%"
                                 :replaceFields="replaceShopCategoryFields"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                 :tree-data="shopCategoryList" placeholder="请选择商品店铺分类" :treeIcon="false">
                  </a-tree-select>
                </a-form-model-item>
                <a-form-model-item label="商品名称" prop="goodsName" class="margin-bottom-10">
                  <a-input v-model="form.goodsName" placeholder="请输入"/>
                </a-form-model-item>
                <a-form-model-item label="商品简介" prop="introduction" class="margin-bottom-10">
                  <a-input v-model="form.introduction" placeholder="请输入"/>
                </a-form-model-item>
                <a-form-model-item label="商品关键字" prop="keywords" class="margin-bottom-10">
                  <a-input v-model="form.keywords" placeholder="请输入"/>
                </a-form-model-item>

                <a-form-model-item label="品牌" prop="brandId" class="margin-bottom-10">
                  <a-tree-select v-model="form.brandId" style="width: 100%"
                                 :replaceFields="replaceBrandFields"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                 :tree-data="brandList" placeholder="请选择品牌" tree-default-expand-all>
                  </a-tree-select>
                </a-form-model-item>


                <a-form-model-item label="商品主图" prop="picture" class="margin-bottom-0">
                  <a-upload :customRequest='fileUploadRequest' :showUploadList="false" list-type="picture-card"
                            :beforeUpload="beforeUpload" @change="handleChange">
                    <img v-if="form.picture" :src="form.picture" alt="主图"
                         style="height:173px;width:173px"/>
                    <div v-else>
                      <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                  <!--                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">-->
                  <!--                    <img alt="example" style="width: 100%" :src="previewImage"/>-->
                  <!--                  </a-modal>-->
                  <span style="color:#ff0000">图片限制小于5M,推荐规格:346*346  </span>

                </a-form-model-item>
                <a-form-model-item label="商品图册" prop="imgIdArray" class="margin-bottom-0">
                  <a-upload :customRequest='fileUploadRequest' list-type="picture-card" :file-list="form.imgIdArray"
                            @preview="handlePreview"
                            @change="uploadImages">
                    <div v-if="form.imgIdArray.length < 9">
                      <a-icon type="plus"/>
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                  <span style="color:#ff0000">图片限制小于5M,推荐规格:750*750 </span>

                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage"/>
                  </a-modal>
                </a-form-model-item>

                <a-form-model-item label="商品划价" prop="price" class="margin-bottom-10">
                  <a-input style="width: 150px;" :min="0" :step="1"  addonAfter="元" v-model="form.price"
                           :disabled="auditPage" type="number" />
                </a-form-model-item>

                <!--         嗨豆商场选择 start       -->
<!--                <a-form-model-item label="商品类型" prop="promotionType" class="margin-bottom-10"-->
<!--                                   v-if="shopInfo && shopInfo.shopType == '0'">-->
<!--                  <a-radio-group v-model="form.promotionType" :disabled="auditPage" button-style="solid"-->
<!--                                 @change="changeVideo">-->
<!--                    <a-radio-button :value="1">-->
<!--                      纯现金-->
<!--                    </a-radio-button>-->
<!--                    <a-radio-button :value="2">-->
<!--                      纯嗨分-->
<!--                    </a-radio-button>-->
<!--                    <a-radio-button :value="3">-->
<!--                      现金+嗨分-->
<!--                    </a-radio-button>-->

<!--                  </a-radio-group>-->
<!--                </a-form-model-item>-->
                <a-form-model-item label="商品零售价" v-if="shopInfo && shopInfo.shopType == '0'"
                                   prop="promotionPrice" class="margin-bottom-10">
                  <a-input style="width: 150px;" :min="0" :step="1" addonAfter="元" v-model="form.promotionPrice"
                           :disabled="auditPage" type="number"/>
                </a-form-model-item>

                <a-form-model-item label="商品优惠价" v-if="shopInfo && shopInfo.shopType == '0'"
                                   prop="beansPrice"
                                   class="margin-bottom-10">
                  <a-input style="width: 150px;" :min="0" :step="1" addonAfter="元" v-model="form.beansPrice"
                           :disabled="auditPage" type="number"/>
                </a-form-model-item>
                <a-form-model-item label="商品嗨豆价"
                                   v-if="shopInfo && shopInfo.shopType == '0'"
                                   prop="beans"
                                   class="margin-bottom-10">
                  <!--                  <a-input style="width: 100px;" addonAfter="嗨豆" v-model="form.beans"-->
                  <!--                           :disabled="auditPage" @blur.native.capture="validatorSkuContent($event,'1')"/>-->
                  <a-input-number :min="1" style="width: 150px;" v-model="form.beans"/>

                </a-form-model-item>

                <!--         嗨豆商场选择 end       -->

                <!--                嗨宝商场只有嗨宝 start-->
                <a-form-model-item label="商品零售价" prop="highCostPrice" class="margin-bottom-10"
                                   v-if="shopInfo && shopInfo.shopType == '1'">
                  <a-input style="width: 150px;" :min="0" :step="1" addonAfter="元" v-model="form.highCostPrice"
                           :disabled="auditPage" type="number"/>
                </a-form-model-item>

                <a-form-model-item label="结算折扣" prop="highDiscountPrice" class="margin-bottom-10"
                                   v-if="shopInfo && shopInfo.shopType == '1'">
                  <!--                  <a-input style="width: 100px;" addonAfter="折" v-model="form.costPrice"-->
                  <!--                           :disabled="auditPage" @blur.native.capture="validatorSkuContent($event,'1')"/>-->
                  <a-input-number :min="0.1" style="width: 150px;" :max="1" :step="0.1"
                                  v-model="form.highDiscountPrice" @change="changeHighDiscountPrice" property="输入范围0-1" />
                </a-form-model-item>

                <a-form-model-item label="嗨宝价格" prop="highCostPrice" class="margin-bottom-10"
                                   v-if="shopInfo && shopInfo.shopType == '1'">
                  {{form.highCostPrice * form.highDiscountPrice / this.gemRatio}}
                </a-form-model-item>

                <a-form-model-item label="商品重量" prop="goodsWeight" class="margin-bottom-10">
                  <a-input style="width: 150px;" :min="0" :step="1" addonAfter="Kg" v-model="form.goodsWeight"
                           :disabled="auditPage" type="number"/>
                </a-form-model-item>
                <!--                嗨宝商场只有嗨宝 end-->


                <a-form-model-item label="是否新品" prop="isNew" class="margin-bottom-10">
                  <a-radio-group v-model="form.isNew" button-style="solid">
                    <a-radio-button v-for="(d, index) in newOptions" :key="index" :value="d.value">
                      {{ d.label }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="是否热卖" prop="isHot" class="margin-bottom-10">
                  <a-radio-group v-model="form.isHot" button-style="solid">
                    <a-radio-button v-for="(d, index) in hotOptions" :key="index" :value="d.value">
                      {{ d.label }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="是否推荐" prop="isRecommend" class="margin-bottom-10">
                  <a-radio-group v-model="form.isRecommend" button-style="solid">
                    <a-radio-button v-for="(d, index) in recommendOptions" :key="index" :value="d.value">
                      {{ d.label }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="是否精品商品" prop="isBoutique" class="margin-bottom-10">
                  <a-radio-group v-model="form.isBoutique" button-style="solid">
                    <a-radio-button v-for="(d, index) in boutiqueOptions" :key="index" :value="d.value">
                      {{ d.label }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="排序" prop="sort" class="margin-bottom-10">
                  <a-input-number v-model="form.sort" :min="0" style="width: 100%"/>

                </a-form-model-item>

                <a-form-model-item label="商品保障" prop="goodsSecuritys" class="margin-bottom-10">
                  <a-checkbox-group v-model="form.goodsSecuritys">
                    <a-checkbox v-for="(item,index) in goodsSecurityList" :key="index" :disabled="auditPage"
                                :value="item.id">
                      {{ item.name }}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
                <a-form-model-item label="运费模版" prop="freightTemplateId" class="margin-bottom-10">
                  <a-select
                    v-model="form.freightTemplateId"
                    showSearch
                    placeholder="请选择运费模板"
                    style="width: 200px"
                    v-bind:disabled="auditPage"
                  >
                    <a-select-option v-for="freight in freightTemplateList" :key="freight.id" :value="freight.id">
                      {{ freight.name }}
                    </a-select-option>
                  </a-select>
                  <a style="margin-left: 10px;" @click="addTemplateFreight">新建运费模板</a>

                </a-form-model-item>
                <a-divider></a-divider>
                <a-row style="margin: 30px 0;">
                  <a-col :span="2"></a-col>
                  <a-col :span="12" class="margin-tb-lg">
                    <a-button type="primary" size="large" @click="changeTab(1, 'next')">下一步</a-button>
                  </a-col>
                </a-row>
              </a-form-model>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="规格库存">
          <a-form-model :model="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 10 }">

            <div>
              <a-form-model-item label="请选择规格">
                <a-select v-model="curSpec" show-search placeholder="请选择规格" style="width: 200px"
                          @change="handleChangeSpec">
                  <a-select-option :value="item.id" v-for="(item, index) in specList" :key="index">
                    {{ item.specName }}
                  </a-select-option>
                </a-select>
                <a-button style="margin-left: 8px" v-if="curSpec" type="primary" @click="confirmSpecBtn">确定
                </a-button>
              </a-form-model-item>

              <!-- start展示选择的规格信息 -->
              <div v-if="specValueList.length">
                <div class="box margin-bottom-sm" v-for="(item, index) in specValueList" :key="index">
                  <div class="margin-bottom-sm">{{ item.specName }}
                    <!--                    <a-icon type="close-circle" class="cursorhand margin-left-sm" @click="delSpec(index)"/>-->
                    <a-popconfirm title="确定要删除此规格吗?" ok-text="确定" cancel-text="取消" @confirm="delSpec(index)">
                      <a-icon type="close-circle" class="delSpec"/>
                    </a-popconfirm>
                  </div>


                  <div class="flex align-center">
                    <div v-for="(item1, index1) in item.specValueList" :key="index1" class="flex align-center">
                      <a-input v-model="item1.specValueName" placeholder="请输入" @blur="changeGuigeshuju($event,item1,index, index1)" size="small" style="width: 80%"/>
                      <a-icon type="close-circle" class="cursorhand margin-left-sm"
                              @click="del(2, index, index1)"></a-icon>

                    </div>
                    <a-button v-if="item.specValueList.length < 5 && item.showBtn"
                              type="primary" size="small"
                              @click="add(2, index)" class="margin-left-sm margin-right">
                      添加
                    </a-button>
                  </div>
                  <!--                  <div class="flex align-center">-->
                  <!--                    <div class="flex align-center">-->
                  <!--                      排序：<a-input-number v-model="item.sort" :min="0" size="small" style="width: 80%"/>-->
                  <!--                    </div>-->
                  <!--                  </div>-->


                </div>
              </div>
              <!-- end  展示选择的规格信息 -->
              <a-button class='margin-tb' type="primary" @click="generateSpecs">立即生成</a-button>
              <!-- start 根据所选的规格，生成skuList,展示表格 -->
              <a-table :loading="loading" size="small" rowKey="attrValueItems" :columns="skuColumns" :data-source="skuList"
                       :pagination="false" :bordered="true">
                <!-- <div slot="key_name" slot-scope="text, record">{{  }}</div> -->
                <div slot="price" slot-scope="text, record">
                  <a-input placeholder="请输入" type="number" addonAfter="元" v-model="record.price"></a-input>
                </div>
                <div slot="promotionPrice" slot-scope="text, record">
                  <span v-if="shopInfo && shopInfo.shopType == 0">
                    <a-input placeholder="请输入" type="number"  :min="0" addonAfter="元" v-model="record.promotionPrice"></a-input>
                  </span>
                  <span v-else>暂无</span>
                </div>
                <div slot="beansPrice" slot-scope="text, record">
                                    <span
                                      v-if="shopInfo && shopInfo.shopType == 0">
                                                        <a-input placeholder="请输入" type="number" addonAfter="元"
                                                                 v-model="record.beansPrice" :min="0"></a-input>
                                    </span>
                  <span v-else>暂无</span>

                </div>

                <div slot="beans" slot-scope="text, record">
                     <span
                       v-if="shopInfo && shopInfo.shopType == 0">
                           <a-input-number :min="0" placeholder="请输入" v-model="record.beans"/>
                     </span>
                  <span v-else>暂无</span>
                </div>
                <div slot="highCostPrice" slot-scope="text, record">
                     <span v-if="shopInfo && shopInfo.shopType == 1">
                           <a-input placeholder="请输入" min="0" type="number" addonAfter="元"
                                    v-model="record.highCostPrice"></a-input>
                     </span>
                  <span v-else>暂无</span>

                </div>
                <div slot="highDiscountPrice" slot-scope="text, record">

                  <span v-if="shopInfo && shopInfo.shopType == 1">
                       <a-input-number placeholder="请输入" :min="0.1" :max="10" :step="0.1"
                                       v-model="record.highDiscountPrice"/>
                     </span>
                  <span v-else>暂无</span>

                </div>

                <div slot="stock" slot-scope="text, record">
                  <a-input-number placeholder="请输入" :min="0" v-model="record.stock"></a-input-number>
                </div>
                <div slot="picture" class="flex align-center justify-center" slot-scope="text, record">
                  <a-upload
                    listType="picture-card"
                    class="table-uploader"
                    :showUploadList="false"
                    :customRequest='fileUploadRequest'
                    :beforeUpload="beforeUpload"
                    @change="handleChangeTable($event,record)"
                  >
                    <img v-if="record.picture" :src="record.picture" alt="规格图片"
                         style="width: 48px;height: 48px;"/>
                    <div v-else>
                      <a-icon :type="record.uploadLoading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">上传</div>
                    </div>
                  </a-upload>
                </div>
              </a-table>
              <!-- end   根据所选的规格，生成skuList,展示表格 -->
            </div>
            <a-divider></a-divider>
            <a-row style="margin: 30px 0;">
              <a-col :span="2"></a-col>
              <a-col :span="12" class="margin-tb-lg">
                <a-button @click="changeTab(2, 'back')" size="large" class="margin-right">上一步</a-button>
                <a-button @click="changeTab(2, 'next')" type="primary" size="large">下一步</a-button>
              </a-col>
            </a-row>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane :key="3" tab="商品详情">
          <a-form-model-item label="商品详情" prop="description" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <editor v-model="form.description"/>
          </a-form-model-item>
          <a-divider></a-divider>
          <a-row style="margin: 30px 0;">
            <a-col :span="2"></a-col>
            <a-col :span="12" class="margin-tb-lg">
              <a-button @click="changeTab(3, 'back')" size="large" class="margin-right">上一步</a-button>
              <a-button @click="changeTab(3, 'next')" type="primary" size="large">下一步</a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane :key="4" tab="商品简介">
          <a-form-model-item label="商品简介" prop="exhibition" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <editor1 v-model="form.exhibition"/>
          </a-form-model-item>
          <a-divider></a-divider>
          <a-row style="margin: 30px 0;">
            <a-col :span="2"></a-col>
            <a-col :span="12" class="margin-tb-lg">
              <a-button @click="changeTab(4, 'back')" size="large" class="margin-right">上一步</a-button>
              <a-button @click="changeTab(4, 'next')" type="primary" size="large">下一步</a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane :key="5" tab="商品参数展示">
          <a-form-model-item label="商品参数展示" prop="parameter" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <editor2 v-model="form.parameter"/>
          </a-form-model-item>
          <a-divider></a-divider>
          <a-row style="margin: 30px 0;">
            <a-col :span="2"></a-col>
            <a-col :span="12" class="margin-tb-lg">
              <a-button @click="changeTab(5, 'back')" size="large" class="margin-right">上一步</a-button>
              <a-button @click="changeTab(5, 'next')" type="primary" size="large">下一步</a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane :key="6" tab="购买须知">
          <a-form-model-item label="购买须知" prop="aftersale" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <editor3 v-model="form.aftersale"/>
          </a-form-model-item>
          <a-divider></a-divider>
          <a-row style="margin: 30px 0;">
            <a-col :span="2"></a-col>
            <a-col :span="12" class="margin-tb-lg">
              <a-button @click="submitForm" :loading="submitLoading" type="primary" size="large">保存</a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <div class="bottom-control">
      <a-space>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          保存
        </a-button>
        <a-button type="dashed" @click="cancel">
          取消
        </a-button>
      </a-space>
    </div> -->

  </page-header-wrapper>
</template>

<script>

import * as article from '@/api/mall/article'
import * as goodscurity from '@/api/mall/goodscurity'
import * as shopCategory from '@/api/shop/shopCategory'
import Editor from '@/components/Editor'
import Editor1 from '../../../components/Editor/Editor1'
import Editor2 from '../../../components/Editor/Editor2'
import Editor3 from '../../../components/Editor/Editor3'
import freightTemplateModal from '../template/add_freight.vue'
import UploadFileToOSS from "@/utils/upload-file-to-oss"
import {getAllGoodsCategoryList} from "@/api/mall/goodscate"
import {listBrand} from "@/api/shop/brand"
import {shippingTemplatesListAll} from "@/api/logistics"
import * as goodsspec from "@/api/mall/goodsspec"
import * as goodsspecvalue from "@/api/mall/goodsspecvalue"
import * as goods from "@/api/mall/goods"
import {edit} from "@/api/mall/goodsspecvalue";
import {listShopCategory} from "@/api/shop/shopCategory";
import {getConfigByPrice} from "@/api/config/config";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: 'CreatePform',
  props: {},
  components: {
    Editor,
    Editor1,
    Editor2,
    Editor3,
    freightTemplateModal
    // skus
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      formTitle: '',
      tabIndex: 1,
      form: {
        id: null,
        categoryId: null, // 商品分类
        shopCategoryId: null, // 店铺分类id
        goodsName: '', // 商品名称
        introduction: '', // 商品简介
        keywords: '', // 商品关键词
        brandId: null, // 品牌id
        picture: '', // 商品主图
        imgIdArray: [], // 商品图片序列最后需要上传的为一个数组
        price: '', // 商品价格
        promotionType: '', // 嗨豆商场暂用有三种商品类型 1-现金 2-嗨分 3-现金+嗨分
        promotionPrice: '', // 嗨豆纯现金使用字段 为1
        beansPrice: '', // 3-嗨豆 现金+嗨分 里面的价格
        beans: '', // 2嗨豆 3嗨豆都用
        costPrice: '', // 嗨宝嗨币的成本价
        highDiscountPrice: null, // 折扣几折
        highCostPrice: '', // 嗨豆成本价
        goodsWeight: '', // 商品重量(KG)
        isNew: 0, // 是否新品//默认否0 是1
        isHot: 0, // 是否热卖默认否0 是1
        isRecommend: 0, // 是否推荐 默认否0 是1
        isBoutique: 0, // 是否精品 默认否0 是1
        sort: '', // 排序
        goodsSecuritys: [], // 商品保障id（多个以逗号隔开）
        freightTemplateId: '', // 运费模板id
        description: '', // 商品详情
        exhibition: '', // 商品介绍
        parameter: '', // 商品参数展示
        aftersale: '' // 购买须知
      },
      specList: [], // 商品属性列表
      specValueList: [], // 商品规格存[{specId:'',specValueName:'',isVisible:'',}]
      isShowSpec: false,
      // 生成的skuList表格数据
      skuColumns: [
        {
          title: '规格',
          dataIndex: 'skuName',
          align: 'center'
        }, {
          title: '商品划价',
          dataIndex: 'price',
          align: 'center',
          scopedSlots: {customRender: 'price'}
        }, {
          title: '商品零售价',
          dataIndex: 'promotionPrice',
          align: 'center',
          scopedSlots: {customRender: 'promotionPrice'}
        }, {
          title: '商品优惠价',
          dataIndex: 'beansPrice',
          align: 'center',
          scopedSlots: {customRender: 'beansPrice'}
        }, {
          title: '商品嗨豆价',
          dataIndex: 'beans',
          align: 'center',
          scopedSlots: {customRender: 'beans'}
        }, {
          title: '商品零售价',
          dataIndex: 'highCostPrice',
          align: 'center',
          scopedSlots: {customRender: 'highCostPrice'}
        }, {
          title: '结算折扣',
          dataIndex: 'highDiscountPrice',
          align: 'center',
          scopedSlots: {customRender: 'highDiscountPrice'}
        }, {
          title: '库存',
          dataIndex: 'stock',
          align: 'center',
          scopedSlots: {customRender: 'stock'}
        },
        {
          title: '图片',
          dataIndex: 'picture',
          align: 'center',
          width: 140,
          scopedSlots: {customRender: 'picture'}
        }
      ],
      curSpec: null,
      skuList: [],
      replaceFields: {
        title: 'categoryName',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      replaceShopCategoryFields: {
        title: 'categoryName',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      replaceBrandFields: {
        title: 'name',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      cateList: [],
      statusOptions: [
        {
          label: '上架',
          value: 1
        },
        {
          label: '下架',
          value: 0
        }
      ],
      newOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      hotOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      recommendOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      boutiqueOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      status: 1,
      // 上传图片
      // open: false,
      previewVisible: false,
      previewImage: '',
      gemRatio: 1,
      fileList: [],
      auditPage: false,//商品是审核的话不可输入，不是的话可输入
      baseRules: {
        categoryId: [{required: true, message: '商品分类不能为空', trigger: 'change'}],
        shopCategoryId: [{required: true, message: '店铺分类不能为空', trigger: 'change'}],
        goodsName: [{required: true, message: '商品名称不能为空', trigger: 'blur'}],
        introduction: [{required: true, message: '商品简介不能为空', trigger: 'blur'}],
        keywords: [{required: true, message: '商品关键字不能为空', trigger: 'blur'}],
        brandId: [{required: false, message: '品牌不能为空', trigger: 'change'}],
        picture: [{required: true, message: '主图不能为空', trigger: 'blur'}],
        imgIdArray: [{required: true, message: '主图不能为空', trigger: 'blur'}],
        price: [{
          required: true,
          // pattern: '^(([1-9]{1}\\d*)|(0{1}))(\\.\\d{1,2})?$',
          message: '请输入商品原价格',
          trigger: 'blur'
        }],
        promotionPrice: [
          {
            required: true,
            // pattern: '^(([1-9]{1}\\d*)|(0{1}))(\\.\\d{1,2})?$',
            message: '请输入促销价格',
            trigger: 'blur'
          }
        ],
        beansPrice: [{
          required: true,
          // pattern: '^(([1-9]{1}\\d*)|(0{1}))(\\.\\d{1,2})?$',
          message: '请输入嗨豆价格',
          trigger: 'blur'
        }],
        highCostPrice: [{
          required: true,
          // pattern: '^(([1-9]{1}\\d*)|(0{1}))(\\.\\d{1,2})?$',
          message: '请输入成本价格',
          trigger: 'blur'
        }],
        highDiscountPrice: [{
          required: true,
          message: '请输入折扣',
          trigger: 'blur'
        }],
        beans: [{
          required: true,
          message: '请输入嗨豆数',
          trigger: 'blur'
        }],
        promotionType: [{
          required: true,
          message: '请选择商品类型'
        }]
      },
      headers: {},
      uploadLoading: false,
      goodsSecurityList: [],//商品保证列表
      freightTemplateList: [],//运费模版列表
      goodsCategoryList: [],//商品分类列表
      shopCategoryList: [],//获取店铺分类列表
      brandList: [],//获取品牌列表
      threeIdData:[]
    }
  },
  filters: {},
  created() {
    this.getGoodsCate();//获取商品分类
    this.getShopCategoryList();//获取店铺分类列表
    this.getBrandList();//获取品牌列表
    this.getGoodsSecurityList();//获取商品保障列表
    this.getFreightTemplateList();//获取物流模版
    this.getSpecList(); // 获取规格数据
  },
  mounted() {
    console.log('进来了数据')
    console.log(this.$route)
    this.form.id = this.$route.query.id
    this.formTitle = this.$route.query.formTitle
    if (this.form.id) {
      this.handleUpdate(this.form.id)
    } else {
      this.handleAdd()
    }
  },
  computed: {
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  watch: {},
  methods: {
    getConfigByPrice() {
      getConfigByPrice().then(res => {
        if(res.success) {
          this.gemRatio = res.data.gemRatioZero;
        }
      })
    },
    changeHighDiscountPrice(e) {
      console.log(typeof e );
      if(typeof e == 'number') {
        console.log('进来了')
        if(this.skuList.length) {
          this.skuList.forEach(j => {
            j.highDiscountPrice = e
          })
        }
      }

    },
    changeCategorySelect(value, label, extra) {

      console.log(this.threeIdData)

       let findeIndex = this.threeIdData.findIndex(i => i.id == value);
      if(findeIndex === -1) {
        this.form.categoryId = null;
        this.$message.warning('只可添加三级分类商品')
      } else {
        this.form.categoryId = value;

      }
      console.log(value)
      console.log(label)
      console.log(label)
    },
    changeGuigeshuju(e,item,index,index1) {
      console.log('改变了')
      if(item.id) {
        goodsspecvalue.edit(item).then(res => {
          if(res.code == 20001) {

          } else {
            this.$message.info('修改失败,请重试');

          }
        })
      } else {
        goodsspecvalue.add(item).then(res => {
          if (res.code == 20001) {
            this.$set(item,'id',res.data.id)
          } else {
            this.$message.info('增加失败,请重试');
          }
        })
      }
    },
    changeTopTabs(value) {
      console.log(value)
      if (value == 2 || value == 3) {
        this.$refs.oneForm.validate(valid => {
          if (valid) {
            this.tabIndex = value
          } else {
            this.tabIndex = 1;

            return false;
          }
        })
      } else {
        this.tabIndex = value
      }

    },

    getSpecList() {
      goodsspec.page().then(res => {
        if (res.code == 20001) {
          this.specList = res.data;
        }
      })
    },

    validatorSkuContent(e, type) {
    },
    /**
     * 重新加载运费模版
     */
    getGoodsSecurityList() {
      goodscurity.list().then(res => {
        if (res.code == 20001) {
          this.goodsSecurityList = res.data;
          console.log('进来了')
          console.log(this.goodsSecurityList)
        }
      })
    },


    getFreightTemplateList() {
      // logistics.
      shippingTemplatesListAll().then(res => {
        if (res.code == 20001) {
          this.freightTemplateList = res.data;
        }
      })

    },


    getGoodsCate() {
      this.loading = false
      getAllGoodsCategoryList().then(res => {
        if (res.code == 20001) {
          this.goodsCategoryList = res.data;
          this.dealThreeList(res.data);
        }
      })

    },

    getShopCategoryList() {
      shopCategory.listShopCategory().then(res => {
        if (res.code == 20001) {
          this.shopCategoryList = res.data;

        }
      })
    },

    dealThreeList(goodsCategoryList) {
      let threeIdData = [];
      console.log('家22222222222222222222222')
      goodsCategoryList.forEach(j => {
        console.log('j')
        console.log(j)

        if(j.children && j.children.length) {
          // 循环二级
          console.log('--------------------------')
          console.log(j)
          j.children.forEach(it => {
            if(it.children && it.children.length) {
              it.children.forEach(GG => {
                if(GG.level == 3) {
                  threeIdData.push(GG)
                }
              })
              // it.children.forEach(jt => {
              //   if(jt.children && jt.children.length) {
              //
              //   }
              // })
            }
          })
        }
      })
      this.threeIdData = threeIdData
      console.log('threeIdData')
      console.log(threeIdData)
    },

    getBrandList() {
      listBrand().then(res => {
        if (res.code == 20001) {
          this.brandList = res.data;
        }
      })
    },

    /**
     * 根据id获取详情
     * @param row
     * @param ids
     */
    handleUpdate(id) {
      this.reset()
      this.formTitle = '修改商品'
      goods.detail({ id: id }).then(response => {
        let forminfo = JSON.parse(JSON.stringify(response.data));
        // forminfo.skuList.forEach(i => {
        //   i.highDiscountPrice = forminfo.highDiscountPrice
        // })
        forminfo.goodsSecuritys = forminfo.goodsSecuritys.split(',')
        forminfo.isBoutique = forminfo.isBoutique ? 1 : 0
        forminfo.isHot = forminfo.isHot ? 1 : 0
        forminfo.isNew = forminfo.isNew ? 1 : 0
        forminfo.isRecommend = forminfo.isRecommend ? 1 : 0
        // forminfo.promotionPrice = forminfo.promotionPrice+''
        // forminfo.price = forminfo.price+''
        forminfo.imgIdArray = forminfo.imgIdArray.split(',').map((i,idx) => ({
          uid:'-' + idx,
          name:'image.png',
          status: 'done',
          url:i
        }))
        let goodsSpecFormat = JSON.parse(forminfo.goodsSpecFormat);
        let speValueList = [];

        this.specList = this.specList.filter(v => !goodsSpecFormat.some((item) => item.spec_id == v.id));
        goodsSpecFormat.forEach((i,idx) => {
          let speValueItem = {
            id:i.spec_id,
            specName:i.spec_name,
            specDes:'',
            showBtn:true,
            specValueList:[]
          }
          speValueList.push(speValueItem)
          i.value.forEach(j => {
            let valueItem = {
              specId:j.spec_id,
              specName:j.spec_name,
              specValueName:j.spec_value_name,
              id:j.spec_value_id,
              isVisible: 1,
              sort: 0,
              remark: '',
            }
            speValueList[idx].specValueList.push(valueItem)
          })
        })
        console.log(speValueList);

        this.specValueList = speValueList
        // this.generateSpecs();

        this.skuList = forminfo.skuList;



        // this.specValueList = JSON.parse();
        this.form = forminfo

      })
    },

    fileUploadRequest(fileInfo) {
      // this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },

    /**
     * 确定规格添加按钮
     */
    confirmSpecBtn() {
      /**
       *   1:往商品规格值specValueList 添加规格数据
       *   2：移除规格列表选择的那一项specList 删除curSpec
       *   3：curSpec选择的规格清空
       */
      if (this.curSpec) {
        let filterIndex = this.specList.findIndex(i => i.id == this.curSpec);
        if (filterIndex !== -1) {
          let addSpecValueItem = {
            id: this.specList[filterIndex].id,
            remark: this.specList[filterIndex].remark,
            specName: this.specList[filterIndex].specName,
            specDes: this.specList[filterIndex].specDes,
            showBtn:true,
            specValueList: [{
              specId: this.specList[filterIndex].id,
              specName: this.specList[filterIndex].specName,
              specValueName: '',
              isVisible: 1,
              sort: 0,
              remark: '',
              id: ''
            }]
          }
          this.specValueList.push(addSpecValueItem);
          // setTimeout(() => {
          this.specList.splice(filterIndex, 1);
          // },700)
          this.curSpec = null;
        }
      }

    },
    /**
     * 删除规格
     */
    del(num, index, index1) {
      if (num == 2) {
        if (this.specValueList[index].specValueList[index1].id) {
          goodsspecvalue.deleteMore(this.specValueList[index].specValueList[index1].id.split(',')).then(res => {
            if (res.code == 20001) {
              this.specValueList[index].specValueList.splice(index1, 1)
            }
          })
        } else {
          this.specValueList[index].specValueList.splice(index1, 1)
        }

      }
    },

    add(num, index, index1) {
      if (num == 2) {
        if (this.specValueList[index].specValueList[this.specValueList[index].specValueList.length - 1].specValueName) {
                    this.specValueList[index].specValueList.push({
                      specId: this.specValueList[index].id,
                      specName: this.specValueList[index].specName,
                      specValueName: '',
                      isVisible: 1,
                      sort: 0,
                      remark: '',
                      id: ''
                    })


              } else {
                this.$message.warning("请填写上一个属性值")
              }
      }
    },
    /**
     * 删除规格
     */
    delSpec(index) {
      let mapIdArray = this.specValueList[index].specValueList.map(i => i.id);
      if (mapIdArray.length) {
        let filterIds = mapIdArray.filter(i => i != '');
        if (filterIds.length) {
          goodsspecvalue.deleteMore(filterIds).then(res => {
            if (res.code == 20001) {
              let oneItem = this.specValueList[index];
              oneItem.specValueList = []
              this.specList.push(oneItem)
              this.specValueList.splice(index, 1)
            }
          })
        } else {
          let oneItem = this.specValueList[index];
          oneItem.specValueList = []
          this.specList.push(oneItem)
          this.specValueList.splice(index, 1)
        }
      } else {
        let oneItem = this.specValueList[index];
        oneItem.specValueList = []
        this.specList.push(oneItem)
        this.specValueList.splice(index, 1)
      }


    },


    //新增運費模板
    addTemplateFreight() {
      // this.$refs.freightTemplateModal.add();
      this.$refs.freightTemplateModal.clear()
      this.$refs.freightTemplateModal.handleShow()
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if (file.size > 5 * 1024 * 1000) {
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    back() {
      this.$router.push('/mall/goods')
    },
    changeTab(index, type) {
      console.log(this.skuList);
      if (type === 'next') {
        if (index == 1) {
          console.log(this.form)
          this.$refs.oneForm.validate(valid => {
            if (valid) {
              if (this.form.id) {
                //  TODO:修改表单
              } else {

              }
              this.tabIndex = index + 1
            } else {
              return false;
            }
          })

        } else {
          this.tabIndex = index + 1
        }

      } else if (type === 'back') {
        this.tabIndex = index - 1
      }
    },

    // 表单重置
    reset() {
      this.form = {
        id:null,
        categoryId: null,//商品分类
        shopCategoryId: null,//店铺分类id
        goodsName: '',//商品名称
        introduction: '',//商品简介
        keywords: '',//商品关键词
        brandId: null,//品牌id
        picture: '',//商品主图
        imgIdArray: [],//商品图片序列最后需要上传的为一个数组
        price: '',//商品价格
        promotionType: '',//嗨豆商场暂用有三种商品类型 1-现金 2-嗨分 3-现金+嗨分
        promotionPrice: '',//嗨豆纯现金使用字段 为1
        beansPrice: '',// 3-嗨豆 现金+嗨分 里面的价格
        beans: '',//2嗨豆 3嗨豆都用
        highDiscountPrice: null, // 折扣几折
        highCostPrice: '', // 嗨豆成本价
        costPrice: '',//嗨宝嗨币的成本价
        goodsWeight: '',//商品重量(KG)
        isNew: 0,//是否新品//默认否0 是1
        isHot: 0,//是否热卖默认否0 是1
        isRecommend: 0,//是否推荐 默认否0 是1
        isBoutique: 0,//是否精品 默认否0 是1
        sort: '',//排序
        goodsSecuritys: [],//商品保障id（多个以逗号隔开）
        freightTemplateId: '',//运费模板id
        description: '',//商品详情
      }
    },
    changeVideo(e) {
      let value = e.target.value
      this.form.promotionType = value
    },

    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      //上传图片回调
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return;
      }

      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.picture = info.file.originFileObj.ossUrl;
      }

    },

    handleChangeTable(info, record) {

      if (info.file.status === 'uploading') {
        record.uploadLoading = true;
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        record.uploadLoading = false;
        record.picture = info.file.originFileObj.ossUrl;
      }
    },
    /** 切换单规格还是多规格 **/
    handleChangeSpec(e) {
      console.log('选中规格', e)
      this.curSpec = e
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.formTitle = '添加商品'
      this.reset()
    },

    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传图片
    uploadImages({ fileList }) {
      console.log(fileList)
      this.form.imgIdArray = fileList;
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.specValueList)
      if(this.skuList.length == 0) {
        this.$message.warning("添加商品规格信息")
        return;
      }
      this.$refs.oneForm.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          console.log('添加商品信息');
          let addNewGoodsInfo = JSON.parse(JSON.stringify(this.form));
          addNewGoodsInfo.goodsSecuritys = addNewGoodsInfo.goodsSecuritys.join(',');
          console.log(addNewGoodsInfo.imgIdArray)
          let newGoodUrls = [];
          addNewGoodsInfo.imgIdArray.forEach(i => {
           if(i.url) {
             newGoodUrls.push(i.url)
           } else {
             newGoodUrls.push(i.originFileObj.ossUrl)
           }
          })
          addNewGoodsInfo.imgIdArray = newGoodUrls.join(',');
          console.log('编辑一下数据')
          console.log(addNewGoodsInfo.imgIdArray )
          if (this.shopInfo) {
            addNewGoodsInfo.goodsType = this.shopInfo.shopType;
            addNewGoodsInfo.shopId = this.shopInfo.id;
          }

          let allStockNum = 0;
          this.skuList.forEach(i => {
            allStockNum = allStockNum + i.stock
          })


          addNewGoodsInfo.stock = allStockNum;

          addNewGoodsInfo.goodsSkuFormat = JSON.stringify(this.skuList);



          let goodsSpecFormat = []
          this.specValueList.forEach((j,index) => {
            let specItem = {
              spec_id:j.id,
              spec_name:j.specName,
              value:[]
            }
            goodsSpecFormat.push(specItem)
            j.specValueList.forEach(ii => {
              let specValueItem = {
                spec_value_id:ii.id,
                spec_id:ii.specId,
                spec_name:ii.specName,
                spec_value_name:ii.specValueName
              }
              goodsSpecFormat[index].value.push(specValueItem)
            })
          })
          addNewGoodsInfo.goodsSpecFormat = JSON.stringify(goodsSpecFormat);


          if (this.form.id) {
            goods.edit(addNewGoodsInfo).then(response => {
              if (response.success){
                this.$message.success(
                  '修改成功',
                  3
                )
                this.reset()
                this.skuList = []
                this.specValueList=[]

                this.$emit('ok')
                this.$router.go(-1);
              }else {
                this.$message.success(
                  '修改失败',
                  3
                )
              }
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            goods.add(addNewGoodsInfo).then(response => {
              if (response.success){
                this.$message.success(
                  '新增成功',
                  3
                )
                // this.open = false
                this.reset();
                this.skuList = [];
                this.specValueList=[];
                this.$emit('ok')
                this.$router.go(-1);
              }else {
                this.$message.success(
                  '新增失败',
                  3
                )
              }

            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /** 生成规格库存 **/
    generateSpecs() {
      let arrs = this.specValueList;
        let result =[]
        if(arrs.length === 1) {
          arrs[0].specValueList.forEach(j => {
            if(j.id) {
              result.push({
                skuName: `${j.specName}:${j.specValueName}`,
                promotionType: this.form.promotionType, //嗨豆商场 1现金 2 嗨分 3 现金+嗨分
                promotionPrice: null,//单独现金
                beansPrice: null,//嗨豆价格
                beans: null,// 嗨豆数量
                highCostPrice: null,// 嗨宝成本价格
                highDiscountPrice: (this.shopInfo && this.shopInfo.shopType == '1') ? this.form.highDiscountPrice : null,// 嗨宝成本价格
                costPrice: null,
                attrValueItems: `${j.id}`,
                attrValueItemsFormat: `${j.specId}:${j.id}`,
                stock: 0,
                price: null,
                uploadLoading: false,//上传
                picture: ''
              })
              // saveArr
            }
          })
        } else {
          result = arrs.reduce((acc, cur, index) => {
            console.log('acc')
            console.log(acc)
            console.log(cur)
            // 从第二项开始合并值
            if (index > 0) {
              let saveArr = []
              acc.specValueList.forEach(item => {
                cur.specValueList.forEach(subItem => {
                  console.log('进来了2222')
                  if(subItem.id && item.id) {
                    saveArr.push({
                      skuName: `${item.specName}:${item.specValueName}/${subItem.specName}:${subItem.specValueName}`,
                      promotionType: this.form.promotionType, //嗨豆商场 1现金 2 嗨分 3 现金+嗨分
                      promotionPrice: null,//单独现金
                      beansPrice: null,//嗨豆价格
                      beans: null,// 嗨豆数量
                      highCostPrice: null,// 嗨宝成本价格
                      highDiscountPrice: (this.shopInfo && this.shopInfo.shopType == '1') ? this.form.highDiscountPrice : null,// 嗨宝成本价格
                      costPrice: null,
                      attrValueItems: `${item.id},${subItem.id}`,
                      attrValueItemsFormat: `${item.specId}:${item.id},${subItem.specId}:${subItem.id}`,
                      stock: 0,
                      price: null,
                      uploadLoading: false,//上传
                      picture: ''
                    })
                  }
                })
              })
              acc = saveArr
            }
            return acc
          }, arrs[0]) // 把数组的第一项传入作为初始值
          console.log('result')
          console.log(result)
        }

      this.skuList = result
      // })

      // arrs.forEach(it => {
      //
      //   it.specValueList.forEach(jt => {
      //     if (jt.id && jt.specValueName) {
      //       console.log('进来了')
      //       let saveArr = {
      //         skuName: `${it.specName}:${jt.specValueName}`,
      //         promotionType: this.form.promotionType, //嗨豆商场 1现金 2 嗨分 3 现金+嗨分
      //         promotionPrice: null,//单独现金
      //         beansPrice: null,//嗨豆价格
      //         beans: null,// 嗨豆数量
      //         highCostPrice: null,// 嗨宝成本价格
      //         highDiscountPrice: null,// 嗨宝成本价格
      //         costPrice: null,
      //         attrValueItems: `${jt.id}`,
      //         attrValueItemsFormat: `${jt.specId}:${jt.id}`,
      //         stock: 0,
      //         price: null,
      //         uploadLoading: false,//上传
      //         picture: ''
      //       }
      //       result.push(saveArr)
      //     } else {
      //       if (it.specValueList.length == 1 && !it.specValueList[0].specValueName && !it.specValueList[0].id) {
      //         this.$message.warning("请先添加后重试");
      //       }
      //     }
      //   })
      // })
      // this.skuList = result
      // console.log('result', result)
      // }
      // })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-list-picture-card-container,
.ant-upload-select-picture-card,
.ant-upload-list-item {
  //width: 80px !important;
  //height: 80px !important;
}

.table-uploader {

}

.cursorhand {
  cursor: pointer;
}


.box {
  position: relative;
  background-color: #f7f7f7;
  padding: 15px 15px 15px 15px;
  margin-bottom: 15px;
  border-radius: 5px;

  //.delSpec {
  //  position: absolute;
  //  right: 0px;
  //  top: 0px;
  //  color: #f5222d;
  //  cursor: pointer;
  //}
}
</style>