<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @ok="submitForm"
           @cancel="onClose"  cancelText="关闭">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="分类类型" prop="type">
        <a-select v-model="form.type" placeholder="请选择" @change="changeType">
          <a-select-option :value="1" >
            嗨宝
          </a-select-option>
          <a-select-option :value="2" >
            嗨豆
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="上级分类" prop="pid">
<!--        showSearch-->
        <a-tree-select v-if="cateList3.length" v-model="form.pid" style="width: 100%"

          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择上级分类"
                       treeNodeFilterProp="title"
          tree-default-expand-all @change="onChangePid">
          <a-tree-select-node v-for="(goodsCategory) in cateList3" :title="goodsCategory.categoryName" :value="goodsCategory.id"  :key="goodsCategory.id" >
            <a-tree-select-node v-for="(item) in goodsCategory.goodsCategoryList" v-if="goodsCategory.goodsCategoryList" :value="item.id" :title="item.categoryName" :key="item.id">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-model-item>

<!--      <a-form-model-item-->
<!--        prop="shopQc"-->
<!--        label="店铺资质分类"-->
<!--        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--        :required="true">-->
<!--        <a-select placeholder="请选择店铺资质分类" v-model="form.shopQc">-->
<!--          <a-select-option v-for="(item, key) in dictOptions" :key="key" :value="item.id">-->
<!--                <span style="display: inline-block;width: 100%" :title=" item.tradeName ">-->
<!--                  {{ item.tradeName }}-->
<!--                </span>-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

      <a-form-model-item label="分类名称" prop="categoryName">
        <a-input v-model="form.categoryName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="是否首页显示" prop="status">
        <a-radio-group v-model="form.isHomePage" button-style="solid">
          <a-radio-button v-for="(d, index) in homeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="是否显示" prop="status">
        <a-radio-group v-model="form.isVisible" button-style="solid">
          <a-radio-button v-for="(d, index) in visibleOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="是否推荐" prop="status">
        <a-radio-group v-model="form.isRecommend" button-style="solid">
          <a-radio-button v-for="(d, index) in recommendOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="显示顺序" prop="postSort">
        <a-input-number v-model="form.sort" :min="0" style="width: 100%" />
      </a-form-model-item>
      <a-form-model-item label="分类图片" prop="categoryPic">
<!--        <a-upload action="" list-type="picture-card" :file-list="fileList" @preview="handlePreview"-->
<!--          @change="uploadImages">-->
<!--          <div v-if="fileList.length < 1">-->
<!--            <a-icon type="plus" />-->
<!--            <div class="ant-upload-text">-->
<!--              上传-->
<!--            </div>-->
<!--          </div>-->
<!--        </a-upload>-->

        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
<!--          {{baseimgerver}}-->
          <img v-if="form.categoryPic" :src="form.categoryPic" alt="分类图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:120*120 </span>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import * as goods from '@/api/mall/goods'
import {add, update, initDictData, detail, queryGoodsMenu} from '@/api/mall/goodscate'
import {getToken} from '@/utils/auth';
import UploadFileToOSS from "@/utils/upload-file-to-oss";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: 'CreateForm',
  props: {

  },
  components: {

  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: true,
      submitLoading: false,
      formTitle: '',
      form: {
        pid: '',
        type:1,
        id: 0,
        label: '',
        categoryPic: '',
        isVisible: 1,
        isHomePage:0,
        isRecommend:0,
        sort:'',
        categoryName:'',
        // shopQc:''
      },
      replaceFields: {
        title: 'categoryName',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      cateList: [],
      cateList3: [],  // 只有三层的分类
      recommendOptions: [ {
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      statusOptions: [
        {
          label: '显示',
          value: 1
        },
        {
          label: '不显示',
          value: 0
        }
      ],
      visibleOptions:[{
        label: '显示',
        value: 1
      },
        {
          label: '不显示',
          value: 0
        }],
      homeOptions:[ {
        label: '显示',
        value: 1
      },
        {
          label: '不显示',
          value: 0
        }],
      status: 1,
      // 上传图片
      open: false,
      previewVisible: false,
      uploadLoading: false,
      previewImage: '',
      rules: {
        label: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        categoryName:[{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        type:[{
          required:true,
          message: '类型不能为空',
        }],
        shopQc:[{
          required:true,
          message: '商铺资质不能为空',
        }]
      },
      headers:{},
      fileUpload:`${process.env.VUE_APP_BASE_API}/upload/image`,
      baseimgerver : `${process.env.VUE_APP_BASE_API}`,
      dictOptions:[],
      addLevel:1,
      type:1,

    }
  },
  filters: {
  },
  created() {
    // this.headers = {
    //   "Authorization" : "Bearer " + getToken()
    // }
    this.getGoodsCate();
    this.initDictData();//获取资质
    this.form.type = 1
  },
  computed: {
      uploadAction: function () {
        return this.fileUpload;
      },
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  watch: {
  },
  methods: {
    changeType(type){
      this.type = type
      this.form.pid = ""
      this.getGoodsCate()
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    initDictData() {
      initDictData().then(res => {
        if(res.code == 20001) {
          this.dictOptions = res.data;
        }
      })
    },

    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      debugger
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.categoryPic = info.file.originFileObj.ossUrl;
      }
    },

    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },

    onChangePid(value ,option) {
      console.log(value)
      // console.log(option)
      this.addLevel = 1;
      this.form.pid = value;
      // this.form.pid = value;
      // try {
        this.cateList3.forEach(i=> {
          if(i.id == value) {
            // console.log('进来了')
            console.log(i.id + '2----')
            this.addLevel = i.level+1;
            // throw new Error("E")
          }


          if(i.goodsCategoryList && i.goodsCategoryList.length) {
            i.goodsCategoryList.forEach(j => {
              if(j.id == value) {
                console.log(i.id + '3----')

                this.addLevel = j.level+1;
                // throw new Error("E")
              }
            })
          }
        })
      // } catch (E) {
      //   console.log(E)
        console.log(this.addLevel)
      // }



    },
    // 获取商品分类
    getGoodsCate() {
      this.loading = false
      queryGoodsMenu({
        type:this.type
      }).then(res => {
        console.log(res.data)
        if (res.code == 20001) {
          let arr3 = JSON.parse(JSON.stringify(res.data))
          this.cateList3 = arr3
        }
      }).catch(err => {
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        pid: '',
        type:'',
        id: 0,
        label: '',
        categoryPic: '',
        isVisible: 1,
        isHomePage:0,
        isRecommend:0,
        sort:'',
        categoryName:'',
        // shopQc:''
      }
    },
    /** 新增按钮操作 */
    handleAdd(record,num) {
      this.getGoodsCate()
      this.formTitle = '添加分类'
      if(num == 1) {
        // this.reset()
        this.form = record;
        this.addLevel =record.level + 1;
      } else {
        this.form = record
        this.addLevel =record.level + 1;
      }
      this.open = true;
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const id = row ? row.id : ids
      this.form = JSON.parse(JSON.stringify(row));
      this.form.pid = this.form.pid == 0 ? null : this.form.pid;
      this.addLevel = row.level;
      this.form.isMangguoShow = this.form.isMangguoShow ? 1 : 0
      this.form.isHomePage = this.form.isHomePage ? 1 : 0
      this.form.isVisible = this.form.isVisible ? 1 : 0
      this.form.isRecommend = this.form.isRecommend ? 1 : 0
        this.formTitle = '修改分类'
      this.getGoodsCate()
      // return
      // detail({ id: id }).then(response => {
      //   this.form = response.data
      //   this.open = true
      //   this.formTitle = '修改分类'
      // })
      this.open = true

    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传图片
    uploadImages({ fileList }) {
      console.log('fileList', fileList)
      this.fileList = fileList
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log('form', this.form)
      // return;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            console.log('form', this.form)
            // return
            update(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // return
            let formInfo = JSON.parse(JSON.stringify(this.form))
            formInfo.level = this.addLevel
            add(formInfo).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
