
import request from '@/utils/request'
export function pageOrder(query) {
  return request({
    url: '/shop/shop-order/page',
    method: 'get',
    params: query
  })
}

export function detailOrder(query) {
  return request({
    url: '/shop/shop-order/detail',
    method: 'get',
    params: query
  })
}

export function deleteOrder(query) {
  return request({
    url: '/shop/shop-order/delete',
    method: 'post',
    data: query
  })
}
export function deliverGoods(data) {
  return request({
    url: '/shop/shop-order/deliverGoods',
    method: 'post',
    data: data
  })
}

export function editSellerMemoOrder(query) {
  return request({
    url: '/shop/shop-order/editSellerMemo',
    method: 'post',
    data: query
  })
}