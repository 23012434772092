<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div id="chart" style="height: 200px;width: 600px;">
      </div>
      <a-tabs @tabClick="changeTab">
        <a-tab-pane key="99" tab="全部订单"/>
        <a-tab-pane key="1" tab="仅退款(审批)"/>
        <a-tab-pane key="8" tab="退货退款(审批)"/>
        <a-tab-pane key="2" tab="退货退款(未发货)"/>
        <a-tab-pane key="3" tab="退货退款(已发货)"/>

      </a-tabs>


      <!--      条件筛选 start-->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="买家昵称">
                <a-input placeholder="请输入买家昵称" v-model="queryParam.userName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="订单编号" prop="orderNo">
                <a-input v-model="queryParam.orderNo" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="退款状态" prop="refundStatus">
                <a-select v-model="returnStatus" placeholder="请选择退款状态">
                  <a-select-option value="1">退款待处理</a-select-option>
                  <a-select-option value="2">待买家发货</a-select-option>
                  <a-select-option value="3">待商家收货</a-select-option>
                  <a-select-option value="4">退款中</a-select-option>
                  <a-select-option value="5">退款成功</a-select-option>
                  <a-select-option value="6">已撤销</a-select-option>
                  <a-select-option value="7">已拒绝退款</a-select-option>
                  <a-select-option value="8">退货退款待处理</a-select-option>


                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="商品名称">
                <a-input placeholder="请输入商品名称" v-model="queryParam.goodsName"></a-input>
              </a-form-item>
            </a-col>


            <a-col :md="8" :sm="24">
              <a-form-item label="成交时间">
                <!--                <a-input placeholder="请输入买家昵称" v-model="queryParam.userName"></a-input>-->
                <a-range-picker @change="onChangeRangePicker" v-model="allXuanzeRngTime" format="YYYY-MM-DD HH:mm:ss"/>
              </a-form-item>
            </a-col>


            <a-col :md="24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="{ float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!--      条件筛选 end-->

      <!--    导出按钮 start  -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:order:export']">
          <a-icon type="download"/>
          导出
        </a-button>
      </div>
      <!--    导出按钮 end  -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: 1100 }">
      >
          <span slot="goodsInfo" slot-scope="text, record">
            <div>
              <div style="float: left;">
                <img style="width: 80px;height: 80px;" v-image-preview
                     :src="record.goodsPicture"
                     preview="商品主图"
                />
              </div>
              <div style="float: left;margin-left: 10px;">
                <div class="ellipsis" style="width: 210px;text-align: left;">{{ record.goodsName }}</div>
                <div class="ellipsis" style="font-size:12px;color:#acacac;width: 210px;text-align: left;">
                  ID:{{ record.id }}
                  <br/>
                  <template v-if="record.goodsCode">商家编码:{{ record.goodsCode }}</template>
                </div>
              </div>
              <div style="clear: both;"></div>
            </div>
        </span>
        <span slot="refundStatus" slot-scope="text, record">
          <a @click="handleInfo(record)">
            <template v-if="record.refundStatus==1">退款待处理</template>
            <template v-if="record.refundStatus==2">待买家发货</template>
            <template v-if="record.refundStatus==3">待商家收货</template>
            <template v-if="record.refundStatus==4">退款中</template>
            <template v-if="record.refundStatus==5">退款成功</template>
            <template v-if="record.refundStatus==6">已撤销</template>
            <template v-if="record.refundStatus==7">已拒绝退款</template>
            <template v-if="record.refundStatus==8">退款中</template>
          </a>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleInfo(record)">详情</a>
<!--          <span v-if="record.refundStatus == 1" v-has="'orderGoods:audit'">-->
<!--            <a-divider type="vertical"/>-->
<!--            <a @click="handleEdit(record)">审核</a>-->
<!--          </span>-->
        </span>
      </a-table>
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <orderGoods-modal ref="modalForm" @ok="getList"></orderGoods-modal>

  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import OrderGoodsModal from './modules/OrderGoodsModal'

import {tableMixin} from "@/store/table-mixin";
import {getUnfinishedCountList, pageReturnOrderList} from "@/api/order/returnorder";

export default {
  name: "aftersalesIndex",
  components: {
    OrderGoodsModal
  },
  mixins: [tableMixin],
  data() {
    return {
      chart: null,
      chartData: [0, 0, 0],
      loading: false,
      queryParam: {
        userName: null,
        orderNo: null,
        goodsName: null,
        pageNum: 1,
        pageSize: 10,
        rangeTime: null,
        refundStatus: null,
        applyType: null
      },
      returnStatus:null,
      columns: [{
        title: '商品信息',
        align: "center",
        dataIndex: 'goodsName',
        width: 320,
        scopedSlots: {customRender: 'goodsInfo'}
      },{
        title: '退款金额',
        align: "center",
        width: 100,
        dataIndex: 'refundMoney'
      }, {
        title: '申请时间',
        align: "center",
        width: 150,
        dataIndex: 'refundApplyTime'
      },{
        title: '买家昵称',
        align: "center",
        width: 80,
        dataIndex: 'userName'
      }, {
        title: '订单编号',
        align: "center",
        width: 180,
        dataIndex: 'orderNo'
      },{
        title: '退款编号',
        align: "center",
        width: 180,
        dataIndex: 'refundSn'
      }, {
        title: '退款原因',
        align: "center",
        dataIndex: 'buyerReason'
      }, {
        title: '退款状态',
        align: "center",
        width: 120,
        dataIndex: 'refundStatus',
        scopedSlots: {customRender: 'refundStatus'}
      }, {
        title: '操作',
        dataIndex: 'action',
        align: "center",
        // fixed: 'right',
        width: 100,
        scopedSlots: {customRender: 'action'},
      }],
      list: [],
      total: 0,
      allXuanzeRngTime: undefined,

    }
  },
  created() {
    this.returnStatus = null;
    // this.loadChart()
    this.getList()
  },
  computed:{
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  mounted() {
    const that = this;
    this.loadChart();
    window.addEventListener("resize", function () {
      if (that.chart) {
        that.chart.resize()
      }
    })
    // this.stop();
  },
  methods: {
    /**
     * 分页数据改变
     * @param current
     * @param pageSize
     */
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },

    /**
     * 改变数据
     * @param current
     * @param pageSize
     */
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    stop() {
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow= 'hidden'
      document.addEventListener('touchmove',mo,false)
    },
    /*
    审核按钮
     */
    handleEdit(record) {
      this.$refs.modalForm.info(record)
      // this.$refs.modalForm.title = "退货详情"
      // this.$refs.modalForm.disableSubmit = false
    },
    handleInfo(record) {
      this.$refs.modalForm.info(record);
      this.$refs.modalForm.title = "退货详情";
      this.$refs.modalForm.disableSubmit = false;
    },
    /**
     * 区间选择
     * @param value
     */
    onChangeRangePicker(value , dateString) {
      if(value.length) {
        this.queryParam.rangeTime = dateString[0] + ' - ' + dateString[1];
      } else {
        this.queryParam.rangeTime = null
      }
      this.getList()
    },
    updateOrderStatus(value) {

    },
    /**
     * 导出按钮
     */
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('/shop/shop-order-refund/export', {
            ...that.queryParam
          }, `退款/售后订单_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    handleQuery() {
      this.queryParam.refundStatus = this.dealRetundStatusAndApplyType(this.returnStatus).refundStatus
      this.queryParam.applyType = this.dealRetundStatusAndApplyType(this.returnStatus).applyType
      this.getList()
    },

    resetQuery() {
      this.queryParam = {
        userName: null,
          orderNo: null,
          goodsName: null,
          refundStatus: null,
          pageNum: 1,
          pageSize: 10,
          rangeTime: null,
        applyType: null
      }
      this.allXuanzeRngTime = undefined
      this.getList()
    },

    getList() {
      this.loading = true
      pageReturnOrderList(this.queryParam).then(res => {
        if(res.code == 20001) {
          this.list = res.data.records
          this.total = res.data.total
          this.loading = false;
        } else {
          this.$message.warning(res.msg)
        }
      })
    },

    changeTab(index) {
      if ("99" == index) {
        index = null
      }


      this.queryParam.refundStatus = this.dealRetundStatusAndApplyType(index).refundStatus
      this.queryParam.applyType = this.dealRetundStatusAndApplyType(index).applyType
      this.getList()
    },

    dealRetundStatusAndApplyType(index) {
      let result = {
        refundStatus:null,
        applyType : null
      }
      if(index == 1) {
        //仅退款(审批)
        result.applyType = 1
        result.refundStatus = 1
      }


      if(index == 2) {
        // 退货退款(未发货)
        result.applyType = 2
        result.refundStatus = 2
      }

      if(index == 3) {
        // 退货退款(已发货)
        result.applyType = 2
        result.refundStatus = 3
      }

      if(index == 4) {
        //  退款中 不等于5、6、7、-1
        result.applyType = null
        result.refundStatus = 99
      }

      if(index == 5) {
        //  退款成功
        result.applyType = null
        result.refundStatus = 5

      }

      if(index == 6) {
        //  已撤销
        result.applyType = null
        result.refundStatus = 6
      }

      if(index == 7) {
        //  已拒绝退款
        result.applyType = null
        result.refundStatus = 7
      }

      if(index == 8) {
        //退货退款(审批)
        result.applyType = 2
        result.refundStatus = 1
      }
      return result;
    },
    /*
      echarts
     */
    loadChart() {
      let that = this;
      that.chart = echarts.init(document.getElementById('chart'))
      that.$nextTick(() => {
        that.chart = echarts.init(document.getElementById('chart'))
      })

      that.chartData = [0, 0, 0]
      getUnfinishedCountList({
        systemId:this.shopInfo ? this.shopInfo.id : ''
      }).then(res => {
        if(res.code == 20001) {
          that.chartData = res.data;
          let echartData = [
            { value: that.chartData[0], name: '退款待处理' },
            { value: that.chartData[1], name: '待买家发货' },
            { value: that.chartData[2], name: '待商家收货' }
          ]
          let option = {
            tooltip: {
              trigger: 'item',
              formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
              orient: 'horizontal',
              x: 'right',
              y: 'center',
              data: ['退款待处理', '待买家发货', '待商家收货'],
              formatter: function (name, item) {
                let value = echartData.filter(i => i.name  == name)[0].value
                return name + ' ' + value
              }
            },
            calculable: true,
            series: [
              {
                name: '未完结',
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['20%', '50%'],
                itemStyle: {
                  normal: {
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  },
                  emphasis: {
                    label: {
                      show: false,
                      position: 'center',
                    }
                  }
                },
                data: echartData
              }
            ]
          }

          that.chart.setOption(option)

        } else {
          this.$message.warning(res.msg);
        }
      })

    }
  }
}
</script>

<style scoped>

</style>