<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺类别名称" prop="categoryName">
                <a-input v-model="queryParam.categoryName" placeholder="请输入店铺类别名称" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="最高发布数量" prop="highestNumber">-->
<!--                <a-input v-model="queryParam.highestNumber" placeholder="请输入最高发布数量" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="店铺是否实名 0否1是" prop="weatherSm">-->
<!--                  <a-input v-model="queryParam.weatherSm" placeholder="请输入店铺是否实名 0否1是" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="店铺是否上传身份证 0否1是" prop="weatherSfz">-->
<!--                  <a-input v-model="queryParam.weatherSfz" placeholder="请输入店铺是否上传身份证 0否1是" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="店铺是否要营业执照 0否1是" prop="weatherZz">-->
<!--                  <a-input v-model="queryParam.weatherZz" placeholder="请输入店铺是否要营业执照 0否1是" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="店铺是否要保证金 0否1是" prop="weatherBzj">-->
<!--                  <a-input v-model="queryParam.weatherBzj" placeholder="请输入店铺是否要保证金 0否1是" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="保证金金额" prop="moneyBzj">-->
<!--                  <a-input v-model="queryParam.moneyBzj" placeholder="请输入保证金金额" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="是否默认类型 0否1是" prop="weatherMr">-->
<!--                  <a-input v-model="queryParam.weatherMr" placeholder="请输入是否默认类型 0否1是" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="店铺资质" prop="shopQc">-->
<!--                  <a-input v-model="queryParam.shopQc" placeholder="请输入店铺资质" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />新增
        </a-button>

        <a-button type="primary" @click="handleSaveSort">
          保存排序
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:shop/category:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" >
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:shop/category:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <input slot="sort" slot-scope="text, record" :value="text" style="width: 100%;text-align: center;" class="ant-input" @change="onCellChange(record.id, $event)">
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['shop:shop/category:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
<!--            <a-icon type="edit" />-->
           编辑
          </a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record)" >
<!--            <a-icon type="delete" />-->
            删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageShopCategory,listShopCategory, delShopCategory ,saveSort} from '@/api/shop/shopCategory'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ShopCategory',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: null,
        // highestNumber: null,
        // weatherSm: null,
        // weatherSfz: null,
        // weatherZz: null,
        // weatherBzj: null,
        // moneyBzj: null,
        // weatherMr: null,
        // shopQc: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },

        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          customRender:function (text, record, index) {
            return index;
          }
        },

        {
          title: '店铺类别名称',
          dataIndex: 'categoryName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          align: "center",
          dataIndex: 'sort',
          width: 100,
          scopedSlots: { customRender: 'sort' }
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '最高发布数量',
        //   dataIndex: 'highestNumber',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店铺是否实名 0否1是',
        //   dataIndex: 'weatherSm',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店铺是否上传身份证 0否1是',
        //   dataIndex: 'weatherSfz',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店铺是否要营业执照 0否1是',
        //   dataIndex: 'weatherZz',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店铺是否要保证金 0否1是',
        //   dataIndex: 'weatherBzj',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '保证金金额',
        //   dataIndex: 'moneyBzj',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否默认类型 0否1是',
        //   dataIndex: 'weatherMr',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店铺资质',
        //   dataIndex: 'shopQc',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      changeSort: []
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleSaveSort:function () {
      const that = this;
      var arr=this.changeSort;
      if(arr.length<1){
        that.$message.warning("暂无数据");
        return;
      }
      saveSort(arr).then((res) => {
        if (res.success) {
          that.$message.success(res.message);
          this.getList();
        } else {
          that.$message.warning(res.message);
        }
      });
      //刷新table

    },
    onCellChange(id,e) {
      var sort=e.target.value;
      var changeSort=this.changeSort;
      var flag=true;
      changeSort.forEach((value) => {
        if(value['id']==id){
          flag=false;
          value['sort']=sort;
          return true;
        }
      });
      if(flag){
        changeSort.push({id:id,sort:sort});
      }
    },
    /** 查询商城-店铺-类别列表 */
    getList () {
      this.loading = true
     pageShopCategory(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        categoryName: undefined,
        // highestNumber: undefined,
        // weatherSm: undefined,
        // weatherSfz: undefined,
        // weatherZz: undefined,
        // weatherBzj: undefined,
        // moneyBzj: undefined,
        // weatherMr: undefined,
        // shopQc: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delShopCategory(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('shop/shop/category/export', {
            ...that.queryParam
          }, `商城-店铺-类别_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
