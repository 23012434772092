import request from '@/utils/request'


// 查询充值套餐列表
export function listRechargeCombo(query) {
  return request({
    url: '/user/user-recharge-combo/list',
    method: 'get',
    params: query
  })
}

// 查询充值套餐分页
export function pageRechargeCombo(query) {
  return request({
    url: '/user/user-recharge-combo/page',
    method: 'get',
    params: query
  })
}

// 查询充值套餐详细
export function getRechargeCombo(data) {
  return request({
    url: '/user/user-recharge-combo/detail',
    method: 'get',
    params: data
  })
}

// 新增充值套餐
export function addRechargeCombo(data) {
  return request({
    url: '/user/user-recharge-combo/add',
    method: 'post',
    data: data
  })
}

// 修改充值套餐
export function updateRechargeCombo(data) {
  return request({
    url: '/user/user-recharge-combo/edit',
    method: 'post',
    data: data
  })
}

// 删除充值套餐
export function delRechargeCombo(data) {
  return request({
    url: '/user/user-recharge-combo/delete',
    method: 'post',
    data: data
  })
}
