import request from '@/utils/request'


// 查询商城-商家退货地址列表
export function listRefundAddr(query) {
  return request({
    url: '/shop/shop-refund-addr/list',
    method: 'get',
    params: query
  })
}

// 查询商城-商家退货地址分页
export function pageRefundAddr(query) {
  return request({
    url: '/shop/shop-refund-addr/page',
    method: 'get',
    params: query
  })
}

// 查询商城-商家退货地址详细
export function getRefundAddr(data) {
  return request({
    url: '/shop/shop-refund-addr/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-商家退货地址
export function addRefundAddr(data) {
  return request({
    url: '/shop/shop-refund-addr/add',
    method: 'post',
    data: data
  })
}

// 修改商城-商家退货地址
export function updateRefundAddr(data) {
  return request({
    url: '/shop/shop-refund-addr/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-商家退货地址
export function delRefundAddr(data) {
  return request({
    url: '/shop/shop-refund-addr/delete',
    method: 'post',
    data: data
  })
}
