import request from '@/utils/request'


// 查询消息模块列表
export function listMessage(query) {
  return request({
    url: '/user/message/list',
    method: 'get',
    params: query
  })
}

// 查询消息模块分页
export function pageMessage(query) {
  return request({
    url: '/user/message/page',
    method: 'get',
    params: query
  })
}

// 查询消息模块详细
export function getMessage(data) {
  return request({
    url: '/user/message/detail',
    method: 'get',
    params: data
  })
}

// 新增消息模块
export function addMessage(data) {
  return request({
    url: '/user/message/add',
    method: 'post',
    data: data
  })
}

// 修改消息模块
export function updateMessage(data) {
  return request({
    url: '/user/message/edit',
    method: 'post',
    data: data
  })
}

// 删除消息模块
export function delMessage(data) {
  return request({
    url: '/user/message/delete',
    method: 'post',
    data: data
  })
}
