<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="平台抽成比例" prop="platformRatio" >
        <a-input-number :min="0" :step="0.01" v-model="form.commissionRatio" placeholder="请输入平台抽成比例" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getShop, addShop, updateShop } from '@/api/shop/shop'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        shopName: null,

        shopType: null,

        userId: null,

        shopAddress: null,

        shopState: null,

        shopCloseInfo: null,

        shopLogo: null,

        shopCover: null,

        shopKeywords: null,

        shopDescription: null,

        shopPhone: null,

        areaCode: null,

        shopCollect: null,

        shopAccount: null,

        settlementAccount: null,

        shopCreateTime: null,

        shopEndTime: null,

        shopScore: null,

        attentionNum: null,

        goodsDescribeScore: null,

        shopDescribeScore: null,

        logisticsScore: null,

        businessLicenceNumberElectronic: null,

        trademarkCertificate: null,

        authCertificate: null,

        shopAreap: null,

        shopAreapId: null,

        shopAreac: null,

        shopAreacId: null,

        shopAreax: null,

        shopAreaxId: null,

        shopQc: null,

        thatViews: null,

        kfAccount: null,

        alarmGoodsStock: null,

        alarmGoodsSkuStock: null,

        shopCategoryId: null,

        weatherBzj: null,

        moneyBzj: null,

        shopProtocol: null,

        businessLicense: null,

        chaopinNo: null,

        shopApplyType: null,

        isLogistics: null,

        isSelfTake: null,

        isStoreDelivery: null,

        shopEnclosure: null,

        lat: null,

        lng: null,

        memberFlag: null,

        memberDueTime: null,

        autoRenew: null,

        commissionRatio: null,

        agencyTax: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        commissionRatio: [
          { required: true, message: '平台抽佣比例不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        shopName: null,
        shopType: null,
        userId: null,
        shopAddress: null,
        shopState: null,
        shopCloseInfo: null,
        shopLogo: null,
        shopCover: null,
        shopKeywords: null,
        shopDescription: null,
        shopPhone: null,
        areaCode: null,
        shopCollect: null,
        shopAccount: null,
        settlementAccount: null,
        shopCreateTime: null,
        shopEndTime: null,
        shopScore: null,
        attentionNum: null,
        goodsDescribeScore: null,
        shopDescribeScore: null,
        logisticsScore: null,
        businessLicenceNumberElectronic: null,
        trademarkCertificate: null,
        authCertificate: null,
        shopAreap: null,
        shopAreapId: null,
        shopAreac: null,
        shopAreacId: null,
        shopAreax: null,
        shopAreaxId: null,
        shopQc: null,
        thatViews: null,
        kfAccount: null,
        alarmGoodsStock: null,
        alarmGoodsSkuStock: null,
        shopCategoryId: null,
        weatherBzj: null,
        moneyBzj: null,
        shopProtocol: null,
        businessLicense: null,
        chaopinNo: null,
        shopApplyType: null,
        platformRatio: null,
        isLogistics: null,
        isSelfTake: null,
        isStoreDelivery: null,
        shopEnclosure: null,
        lat: null,
        lng: null,
        memberFlag: null,
        memberDueTime: null,
        autoRenew: null,
        commissionRatio: null,
        agencyTax: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShop({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShop(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShop(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
