<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="用户id" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" placeholder="请输入用户id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="真实姓名" prop="realName">
                <a-input v-model="queryParam.realName" placeholder="请输入真实姓名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="证件号码" prop="idCard">
                <a-input v-model="queryParam.idCard" placeholder="请输入证件号码" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否实名" prop="isAutonym">
<!--                <a-input v-model="queryParam.isautonym" placeholder="请输入是否实名 1-是 0-否" allow-clear/>-->
                <a-select v-model="queryParam.isAutonym" placeholder="请选择是否实名">
                  <a-select-option value="1">
                    是
                  </a-select-option>
                  <a-select-option value="0">
                    否
                  </a-select-option>

                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="是否1000粉丝" prop="isOneThousandFans">
<!--                  <a-input v-model="queryParam.isOneThousandFans" placeholder="请输入是否1000粉丝 1-是 0-否" allow-clear/>-->
                  <a-select v-model="queryParam.isOneThousandFans" placeholder="请选择是否1000粉丝">
                    <a-select-option value="1">
                      是
                    </a-select-option>
                    <a-select-option value="0">
                      否
                    </a-select-option>

                  </a-select>
                </a-form-item>
              </a-col>


<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="审核意见" prop="auditOpinion">-->
<!--                  <a-input v-model="queryParam.auditOpinion" placeholder="请输入审核意见" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="分类名称" prop="cateName">-->
<!--                  <a-input v-model="queryParam.cateName" placeholder="请输入分类名称" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['live:apply:add']">-->
        <!--          <a-icon type="plus" />新增-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['live:apply:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['live:apply:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['live:apply:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <!--        <table-setting-->
        <!--          :style="{float: 'right'}"-->
        <!--          :table-size.sync="tableSize"-->
        <!--          v-model="columns"-->
        <!--          :refresh-loading="loading"-->
        <!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
<!--      <create-form-->
<!--        ref="createForm"-->
<!--        @ok="getList"-->
<!--      />-->

      <refuse-apply ref="refuseApply" @ok="getList"/>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['live:apply:edit']"/>-->
          <!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['live:apply:edit']">-->
          <!--            <a-icon type="edit" />修改-->
          <!--          </a>-->
                       <a-popconfirm title="确认通过吗?" @confirm="() => handleUpdate(record)" v-if="record.status == 1" v-hasPermi="['live:apply:edit']">

          <a>
            通过
          </a>
                       </a-popconfirm>

                    <a-divider type="vertical" v-hasPermi="['live:apply:edit']" v-if="record.status == 1"/>
          <!-- v-if="record == 0"-->
            <a @click="$refs.refuseApply.handleUpdate(record, undefined)" v-if="record.status == 1"
               v-hasPermi="['live:apply:edit']">
            拒绝
          </a>
          <a-divider type="vertical" v-hasPermi="['live:apply:remove']" v-if="record.status == 1"/>
          <a @click="handleDelete(record)" v-hasPermi="['live:apply:remove']">
<!--            <a-icon type="delete"/>-->
            删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {delApply, pageApply, updateApply} from '@/api/live/apply'
// import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import RefuseApply from "./modules/RefuseApply"
export default {
  name: 'Apply',
  components: {
    // CreateForm ,
    RefuseApply
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        isAutonym: null,
        isOneThousandFans: null,
        realName: null,
        idCard: null,
        status: null,
        auditOpinion: null,
        cateName: null,
        pageNum: 1,
        pageSize: 10,
        sortField:'createTime',
        sortOrder:'DESCEND'
      },
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          customRender: function (text, record, index) {
            return (index + 1)
          }
        },
        {
          title: '主播类型',
          dataIndex: 'clientType',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if(text==1) {
              return '普通主播'
            } else {
              return '交友主播'
            }
          }
        },
        {
          title: '真实姓名',
          dataIndex: 'realName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '证件号码',
          dataIndex: 'idCard',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '用户id',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '是否实名',
          dataIndex: 'isAutonym',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1) {
              return '是'
            } else {
              return '否'
            }
          }
        },
        {
          title: '是否1000粉丝',
          dataIndex: 'isOneThousandFans',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1) {
              return '是'
            } else {
              return '否'
            }
          }
        },

        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1) {
              return '待审核'
            } else if(text == 2){
              return '已通过'
            } else {
              return '已拒绝'
            }
          }
        },
        {
          title: '审核意见',
          dataIndex: 'auditOpinion',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '分类名称',
        //   dataIndex: 'cateName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 确认通过
     */
    handleUpdate(record) {
      let formData = {
        id: record.id,
        status: 2,
        auditOpinion: record.auditOpinion
      }
      updateApply(formData).then(response => {
        this.getList()
      }).finally(() => {
        // this.submitLoading = false
      })
    },
    /** 查询主播申请列表 */
    getList() {
      this.loading = true
      pageApply(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: null,
        isAutonym: null,
        isOneThousandFans: null,
        realName: null,
        idCard: null,
        status: null,
        auditOpinion: null,
        cateName: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delApply(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('live/apply/export', {
            ...that.queryParam
          }, `主播申请_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
