import request from '@/utils/request'


// 查询交友会员套餐列表
export function listFriendCombo(query) {
  return request({
    url: '/user/vip-friend-combo/list',
    method: 'get',
    params: query
  })
}

// 查询交友会员套餐分页
export function pageFriendCombo(query) {
  return request({
    url: '/user/vip-friend-combo/page',
    method: 'get',
    params: query
  })
}

// 查询交友会员套餐详细
export function getFriendCombo(data) {
  return request({
    url: '/user/vip-friend-combo/detail',
    method: 'get',
    params: data
  })
}

// 新增交友会员套餐
export function addFriendCombo(data) {
  return request({
    url: '/user/vip-friend-combo/add',
    method: 'post',
    data: data
  })
}

// 修改交友会员套餐
export function updateFriendCombo(data) {
  return request({
    url: '/user/vip-friend-combo/edit',
    method: 'post',
    data: data
  })
}

// 删除交友会员套餐
export function delFriendCombo(data) {
  return request({
    url: '/user/vip-friend-combo/delete',
    method: 'post',
    data: data
  })
}

export function changeStatusVip(data) {
  return request({
    url: '/user/vip-friend-combo/changeStatus',
    method: 'post',
    data: data
  })
}
