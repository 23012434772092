<template>
  <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
    <a-drawer id="antdVue" class="  diypageuser_modal_0" :centered="false" :closable="true" :mask="true" :mask-closable="true" placement="right" title="用户信息" width="50%" height="50%" :z-index="1000" :maskClosable="true" :visible='eiditFlag'>
      <a-form-model ref="formRef1685517156731" class="benben-flex-form diypageuser_modal0_0" :model="subData" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
        <a-form-model-item need="false" :rules="[]" :colon="true" label="标题">
          <a-input class="diypageuser_modal0_0_c0" v-model="subData.userName" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item need="false" :rules="[]" :colon="true" label="标题">
          <a-input-number class="diypageuser_modal0_0_c1" v-model="subData.mobile" :disabled="false" :max="Infinity" :min="-Infinity" step="1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input-number>
        </a-form-model-item>
        <a-row class="benben-grid width_fullscreen diypageuser_modal0_0_c2" layout="24" :gutter="[8,8]">

          <a-col :span="24" class="diypageuser_modal0_0_c2_c0">
            <a-button class="diypageuser_modal0_0_c2_c0_c0" :disabled="false" type="primary">保存</a-button>

            <a-button :disabled="false" @click="addEditpage()">取消</a-button>


          </a-col>




          <a-col :span="0">

          </a-col>

        </a-row>
      </a-form-model>
    </a-drawer>

    <!---弹性布局flex布局开始-->
    <div class="flex benben-flex-layout width_fullscreen flex-wrap  diypageuser_flex_1">
      <a-form-model ref="formRef1685516825708" class="benben-flex-form diypageuser_fd1_0" :model="formData" label-align="right" layout="inline" :label-col="{}" :wrapper-col="{}">
        <a-form-model-item need="false" :rules="[]" :colon="true" label="用户名称">
          <a-input class="diypageuser_fd1_0_c0" v-model="formData.userName" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item need="false" :rules="[]" :colon="true" label="手机号">
          <a-input-number class="diypageuser_fd1_0_c1" v-model="formData.mobile" :disabled="false" :max="Infinity" :min="-Infinity" step="1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input-number>
        </a-form-model-item>
        <a-button class="diypageuser_fd1_0_c2" :disabled="false" type="primary" @click="getList()">查询</a-button>

        <a-button class="diypageuser_fd1_0_c3" :disabled="false">重置</a-button>

      </a-form-model>
      <div class="flex  flex-wrap align-center diypageuser_fd1_1" isAlone="false">
        <a-button class="diypageuser_fd1_1_c0" :disabled="false" @click="addEditpage()">新增</a-button>


      </div>

      <a-table class="diypageuser_otherTablefd1_2" tableLayout="fixed" :bordered="true" size="small" :pagination="false" :data-source="userList" :columns="columns_9279">
        <template slot="userId" slot-scope="text,record,index"><span>{{text}}</span></template>
        <template slot="userName" slot-scope="text,record,index"><span>{{text}}</span></template>
        <template slot="nickname" slot-scope="text,record,index"><span>{{text}}</span></template>
        <template slot="status" slot-scope="text,record,index">
          <a-switch :disabled="false" :value="text"></a-switch>
        </template>
        <template slot="operation" slot-scope="text,record,index"> <a href="javascript:;" @click="()=>{}" style="color:#e6a23c;">详情</a>
          <a href="javascript:;" @click="()=>{}" style="color:#409eff">编辑</a>
          <a-popconfirm title="确定删除?" @confirm="()=>{}"><a href="javascript:;" style="color:#f56c6c">删除</a></a-popconfirm>
        </template>

      </a-table>

    </div>
    <!---弹性布局flex布局结束-->



  </div>
</template>

<script>
import {
  receive,
  benbenSelectDiy,
  benbenFlexNumberBox,
  scrollList,
  treeSelect,
  simpleSelect,
  multipleSelect,
  cascaderForm,
  checkboxForm,
  switchForm,
  messageReply,
  rangePicker,
  handSignature,
  mixinAddTableItem,
  editorForm,
  qiunDataCharts,
  copyText,
  mixinTableItemChange,
  in_array,
  themeColorInit,
  getUrlKey
} from '@/assets/diypaaspc/diy-pass-pc.js'
import {
  provinceForm
} from '@/assets/diypaaspc/province_area.js'
import request from '@/utils/request'
export default {
  name: "goods",
  components: {
    qiunDataCharts,
    benbenSelectDiy,
    //            productSelect,
    benbenFlexNumberBox,
    scrollList,
    treeSelect,
    simpleSelect,
    messageReply,
    rangePicker,
    multipleSelect,
    provinceForm,
    editorForm,
    cascaderForm,
    switchForm,
    checkboxForm,
    //            uploadImage,
    //            uploadFile,
    //            uploadMedia,
    //            uploadSingleFile,
    handSignature
  },
  data() {
    receive(this);
    const themeColorConfig = {
      "presetApi": {
        "getClassifyOne": "",
        "getClassifyTow": "",
        "getClassifyShop": ""
      },
      "themeType": 0,
      "themeConfig": [{
        "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
        "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
        "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
      }]
    };
    return {
      themeColorConfig,
      "columns_9279": [{
        "show": true,
        "title": "用户编号",
        "dataIndex": "userId",
        "key": "1685516517797",
        "fixed": false,
        "align": "left",
        "ellipsis": true,
        "type": "text",
        "scopedSlots": {
          "customRender": "userId"
        },
        "slots": {
          "title": "customuserId"
        }
      }, {
        "show": true,
        "title": "用户名称",
        "dataIndex": "userName",
        "key": "1685517345983",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "userName"
        },
        "slots": {
          "title": "customuserName"
        }
      }, {
        "show": true,
        "title": "用户昵称",
        "dataIndex": "nickname",
        "key": "1685516799145",
        "ellipsis": true,
        "fixed": false,
        "align": "left",
        "type": "text",
        "autoTitle": false,
        "scopedSlots": {
          "customRender": "nickname"
        },
        "slots": {
          "title": "customnickname"
        }
      }, {
        "show": true,
        "title": "状态",
        "dataIndex": "status",
        "key": "1685517002832",
        "ellipsis": true,
        "fixed": false,
        "align": "left",
        "type": "switch",
        "autoTitle": false,
        "scopedSlots": {
          "customRender": "status"
        },
        "slots": {
          "title": "customstatus"
        }
      }, {
        "show": false,
        "title": "操作",
        "dataIndex": "operation",
        "key": "operation",
        "fixed": false,
        "align": "left",
        "ellipsis": true,
        "type": "operation",
        "scopedSlots": {
          "customRender": "operation"
        }
      }],
      "formData": {
        "userName": "",
        "mobile": ""
      },
      "userList": [],
      "subData": {
        "userName": "",
        "mobile": ""
      },
      "eiditFlag": false,
      "userInfo": {
        "modifier": "",
        "roles": [],
        "phonenumber": "",
        "admin": false,
        "loginDate": "",
        "remark": "",
        "delFlag": "",
        "password": "",
        "postIds": [],
        "loginIp": "",
        "email": "",
        "creator": "",
        "nickname": "",
        "roleId": 0,
        "sex": "",
        "deptId": 0,
        "updateTime": "",
        "avatar": "",
        "dept": {
          "deptName": "",
          "leader": "",
          "creator": "",
          "modifier": "",
          "deptId": 0,
          "orderNum": 0,
          "remark": "",
          "updateTime": "",
          "delFlag": "",
          "params": "",
          "parentId": 0,
          "parentName": "",
          "children": [],
          "createTime": "",
          "phone": "",
          "ancestors": "",
          "email": "",
          "status": ""
        },
        "params": "",
        "userName": "",
        "userId": 0,
        "roleIds": [],
        "createTime": "",
        "status": ""
      }
    };

  },
  computed: {

  },
  created() {
    this.getList()
  },
  mounted() {

  },
  beforeDestroy() {

  },
  watch: {},
  methods: {
    copyText,
    mixinTableItemChange,
    in_array,
    themeColorInit,
    //获取数据
    getList() {
      //请求方法
      //数据验证

      request({
        url: '/test/user/list',
        method: 'post',
        data: {
          phone: this.formData.mobile,
          userName: this.formData.userName
        }
      }).then((res) => {
        if (res.code != '20001') {
          this.$message.info(res.msg);
          return
        }
        let infouserList = res;

        this.userList = infouserList.data



      })
    },
    //弹窗显隐
    addEditpage() {
      if (this.eiditFlag === true) {
        this.eiditFlag = false;
      } else {
        this.eiditFlag = true;
      }
    },
    //保存
    saveData() {
      this.$refs.formRef1685516825708.validate(valid => {
        if (valid) {
          //请求方法
          //数据验证

          $.ajax({
            type: 'GET',
            url: baseURL + '/api/m' + member_id + '/',
            data: {},
            headers: {
              'user-token': user_token
            },
            dataType: 'json',
            success: res => {
              if (res.code != 1) {
                this.$message.info(res.msg);
                return
              }




            }

          })

        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

.antdVue {
  width: 100%;
  background: #F8F8F8;
  background-size: 100% auto !important;
}

#antdVue .diypageuser_modal0_0_c2_c0_c0 {
  margin: 0px 10px 0px 0px;
}

#antdVue .diypageuser_modal0_0_c2_c0 {
  text-align: right;
}

#antdVue .diypageuser_modal0_0_c2 {
  background: #fff;
  width: 100%;
}

#antdVue .diypageuser_modal0_0_c1 {
  width: 100%;
  font-size: 14px;
}

#antdVue .diypageuser_modal0_0_c0 {
  font-size: 14px;
}

#antdVue .diypageuser_modal0_0 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .diypageuser_modal_0 {
  width: 100%;
}

#antdVue .diypageuser_flex_1 {
  width: 100%;
  min-height: 100px;
  padding: 0px 0px 110px 0px;
}

#antdVue .diypageuser_otherTablefd1_2 {
  margin: 10px 0px 0px 0px;
}

#antdVue .diypageuser_fd1_1_c0 {
  margin: 0px 0px 0px 10px;
}

#antdVue .diypageuser_fd1_1 {
  width: 100%;
}

#antdVue .diypageuser_fd1_0_c3 {
  margin: 0px 0px 0px 10px;
}

#antdVue .diypageuser_fd1_0_c2 {
  margin: 5px 0px 0px 0px;
}

#antdVue .diypageuser_fd1_0_c1 {
  width: 100%;
  font-size: 14px;
}

#antdVue .diypageuser_fd1_0_c0 {
  font-size: 14px;
}

#antdVue .diypageuser_fd1_0 {
  background: #fff;
  width: 100%;
}
</style>