<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="分类名称">
                <a-input v-model="queryParam.categoryName" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="是否显示">
                  <a-select placeholder="请选择" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type['sys_normal_disable']" :key="index"
                      :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click=" addCateGory" v-hasPermi="['system:goodscate:add']">
          <a-icon type="plus" />新增
        </a-button>

        <a-button type="primary" @click="handleSaveSort" v-hasPermi="['system:goodscate:add']">
          保存排序
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"-->
<!--          v-hasPermi="['system:goodscate:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:goodscate:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"-->
<!--          :refresh-loading="loading" @refresh="getList" />-->
<!--        -->

      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :statusOptions="dict.type['sys_normal_disable']" @ok="xinzengchenggongOk" />
      <goodsListModal ref="goodsListModal"></goodsListModal>
      <goodsCategoryModalForm ref="goodsCategoryModalForm"></goodsCategoryModalForm>
      <!-- 数据展示 -->
<!--      :row-selection='{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }'-->
<!--      @expand="handleExpand"-->

<!--      :expandedRowKeys="expandedRowKeys"-->
      <a-table :loading="loading" :size="tableSize"  rowKey="id" :columns="columns" :data-source="list"
        :pagination="false" :bordered="tableBordered"
               :expandedRowKeys="expandedRowKeys"
               :row-selection='rowSelection'
               @expandedRowsChange="handleExpand"
      >
        <span slot="img" slot-scope="text, record">
          <a-avatar :src="text" shape="square" size="large">
          </a-avatar>
        </span>
<!--        <span slot="status" slot-scope="text, record">-->
<!--          <dict-tag :options="dict.type['sys_show_hide']" :value="record.status" />-->
<!--          <a-popconfirm ok-text="是" cancel-text="否" @confirm="confirmHandleStatus(record)"-->
<!--            @cancel="cancelHandleStatus(record)">-->
<!--            <span slot="title">确认<b>{{ record.status === '1' ? '显示' : '隐藏' }}</b>这篇文章吗?</span>-->
<!--            <a-switch checked-children="开" un-checked-children="关" :checked="record.status == 1" />-->
<!--          </a-popconfirm>-->
<!--        </span>-->
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
<!--        <a-image-->
<!--          :width="248"-->
<!--          slot-scope="text, record"-->
<!--          slot="categoryPic"-->
<!--          :src="url.imgerver+'/'+record.categoryPic"-->
<!--        />-->
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.categoryPic" slot="categoryPic" slot-scope="text, record">

        <input slot="sort" slot-scope="text, record" :value="text" style="width: 100%;text-align: center;" class="ant-input"  @change="onCellChange(record.id, $event)">

        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record,undefined)" v-hasPermi="['system:goodscate:edit']">
<!--            <a-icon type="edit" />-->
            编辑
          </a>
          <a-divider type="vertical" v-hasPermi="['system:goodscate:remove']" />
<!--          <a @click="handleDelete(record)" v-hasPermi="['system:goodscate:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
<!--          <a @click="handleArticleList(record)" v-hasPermi="['system:goodscate:remove']">-->
<!--            <a-icon type="eye" />查看商品-->
<!--          </a>-->
           <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down"/></a>
            <a-menu slot="overlay">
              <a-menu-item v-if="record.level<3">
                  <a @click="addChildrenCateGory(record,1)">新增下級</a>
              </a-menu-item>
               <a-menu-item v-if="record.level<3">
                  <a @click="handleArticleList(record)">查看商品</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确认删除吗?" @confirm="() => handleDeleteSingle(record)">
                  <a>刪除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import CreateForm from './modules/CreateForm'
import goodsCategoryModalForm from './modules/GoodsCategoryModal'
import goodsListModal from "./modules/GoodsListModal"
import { tableMixin } from '@/store/table-mixin'
// import * as article from '@/api/mall/article'
import * as goodscate from '@/api/mall/goodscate'
import GenInfoForm from '@/views/tool/gen/modules/GenInfoForm.vue'
import {childList, saveSort} from "@/api/mall/goodscate";

export default {
  name: 'Post',
  components: {
    CreateForm,
    goodsCategoryModalForm,
    goodsListModal
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      url:{
        imgerver: window._CONFIG['fileURL']
      },
      hasChildrenField: "hasChild",
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryName:''
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   align: 'center'
        // },
        {
          title: '类型',
          align: "left",
          dataIndex: 'type',
          customRender: function (value) {
            if (value == 1) {
              return "嗨宝";
            } else {
              return "嗨豆";
            }
          }
        },
        {
          title: '分类名称',
          dataIndex: 'categoryName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分类图片',
          dataIndex: 'categoryPic',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'categoryPic' }

        },
        {
          title: '是否首页显示',
          align: "center",
          dataIndex: 'isHomePage',
          customRender: function (value) {
            if (value == 0) {
              return "不显示";
            } else {
              return "显示";
            }
          }
        },
        {
          title: '是否显示',
          align: "center",
          dataIndex: 'isVisible',
          customRender: function (value) {
            if (value == 0) {
              return "不显示";
            } else {
              return "显示";
            }
          }
        },
        {
          title: '是否推荐',
          align: "center",
          dataIndex: 'isRecommend',
          customRender: function (value) {
            if (value == 0) {
              return "否";
            } else {
              return "是";
            }
          }
        },
        {
          title: '排序',
          align: "center",
          dataIndex: 'sort',
          width: 100,
          scopedSlots: { customRender: 'sort' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      changeSort:[],
      expandedRowKeys: [],

    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange:this.onSelectChange,
        hideDefaultSelections: true,
        onSelect: this.onSelect,//触发级联的方法
      }
    }
  },
  watch: {
  },
  methods: {
    xinzengchenggongOk() {
      this.getList()
    },
    handleExpand(expandedRowKeys, record) {
      this.expandedRowKeys = expandedRowKeys;
      console.log(expandedRowKeys);
      console.log('是否打开了');
      // 判斷是否是展開狀態
      // if (expanded) {
      //   this.expandedRowKeys.push(record.id)
      //   if (record.children.length > 0 && record.children[0].isLoading === true) {
      //     let params = this.queryParam;//查詢條件
      //     params["pid"] = record.id;
      //     childList(params).then((res) => {
      //       if (res.success) {
      //         if (res.data.records && res.data.records.length > 0) {
      //           record.children = this.getDataByResult(res.data.records)
      //           this.list = [...this.list]
      //         } else {
      //           record.children = ''
      //           record.hasChildrenField = '0'
      //         }
      //       } else {
      //         this.$message.warning(res.message)
      //       }
      //     })
      //   }
      // } else {
      //   let keyIndex = this.expandedRowKeys.indexOf(record.id)
      //   if (keyIndex >= 0) {
      //     this.expandedRowKeys.splice(keyIndex, 1);
      //   }
      // }
    },
    /**
     * 修改哪个排序
     * @param id
     * @param e
     */
    onCellChange(id,e) {
      var sort=e.target.value;
      var changeSort=this.changeSort;
      var flag=true;
      changeSort.forEach((value) => {
        if(value['id']==id){
          flag=false;
          value['sort']=sort;
          return true;
        }
      });
      if(flag){
        changeSort.push({id:id,sort:sort});
      }
    },

    /**
     * 保存排序
     */
    handleSaveSort:function () {
      const that = this;
      var arr=this.changeSort;
      if(arr.length<1){
        that.$message.warning("暂无数据");
        return;
      }
     saveSort(arr).then((res) => {
        if (res.success) {
          that.$message.success(res.message);
          this.getList();
        } else {
          that.$message.warning(res.message);
        }
      });
      //刷新table

    },

    /**
     * 新增
     */
    addCateGory() {
      let newRecord = {
        pid:'',
        id:null,
        categoryName:'',
        isRecommend:0,
        isMangguoShow:0,
        isHomePage:0,
        isVisible:1,
        sort:0,
        type: null,
        level:0
      }
      this.$refs.createForm.handleAdd(newRecord,1)
    },

    /**
     * 新增下级
     * @param record
     */
    addChildrenCateGory(record) {
      let newRecord = {
        pid:record.id,
        categoryName:'',
        isRecommend:0,
        isMangguoShow:0,
        isHomePage:0,
        isVisible:1,
        sort:0,
        type: record.type,
        level:record.level
      }
      // if(record.level == 1) {
      // //  一级
      //
      // }
      //
      // if(record.level == 2) {
      //   //  一级
      // }
      this.$refs.createForm.handleAdd(newRecord,2)

    },
    handleEdit: function (record) {
      this.$refs.goodsCategoryModalForm.edit(record);
      this.$refs.goodsCategoryModalForm.title = "编辑";
      this.$refs.goodsCategoryModalForm.disableSubmit = false;
    },
    /** 查询部门列表 */
    getList() {
      this.loading = true
      goodscate.rootList(this.queryParam).then(response => {
        // this.list = this.getDataByResult(response.data.records);
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
        // this.$nextTick(() => {
        //   this.list = this.getDataByResult(response.data.records);
        //   this.total = response.data.total
        //   this.loading = false
        // })
        // location.reload()
      })
    },
    getDataByResult(result) {
      return result.map(item => {
        //判斷是否標記了帶有子節點
        if (item[this.hasChildrenField] == '1') {
          let loadChild = {id: item.id + '_loadChild', name: 'loading...', isLoading: true}
          item.children = [loadChild]
        }
        return item
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        categoryName:'',
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys , selectedRows) {
      console.log('selectedRowKeys')
      console.log(selectedRowKeys)
      console.log(selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // console.log(selectedRows)
      this.ids = this.selectedRows.map(item => item.id)
      // this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    onSelect(record, selected){//每个复选框点击都会触发
      // console.log(record);
      // console.log(selected);
      const selectrows=[];
      // const selectrows=[record.id];
      if(record.hasOwnProperty('children')){
        const generator = (record) => {
          //这里做一个递归查找
          if(record && record.length) {
            record.forEach(item => {
              selectrows.push(item.id)
              if (item.children && item.children.length > 0) {
                generator(item.children)
              }
            })
          }
        }
        generator(record.children)
      }
      const newselect=this.selectedRowKeys.filter(item=>!selectrows.includes(item))
      //选中和取消选中的级联
      selected ? (this.selectedRowKeys = [...this.selectedRowKeys,...selectrows]):(this.selectedRowKeys = newselect)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleDeleteSingle(row) {
      var that = this
      goodscate.del([row.id])
        .then((res) => {
          if(res.code == 20001) {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success(
              '删除成功',
              3
            )
            // location.reload()
          } else {
            that.$message.warning(res.message)
          }

        })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const postIds =this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + postIds + '的数据',
        onOk() {
          goodscate.del(postIds)
            .then((res) => {
              if(res.code == 20001) {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  '删除成功',
                  3
                )
                // location.reload()
              } else {
                that.$message.warning(res.message)
              }

            })
        },
          onCancel() {
            //取消清空删除ids列表
            that.ids = []
          }
      }
      )

    },
    /** 查看当前分类下的 文章列表 **/
    handleArticleList(info) {
      this.$refs.goodsListModal.open(info)
      // this.$router.push({
      //   path: '/mall/goods',
      //   query: { id: info.id }
      // })
    }
  }
}
</script>
