import request from '@/utils/request'


// 查询用户信息列表
export function listUserAdmin(query) {
  return request({
    url: '/userAdmin/userAdmin/list',
    method: 'get',
    params: query
  })
}

// 查询用户信息分页
export function pageUserAdmin(query) {
  return request({
    url: '/userAdmin/userAdmin/querypage',
    method: 'get',
    params: query
  })
}
export function queryVipPage(query) {
  return request({
    url: '/userAdmin/userAdmin/queryVipPage',
    method: 'get',
    params: query
  })
}


// 新增用户信息
export function resetPwd(data) {
  return request({
    url: '/userAdmin/userAdmin/resetPwd',
    method: 'post',
    data: data
  })
}
// 查询用户信息详细
export function getUserAdmin(data) {
  return request({
    url: '/userAdmin/userAdmin/detail',
    method: 'get',
    params: data
  })
}

export function updateOnline(data) {
  return request({
    url: '/userAdmin/userAdmin/updateIsAdmin',
    method: 'get',
    params: data
  })
}

// 新增用户信息
export function addUserAdmin(data) {
  return request({
    url: '/userAdmin/userAdmin/add',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUserAdmin(data) {
  return request({
    url: '/userAdmin/userAdmin/edit',
    method: 'post',
    data: data
  })
}

// 删除用户信息
export function delUserAdmin(data) {
  return request({
    url: '/userAdmin/userAdmin/delete',
    method: 'post',
    data: data
  })
}

export function disabledUser(data) {
  return request({
    url: '/userAdmin/userAdmin/disabledUser',
    method: 'post',
    data: data
  })
}
