<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="模块类型" prop="clientType">-->
<!--        <a-select v-model="form.clientType">-->
<!--          <a-select-option :key="1">短视频端</a-select-option>-->
<!--          <a-select-option :key="2">交友端</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="图片信息" prop="imgUrl" >
<!--        <a-input v-model="form.imgUrl" placeholder="请输入图片信息" />-->
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <!--          {{baseimgerver}}-->
          <img v-if="form.imgUrl" :src="form.imgUrl" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:327*164 </span>
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>

<!--      <a-form-model-item label="发送人" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入发送人" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="接收人" prop="issuerId" >-->
<!--        <a-input v-model="form.issuerId" placeholder="请输入接收人" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="外部资源id" prop="foreignId" >-->
<!--        <a-input v-model="form.foreignId" placeholder="请输入外部资源id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="排序" prop="seq" >
        <a-input v-model="form.seq" placeholder="请输入排序" />
      </a-form-model-item>
<!--      <a-form-model-item label="0-未删除 1-删除" prop="applyDel" >-->
<!--        <a-input v-model="form.applyDel" placeholder="请输入0-未删除 1-删除" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="0-未删除 1-删除" prop="auditDel" >-->
<!--        <a-input v-model="form.auditDel" placeholder="请输入0-未删除 1-删除" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="分类名称" prop="cateName" >-->
<!--        <a-input v-model="form.cateName" placeholder="请输入分类名称" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMessage, addMessage, updateMessage } from '@/api/user/message'
import Editor from '@/components/Editor'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: 1,
        clientType: null,
        title: null,

        content: null,

        imgUrl: null,

        userId: null,

        issuerId: null,

        foreignId: null,

        seq: null,

        applyDel: null,

        auditDel: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      uploadLoading:false,
      rules: {
        clientType: [{
          required: true, message: '模块类型不能为空'
        }],
        title: [{
          required: true, message: '标题不能为空', trigger: 'blur'
        }]
        // cateName: [
        //   { required: true, message: '分类名称不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.imgUrl = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: 1,
        clientType: null,
        title: null,
        content: null,
        imgUrl: null,
        userId: null,
        issuerId: null,
        foreignId: null,
        seq: null,
        applyDel: null,
        auditDel: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessage({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          debugger
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMessage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMessage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
