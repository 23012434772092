<template xmlns:font-size="http://www.w3.org/1999/xhtml">
  <a-drawer
    :title="title"
    :width="577"
    @close="onClose"
    :visible="visible"
    :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
  >
    <a-card title="账户信息" :bordered="false">
      <div class="content-block" v-if="model.availableMoney">
        <div>账户余额：</div>
        <div>{{model.availableMoney}}</div>
      </div>
      <div class="content-block"  v-if="model.freezeMoney">
        <div>冻结金额：</div>
        <div>{{model.freezeMoney}}</div>
      </div>
      <div class="content-block"  v-if="model.withdrawMoney">
        <div>提现金额：</div>
        <div>{{model.withdrawMoney}}</div>
      </div>
      <div class="content-block"  v-if="model.openMoney">
        <div>开通费用：</div>
        <div>{{model.openMoney}}</div>
      </div>
      <div class="content-block"  v-if="model.screenshot">
        <div>打款截图：</div>
        <div class="anty-img-wrap">
          <img style="width: 140px;height: 140px" :src='model.screenshot' alt='打款截图' />
        </div>
      </div>
      <div class="content-block"  v-if="model.payNote">
        <div>打款备注：</div>
        <div>{{model.payNote}}</div>
      </div>

    </a-card >
  </a-drawer>
</template>

<script>
import { getShopApply, addShopApply, updateShopApply } from '@/api/shop/shopApply'
export default {
  name: "AccountDetails",
  props: {
  },
  components: {
  },
  data() {
    return {
      title: "详情",
      visible: false,
      model: {},
      labelCol: {
        xs: {span: 24},
        sm: {span: 5},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
      uploadLoading: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      url: {
        imgerver: window._CONFIG['fileURL'],
      },
    }
  },
  created() {
  },
  methods: {
    show(record) {
      this.model = record.accountMessage;
      this.visible = true;
    },
    onClose() {
      this.visible = false
    },

  }
}
</script>

<style lang="less" scoped>
.content-block {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  line-height: 37px;
  flex-wrap: wrap;
}
</style>
