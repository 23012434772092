import request from '@/utils/request'


// 查询商城-品牌列表
export function listBrand(query) {
  return request({
    url: '/shop/shop-brand/list',
    method: 'get',
    params: query
  })
}

// 查询商城-品牌分页
export function pageBrand(query) {
  return request({
    url: '/shop/shop-brand/page',
    method: 'get',
    params: query
  })
}

// 查询商城-品牌详细
export function getBrand(data) {
  return request({
    url: '/shop/shop-brand/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-品牌
export function addBrand(data) {
  return request({
    url: '/shop/shop-brand/add',
    method: 'post',
    data: data
  })
}

// 修改商城-品牌
export function updateBrand(data) {
  return request({
    url: '/shop/shop-brand/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-品牌
export function delBrand(data) {
  return request({
    url: '/shop/shop-brand/delete',
    method: 'post',
    data: data
  })
}

// 删除商城-品牌
export function updateBrandEnable(data) {
  return request({
    url: '/shop/shop-brand/updateBrandEnable',
    method: 'get',
    params: data
  })
}


export  function saveSort(data) {
  return request({
    url:  '/shop/shop-brand/saveSort',
    method: 'post',
    data: data
  })
}
