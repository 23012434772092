import request from '@/utils/request'
const userApi = {
  add: '/shop/shop-goods-category/add',
  del: '/shop/shop-goods-category/delete',
  update: '/shop/shop-goods-category/edit',
  editStatus: '/goodscate/update-status',
  detail: '/shop/shop-goods-category/detail',
  detailByMId: '/goodscate/get-channel',
  list: '/shop/shop-goods-category/list',
  rootList:'/shop/shop-goods-category/rootList',
  queryGoodsMenu:'/shop/shop-goods-category/queryGoodsMenu',
  saveSort:'/shop/shop-goods-category/saveSort',
  childList:'/shop/shop-goods-category/childList',
  initDictData:'/shop/shop-shop-trade/list',
  getAllGoodsCategoryList:'/shop/shop-goods-category/getAllGoodsCategoryList',
  getLevelList: '/shop/shop-goods-category/getLevelList'
}

// 查询商城-物流公司详细
export function getLevelList(data) {
  return request({
    url: userApi.getLevelList,
    method: 'get',
    params: data
  })
}
// 查询商城-物流公司详细
export function detail(data) {
  return request({
    url: userApi.detail,
    method: 'get',
    params: data
  })
}

export function getAllGoodsCategoryList(data) {
  return request({
    url: userApi.getAllGoodsCategoryList,
    method: 'get',
    params: data
  })
}
// 查询分类列表
export function list (query) {
  return request({
    url: userApi.list,
    method: 'get',
    params: query
  })
}

export function initDictData () {
  return request({
    url: userApi.initDictData,
    method: 'get'
  })
}

export function rootList (query) {
  return request({
    url: userApi.rootList,
    method: 'get',
    params: query
  })
}

export function childList(query) {
  return request({
    url: userApi.childList,
    method: 'get',
    params: query
  })
}
export function queryGoodsMenu(query) {
  return request({
    url: userApi.queryGoodsMenu,
    method: 'get',
    params: query
  })
}

// 新增分类
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删
export function del (data) {
  return request({
    url: userApi.del,
    method: 'post',
    // params: parameter,
    data: data

  })
}
// 修改分类
export function update (data) {
  return request({
    url: userApi.update,
    method: 'post',
    data: data
  })
}


export  function saveSort(data) {
  return request({
    url: userApi.saveSort,
    method: 'post',
    data: data
  })
}
