import request from '@/utils/request'


// 查询短视频音乐列表
export function listVideoMusic(query) {
  return request({
    url: '/video/video-video-music/list',
    method: 'get',
    params: query
  })
}

// 查询短视频音乐分页
export function pageVideoMusic(query) {
  return request({
    url: '/video/video-video-music/page',
    method: 'get',
    params: query
  })
}

// 查询短视频音乐详细
export function getVideoMusic(data) {
  return request({
    url: '/video/video-video-music/detail',
    method: 'get',
    params: data
  })
}

// 新增短视频音乐
export function addVideoMusic(data) {
  return request({
    url: '/video/video-video-music/add',
    method: 'post',
    data: data
  })
}

// 修改短视频音乐
export function updateVideoMusic(data) {
  return request({
    url: '/video/video-video-music/edit',
    method: 'post',
    data: data
  })
}

// 删除短视频音乐
export function delVideoMusic(data) {
  return request({
    url: '/video/video-video-music/delete',
    method: 'post',
    data: data
  })
}
