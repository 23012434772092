import request from '@/utils/request'
const userApi = {
  add: '/shop/shop-goods-spec/add',
  del: '/shop/shop-goods-spec/delete',
  detail: '/shop/shop-goods-spec/detail',
  list: '/shop/shop-goods-spec/page',
  edit:'/shop/shop-goods-spec/edit',
  page:'/shop/shop-goods-spec/list'
}
// 增
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}

//改
export function edit (data) {
  return request({
    url: userApi.edit,
    method: 'post',
    data: data
  })
}
// 删
export function del (data) {
  return request({
    url: userApi.del,
    method: 'post',
    data: data
  })
}
// 查 详情
export function detail (parameter) {
  return request({
    url: userApi.detail,
    method: 'get',
    params: parameter
  })
}
// 查 列表
export function list (parameter) {
  return request({
    url: userApi.list,
    method: 'get',
    params: parameter
  })
}

// 查 列表
export function page (parameter) {
  return request({
    url: userApi.page,
    method: 'get',
    params: parameter
  })
}
