import request from '@/utils/request'
export function pageReturnOrderList(query) {
  return request({
    url: '/shop/shop-order-refund/adminlist',
    method: 'get',
    params: query
  })
}

export function changeOrderStatusCheck(data) {
  return request({
    url: '/shop/shop-order-refund/check',
    method: 'post',
    data: data
  })
}

export function LookOrderLogisticsData(query) {
  return request({
    url: '/shop/shop-refund-delivery/logisticsData',
    method: 'get',
    params: query
  })
}

export function getUnfinishedCountList(query) {
  return request({
    url: '/shop/shop-order-refund/getUnfinishedCountList',
    method: 'get',
    params: query
  })
}

/**
 * 仅退款*
 * @param query
 * @returns {AxiosPromise}
 */
export function justRetundProcess(data) {
  return request({
    url: '/shop/shop-order-refund/process',
    method: 'post',
    data: data
  })
}

/**
 * 退货退款*
 * @param query
 * @returns {AxiosPromise}
 */
export function returnMoneyAndThing(data) {
  return request({
    url: '/shop/shop-order-refund/returnMoney',
    method: 'post',
    data: data
  })
}