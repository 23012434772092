<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="状态" prop="status" >
        <a-select v-model="form.status">
          <a-select-option :value="1" v-if="isShowStatus">
            审核驳回
          </a-select-option>

          <a-select-option :value="2" v-if="isShowStatus">
            审核通过
          </a-select-option>

          <a-select-option :value="3" v-if="!isShowStatus">
            已打款
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="审核意见"  v-if="form.status == 1" prop="auditOpinion" >
        <a-input v-model="form.auditOpinion" placeholder="请输入审核意见" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWithdraw, addWithdraw, updateWithdraw } from '@/api/user/withdraw'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        money: null,

        status: 0,

        withdrawType: null,

        withdrawUserType: null,

        orderNo: null,

        failureReason: null,

        auditOpinion: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      isShowStatus:false,
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，外键不能为空', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '提现金额不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态，0-未审核 1-审核未通过 2-审核已通过 3-已打款不能为空', trigger: 'blur' }
        ],
        withdrawType: [
          { required: true, message: '1-支付宝，2-微信不能为空', trigger: 'change' }
        ],
        auditOpinion: [
          { required: true, message: '审核意见不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        money: null,
        status: 0,
        withdrawType: null,
        withdrawUserType: null,
        orderNo: null,
        failureReason: null,
        auditOpinion: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWithdraw({"id":id}).then(response => {
        this.form = response.data
        this.isShowStatus = JSON.parse(JSON.stringify(response.data.status)) == 0 ? true: false;
        this.form.status = null;
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWithdraw(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addWithdraw(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
