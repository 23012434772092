<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="举报人用户" prop="userId">
                <a-input v-model="queryParam.userNickname" placeholder="请输入举报人用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="被举报用户" prop="targetUserId">
                <a-input v-model="queryParam.targetNickname" placeholder="请输入被举报用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="审核状态" prop="auditStatus">
                <a-select v-model="queryParam.auditStatus" placeholder="请选择审核状态">
                  <a-select-option value="0">
                    未审核
                  </a-select-option>
                  <a-select-option value="1">
                   审核成功
                  </a-select-option>
                  <a-select-option value="2">
                    审核失败
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="数据类型" prop="dataType">
<!--                  <a-input v-model="queryParam.dataType"-->
<!--                           placeholder="数据类型" allow-clear/>-->

                  <a-select v-model="queryParam.dataType" placeholder="请选择数据类型">
                    <a-select-option value="1">
                    用户
                    </a-select-option>
                    <a-select-option value="2">
                     动态
                    </a-select-option>
                    <a-select-option value="3">
                    直播
                    </a-select-option>
                    <a-select-option value="4">
                     短视频
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['report:report:add']">-->
<!--          <a-icon type="plus"/>-->
<!--          新增-->
<!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['report:report:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['report:report:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['report:report:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
                       <a-popconfirm title="确认通过吗?" @confirm="() => handleUpdate(record)" v-if="record.audit_status == 0">

          <a>
            通过
          </a>
                       </a-popconfirm>

                    <a-divider type="vertical" v-hasPermi="['report:report:edit']" v-if="record.audit_status == 0"/>
          <!-- v-if="record == 0"-->
            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.audit_status == 0" v-hasPermi="['report:report:edit']">
            拒绝
          </a>
          <a-divider type="vertical" v-hasPermi="['report:report:remove']" v-if="record.audit_status == 0" />
          <a @click="handleDelete(record)" v-hasPermi="['report:report:remove']">
<!--            <a-icon type="delete" />-->
            删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {delReport, pageReport, updateReport} from '@/api/report/report'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'

export default {
  name: 'Report',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        // userId: null,
        // targetUserId: null,
        dataType: null,
        // dataId: null,
        // reportType: null,
        // reportImg: null,
        // reportContent: null,
        // auditStatus: null,
        // auditReason: null,
        auditStatus:null,
        // dataId:null,
        targetNickname:null,
        userNickname:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          customRender: function (text, record, index) {
            return (index + 1);
          }
        },


        {
          title: '举报人',
          dataIndex: 'userNickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '被举报用户',
          dataIndex: 'targetNickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '数据类型',
          dataIndex: 'dataType',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1) {
              return '用户'
            } else if (text == 2) {
              return '动态'
            } else if (text == 3) {
              return '直播'
            } else {
              return '短视频'
            }
          }
        },
        // {
        //   title: '数据id, 以 data_type 为准, 1-用户id, 2-动态id, 3-直播id, 4-短视频id',
        //   dataIndex: 'dataId',
        //   ellipsis: true,
        //   align: 'center',
        //
        // },
        {
          title: '举报类型',
          dataIndex: 'reportTypeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报图片地址',
          dataIndex: 'reportImg',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报内容',
          dataIndex: 'reportContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 0) {
              return '未审核'
            } else if (text == 1) {
              return '审核成功'
            } else {
              return '审核失败'
            }
          }
        },

        {
          title: '审核原因',
          dataIndex: 'auditReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 确认通过
     */
    handleUpdate(record) {
      let formData = {
        id: record.id,
        auditStatus: 1
      }
      updateReport(formData).then(response => {
        this.getList()
      }).finally(() => {
        // this.submitLoading = false
      })
    },
    /** 查询举报列表 */
    getList() {
      this.loading = true
      pageReport(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        // dataId:null,
        dataType:null,
        auditStatus:null,
        targetNickname:null,
        userNickname:null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delReport(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('report/report/export', {
            ...that.queryParam
          }, `举报_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
