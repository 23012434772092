import request from '@/utils/request'


// 查询创业用户列表
export function listUser(query) {
  return request({
    url: '/distribution/distribution-user/list',
    method: 'get',
    params: query
  })
}

// 查询创业用户分页
export function pageUser(query) {
  return request({
    url: '/distribution/distribution-user/page',
    method: 'get',
    params: query
  })
}

// 查询创业用户详细
export function getUser(data) {
  return request({
    url: '/distribution/distribution-user/detail',
    method: 'get',
    params: data
  })
}

// 新增创业用户
export function addUser(data) {
  return request({
    url: '/distribution/distribution-user/add',
    method: 'post',
    data: data
  })
}

// 修改创业用户
export function updateUser(data) {
  return request({
    url: '/distribution/distribution-user/edit',
    method: 'post',
    data: data
  })
}

// 删除创业用户
export function delUser(data) {
  return request({
    url: '/distribution/distribution-user/delete',
    method: 'post',
    data: data
  })
}
