<template>
  <a-modal :title="formTitle" :width="800" :visible="open"
           @cancel="cancel"  cancelText="关闭" @ok="submitForm">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="规格名称" prop="specName" >
        <a-input v-model="form.specName" placeholder="请输入" />
      </a-form-model-item>

      <a-form-model-item label="是否可视" prop="isVisible" >
        <a-radio-group v-model="form.isVisible" button-style="solid">
          <a-radio-button v-for="(d, index) in visibleOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="排序" prop="sort">
        <a-input-number v-model="form.sort" :min="0" />
      </a-form-model-item>

      <a-form-model-item label="规格说明" prop="specDes" >
        <a-input v-model="form.specDes" placeholder="请输入" />
      </a-form-model-item>
<!--      <div class="bottom-control">-->
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
<!--      </div>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import {edit,add} from "@/api/mall/goodsspec";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: 'CreateForm',
  props: {

  },
  components: {

  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      visibleOptions:[ {
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      loading: true,
      submitLoading: false,
      formTitle: '',
      // labelCol: { span: 4 },
      // wrapperCol: { span: 14 },
      form: {},
      replaceFields: {
        title: 'label',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      cateList: [],
      cateList3: [],  // 只有三层的分类
      statusOptions: [
        {
          label: '显示',
          value: 1
        },
        {
          label: '隐藏',
          value: 0
        }
      ],
      status: 1,
      // 上传图片
      open: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      rules: {
        specName: [{ required: true, message: '规格名称不能为空', trigger: 'blur' }],
        isVisible:[{ required: true, message: '是否可规不能为空', trigger: 'blur' }],
        sort:[{required:true,message:'排序不能为空',trigger:'blur'}],
        specDes:[{required:true,message:'规格说明不能为空',trigger:'blur'}],
      },
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  methods: {

    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        specName:'',
        isVisible:1,
        sort:'',
        specDes:''
      }
    },
    getCateList() { },
    /** 新增按钮操作 */
    handleAdd() {
      this.formTitle = '添加规格'
      this.reset()
      this.open = true

    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      // this.reset()
      // const id = row ? row.id : ids
      this.form.id = row.id;
      this.form.isVisible = row.isVisible ? 1 : 0;
      this.form.specDes = row.specDes;
      this.form.specName = row.specName;
      this.form.sort = row.sort;
      this.open = true
      this.formTitle = '编辑规格'
      // detail({ id: id }).then(response => {
      //   this.form = response.data
      //   this.open = true
      //   this.formTitle = '修改规格'
      // })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传图片
    uploadImages({ fileList }) {
      // console.log('fileList', fileList)
      this.fileList = fileList
    },
    add(type, index1, index2) {
      console.log('哈哈', type, index1, index2)
      if (type === 1) {
        this.form.specs.push({
          "label": "",
          "spec_value": [
            {
              "label": "",
              "thumb": ""
            }
          ]
        })
      } else if (type === 2) {
        if (!this.form.specs[index1].spec_value[index2].label) return this.$message.error("请输入规格值")
        this.form.specs[index1].spec_value.push({
          "label": "",
          "thumb": ""
        })
      }
    },
    del(type, index1, index2) {
      if (type === 2) {
        this.form.specs[index1].spec_value.splice(index2, 1)
      }
    },
    delSpecs(index) {
      this.form.specs.splice(index, 1)
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log('编辑一下')
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {

            edit(this.form).then(response => {
              if(response.code == 20001) {
                this.$message.success(
                  '修改成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              } else {
                this.$message.warning(response.message)
                this.open = false

              }

            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            console.log('进来了')
            add(this.form).then(response => {
              if(response.code == 20001) {
                this.$message.success(
                  '新增成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              } else {
                this.$message.warning(response.message)
                this.open = false
              }

            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less">
.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.box {
  position: relative;
  background-color: #f7f7f7;
  padding: 0 15px 15px 15px;
  margin-bottom: 15px;
  border-radius: 5px;

  .delSpec {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #f5222d;
    cursor: pointer;
  }
}
</style>