<!-- 举报类型 -->
<template>
  <crud :config="config"></crud>
</template>

<script>
import Crud from '@/components/crud/Crud.vue'

export default {
  name: 'Type',
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          { field: 'title', name: '名称', },
        ],
        button: [
          { name: '新增', color: 'primary', icon: 'plus', event: 'inner-add' },
          { name: '编辑', color: 'primary', icon: 'edit', event: 'inner-edit', single: true },
          { name: '删除', color: 'danger', icon: 'delete', event: 'inner-delete', multiple: true },
          // { name: '导出', color: 'primary', icon: 'download', event: 'inner-export' }
        ],
        table: {
          api: '/report/report-type/page',
          deleteApi: '/report/report-type/delete',
          exportApi: 'report/report-type/export',
          col: [
            { field: 'title', name: '名称' },
            { field: 'sort', name: '排序' },
            { field: 'isEnable', name: '是否启用', type: 'switch', api: '/report/report-type/edit' },
            { field: 'createTime', name: '创建时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '编辑', event: 'inner-edit', icon: 'edit' },
                { name: '删除', event: 'inner-delete', icon: 'delete' }
              ]
            }
          ]
        },
        form: {
          addApi: '/report/report-type/add',
          editApi: '/report/report-type/edit',
          item: [
            { field: 'title', name: '名称', required: true },
            { field: 'sort', name: '排序', type: 'number', default: 100 },
            { field: 'isEnable', name: '是否启用', type: 'switch', default: true }
          ]
        }
      },

    }
  },
}
</script>
