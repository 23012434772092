import request from '@/utils/request'


// 查询商城-店铺-类别列表
export function listShopCategory(query) {
  return request({
    url: '/shop/shop-shop-category/list',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-类别分页
export function pageShopCategory(query) {
  return request({
    url: '/shop/shop-shop-category/page',
    method: 'get',
    params: query
  })
}
export  function saveSort(data) {
  return request({
    url: '/shop/shop-shop-category/saveSort',
    method: 'post',
    params: data
  })
}
// 查询商城-店铺-类别详细
export function getShopCategory(data) {
  return request({
    url: '/shop/shop-shop-category/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-店铺-类别
export function addShopCategory(data) {
  return request({
    url: '/shop/shop-shop-category/add',
    method: 'post',
    data: data
  })
}

// 修改商城-店铺-类别
export function updateShopCategory(data) {
  return request({
    url: '/shop/shop-shop-category/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-店铺-类别
export function delShopCategory(data) {
  return request({
    url: '/shop/shop-shop-category/delete',
    method: 'post',
    data: data
  })
}
