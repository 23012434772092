import request from '@/utils/request'
// 查询用户动态列表
export function pageDynamic(query) {
  return request({
    url: '/dynamic/dynamic-dynamic-comment/page',
    method: 'get',
    params: query
  })
}

// 新增用户动态
export function addDynamic(data) {
  return request({
    url: '/dynamic/dynamic-dynamic/add',
    method: 'post',
    data: data
  })
}


export function deleteDynamic(data) {
  return request({
    url: '/dynamic/dynamic-dynamic-comment/delete',
    method: 'post',
    data: data
  })
}