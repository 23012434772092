<template xmlns:font-size="http://www.w3.org/1999/xhtml">
  <a-drawer
    :title="title"
    :width="577"
    @close="onClose"
    :visible="visible"
    :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
  >
    <a-card title="基本信息" :bordered="false">
      <div class="content-block">
        <div>商家名称：</div>
        <div>{{model.shopName}}</div>
      </div>
      <div class="content-block" v-if="model.shopEndTime">
        <div>到期时间：</div>
        <div>{{model.shopEndTime}}</div>
      </div>
      <div class="content-block" v-if="model.shopPhone">
        <div>商家电话：</div>
        <div>{{model.shopPhone}}</div>
      </div>

      <div class="content-block">
        <div>店铺类型：</div>
        <div>{{model.shopType == 1 ? '自营' : '非自营'}}</div>
      </div>
      <div class="content-block" v-if="model.tradeName">
        <div>所属行业：</div>
        <div>{{model.tradeName}}</div>
      </div>
      <div class="content-block" v-if="model.shopLogo">
        <div>商家logo：</div>
        <div class="anty-img-wrap">
          <img style="width: 72px;height: 72px;" :src='model.shopLogo' alt='商家logo' />
        </div>
      </div>
      <div class="content-block" v-if="model.shopCover" style="margin-top: 20px">
        <div>商家背景：</div>
        <div class="anty-img-wrap">
          <img style="width: 375px;height: 135px" :src='model.shopCover' alt='商家背景' />
        </div>
      </div>
      <div class="content-block">
        <div>省市区：</div>
        <div>{{model.shopAreap}}-{{model.shopAreac}}-{{model.shopAreax}}</div>
      </div>
      <div class="content-block" v-if="model.shopAddress">
        <div>详细地址：</div>
        <div>{{model.shopAddress}}</div>
      </div>
<!--      <div class="content-block">-->
<!--        <div>地图选点：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->

      <div class="content-block" v-if="model.kfPhone">
        <div>客服电话：</div>
        <div>{{model.kfPhone}}</div>
      </div>

<!--      <div class="content-block">-->
<!--        <div>店铺套餐：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->

      <div class="content-block" v-if="model.shopDescription">
        <div>店铺简介：</div>
        <div>{{model.shopDescription}}</div>
      </div>

      <div class="content-block">
        <div>店铺状态：</div>
        <div>{{model.shopState == 1 ? '开启' : model.shopState == 2 ? '审核中' : '关闭' }}</div>
      </div>

<!--      <div class="content-block">-->
<!--        <div>是否推荐：</div>-->
<!--        <div>{{model.tradeName}}</div>-->
<!--      </div>-->
<!--      <div class="content-block">-->
<!--        <div>工作日：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->
<!--      <div class="content-block">-->
<!--        <div>营业时间：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->
    </a-card >
  </a-drawer>
</template>

<script>
import { getShopApply, addShopApply, updateShopApply } from '@/api/shop/shopApply'
export default {
  name: "BasicDetails",
  props: {
  },
  components: {
  },
  data() {
    return {
      title: "详情",
      visible: false,
      model: {},
      labelCol: {
        xs: {span: 24},
        sm: {span: 5},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
      uploadLoading: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      url: {
        imgerver: window._CONFIG['fileURL'],
      },
    }
  },
  created() {
  },
  methods: {
    show(record) {
      this.model = record.basicMessage;
      this.visible = true;
    },
    onClose() {
      this.visible = false
    },

  }
}
</script>

<style lang="less" scoped>
.content-block {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  line-height: 37px;
  flex-wrap: wrap;
}
</style>
