import request from '@/utils/request'
const userApi = {
  add: '',
  del: '',
  list: '/shop/shop-shop/list',
  update: '',
  queryShopInfoById: '/shop/shop-shop/queryShopInfoById',
  updateAlarmStock: '/shop/shop-shop/updateAlarmStock'
}
export function updateAlarmStock(data) {
  return request({
    url: userApi.updateAlarmStock,
    method: 'put',
    data: data
  })
}
// 新增商品
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删除商品
export function del (id) {
  return request({
    url: userApi.del + id,
    method: 'delete'
  })
}

export function queryShopInfoById() {
  return request({
    url: userApi.queryShopInfoById,
    method: 'get'
  })
}
// 查询商品列表
export function queryShopList (query) {
  return request({
    url: userApi.list,
    method: 'get',
    params: query
  })
}
// 修改商品
export function update (data) {
  return request({
    url: userApi.update,
    method: 'put',
    data: data
  })
}
// 改 状态 开启和关闭  微信
export function editStatus (data) {
  return request({
    url: userApi.editStatus,
    method: 'post',
    data: data
  })
}

// 查询商城-店铺列表
export function listShop(query) {
  return request({
    url: '/shop/shop-shop/list',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺分页
export function pageShop(query) {
  return request({
    url: '/shop/shop-shop/page',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺详细
export function getShop(data) {
  return request({
    url: '/shop/shop-shop/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-店铺
export function addShop(data) {
  return request({
    url: '/shop/shop-shop/add',
    method: 'post',
    data: data
  })
}

// 修改商城-店铺
export function updateShop(data) {
  return request({
    url: '/shop/shop-shop/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-店铺
export function delShop(data) {
  return request({
    url: '/shop/shop-shop/delete',
    method: 'post',
    data: data
  })
}
