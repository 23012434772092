<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel.stop="handleCancel">

    <a-steps v-if="model.applyType==2" :current="current" size="small">
      <a-step title="买家申请退货退款"/>
      <a-step title="卖家处理退货申请"/>
      <a-step title="买家退货"/>
      <a-step title="退款完毕"/>
    </a-steps>

    <a-steps v-if="model.applyType==1" :current="current" size="small">
      <a-step title="买家申请仅退款"/>
      <a-step title="卖家处理退款申请"/>
      <a-step title="退款完毕"/>
    </a-steps>

    <div class="refundContent">
      <div class="contentLeft">
        <div style="margin-top: 40px;margin-left: 20px;margin-right: 20px;">
          <div>
            <!--    applyType == 2 为退货退款         -->
            <p style="font-size: 16px;font-weight: bold;">
              <template v-if="model.refundStatus==1 && model.applyType==2">请处理退货退款申请</template>
              <template v-if="model.refundStatus==2 && model.applyType==2">请等待买家退货</template>

              <template v-if="model.refundStatus==1 && model.applyType==1">请处理退款申请</template>
              <template v-if="model.refundStatus==4 && model.applyType==2">退货已签收，请处理退款申请</template>



              <!--<template v-if="model.refundStatus==7">买家已退货</template>-->

              <template v-if="model.refundStatus==7">卖家已拒绝退款</template>
              <template v-if="model.refundStatus==5">退款成功</template>
              <template v-if="model.refundStatus==6">退款撤销</template>
<!--              <template v-if="model.refundStatus==8">退款中...</template>-->
            </p>

            <div v-if="model.refundStatus==7" style="color: #6c6c6c;line-height: 1.5;">
              拒绝原因：{{ model.rejectMessage }}
            </div>

            <div v-if="model.refundStatus==5" style="color: #6c6c6c;line-height: 1.5;">
              退款成功时间:{{ model.refundTime }}<br/>
              退款金额：￥ {{ model.refundMoney }}
            </div>

            <div v-if="model.refundStatus==6" style="color: #6c6c6c;line-height: 1.5;">
              因买家取消退款申请，退款已关闭，交易将正常进行
            </div>

            <div v-if="model.refundStatus==3 && model.applyType==2">
              <p style="display: inline-block;font-weight: bold;">买家已退货
<!--                {{ model.refundShippingCompany }}({{ model.refundShippingCode }})-->
              </p>
              <a style="margin-left: 20px;" @click="logisticsDetails">查看物流详情</a>
            </div>
            <template v-if="model.photoFiles">
              <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;"

                   v-for="(img,imgIndex) in model.photoFiles.split(',')"
                   :src="img"
                   preview="用户上传"/>
            </template>

            <!--状态为 2：退款失败 3：退款成功  4：已撤销 8：退款中 时不显示操作按钮-->
            <template v-if="model.refundStatus!== -1 && model.refundStatus!=5 && model.refundStatus!=6 &&model.refundStatus!=7">

              <a-divider/>

              <div v-if="shopInfo">

                <a-button
                  v-if="model.refundStatus==1||model.refundStatus==3"
                  type="primary" @click="agreeRefund">
                  <template v-if="model.applyType==2&&model.refundStatus == 3">确认收货，同意退款</template>
<!--                  <template v-if="model.applyType==2&&model.refundStatus==5">同意退货退款申请</template>-->
<!--                  <template v-if="model.applyType==2&&(model.refundStatus==4)">已收到货，同意退款</template>-->
                  <template v-if="model.applyType==2&&(model.refundStatus==1)">同意申请</template>
                  <template v-if="model.applyType==1">同意退款</template>
                </a-button>

                <a-button v-if="model.refundStatus!=2 && model.refundStatus!=6 && model.refundStatus!=7 && model.refundStatus!=-1" style="margin-left: 20px;" @click="refuseRefund">
                  拒绝退款
                </a-button>
              </div>
            </template>

          </div>
        </div>
      </div>
      <div class="contentRight">
        <div>
          <strong style="font-size: 16px;">退款详情</strong>
        </div>

        <a-divider style="margin: 12px 0;"/>

        <div>
          <img class="contentImg" :src="model.goodsPicture"/>
          <div class="content" style="color: #333;font-size: 14px;">
            <span>{{ model.goodsName }}</span>
          </div>
          <div style="clear: both;"></div>
        </div>

        <a-divider/>

        <div>
          <span class="contentTiele">买家 </span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.userName }}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">订单编号</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.orderNo }}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">退款编号</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.refundSn }}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">成交时间</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.createTime }}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">单价</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">￥{{ model.price }}*{{ model.num }}(数量)</div>
          <div style="clear: both;"></div>
        </div>

        <div>
          <span class="contentTiele">商品总价</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">￥{{ model.goodsMoney }}</div>
          <div style="clear: both;"></div>
        </div>

        <a-divider style="margin: 2px 0"/>

        <div>
          <span class="contentTiele">退款金额</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">￥{{ model.refundMoney }}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">原因</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.buyerReason }}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">说明</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.buyerDesc || '' }}</div>
          <div style="clear: both;"></div>
        </div>
        <a-divider v-if="model.refundStatus > 3" style="margin: 2px 0"/>
        <div v-if="model.refundStatus > 3">
          <span class="contentTiele">物流公司</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.dvyName || '' }}</div>
          <div style="clear: both;"></div>
        </div>
        <div v-if="model.refundStatus > 3">
          <span class="contentTiele">物流单号</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.dvyNumber || '' }}</div>
          <div style="clear: both;"></div>
        </div>
        <div v-if="model.refundStatus > 3">
          <span class="contentTiele">联系电话</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.senderMobile || '' }}</div>
          <div style="clear: both;"></div>
        </div>
        <div v-if="model.refundStatus > 3">
          <span class="contentTiele">退换说明</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{ model.senderRemarks || '' }}</div>
          <div style="clear: both;"></div>
        </div>
        <div v-if="model.refundStatus > 3">
          <span class="contentTiele">凭证</span>
          <sapn style="float: left;">:</sapn>
          <div class="content"><img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;"
                                    v-for="(img,imgIndex) in model.imgs.split(',')"
                                    :src="img"
                                    preview="用户上传"/></div>
          <div style="clear: both;"></div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>

    <!--拒绝退款原因-->
    <a-modal v-model="memoVisible" title="填写拒绝原因" ok-text="确认" cancel-text="取消" @ok="memoOk">
      <a-textarea maxlength="255" :rows="5" v-model="memo" placeholder="请输入拒绝原因"/>
    </a-modal>

    <!--物流信息-->
    <logistics-details-modal ref="logisticsDetailsModal"></logistics-details-modal>
  </a-modal>
</template>

<script>
// import {httpAction} from '@/api/manage'
import LogisticsDetailsModal from "./LogisticsDetailsModal"
import {changeOrderStatusCheck, justRetundProcess, returnMoneyAndThing} from "@/api/order/returnorder"

export default {
  name: "OrderGoodsModal",
  data() {
    return {
      title: "操作",
      visible: false,
      current: "0",
      memo: "",
      memoVisible: false,
      model: {
        refundVoucher: "",
      },
      labelCol: {
        xs: {span: 24},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
      },
      labelColMe: {
        xs: {span: 24},
        sm: {span: 4},
      },
      confirmLoading: false,

    }
  },
  components: {
    LogisticsDetailsModal,
  },
  created() {
  },
  computed:{
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  methods: {
    info(record) {
      this.memo = "";
      this.model = Object.assign({}, record);

      var refundWay = record.applyType;
      var refundStatus = record.refundStatus;
      //退款方式 1:退款退货   2:仅退款
      if (refundWay == 2) {
        if (refundStatus == 1) {
          this.current = 1;
        }
        if (refundStatus == 1 || refundStatus == 2) {
          this.current = 2;
        }
        if (refundStatus == 2) {
          this.current = 3;
        }
        //退款成功或拒绝退款或一撤销退款
        if (refundStatus == 5 || refundStatus == 7) {
          this.current = 4;
        }
      }

      if (refundWay == 1) {
        if (refundStatus == 1) {
          this.current = 1;
        }
        if (refundStatus == 1) {
          this.current = 2;
        }
        //退款成功或拒绝退款或一撤销退款
        if (refundStatus == 5 || refundStatus == 7) {
          this.current = 3;
        }
      }
      this.visible = true;
    },
    close() {
      this.$emit('close');
      this.visible = false;
    },
    /**
     * @param type 1:同意退款（包含同意退货） 0:拒绝退款 （包含拒绝退货）
     * @param msg 拒绝原因
     */
    handleCheck(type, memo) {
      const that = this;
      let formData = {
        id: that.model.id,
        refundStatus: type,
        rejectMessage: memo
      };
      changeOrderStatusCheck(formData).then((res) => {
        if (res.success) {
          that.$message.success(res.message);
          that.$emit('ok');
        } else {
          that.$message.warning(res.message);
        }
      }).finally(() => {
        that.confirmLoading = false;
        that.close();
      })
    },
    handleCancel() {
      this.close()
    },
    handleOk() {
      this.close()
    },
    //展示物流信息
    logisticsDetails: function () {
      var refundSn = this.model.refundSn;
      this.$refs.logisticsDetailsModal.info(refundSn);
    },
    agreeRefund() {

    if(this.model.applyType == 1) {
      //仅退款
      justRetundProcess({
        refundId:this.model.id,
        refundSn:this.model.refundSn,
        refundSts:2,
        isReceiver:this.model.isReceiver,
        sellerMsg:this.model.sellerMsg,
        rejectMessage:null,
      }).then(res => {
        if(res.code == 20001) {
          this.$message.success(res.message);
          this.$emit('ok');
          this.close();
        } else {
          this.$message.warning(res.msg);
        }

      })
    }

      if(this.model.applyType == 2 && this.model.refundStatus == 1) {
        //仅退款
        justRetundProcess({
          refundId:this.model.id,
          refundSn:this.model.refundSn,
          refundSts:2,
          isReceiver:this.model.isReceiver,
          sellerMsg:this.model.sellerMsg,
          rejectMessage:null,
        }).then(res => {
          if(res.code == 20001) {
            this.$message.success(res.message);
            this.$emit('ok');
            this.close();
          } else {
            this.$message.warning(res.msg);
          }

        })
      }

      if(this.model.applyType == 2 && this.model.refundStatus == 3) {
        //退款退货
        returnMoneyAndThing({
          refundId:this.model.id,
          refundSn:this.model.refundSn,
          refundSts:2,
          rejectMessage:null,
          isReceiver:this.model.isReceiver,
          sellerMsg:this.model.sellerMsg,
        }).then(res => {
          if(res.code == 20001) {
            this.$message.success(res.message);
            this.$emit('ok');
            this.close();
          } else {
            this.$message.warning(res.msg);
          }

        })
      }

    },
    refuseRefund() {
      this.memo = "";
      this.memoVisible = true;
    },
    memoOk() {
      var memo = this.memo;
      if (!memo) {
        this.$message.warning('拒绝原因不能为空');
        return;
      }

      if(this.model.applyType == 1) {
        //仅退款
        justRetundProcess({
          refundId:this.model.id,
          refundSn:this.model.refundSn,
          refundSts:3,
          isReceiver:this.model.isReceiver,
          sellerMsg:this.model.sellerMsg,
          rejectMessage:this.memo,
          //TODO:地址填写什么
          refundAddrId: ''
        }).then(res => {
          if(res.code == 20001) {
            this.memoVisible = false;
            this.$message.success(res.message);
            this.$emit('ok');
            this.close();
          } else {
            this.$message.warning(res.msg);
          }

        })
      }

      if(this.model.applyType == 2) {
        //退款退货
        returnMoneyAndThing({
          refundId:this.model.id,
          refundSn:this.model.refundSn,
          refundSts:3,
          rejectMessage:this.memo,
          isReceiver:this.model.isReceiver,
          sellerMsg:this.model.sellerMsg,
          //TODO:地址填写什么
          refundAddrId: ''
        }).then(res => {
          if(res.code == 20001) {
            this.$message.success(res.message);
            this.memoVisible = false;

            that.$emit('ok');
            this.close();
          } else {
            this.$message.warning(res.msg);
          }

        })
      }

      // this.handleCheck(7, memo);
    },
  }
}
</script>

<style lang="less" scoped>
.refundContent {
  border: 1px solid #d3d3d3;
  margin-top: 20px;

  .contentLeft {
    width: 700px;
    float: left;
  }

  .contentRight {
    float: left;
    width: 250px;
    border-left: 1px solid #d3d3d3;
    padding: 20px;

    .contentImg {
      width: 60px;
      height: 60px;
      line-height: 60px;
      float: left;
    }

    .contentTiele {
      float: left;
      font-size: 12px;
      color: #9c9c9c;
      width: 58px;
      display: inline-block;
      text-align: justify;
      text-align-last: justify;
      margin-bottom: 7px;
    }

    .content {
      margin-left: 65px;
      font-size: 12px;
    }
  }
}
</style>