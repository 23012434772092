<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="类型">
          <a-select  v-model="model.type">
            <a-select-option key="1">
              嗨宝
            </a-select-option>
            <a-select-option key="2">
              嗨豆
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="上级分类">
          <a-tree-select v-decorator="[ 'pid', validatorRules.pid]"
                         showSearch
                         style="width: 300px"
                         :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                         placeholder="请选择上级分类"
                         allowClear
                         treeDefaultExpandAll
                         treeNodeFilterProp="title"
                         @change="onChangePid"
          >
            <a-tree-select-node v-for="(goodsCategory,index) in goodsList" :title="goodsCategory.categoryName" :value="goodsCategory.id"  :key="goodsCategory.id"  >
              <a-tree-select-node v-for="(item,index) in goodsCategory.goodsCategoryList" :value="item.id" :title="item.categoryName" :key="item.id">
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-item>

<!--        <a-form-item
          v-has="'shop:close'"
          v-if="!this.model.pid"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="自营店铺活动分类"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :required="false">
          <a-select placeholder="请选择活动分类" v-decorator="['activityConfigId',{}]" style="width: 300px;">
            <a-select-option v-for="(item, key) in activityList" :key="key" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>-->

<!--        <a-form-item-->
<!--          :labelCol="labelCol"-->
<!--          :wrapperCol="wrapperCol"-->
<!--          label="店铺资质分类"-->
<!--          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--          :required="true">-->
<!--          <a-select placeholder="请选择店铺资质分类" v-decorator="['shopQc',validatorRules.shopQc]" style="width: 300px;">-->
<!--            <a-select-option v-for="(item, key) in dictOptions" :key="key" :value="item.value">-->
<!--                <span style="display: inline-block;width: 100%" :title=" item.text || item.label ">-->
<!--                  {{ item.text || item.label }}-->
<!--                </span>-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->


        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="分类名称">
          <a-input placeholder="请输入分类名称" v-decorator="['categoryName', validatorRules.categoryName ]" />
        </a-form-item>
<!--        <a-form-item-->
<!--          :labelCol="labelCol"-->
<!--          :wrapperCol="wrapperCol"-->
<!--          label="分类简称">-->
<!--          <a-input placeholder="请输入分类简称" v-decorator="['shortName', validatorRules.shortName ]" />-->
<!--        </a-form-item>-->

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="分类图片">
          <a-upload
            listType="picture-card"
            class="avatar-uploader"
            :showUploadList="false"
            :action="uploadAction"
            :headers="headers"
            :beforeUpload="beforeUpload"
            @change="handleChange"
            v-decorator="['categoryPic', validatorRules.categoryPic]"
          >
            <img  v-if="model.categoryPic" :src="url.imgerver +'/'+model.categoryPic" alt="分类图片" style="height:104px;max-width:300px"/>
            <div v-else>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <span style="color:#ff0000">图片限制小于5M,推荐规格:120*120 </span>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="排序">
          <a-input-number v-decorator="[ 'sort', {}]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否显示">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="isVisible" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否首页展示" v-hasPermi="['shop:close']">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="isHomePage" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否推荐" v-hasPermi="['shop:close']">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="isRecommend" />
        </a-form-item>
        <a-form-item
          v-if="model.level === 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否商城展示">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="isMangguoShow" />
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
  // import { httpAction } from '@/api/manage'
  // import { getAction } from '@/api/manage'
  import pick from 'lodash.pick'
  // import { getFirstMenu } from '@/api/api'
  import Vue from 'vue'
  import { ACCESS_TOKEN } from "@/store/mutation-types"
  import {queryGoodsMenu} from "@/api/mall/goodscate";
  import {queryShopInfoById} from "@/api/shop/shop";
  import {getToken} from "@/utils/auth";
  // import {getGoodsCategorys} from '@/api/api'
  // import {ajaxGetDictItems,ajaxGetActivityItems} from '@/api/api'


  export default {
    name: "GoodsCategoryModal",
    data () {
      return {
        title:"操作",
        visible: false,
        isVisible:true,
        isHomePage:false,
        isMangguoShow:false,
        isRecommend:false,
        shopId:"",
        dictOptions:[],
        activityList:[],
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        headers:{},
        uploadLoading: false,
        confirmLoading: false,
        form: this.$form.createForm(this),
        validatorRules:{
          shopQc:{rules: [{ required: true, message: '请选择资质分类!' }]},
          categoryName:{rules: [{ required: true, message: '请输入分类名称!' }]},
          shortName:{rules: [{ required: true, message: '请输入分类简称 !' }]},
          sharerRatio:{rules: [{ required: true, message: '分享者抽成比例!' },{validator: this.validateNumber}]},
          inviterRatio:{rules: [{ required: true, message: '邀请者抽成比例!' },{validator: this.validateNumber}]},
        },
        url: {
          fileUpload: window._CONFIG['domianURL'] + "/sys/common/upload",
          imgerver: window._CONFIG['fileURL'],
        },
        goodsCategoryList:[],
        goodsList:[],
      }
    },
    created () {
      console.log('222222222222----')
      const token = getToken();
      console.log(token)
      this.headers = {"TOKEN":token};
      // this.queryShopId();//获得店铺id
      // this.queryGoodsMenu(shopId);//获得商品分类

      // this.queryFirstMenu();
      // this.getGoodsCategoryValue();//初始化商品分類
      // this.initDictData();//資質分類
      // this.initActivityData();//資質分類

    },
    computed : {
      uploadAction: function () {
        return this.url.fileUpload;
      }
    },
    methods: {
      //初始化商品分類數據
      getGoodsCategoryValue() {
        const that=this;
        getGoodsCategorys().then((res) => {
          if (res.success) {
            that.goodsCategoryList = [];
            let list = res.result
            for(let i=0;i<list.length;i++){
              let temp = list[i];
              that.goodsCategoryList.push(temp);
            }
            console.log("res.result:",res.result);
          } else {
            console.log(res.message);
          }
        });
      },
      onChangePid(value) {
        this.model.pid = value;
      },
      add (record) {
        this.form.resetFields();
        this.isVisible = true;
        this.isHomePage = false;
        this.isMangguoShow = false;
        this.isRecommend = false;
        this.model = Object.assign({}, record);
        // console.log("this.model",this.model)
        // debugger
        this.queryGoodsMenu(this.shopId);//获得商品分类
        // debugger
        this.visible = true;
        if (record) {
          this.$nextTick(() => {
            this.form.setFieldsValue(pick(this.model, 'type','shopQc', 'sharerRatio', 'inviterRatio', 'categoryName', 'shortName', 'pid','activityConfigId', 'level', 'isVisible', 'attrId', 'attrName', 'keywords', 'description', 'sort', 'categoryPic'))
          });
        }

        // console.log("this.model",this.model)
      },
      edit (record) {
        // console.log('进来了');
        if (record.pid == "0") {
          record.pid = "";
        }
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.isVisible = record.isVisible == 1;
        this.isHomePage = record.isHomePage == 1;
        this.isMangguoShow = record.isMangguoShow == 1;
        this.isRecommend = record.isRecommend == 1;
        // this.model.shopQc=record.shopQc+'';
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model, 'type','shopQc', 'sharerRatio', 'inviterRatio', 'categoryName', 'shortName', 'pid','activityConfigId', 'level', 'isVisible', 'attrId', 'attrName', 'keywords', 'description', 'sort', 'categoryPic'))
        });
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 觸發表單驗證
        // var categoryPic=that.model.categoryPic;
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
              method = 'put';
            }
            let formData = Object.assign(this.model, values);
            // debugger
            console.log("formData",formData)
            // debugger
            formData.isVisible=this.isVisible?1:0;
            formData.isHomePage=this.isHomePage?1:0;
            formData.isMangguoShow=this.isMangguoShow?1:0;
            formData.categoryPic=categoryPic;
            httpAction(httpurl,formData,method).then((res)=>{
              that.getGoodsCategoryValue();//初始化商品分類
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
              //調取模態框監聽的事件
              that.$emit('loadData');
            })

          }
        })
      },
      handleCancel () {
        this.close()
      },
      queryFirstMenu() {
        // debugger
        getFirstMenu().then((res)=> {
          // debugger
          this.goodsList = res.result;
        })
      },
      queryShopId(){
        console.log('进来了们')
        // debugger
        queryShopInfoById().then((res) => {
          if (res.success) {
            this.shopId = res.result[0].id;
            // debugger
            console.log("shopId",this.shopId)
            // debugger
          } else {
            this.$message.warning(res.message);
          }
        })
      },
      queryGoodsMenu(shopId){
        // debugger
        queryGoodsMenu({shopId:shopId}).then((res) => {
          // debugger
          if (res.success) {
            this.goodsList = res.result;
            // debugger
            // console.log("this.goodsList",this.goodsList)
            // debugger
          } else {
            that.$message.warning(res.message);
          }
        })
      },
      beforeUpload: function (file) {
        return new Promise((resolve, reject) => {
          if(file.size>5*1024*1000){
            this.$message.warning("圖片大小不能超過5M----");
            return reject(false);
          }

          var fileType = file.type;
          if (fileType.indexOf('image') < 0) {
            this.$message.warning('請上傳圖片');
            return reject(false);
          }
          resolve(true);
        });
      },
      handleChange(info) { //上傳圖片回調
        /*this.picUrlPic = "";*/
        if (info.file.status === 'uploading') {
          this.uploadLoading = true
          return
        }
        if (info.file.status === 'done') {
          let response = info.file.response;
          this.uploadLoading = false;
          if (response.success) {
            this.model.categoryPic = response.message;
            /*this.picUrlPic = "Has no pic url yet";*/
          } else {
            this.$message.warning(response.message);
          }
        }
      },
      //初始化店鋪資質類型
      initDictData() {
        ajaxGetDictItems("shop_qc", null).then((res) => {
          if (res.success) {
            this.dictOptions = res.result;
            console.log("this.dictOptions",this.dictOptions)
          }
        })
      },
      //初始化店鋪資質類型
      initActivityData() {
        ajaxGetActivityItems("", null).then((res) => {
          if (res.success) {
            this.activityList = res.result;
          }
        })
      },
      validateNumber(rule, value, callback){
        if(!value || new RegExp(/^[0-9]*[1-9][0-9]*$/).test(value)){
          callback();
        }else{
          callback("請輸入正整數!");
        }
      },


    }
  }
</script>

<style lang="less" scoped>

</style>