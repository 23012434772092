import request from '@/utils/request'


// 查询企业信息列表
export function listCompany(query) {
  return request({
    url: '/user/company/list',
    method: 'get',
    params: query
  })
}

// 查询企业信息分页
export function pageCompany(query) {
  return request({
    url: '/user/company/page',
    method: 'get',
    params: query
  })
}

// 查询企业信息详细
export function getCompany(data) {
  return request({
    url: '/user/company/detail',
    method: 'get',
    params: data
  })
}

// 新增企业信息
export function addCompany(data) {
  return request({
    url: '/user/company/add',
    method: 'post',
    data: data
  })
}

// 修改企业信息
export function updateCompany(data) {
  return request({
    url: '/user/company/edit',
    method: 'post',
    data: data
  })
}

// 删除企业信息
export function delCompany(data) {
  return request({
    url: '/user/company/delete',
    method: 'post',
    data: data
  })
}
