<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="订单编号" prop="orderNo" >
        <a-input v-model="form.orderNo" placeholder="请输入订单编号" />
      </a-form-model-item>
      <a-form-model-item label="外部交易号" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" placeholder="请输入外部交易号" />
      </a-form-model-item>
      <a-form-model-item label="支付类型1余额 2支付宝 3微信 4积分" prop="paymentType" >
      </a-form-model-item>
      <a-form-model-item label="买家id" prop="buyerId" >
        <a-input v-model="form.buyerId" placeholder="请输入买家id" />
      </a-form-model-item>
      <a-form-model-item label="订单总价" prop="orderMoney" >
        <a-input v-model="form.orderMoney" placeholder="请输入订单总价" />
      </a-form-model-item>
      <a-form-model-item label="订单实付金额" prop="payMoney" >
        <a-input v-model="form.payMoney" placeholder="请输入订单实付金额" />
      </a-form-model-item>
      <a-form-model-item label="订单状态1待付款 2已完成 0 已取消" prop="orderStatus" >
      </a-form-model-item>
      <a-form-model-item label="订单付款时间" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="订单完成时间" prop="finishTime" >
        <a-date-picker style="width: 100%" v-model="form.finishTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="最后付款时间" prop="lastPayTime" >
        <a-date-picker style="width: 100%" v-model="form.lastPayTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVipOrder, addVipOrder, updateVipOrder } from '@/api/distribution/vipOrder'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        orderNo: null,

        outTradeNo: null,

        paymentType: null,

        buyerId: null,

        orderMoney: null,

        payMoney: null,

        orderStatus: 0,

        payTime: null,

        finishTime: null,

        lastPayTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        outTradeNo: [
          { required: true, message: '外部交易号不能为空', trigger: 'blur' }
        ],
        paymentType: [
          { required: true, message: '支付类型1余额 2支付宝 3微信 4积分不能为空', trigger: 'change' }
        ],
        orderMoney: [
          { required: true, message: '订单总价不能为空', trigger: 'blur' }
        ],
        orderStatus: [
          { required: true, message: '订单状态1待付款 2已完成 0 已取消不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        orderNo: null,
        outTradeNo: null,
        paymentType: null,
        buyerId: null,
        orderMoney: null,
        payMoney: null,
        orderStatus: 0,
        payTime: null,
        finishTime: null,
        lastPayTime: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVipOrder({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVipOrder(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVipOrder(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
