<template>
  <page-header-wrapper>
    <template v-slot:content>
      <div class="page-header-content">
        <div class="avatar">
          <a-avatar size="large" :src="avatar"/>
        </div>
        <div class="content">
          <div class="content-title">
            {{ timeFix }}，{{ nickname }}<span class="welcome-text"></span>
          </div>
<!--          <div> {{ postGroup }} | {{ user.dept&&user.dept.deptName }}</div>-->
        </div>
      </div>
      <div class="page-header-index-wide">
        <a-row :gutter="24" >
          <a-col :sm="24" :md="24" :xl="24" :style="{ marginBottom: '24px' }">
            <a-card
              class="project-list"
              :loading="loading"
              style="margin-bottom: 24px;"
              :bordered="false"
              title="待处理数据">
              <div class="user-data-div">
                <a-col
                  class="project-card-grid"
                  :sm="24"
                  :md="12"
                  :xl="6"
                  :style="{ marginBottom: '24px' }"
                  :key="i"
                  v-for="(item, i) in pendingData">
                  <a-card :body-style="{ padding: '10px' }" style="margin: 10px;padding: -10px;">
                    <div class="chart-card-header">
                      <div class="meta">
                        <span class="chart-card-title">{{ item.title }}</span>
                      </div>
                      <div style="padding: 10% 0 10% 10%">
                        <span class="total-left"> {{ item.value }}</span>
                        <span class="total-right" style="margin-right: 25%;">(条)</span>
                        <div style="clear: both;"></div>
                      </div>
                    </div>
                  </a-card>
                </a-col>
              </div>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="24" >
          <a-col :sm="24" :md="24" :xl="24" :style="{ marginBottom: '24px' }">
            <a-card
              class="project-list"
              :loading="loading1"
              style="margin-bottom: 24px;"
              :bordered="false"
              title="平台数据">
              <div class="extra-wrapper" slot="extra">
                <a-form :form="form">
                  <div class="extra-item">
                    <a @click="changeDate(0)">今日</a>
                    <a-divider type="vertical" />
                    <a @click="changeDate(1)">本周</a>
                    <a-divider type="vertical" />
                    <a @click="changeDate(2)">本月</a>
                    <a-divider type="vertical" />
                    <a @click="changeDate(3)">本年</a>
                  </div>
                  <a-range-picker
                    @change="timeChange"
                    v-decorator="[ 'time', {}]"
                    :style="{width: '256px'}"
                    :value="[startTime,endTime]"/>
                </a-form>
              </div>
              <div class="user-data-div">
                <a-col
                  class="project-card-grid"
                  :sm="24"
                  :md="12"
                  :xl="6"
                  :style="{ marginBottom: '24px' }"
                  :key="i"
                  v-for="(item, i) in userData">
                  <a-card :body-style="{ padding: '10px' }" style="margin: 10px;padding: -10px;">
                    <div class="chart-card-header">
                      <div class="meta">
                        <span class="chart-card-title">{{ item.title }}</span>
                      </div>
                      <div style="padding: 10% 0 10% 10%">
                        <span class="total-left"> {{ item.value }}</span>
                        <span class="total-right" style="margin-right: 25%;"></span>
                        <div style="clear: both;"></div>
                      </div>
                    </div>
                  </a-card>
                </a-col>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </template>
  </page-header-wrapper>
</template>

<script>
import { timeFix } from '@/utils/util'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { PageHeaderWrapper } from '@/components/ProLayout'
import ruoyiLogo from '@/assets/projects/ruoyi.png'
import antdvLogo from '@/assets/projects/antdv.png'
import ACol from 'ant-design-vue/es/grid/Col'
import ATooltip from 'ant-design-vue/es/tooltip/Tooltip'
import { getAction } from '@/api/manage'
import { getUserProfile } from '@/api/system/user'

moment.locale('zh-cn')
export default {
  name: 'Index',
  components: {
    ATooltip,
    ACol,
    PageHeaderWrapper
  },
  data () {
    return {
      // 贡献者
      contributors: [
        {
          name: 'wangze',
          avatar: 'https://portrait.gitee.com/uploads/avatars/user/1662/4988475_fuzui_1586973704.png!avatar60',
          email: '73400@163.com'
        }
      ],
      // 赞助
      sponsorList: [
        {
          key: 'RuoYi',
          tab: 'RuoYi'
        },
        {
          key: 'Antdv',
          tab: 'Antdv'
        }
      ],
      noTitleKey: 'RuoYi',
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },
      roleGroup: {},
      postGroup: {},
      userData: [],
      pendingData: [],
      dateTime: [],
      dateMarket: [],
      dateOrder: [],
      datePlatform: [],
      startTime: '',
      endTime: '',
      startTime1: '',
      endTime1: '',
      startTime2: '',
      endTime2: '',
      shopId: '',
      loading: true,
      loading1: true,
      form: this.$form.createForm(this),
      url: {
        userData: '/index/dataStatistics/userData',
        pendingData: '/index/dataStatistics/pendingData',
      },
      // 致谢项目
      projects: [
        {
          logo: ruoyiLogo,
          name: 'RuoYi-Vue',
          description: '基于SpringBoot，Spring Security，JWT，Vue & Element 的前后端分离权限管理系统。',
          website: 'http://ruoyi.vip',
          downloadUrl: 'https://gitee.com/y_project/RuoYi-Vue'
        },
        {
          logo: antdvLogo,
          name: 'Ant Design Vue',
          description: 'An enterprise-class UI components based on Ant Design and Vue. ',
          website: 'https://antdv.com',
          downloadUrl: 'https://github.com/vueComponent/ant-design-vue/'
        },
        {
          logo: antdvLogo,
          name: 'Antd Pro Vue',
          description: 'An out-of-box UI solution for enterprise applications as a Vue boilerplate. based on Ant Design of Vue.',
          website: 'https://pro.antdv.com',
          downloadUrl: 'https://github.com/vueComponent/ant-design-vue/'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
  created () {
    this.getUser()
    this.getPendingData()
    this.getUserData()
  },
  mounted () {
  },
  methods: {
    timeChange(a, b) {
      this.startTime = a[0]
      this.endTime = a[1]
      // this.form.setFieldsValue({ 'time': [a[0], a[1]] })
      this.getUserData()
    },
    changeDate(type) {
      var startTime
      var endTime
      switch (type) {
        case 0:
          startTime = moment().week(moment().week()).startOf('day')
          endTime = moment().week(moment().week()).endOf('day')
          break
        case 1:
          startTime = moment().week(moment().week()).startOf('week')
          endTime = moment().week(moment().week()).endOf('week')
          break
        case 2:
          startTime = moment().month(moment().month()).startOf('month')
          endTime = moment().month(moment().month()).endOf('month')
          break
        case 3:
          startTime = moment().year(moment().year()).startOf('year')
          endTime = moment().year(moment().year()).endOf('year')
          break
      }
      this.startTime = startTime
      this.endTime = endTime
      // this.form.setFieldsValue({ 'time': [startTime, endTime] })
      this.getUserData()
    },
    // 获取用户数据
    getUserData() {
      const that = this
      if (this.startTime == '' || this.endTime == '') {
        this.changeDate(1)
      }
      getAction(this.url.userData, { 'startTime': this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        'endTime': this.endTime.format('YYYY-MM-DD HH:mm:ss') }).then((res) => {
        if (res.success) {
          that.userData = res.data
          that.loading1 = false
        }
      })
    },
    getPendingData() {
      const that = this
      getAction(this.url.pendingData).then((res) => {
        if (res.success) {
          that.pendingData = res.data
          that.loading = false
        }
      })
    },
    // 获取用户信息
    getUser () {
      getUserProfile().then(response => {window.console.log('response 111',response)
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    onSponsorTabChange (key, type) {
      this[type] = key
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}
.project-list {

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 66px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .download {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.mobile {

  .project-list {

    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

</style>
