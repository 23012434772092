import request from '@/utils/request'


// 查询创业资金池列表
export function listCapitalPool(query) {
  return request({
    url: '/distribution/distribution-capital-pool/list',
    method: 'get',
    params: query
  })
}

// 查询创业资金池分页
export function pageCapitalPool(query) {
  return request({
    url: '/distribution/distribution-capital-pool/page',
    method: 'get',
    params: query
  })
}

// 查询创业资金池详细
export function getCapitalPool(data) {
  return request({
    url: '/distribution/distribution-capital-pool/detail',
    method: 'get',
    params: data
  })
}

// 新增创业资金池
export function addCapitalPool(data) {
  return request({
    url: '/distribution/distribution-capital-pool/add',
    method: 'post',
    data: data
  })
}

// 修改创业资金池
export function updateCapitalPool(data) {
  return request({
    url: '/distribution/distribution-capital-pool/edit',
    method: 'post',
    data: data
  })
}

// 删除创业资金池
export function delCapitalPool(data) {
  return request({
    url: '/distribution/distribution-capital-pool/delete',
    method: 'post',
    data: data
  })
}
