import request from '@/utils/request'


// 查询商城-店铺-入驻时间列表
export function listShopEnter(query) {
  return request({
    url: '/shop/shop-shop-enter/list',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-入驻时间分页
export function pageShopEnter(query) {
  return request({
    url: '/shop/shop-shop-enter/page',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-入驻时间详细
export function getShopEnter(data) {
  return request({
    url: '/shop/shop-shop-enter/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-店铺-入驻时间
export function addShopEnter(data) {
  return request({
    url: '/shop/shop-shop-enter/add',
    method: 'post',
    data: data
  })
}

// 修改商城-店铺-入驻时间
export function updateShopEnter(data) {
  return request({
    url: '/shop/shop-shop-enter/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-店铺-入驻时间
export function delShopEnter(data) {
  return request({
    url: '/shop/shop-shop-enter/delete',
    method: 'post',
    data: data
  })
}
