<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="收货人名字" prop="receiverName" >
        <a-input v-model="form.receiverName" placeholder="请输入收货人名字" rules./>
      </a-form-model-item>
      <a-form-model-item label="收货人手机号码" prop="receiverMobile" >
        <a-input v-model="form.receiverMobile" placeholder="请输入收货人手机号码" />
      </a-form-model-item>
      <a-form-model-item label="收货人座机" prop="receiverTelephone" >
        <a-input v-model="form.receiverTelephone" placeholder="请输入收货人座机" />
      </a-form-model-item>
      <a-form-model-item label="邮政编码" prop="postCode" >
        <a-input v-model="form.postCode" placeholder="请输入邮政编码" />
      </a-form-model-item>
      <a-form-model-item label="省" prop="province" >
        <a-input v-model="form.province" placeholder="请输入省" />
      </a-form-model-item>
      <a-form-model-item label="城市" prop="city" >
        <a-input v-model="form.city" placeholder="请输入城市" />
      </a-form-model-item>
      <a-form-model-item label="区" prop="area" >
        <a-input v-model="form.area" placeholder="请输入区" />
      </a-form-model-item>
      <a-form-model-item label="具体地址" prop="addr" >
        <a-input v-model="form.addr" placeholder="请输入具体地址" />
      </a-form-model-item>
      <a-form-model-item label="是否为默认地址" prop="defaultAddr" >
        <a-select v-model="form.defaultAddr" placeholder="请选择是否为默认地址">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-select v-model="form.status" placeholder="请选择状态">
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="0">弃用</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRefundAddr, addRefundAddr, updateRefundAddr } from '@/api/shop/refundAddr'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        shopId: null,

        receiverName: null,

        receiverMobile: null,

        receiverTelephone: null,

        postCode: null,

        provinceId: null,

        province: null,

        cityId: null,

        city: null,

        areaId: null,

        area: null,

        addr: null,

        defaultAddr: null,

        status: 0

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        receiverName: [
          { required: true, message: '收货人名字不能为空', trigger: 'blur' }
        ],
        receiverMobile: [
          { required: true, message: '收货人手机号码不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ],
        receiverTelephone: [
          { required: true, message: '收货人座机不能为空', trigger: 'blur' }
        ],
        postCode: [
          { required: true, message: '邮政编码不能为空', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '省不能为空', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '城市不能为空', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '区不能为空', trigger: 'blur' }
        ],
        addr: [
          { required: true, message: '具体地址不能为空', trigger: 'blur' }
        ],
        defaultAddr: [
          { required: true, message: '是否为默认地址不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        shopId: null,
        receiverName: null,
        receiverMobile: null,
        receiverTelephone: null,
        postCode: null,
        provinceId: null,
        province: null,
        cityId: null,
        city: null,
        areaId: null,
        area: null,
        addr: null,
        defaultAddr: null,
        status: 1
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRefundAddr({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRefundAddr(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRefundAddr(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
