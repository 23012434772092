import request from '@/utils/request'

// 查询商城-店铺-行业列表
export function listShopTrade(query) {
  return request({
    url: '/shop/shop-shop-trade/list',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-行业分页
export function pageShopTrade(query) {
  return request({
    url: '/shop/shop-shop-trade/page',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-行业详细
export function getShopTrade(data) {
  return request({
    url: '/shop/shop-shop-trade/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-店铺-行业
export function addShopTrade(data) {
  return request({
    url: '/shop/shop-shop-trade/add',
    method: 'post',
    data: data
  })
}

// 修改商城-店铺-行业
export function updateShopTrade(data) {
  return request({
    url: '/shop/shop-shop-trade/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-店铺-行业
export function delShopTrade(data) {
  return request({
    url: '/shop/shop-shop-trade/delete',
    method: 'post',
    data: data
  })
}
