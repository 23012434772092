<template>
  <a-modal title="商品列表" :visible="visible" @cancel="visible = fasle" width="50%" cancelText="关闭" @ok="submitForm">
    <!--    <page-header-wrapper>-->
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="12" :sm="24">
              <a-form-item label="商品名称" prop="nickname">
                <a-input v-model="queryParam.goodsName" placeholder="请输入商品名称" allow-clear/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="48">
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button type="danger" :disabled="multiple" style="margin-left: 8px"  @click="handleDelete" v-hasPermi="['video:video/comment:remove']">

                  清空
          </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

      </div>

      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type:'radio' }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <img style="width: 48px;height: 48px;" preview="分类图片" :src="record.picture" slot="picture" slot-scope="text, record">
S
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!--    </page-header-wrapper>-->
  </a-modal>


</template>

<script>
import { list } from '@/api/mall/goods'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'VideoComment',
  components: {
    // CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      visible:false,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        goodsType: null,
        goodsName: null,
        state: 1,
        pageNum: 1,
        pageSize: 10
      },
      url:{
        imgerver: window._CONFIG['fileURL']
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商品图片',
          dataIndex: 'picture',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'picture' }

        },
        {
          title: '商品描述',
          dataIndex: 'description',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商品库存',
          dataIndex: 'stock',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    submitForm() {
      console.log(this.selectedRowKeys)
      console.log(this.selectedRows);
      if(this.selectedRows.length) {
        this.$emit('ok', this.selectedRows[0])
        this.visible = false;
      } else {
        this.$message.warning("请选择商品")
      }

      console.log("选择");
    },
    open(rowData) {
      this.queryParam.goodsType = rowData.goodsType; // 1平台自营 0商家自营
      this.queryParam.goodsName = null; // 1平台自营 0商家自营
      this.getList()
      this.visible = true
    },
    /** 查询短视频评论列表 */
    getList () {
      this.loading = true
      list(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      let that = this;
      that.onSelectChange([], [])
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('video/video/comment/export', {
            ...that.queryParam
          }, `短视频评论_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
/deep/ .ant-card-body {
  padding: 0!important;
}
</style>
