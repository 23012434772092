import request from '@/utils/request'


// 查询主播等级列表
export function listAnchorlevel(query) {
  return request({
    url: '/live/live-anchorlevel/list',
    method: 'get',
    params: query
  })
}

// 查询主播等级分页
export function pageAnchorlevel(query) {
  return request({
    url: '/live/live-anchorlevel/page',
    method: 'get',
    params: query
  })
}

// 查询主播等级详细
export function getAnchorlevel(data) {
  return request({
    url: '/live/live-anchorlevel/detail',
    method: 'get',
    params: data
  })
}

// 新增主播等级
export function addAnchorlevel(data) {
  return request({
    url: '/live/live-anchorlevel/add',
    method: 'post',
    data: data
  })
}

// 修改主播等级
export function updateAnchorlevel(data) {
  return request({
    url: '/live/live-anchorlevel/edit',
    method: 'post',
    data: data
  })
}

// 删除主播等级
export function delAnchorlevel(data) {
  return request({
    url: '/live/live-anchorlevel/delete',
    method: 'post',
    data: data
  })
}
