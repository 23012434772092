<template>
  <a-modal :title="formTitle" :visible="open" width="50%" @cancel="cancel" @ok="submitForm">

    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
<!--      <a-form-model-item label="用户类型" prop="type" >-->
<!--        <a-select v-model="form.type" placeholder="请选择用户类型">-->
<!--          <a-select-option value="0">-->
<!--            普通用户-->
<!--          </a-select-option>-->
<!--          <a-select-option value="1">-->
<!--            否-->
<!--          </a-select-option>-->
<!--          <a-select-option value="2">-->
<!--            否-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="上级用户id，外键(user.id)" prop="topUserId" >-->
<!--        <a-input v-model="form.topUserId" placeholder="请输入上级用户id，外键(user.id)" />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="密码" prop="passwd" >-->
<!--        <a-input v-model="form.passwd" placeholder="请输入密码" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="头像" prop="avatar" >-->
<!--          <a-upload-->
<!--            listType="picture-card"-->
<!--            class="avatar-uploader"-->
<!--            :showUploadList="false"-->
<!--            :customRequest='fileUploadRequest'-->
<!--            :beforeUpload="beforeUpload"-->
<!--            @change="handleChange"-->
<!--          >-->
<!--            &lt;!&ndash;          {{baseimgerver}}&ndash;&gt;-->
<!--            <img v-if="form.avatar" :src="form.avatar" alt="图片" style="height:104px;max-width:300px"/>-->
<!--            <div v-else>-->
<!--              <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>-->
<!--              <div class="ant-upload-text">上传</div>-->
<!--            </div>-->
<!--          </a-upload>-->
<!--          <span style="color:#ff0000">图片限制小于5M,推荐规格:800*800 </span>-->
<!--&lt;!&ndash;        <a-input v-model="form.avatar" placeholder="请输入头像链接" />&ndash;&gt;-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="昵称" prop="nickname" >-->
<!--        <a-input v-model="form.nickname" placeholder="请输入昵称" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="性别" prop="sex" >-->
<!--        <a-select v-model="form.sex" placeholder="请选择用户类型">-->
<!--          <a-select-option value="0">-->
<!--           保密-->
<!--          </a-select-option>-->
<!--          <a-select-option value="1">-->
<!--            男-->
<!--          </a-select-option>-->
<!--          <a-select-option value="2">-->
<!--           女-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="生日" prop="birthday" >-->
<!--        <a-date-picker v-model="birthday" :format="dateFormat" @change="onChangeBirthday"/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="身高" prop="height" >-->
<!--        <a-input v-model="form.height" type="number" :min="0" placeholder="请输入身高" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="体重" prop="weight" >-->
<!--        <a-input v-model="form.weight" type="number" :min="0" placeholder="请输入体重" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="所在城市" prop="city" >-->
<!--&lt;!&ndash;        <a-input v-model="form.city" placeholder="请输入所在城市" />&ndash;&gt;-->
<!--        <a-tree-select-->
<!--          v-model="form.city"-->
<!--          show-search-->
<!--          style="width: 100%"-->
<!--          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--          allow-clear-->
<!--          tree-default-expand-all-->
<!--          placeholder="请所在城市"-->
<!--          @change="changeCitySelect"-->
<!--        >-->
<!--          <a-tree-select-node :key="item.areaId" :value="item.areaId" :title="item.areaName" v-for="(item,index) in acityList">-->
<!--            <a-tree-select-node :key="ite.areaId" :value="ite.areaId" :title="ite.areaName" v-for="(ite,idx) in item.children">-->
<!--&lt;!&ndash;              <a-tree-select-node :key="ite1.areaId" :value="ite1.areaId" :title="ite1.areaName" v-for="(ite1,idx1) in ite.children"/>&ndash;&gt;-->
<!--            </a-tree-select-node>-->
<!--          </a-tree-select-node>-->
<!--        </a-tree-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="所在城市是否保密" prop="isCitySecrecy" >-->
<!--        <a-select v-model="form.isCitySecrecy" placeholder="所在城市是否保密">-->
<!--          <a-select-option value="0">-->
<!--            否-->
<!--          </a-select-option>-->
<!--          <a-select-option value="1">-->
<!--            是-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--&lt;!&ndash;        <a-input v-model="form.isCitySecrecy" placeholder="请输入所在城市是否保密 1-是 0-否" />&ndash;&gt;-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手机号" prop="mobile" >-->
<!--        <a-input v-model="form.mobile" placeholder="请输入手机号" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="星座" prop="constellation" >-->
<!--        <a-input v-model="form.constellation" placeholder="请输入星座" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="职业" prop="occupation" >-->
<!--        <a-input v-model="form.occupation" placeholder="请输入职业" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="婚姻状态，0-保密，1-已婚，2-未婚，3-离异，4-丧偶" prop="maritalStatus" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="收入情况，0-保密，1-5万以下，2-5-10万，3-10-15万，4-15-20万，5-20-25万，6-50万以上" prop="income" >-->
<!--        <a-input v-model="form.income" placeholder="请输入收入情况，0-保密，1-5万以下，2-5-10万，3-10-15万，4-15-20万，5-20-25万，6-50万以上" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="居住情况，0-暂不设置，1-接收婚前同居，2-接收婚后同居，3-保密" prop="dwellCondition" >-->
<!--        <a-input v-model="form.dwellCondition" placeholder="请输入居住情况，0-暂不设置，1-接收婚前同居，2-接收婚后同居，3-保密" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否实名认证，1-是 0-否" prop="isAuthentication" >-->
<!--        <a-input v-model="form.isAuthentication" placeholder="请输入是否实名认证，1-是 0-否" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否真人认证，1-是 0-否" prop="isRealAuthentication" >-->
<!--        <a-input v-model="form.isRealAuthentication" placeholder="请输入是否真人认证，1-是 0-否" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否在线，1-是 0-否" prop="isOnline" >-->
<!--        <a-input v-model="form.isOnline" placeholder="请输入是否在线，1-是 0-否" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否会员，1-是 0-否" prop="isVip" >-->
<!--        <a-input v-model="form.isVip" placeholder="请输入是否会员，1-是 0-否" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="会员等级，0-普通用户，1-黄金会员，2-白金会员，3-钻石会员，4-至尊会员" prop="vipGrade" >-->
<!--        <a-input v-model="form.vipGrade" placeholder="请输入会员等级，0-普通用户，1-黄金会员，2-白金会员，3-钻石会员，4-至尊会员" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="身份等级，0-普通用户，1-科员，2-科长，3-主任，4-经理，5-总经理，6-县级股东，7-地区股东，8-省级股东" prop="rankGrade" >-->
<!--        <a-input v-model="form.rankGrade" placeholder="请输入身份等级，0-普通用户，1-科员，2-科长，3-主任，4-经理，5-总经理，6-县级股东，7-地区股东，8-省级股东" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="邀请码" prop="invitationCode" >-->
<!--        <a-input v-model="form.invitationCode" placeholder="请输入邀请码" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户个性签名" prop="userSign" >-->
<!--        <a-input v-model="form.userSign" placeholder="请输入用户个性签名" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否推送 1-是 0-否" prop="isNotice" >-->
<!--        <a-input v-model="form.isNotice" placeholder="请输入是否推送 1-是 0-否" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录" prop="status" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="邮箱" prop="email" >-->
<!--        <a-input v-model="form.email" placeholder="请输入邮箱" />-->
<!--      </a-form-model-item>-->

      <a-form-model-item label="是否是县级级股东" prop="isCountyShareholder" >
        <a-select v-model="form.isCountyShareholder" placeholder="是否是区级股东" >
          <a-select-option :value="0">
            否
          </a-select-option>
          <a-select-option :value="1">
            是
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否是区级股东" prop="isRegionShareholder" >
        <a-select v-model="form.isRegionShareholder" placeholder="是否是区级股东" @change="changeIsRegionShareholder">
          <a-select-option :value="0">
            否
          </a-select-option>
          <a-select-option :value="1">
            是
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="是否是省级股东" prop="isProvinceShareholder" >
        <a-select v-model="form.isProvinceShareholder" placeholder="是否是省级股东">
          <a-select-option :value="0">
            否
          </a-select-option>
          <a-select-option :value="1">
            是
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否推荐" prop="isRecommend" >
        <a-select v-model="form.isRecommend" placeholder="是否推荐">
          <a-select-option :value="0">
            否
          </a-select-option>
          <a-select-option :value="1">
            是
          </a-select-option>
        </a-select>
      </a-form-model-item>
        <a-form-model-item label="是否是经理" prop="isManager" >
          <a-select v-model="form.isManager" placeholder="是否是经理">
            <a-select-option :value="0">
              否
            </a-select-option>
            <a-select-option :value="1">
              是
            </a-select-option>
          </a-select>
        </a-form-model-item>
      <a-form-model-item v-if="form.isManager == 1" label="经理直销比例" prop="managerRatioOne" >
        <a-input-number :min="0" :step="0.01" :max="1" v-model="form.managerRatioOne" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item v-if="form.isManager == 1" label="经理间销比例" prop="managerRatioTwo" >
        <a-input-number :min="0" :step="0.01" :max="1" v-model="form.managerRatioTwo" placeholder="请输入"/>
      </a-form-model-item>

</a-form-model>


      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
  </a-modal>
</template>

<script>
import { getUserAdmin, addUserAdmin, updateUserAdmin } from '@/api/userAdmin/userAdmin'
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import moment from 'moment'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      dateFormat: 'YYYY-MM-DD',
      formTitle: '',
      selectedKeys: [],
      uploadLoading: false,
      birthday: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        topUserId: null,

        mobile: null,

        passwd: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        height: null,

        weight: null,

        city: null,

        isCitySecrecy: null,

        constellation: null,

        occupation: null,

        maritalStatus: 0,

        income: null,

        dwellCondition: null,

        isAuthentication: null,

        isRealAuthentication: null,

        isOnline: null,

        isVip: null,

        vipGrade: null,

        rankGrade: null,

        invitationCode: null,

        userSign: null,

        isNotice: null,

        status: 0,

        createTime: null,

        remark: null,

        email: null,

        isRecommend: null,

        isManager: null,

        isFriendster: null,
        isProvinceShareholder:null,
        isRegionShareholder:null,
        isCountyShareholder: null,
        managerRatioOne: 0,
        managerRatioTwo: 0,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isManager: [
          { required: true, message: '是否为经理不能为空', trigger: 'change' }
        ],
        managerRatioOne: [
          { required: true, message: '经理直销比例不能为空', trigger: 'change' }
        ],
        managerRatioTwo: [
          { required: true, message: '经理间销比例不能为空', trigger: 'change' }
        ],
        type: [
          { required: true, message: '用户类型，0-普通用户，1-主播，2-商家不能为空', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '用户名/手机号，用于登录不能为空', trigger: 'blur' }
        ],
        passwd: [
          { required: true, message: '密码，md5不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '头像链接不能为空', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '昵称不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        isCitySecrecy: [
          { required: true, message: '所在城市是否保密 1-是 0-否不能为空', trigger: 'blur' }
        ],
        maritalStatus: [
          { required: true, message: '婚姻状态，0-保密，1-已婚，2-未婚，3-离异，4-丧偶不能为空', trigger: 'blur' }
        ],
        income: [
          { required: true, message: '收入情况，0-保密，1-5万以下，2-5-10万，3-10-15万，4-15-20万，5-20-25万，6-50万以上不能为空', trigger: 'blur' }
        ],
        dwellCondition: [
          { required: true, message: '居住情况，0-暂不设置，1-接收婚前同居，2-接收婚后同居，3-保密不能为空', trigger: 'blur' }
        ],
        isAuthentication: [
          { required: true, message: '是否实名认证，1-是 0-否不能为空', trigger: 'blur' }
        ],
        isRealAuthentication: [
          { required: true, message: '是否真人认证，1-是 0-否不能为空', trigger: 'blur' }
        ],
        isOnline: [
          { required: true, message: '是否在线，1-是 0-否不能为空', trigger: 'blur' }
        ],
        isVip: [
          { required: true, message: '是否会员，1-是 0-否不能为空', trigger: 'blur' }
        ],
        vipGrade: [
          { required: true, message: '会员等级，0-普通用户，1-黄金会员，2-白金会员，3-钻石会员，4-至尊会员不能为空', trigger: 'blur' }
        ],
        rankGrade: [
          { required: true, message: '身份等级，0-普通用户，1-科员，2-科长，3-主任，4-经理，5-总经理，6-县级股东，7-地区股东，8-省级股东不能为空', trigger: 'blur' }
        ],
        userSign: [
          { required: true, message: '用户个性签名不能为空', trigger: 'blur' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    acityList() {
      return this.$store.getters.acityList
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeIsRegionShareholder(e) {
      console.log(e)
      this.form.isRegionShareholder = e;
    },
    changeCitySelect(value, label, extra) {
      console.log(value)
      console.log(label)
      this.form.city = label[0]
      console.log(extra)
    },
    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.avatar = info.file.originFileObj.ossUrl;
      }
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url
          fileInfo.file.ossUrl = ossUrl
          fileInfo.file.ossName = ossUrl
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    onCheck(checkedKeys) {
      console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info)
      console.log('onSelect', selectedKeys)
      this.selectedKeys = selectedKeys
    },
    onChangeBirthday(date, dateString) {
      this.form.birthday = dateString
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        topUserId: null,
        mobile: null,
        passwd: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        height: null,
        weight: null,
        city: null,
        isCitySecrecy: null,
        constellation: null,
        occupation: null,
        maritalStatus: 0,
        income: null,
        dwellCondition: null,
        isAuthentication: null,
        isRealAuthentication: null,
        isOnline: null,
        isVip: null,
        vipGrade: null,
        rankGrade: null,
        invitationCode: null,
        userSign: null,
        isNotice: null,
        status: 0,
        createTime: null,
        remark: null,
        email: null,
        isProvinceShareholder: null,
        isRegionShareholder: null,
        isCountyShareholder: null,
        managerRatioOne: 0,
        managerRatioTwo: 0,
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserAdmin({"id":id}).then(response => {
        let form = response.data
        form.sex = response.data.sex + ''
        form.isCountyShareholder = JSON.parse(JSON.stringify(row)).isCountyShareholder
        form.isProvinceShareholder = JSON.parse(JSON.stringify(row)).isProvinceShareholder
        form.isRegionShareholder = JSON.parse(JSON.stringify(row)).isRegionShareholder
        form.managerRatioOne = row.managerRatioOne
        form.managerRatioTwo = row.managerRatioTwo
        form.isCitySecrecy = response.data.isCitySecrecy ? '1' : '0'
        console.log(this.form);
        this.form =form;
        this.birthday = response.data.birthday ? moment(response.data.birthday).format('YYYY-MM-DD') : ''
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            let jsonData = JSON.parse(JSON.stringify(this.form))
            jsonData.isCitySecrecy = jsonData.isCitySecrecy == 1 ? true : false
            updateUserAdmin(jsonData).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUserAdmin(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
