<template>
  <a-modal title="商品详情" :width="800" :visible="open" @ok="submitForm"
           @cancel="onClose" cancelText="关闭">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="商品分类">
        <a-tree-select v-model="form.categoryId" style="width: 100%"
                       :replaceFields="replaceFields"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       showCheckedStrategy="SHOW_ALL"
                       :tree-data="goodsCategoryList" placeholder="请选择商品分类" disabled tree-default-expand-all>
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="商品店铺分类">
        <a-tree-select v-model="form.shopCategoryId" style="width: 100%"
                       :replaceFields="replaceShopCategoryFields"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       :tree-data="shopCategoryList" placeholder="请选择商品店铺分类" disabled :treeIcon="false">
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="商品名称">
        <a-input v-model="form.goodsName" disabled placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="商品简介">
        <a-input v-model="form.introduction" disabled placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="商品关键字">
        <a-input v-model="form.keywords" disabled placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="品牌" v-if="form.brandId">
        <a-tree-select v-model="form.brandId" style="width: 100%"
                       :replaceFields="replaceBrandFields"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       :tree-data="brandList" placeholder="请选择品牌" disabled tree-default-expand-all>
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="商品主图">
        <img v-if="form.picture" :src="form.picture" alt="主图"
             style="height:173px;width:173px"/>
      </a-form-model-item>
      <a-form-model-item label="商品图册">
        <img style="width: 150px;height: 150px" v-for="(item,index) in form.imgIdArray" :src="item" :key="index" />
      </a-form-model-item>
      <a-form-model-item label="商品划价">
        <a-input style="width: 150px;" :min="0" :step="1"  addonAfter="元" v-model="form.price"
                 disabled type="number" />
      </a-form-model-item>
      <a-form-model-item label="商品零售价" v-if="form.goodsType == 0">
        <a-input style="width: 150px;" :min="0" :step="1" addonAfter="元" v-model="form.promotionPrice"
                 disabled type="number"/>
      </a-form-model-item>
      <a-form-model-item label="商品优惠价" v-if="form.goodsType == 0">
        <a-input style="width: 150px;" :min="0" :step="1" addonAfter="元" v-model="form.beansPrice"
                 disabled type="number"/>
      </a-form-model-item>
      <a-form-model-item label="商品嗨豆价" v-if="form.goodsType == 0">
        <a-input-number :min="1" style="width: 150px;" disabled v-model="form.beans"/>
      </a-form-model-item>
      <a-form-model-item label="商品零售价" v-if="form.goodsType == 1">
        <a-input style="width: 150px;" :min="0" :step="1" addonAfter="元" v-model="form.highCostPrice"
                 disabled type="number"/>
      </a-form-model-item>
      <a-form-model-item label="结算折扣" v-if="form.goodsType == 1">
        <a-input-number :min="0.1" style="width: 150px;" :max="1" :step="0.1"
                        v-model="form.highDiscountPrice" disabled/>
      </a-form-model-item>
      <a-form-model-item label="商品重量">
        <a-input style="width: 150px;" :min="0" :step="1" addonAfter="Kg" v-model="form.goodsWeight"
                 disabled  type="number"/>
      </a-form-model-item>
      <a-form-model-item label="是否新品">
        <a-radio-group v-model="form.isNew" disabled button-style="solid">
          <a-radio-button v-for="(d, index) in newOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否热卖">
        <a-radio-group v-model="form.isHot" disabled button-style="solid">
          <a-radio-button v-for="(d, index) in hotOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否推荐">
        <a-radio-group v-model="form.isRecommend" disabled button-style="solid">
          <a-radio-button v-for="(d, index) in recommendOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否精品商品">
        <a-radio-group v-model="form.isBoutique" disabled button-style="solid">
          <a-radio-button v-for="(d, index) in boutiqueOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="排序">
        <a-input-number v-model="form.sort" disabled :min="0" style="width: 100%"/>
      </a-form-model-item>
      <a-form-model-item label="商品保障">
        <a-checkbox-group v-model="form.goodsSecuritys" disabled>
          <a-checkbox v-for="(item,index) in goodsSecurityList" :key="index" disabled
                      :value="item.id">
            {{ item.name }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="运费模版" v-if="form.freightTemplateId">
        <a-select
          v-model="form.freightTemplateId"
          showSearch
          placeholder="请选择运费模板"
          style="width: 200px"
          disabled
        >
          <a-select-option v-for="freight in freightTemplateList" :key="freight.id" disabled :value="freight.id">
            {{ freight.name }}
          </a-select-option>
        </a-select>
  <a @click.stop="lookLogInfo(form.freightTemplateId)" style="margin-left: 10px">查看</a>
      </a-form-model-item>
      <a-divider></a-divider>

      <a-table :loading="loading" size="small" rowKey="attrValueItems" :columns="skuColumns" :data-source="skuList"
               :pagination="false" :bordered="true">
        <!-- <div slot="key_name" slot-scope="text, record">{{  }}</div> -->
        <div slot="price" slot-scope="text, record">
          <a-input placeholder="请输入" disabled v-model="record.price"></a-input>
        </div>
        <div slot="promotionPrice" slot-scope="text, record">
                  <span v-if="form.goodsType == 0">
                    <a-input placeholder="请输入" type="number"  :min="0" disabled v-model="record.promotionPrice"></a-input>
                  </span>
          <span v-else>-</span>
        </div>
        <div slot="beansPrice" slot-scope="text, record">
                                    <span v-if="form.goodsType == 0">
                                                        <a-input placeholder="请输入" type="number"
                                                                 v-model="record.beansPrice" disabled :min="0"></a-input>
                                    </span>
          <span v-else>-</span>


        </div>

        <div slot="beans" slot-scope="text, record">
                     <span v-if="form.goodsType == 0">
                           <a-input-number disabled :min="0" placeholder="请输入" v-model="record.beans"/>
                     </span>
          <span v-else>-</span>

        </div>
        <div slot="highCostPrice" slot-scope="text, record">
                     <span v-if="form.goodsType == 1">
                           <a-input placeholder="请输入" disabled min="0" type="number" addonAfter="元"
                                    v-model="record.highCostPrice"></a-input>
                     </span>
          <span v-else>-</span>


        </div>
        <div slot="highDiscountPrice" slot-scope="text, record">

                  <span v-if="form.goodsType == 1">
                       <a-input-number placeholder="请输入" :min="0.1" :max="10" :step="0.1"
                                       v-model="record.highDiscountPrice" disabled/>
                     </span>
          <span v-else>-</span>


        </div>

        <div slot="stock" slot-scope="text, record">
          <a-input-number placeholder="请输入" :min="0" v-model="record.stock" disabled></a-input-number>
        </div>
        <div slot="picture" class="flex align-center justify-center" slot-scope="text, record">

            <img v-if="record.picture" :src="record.picture" alt="规格图片"
                 style="height:100px;width:100px;"/>
        </div>
      </a-table>
      <!-- end   根据所选的规格，生成skuList,展示表格 -->
      </div>
      <a-divider></a-divider>
      <a-form-model-item label="商品详情">
        <div v-html="form.description"></div>
      </a-form-model-item>

      <a-form-model-item label="商品简介">
        <div v-html="form.exhibition"></div>
      </a-form-model-item>

      <a-form-model-item label="商品参数展示">
        <div v-html="form.parameter"></div>
      </a-form-model-item>

      <a-form-model-item label="购买须知">
        <div v-html="form.aftersale"></div>
      </a-form-model-item>
<freightTemplateModal ref="freightTemplateModal" />
    </a-form-model>
  </a-modal>
</template>

<script>
import freightTemplateModal from '../../template/look_freight.vue'

import { auditGoods } from '@/api/mall/goods'
import {getAllGoodsCategoryList} from "@/api/mall/goodscate";
import * as goods from "@/api/mall/goods";
import * as shopCategory from "@/api/shop/shopCategory";
import * as goodsspec from "@/api/mall/goodsspec";
import {listBrand} from "@/api/shop/brand";
import * as goodscurity from "@/api/mall/goodscurity";
import {shippingTemplatesListAll} from "@/api/logistics";
export default {
  name: "AuditDetailGoods" ,
  components:{
    freightTemplateModal
  },
  data() {
    return {
      open: false,
      form: {
        id: ''
      },
      replaceFields: {
        title: 'categoryName',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      replaceShopCategoryFields: {
        title: 'categoryName',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      replaceBrandFields: {
        title: 'name',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      rules: {
        auditMessage: [{
          required: true,
          message: '驳回意见不能为空',
          trigger: 'blur'
        }]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      goodsCategoryList:[],
      specValueList:[],
      skuList:[],
      shopCategoryList:[],
      brandList:[],
      newOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      hotOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      recommendOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      boutiqueOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      goodsSecurityList:[],
      freightTemplateList:[],
      loading:false,
      skuColumns: [
        {
          title: '规格',
          dataIndex: 'skuName',
          align: 'center'
        }, {
          title: '商品划价',
          dataIndex: 'price',
          align: 'center',
          scopedSlots: {customRender: 'price'}
        }, {
          title: '商品零售价',
          dataIndex: 'promotionPrice',
          align: 'center',
          scopedSlots: {customRender: 'promotionPrice'}
        }, {
          title: '商品优惠价',
          dataIndex: 'beansPrice',
          align: 'center',
          scopedSlots: {customRender: 'beansPrice'}
        }, {
          title: '商品嗨豆价',
          dataIndex: 'beans',
          align: 'center',
          scopedSlots: {customRender: 'beans'}
        }, {
          title: '商品零售价',
          dataIndex: 'highCostPrice',
          align: 'center',
          scopedSlots: {customRender: 'highCostPrice'}
        }, {
          title: '结算折扣',
          dataIndex: 'highDiscountPrice',
          align: 'center',
          scopedSlots: {customRender: 'highDiscountPrice'}
        }, {
          title: '库存',
          dataIndex: 'stock',
          align: 'center',
          scopedSlots: {customRender: 'stock'}
        },
        {
          title: '图片',
          dataIndex: 'picture',
          align: 'center',
          width: 140,
          scopedSlots: {customRender: 'picture'}
        }
      ],
    }
  },
  created() {
    this.getGoodsCate();
    this.getShopCategoryList();
    this.getSpecList();
    this.getBrandList();
    this.getGoodsSecurityList();
    this.getFreightTemplateList();
  },
  methods: {
    lookLogInfo(id) {
      this.$refs['freightTemplateModal'].getInfo({
        id: id
      })
    },
    getFreightTemplateList() {
      // logistics.
      shippingTemplatesListAll().then(res => {
        if (res.code == 20001) {
          console.log("11111111111111")
          console.log(res.data)
          this.freightTemplateList = res.data;
        }
      })

    },
    getGoodsSecurityList() {
      goodscurity.list().then(res => {
        if (res.code == 20001) {
          this.goodsSecurityList = res.data;
          console.log('进来了')
          console.log(this.goodsSecurityList)
        }
      })
    },
    getBrandList() {
      listBrand().then(res => {
        if (res.code == 20001) {
          this.brandList = res.data;
        }
      })
    },
    getShopCategoryList() {
      shopCategory.listShopCategory().then(res => {
        if (res.code == 20001) {
          this.shopCategoryList = res.data;
        }
      })
    },
    getSpecList() {
      goodsspec.page().then(res => {
        if (res.code == 20001) {
          this.specList = res.data;
        }
      })
    },
    handleUpdate(id) {
      // this.reset()
      this.formTitle = '修改商品'
      goods.detail({ id: id }).then(response => {
        let forminfo = JSON.parse(JSON.stringify(response.data));
        forminfo.goodsSecuritys = forminfo.goodsSecuritys.split(',')
        forminfo.isBoutique = forminfo.isBoutique ? 1 : 0
        forminfo.isHot = forminfo.isHot ? 1 : 0
        forminfo.isNew = forminfo.isNew ? 1 : 0
        forminfo.isRecommend = forminfo.isRecommend ? 1 : 0
        forminfo.imgIdArray = forminfo.imgIdArray.split(',')
        //   .map((i,idx) => ({
        //   uid:'-' + idx,
        //   name:'image.png',
        //   status: 'done',
        //   url:i
        // }))
        let goodsSpecFormat = JSON.parse(forminfo.goodsSpecFormat);
        let speValueList = [];

        this.specList = this.specList.filter(v => !goodsSpecFormat.some((item) => item.spec_id == v.id));
        goodsSpecFormat.forEach((i,idx) => {
          let speValueItem = {
            id:i.spec_id,
            specName:i.spec_name,
            specDes:'',
            showBtn:true,
            specValueList:[]
          }
          speValueList.push(speValueItem)
          i.value.forEach(j => {
            let valueItem = {
              specId:j.spec_id,
              specName:j.spec_name,
              specValueName:j.spec_value_name,
              id:j.spec_value_id,
              isVisible: 1,
              sort: 0,
              remark: '',
            }
            speValueList[idx].specValueList.push(valueItem)
          })
        })
        this.specValueList = speValueList
        this.skuList = forminfo.skuList;
        this.form = forminfo

      })
    },
    getGoodsCate() {
      this.loading = false
      getAllGoodsCategoryList().then(res => {
        if (res.code == 20001) {
          this.goodsCategoryList = res.data;
          this.dealThreeList(res.data);
        }
      })

    },
    dealThreeList(goodsCategoryList) {
      let threeIdData = [];
      console.log('家22222222222222222222222')
      goodsCategoryList.forEach(j => {
        console.log('j')
        console.log(j)

        if(j.children && j.children.length) {
          // 循环二级
          console.log('--------------------------')
          console.log(j)
          j.children.forEach(it => {
            if(it.children && it.children.length) {
              it.children.forEach(GG => {
                if(GG.level == 3) {
                  threeIdData.push(GG)
                }
              })
              // it.children.forEach(jt => {
              //   if(jt.children && jt.children.length) {
              //
              //   }
              // })
            }
          })
        }
      })
      this.threeIdData = threeIdData
      console.log('threeIdData')
    },
    onClose() {
      this.open = false
    },
    handleOpen(record) {
      // this.form.auditMessage = '';
      this.form.id = record.id;
      this.handleUpdate(record.id);
      this.open = true;
    },
    submitForm() {
      let that = this;
      that.open = false
      // this.$refs.form.validate(valid => {
      //   if (valid) {
      //     let recordInfo = JSON.parse(JSON.stringify(that.form))
      //     recordInfo.state = 3;
      //     auditGoods(recordInfo).then(res => {
      //       if(res.code == 20001) {
      //         that.open = false
      //         this.$emit('ok')
      //       } else {
      //         that.open = false
      //       }
      //     })
      //   } else {
      //     return false
      //   }
      // })
    }
  }
}
</script>

<style scoped>

</style>