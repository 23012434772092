<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="ID" prop="account">
                <a-input v-model="queryParam.account" placeholder="请输入ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="身份证号" prop="idCard">
                <a-input v-model="queryParam.idCard" placeholder="请输入身份证号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="地区" prop="address">
                <a-input v-model="queryParam.address" placeholder="请输入地区" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['userAdmin:userAdmin:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" >
          <a-icon type="download" />导出
        </a-button>
      </div>
      <ResetPwdModalForm
        ref="ResetPwdModalForm"
      />
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <dynamicModalTables ref="dynamicModalTables" />
      <rechargeRecord ref="rechargeRecord"/>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="true"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <a-switch slot="isAdmin" slot-scope="text, record" @click="updateUserState(record)" v-model="record.isAdmin">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>
        <a-switch slot="isSend" slot-scope="text, record" @click="updateSendState(record)" v-model="record.isSend">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>
        <a slot="videoNumber" slot-scope="text, record">{{ record.videoNumber }}</a>
        <a slot="friendNumber" slot-scope="text, record">{{ record.friendNumber }}</a>
        <a slot="followedNumber" slot-scope="text, record">{{ record.followedNumber }}</a>
        <a slot="fansNumber" slot-scope="text, record">{{ record.fansNumber }}</a>
        <a slot="dynamic" slot-scope="text, record" @click.stop="lookDynamic(record)">查看动态</a>
        <a slot="rechargeRecords" @click.stop="openRecord(record)" slot-scope="text, record">查看</a>
        <a-tag slot="type" slot-scope="text,record">{{ record.type == 1 ? '主播' : record.type == 2 ? '商家' : '粉丝' }}</a-tag>
        <img slot="avatar" slot-scope="text,record" style="width: 32px;height: 32px;border-radius: 50%" :src="record.avatar" v-image-preview/>
        <span slot="operation" slot-scope="text, record">
          <!--          <a-divider type="vertical" v-hasPermi="['userAdmin:userAdmin:edit']" />-->
          <!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" >-->
          <!--&lt;!&ndash;            <a-icon type="edit" />&ndash;&gt;-->
          <!--            编辑-->
          <!--          </a>-->
          <a-divider type="vertical" />
          <a v-if="record.status == 1" @click="disabledUser(record,1)" >
            禁用
          </a>
          <a v-if="record.status == 0" @click="disabledUser(record,2)" >
            解禁
          </a>
          <a-divider type="vertical" />
          <a @click.stop="chongzhiMima(record)">
            重置密码
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" >
            <!--            <a-icon type="delete" />-->
            删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUserAdmin, listUserAdmin, delUserAdmin, updateOnline, disabledUser } from '@/api/userAdmin/userAdmin'
import CreateForm from './modules/CreateModalForm'
import { tableMixin } from '@/store/table-mixin'
import dynamicModalTables from './modules/dynamicModalTables'
import rechargeRecord from './modules/RechargeRecord'
import ResetPwdModalForm from '../vipUser/modules/resetPwdModalForm'
export default {
  name: 'UserAdmin',
  components: {
    CreateForm,
    dynamicModalTables,
    rechargeRecord,
    ResetPwdModalForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        account: null,
        type: null,
        topUserId: null,
        mobile: null,
        passwd: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        height: null,
        weight: null,
        city: null,
        idCard: null,
        isCitySecrecy: null,
        constellation: null,
        occupation: null,
        maritalStatus: null,
        income: null,
        dwellCondition: null,
        isAuthentication: null,
        isRealAuthentication: null,
        isOnline: null,
        isVip: null,
        vipGrade: null,
        rankGrade: null,
        invitationCode: null,
        userSign: null,
        isNotice: null,
        status: null,
        email: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '#',
        //   dataIndex: 'id',
        //   align: 'center',
        //   width: 60,
        //   customRender: function (text, record, index) {
        //     return (index + 1)
        //   }
        // },
        // {
        //   title: '用户类型',
        //   dataIndex: 'type',
        //   align: 'center',
        //   scopedSlots: { customRender: "type" }
        // },
        // {
        //   title: '上级用户id，外键(user.id)',
        //   dataIndex: 'topUserId',
        //   ellipsis: true,
        //   align: 'center'
        // },

        // {
        //   title: '密码，md5',
        //   dataIndex: 'passwd',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'ID',
          dataIndex: 'account',
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          align: 'center',
          scopedSlots: { customRender: 'avatar' }
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1) {
              return '男'
            } else if (text == 2) {
              return '女'
            } else {
              return '保密'
            }
          }
        },
        {
          title: '身份证号',
          dataIndex: 'idCard',
          align: 'center',
          customRender: function (text) {
            if (text) {
              return text
            } else {
              return '-'
            }
          }
        },
        {
          title: '地区',
          dataIndex: 'address',
          align: 'center',
          customRender: function (text) {
            if (text) {
              return text
            } else {
              return '-'
            }
          }
        },
        {
          title: '余额',
          dataIndex: 'money',
          align: 'center'
        },
        {
          title: '是否管理员',
          dataIndex: 'isAdmin',
          align: 'center',
          scopedSlots: { customRender: 'isAdmin' }
        },
        {
          title: '是否有音视频通话权限',
          dataIndex: 'isSend',
          align: 'center',
          scopedSlots: { customRender: 'isSend' }
        },
        {
          title: '流水记录',
          dataIndex: 'rechargeRecords',
          align: 'center',
          scopedSlots: { customRender: 'rechargeRecords' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    openRecord(record) {
      const queryData = {
        userId: record.id
      }
      this.$refs['rechargeRecord'].open(queryData)
    },

    lookDynamic(record) {
      console.log('点击了')
      this.$refs['dynamicModalTables'].open(record)
    },
    chongzhiMima(record) {
      // this.$refs['dynamicModalTables'].open(record)
      debugger
      this.$refs['ResetPwdModalForm'].openModal(record.id)
    },
    disabledUser(record, type) {
      var that = this
      const ids = record.id || this.ids
      if (record.id) {
        this.ids.push(record.id)
      }
      this.$confirm({
        title: type == 1 ? '确认禁用所选中数据?' : '确认解禁所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id, 'type': type }))
          return disabledUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                type == 1 ? '禁用成功' : '解禁成功',
                3
              )
            })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /**
     * 更新用户操作
     * @param record
     */
    updateUserState(record) {
      console.log('更新操作')
      const recordParams = {
        id: record.id,
        isAdmin: record.isAdmin ? 1 : 0
      }
      updateOnline(recordParams).then(res => {
        if (res.code == 20001) {

        } else {
          this.$message.warning(res.msg)
          record.isAdmin ? 0 : 1
        }
      })
    },
    /**
     * 更新用户操作
     * @param record
     */
    updateSendState(record) {
      console.log('更新操作')
      const recordParams = {
        id: record.id,
        isSend: record.isSend ? 1 : 0
      }
      updateOnline(recordParams).then(res => {
        if (res.code == 20001) {

        } else {
          this.$message.warning(res.msg)
          record.isSend ? 0 : 1
        }
      })
    },
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUserAdmin(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        topUserId: undefined,
        mobile: undefined,
        passwd: undefined,
        avatar: undefined,
        nickname: undefined,
        sex: undefined,
        birthday: undefined,
        height: undefined,
        weight: undefined,
        city: undefined,
        isCitySecrecy: undefined,
        constellation: undefined,
        occupation: undefined,
        maritalStatus: undefined,
        income: undefined,
        dwellCondition: undefined,
        isAuthentication: undefined,
        isRealAuthentication: undefined,
        isOnline: undefined,
        isVip: undefined,
        vipGrade: undefined,
        rankGrade: undefined,
        invitationCode: undefined,
        userSign: undefined,
        isNotice: undefined,
        status: undefined,
        email: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delUserAdmin(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('userAdmin/userAdmin/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
