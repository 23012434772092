<template>
  <crud :config="config"></crud>
</template>
<script>
import Crud from "@/components/crud/Crud.vue";

export default {
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          {
            field: 'type', name: '用户类型', type: 'select', option: [
              { id: 0, name: '普通用户' },
              { id: 1, name: '主播' },
              { id: 2, name: '商家' }
            ]
          },
          { field: 'nickname', name: '昵称' },
          { field: 'mobile', name: '手机号' },
          {
            field: 'sex', name: '性别', type: 'select', option: [
              { id: 0, name: '未知' },
              { id: 1, name: '男' },
              { id: 2, name: '女' }
            ], show: false
          },
          {
            field: 'isAuthentication', name: '实名认证', type: 'select', option: [
              { id: 1, name: '是' },
              { id: 0, name: '否' }
            ], show: false
          },
          {
            field: 'vipGrade', name: '会员等级', type: 'select', option: [
              { id: 0, name: '普通用户' },
              { id: 1, name: '黄金会员' },
              { id: 2, name: '白金会员' },
              { id: 3, name: '钻石会员' },
              { id: 4, name: '至尊会员' }
            ], show: false
          },
          {
            field: 'rankGrade', name: '身份等级', type: 'select', option: [
              { id: 0, name: '普通用户' },
              { id: 1, name: '科员' },
              { id: 2, name: '科长' },
              { id: 3, name: '主任' },
              { id: 4, name: '经理' },
              { id: 5, name: '县级股东' },
              { id: 6, name: '地区股东' },
              { id: 7, name: '省级股东' },
            ], show: false
          }
        ],
        table: {
          api: '/userAdmin/userAdmin/page',
          deleteApi: '/userAdmin/userAdmin/delete',
          col: [
            {
              field: 'type', name: '用户类型', width: 80, type: 'tag', tags: [
                { name: '普通用户' },
                { name: '主播', color: 'blue' },
                { name: '商家', color: 'green' }
              ]
            },
            { field: 'avatar', name: '头像', type: 'avatar' },
            { field: 'nickname', name: '昵称' },
            { field: 'mobile', name: '手机号', width: 110 },

            {
              field: 'sex', name: '性别', type: 'tag', width: 50, tags: [
                { name: '保密' },
                { name: '男', color: 'blue' },
                { name: '女', color: 'red' }
              ]
            },
            { field: 'city', name: '所在城市' },
            {
              field: 'isAuthentication', name: '实名认证', fmt: (value) => value ? '是' : '否'
            },
            {
              field: 'vipGrade', name: '会员等级', type: 'tag', tags: [
                { name: '普通用户' },
                { name: '黄金会员' },
                { name: '白金会员' },
                { name: '钻石会员' },
                { name: '至尊会员' }
              ]
            },
            {
              field: 'rankGrade', name: '身份等级', type: 'tag', tags: [
                { name: '普通用户' },
                { name: '科员' },
                { name: '科长' },
                { name: '主任' },
                { name: '经理' },
                { name: '县级股东' },
                { name: '地区股东' },
                { name: '省级股东' },
              ]
            },
            { field: 'createTime', name: '注册时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '删除', event: 'inner-delete' }
              ]
            }
          ]
        }
      }
    }
  }
}
</script>