import request from '@/utils/request'


// 查询常用语列表
export function listFriendUseful(query) {
  return request({
    url: '/user/friend-useful/list',
    method: 'get',
    params: query
  })
}

// 查询常用语分页
export function pageFriendUseful(query) {
  return request({
    url: '/user/friend-useful/page',
    method: 'get',
    params: query
  })
}

// 查询常用语详细
export function getFriendUseful(data) {
  return request({
    url: '/user/friend-useful/detail',
    method: 'get',
    params: data
  })
}

// 新增常用语
export function addFriendUseful(data) {
  return request({
    url: '/user/friend-useful/add',
    method: 'post',
    data: data
  })
}

// 修改常用语
export function updateFriendUseful(data) {
  return request({
    url: '/user/friend-useful/edit',
    method: 'post',
    data: data
  })
}

// 删除常用语
export function delFriendUseful(data) {
  return request({
    url: '/user/friend-useful/delete',
    method: 'post',
    data: data
  })
}
