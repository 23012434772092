import request from '@/utils/request'


// 查询商城-商品评价列表
export function listGoodsEvaluate(query) {
  return request({
    url: '/shop/shop-goods-evaluate/list',
    method: 'get',
    params: query
  })
}

// 查询商城-商品评价分页
export function pageGoodsEvaluate(query) {
  return request({
    url: '/shop/shop-goods-evaluate/page',
    method: 'get',
    params: query
  })
}

export function shopReply(query) {
  return request({
    url: '/shop/shop-goods-evaluate/shopReply',
    method: 'get',
    params: query
  })
}

// 查询商城-商品评价详细
export function getGoodsEvaluate(data) {
  return request({
    url: '/shop/shop-goods-evaluate/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-商品评价
export function addGoodsEvaluate(data) {
  return request({
    url: '/shop/shop-goods-evaluate/add',
    method: 'post',
    data: data
  })
}

// 修改商城-商品评价
export function updateGoodsEvaluate(data) {
  return request({
    url: '/shop/shop-goods-evaluate/edit',
    method: 'post',
    data: data
  })
}

// 删除商城-商品评价
export function delGoodsEvaluate(data) {
  return request({
    url: '/shop/shop-goods-evaluate/delete',
    method: 'post',
    data: data
  })
}
