<template>
  <a-modal title="驳回原因" :width="800" :visible="open" @ok="submitForm"
           @cancel="onClose" cancelText="关闭">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="驳回意见" prop="auditMessage">
        <a-input v-model="form.auditMessage" placeholder="请输入" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { auditGoods } from '@/api/mall/goods'
export default {
  name: "AuditGoods" ,
  data() {
    return {
      open: false,
      form: {
        auditMessage: '',
        id: ''
      },
      rules: {
        auditMessage: [{
          required: true,
          message: '驳回意见不能为空',
          trigger: 'blur'
        }]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    }
  },
  methods: {
    onClose() {
      this.open = false
    },
    handleOpen(record) {
      this.form.auditMessage = '';
      this.form.id = record.id;
      this.open = true;
    },
    submitForm() {
      let that = this;
      this.$refs.form.validate(valid => {
        if (valid) {
          let recordInfo = JSON.parse(JSON.stringify(that.form))
          recordInfo.state = 3;
          auditGoods(recordInfo).then(res => {
            if(res.code == 20001) {
              that.open = false
              this.$emit('ok')
            } else {
              that.open = false
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>