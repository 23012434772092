<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="是否实名 1-是 0-否" prop="isautonym" >
        <a-input v-model="form.isautonym" placeholder="请输入是否实名 1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="是否1000粉丝 1-是 0-否" prop="isOneThousandFans" >
        <a-input v-model="form.isOneThousandFans" placeholder="请输入是否1000粉丝 1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="真实姓名" prop="realName" >
        <a-input v-model="form.realName" placeholder="请输入真实姓名" />
      </a-form-model-item>
      <a-form-model-item label="证件号码" prop="idCard" >
        <a-input v-model="form.idCard" placeholder="请输入证件号码" />
      </a-form-model-item>
      <a-form-model-item label="状态 1-待审核 2-通过 3-拒绝" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="审核意见" prop="auditOpinion" >
        <a-input v-model="form.auditOpinion" placeholder="请输入审核意见" />
      </a-form-model-item>
      <a-form-model-item label="分类名称" prop="cateName" >
        <a-input v-model="form.cateName" placeholder="请输入分类名称" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApply, addApply, updateApply } from '@/api/live/apply'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        isautonym: null,

        isOneThousandFans: null,

        realName: null,

        idCard: null,

        status: 0,

        auditOpinion: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cateName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        isautonym: null,
        isOneThousandFans: null,
        realName: null,
        idCard: null,
        status: 0,
        auditOpinion: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApply({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApply(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addApply(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
