<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @cancel="onClose" cancelText="关闭" @ok="submitForm">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="展示位置" prop="type" >
        <a-select v-model="form.type" :disabled="isShop" placeholder="请选择展示位置">
          <a-select-option value="1">
            首页
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="链接类型" prop="linkType" >
        <a-select v-model="form.linkType" placeholder="请选择链接类型" @change="changeLinkTypeSelect">
          <a-select-option value="1">
           不跳转
          </a-select-option>
          <a-select-option value="2">
            外链
          </a-select-option>
<!--          <a-select-option value="3">-->
<!--            普通商品-->
<!--          </a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="跳转链接" prop="link" v-if="form.linkType == 2">
        <a-input v-model="form.link" placeholder="请输入外链链接" />
      </a-form-model-item>

      <a-form-model-item label="选择商品" prop="link" v-if="form.linkType == 3">
        <div @click="addformGoodBtns" v-if="goodInfo">
          <a-tag closable @close.stop="closeTag">{{goodInfo.goodsName}}</a-tag>
        </div>

        <a-button type="primary" @click="addformGoodBtns" v-else>
           添加
        </a-button>
      </a-form-model-item>


          <!--      <a-form-model-item label="标题" prop="title" >-->
<!--        <a-input v-model="form.title" placeholder="请输入标题" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="内容" prop="content" >-->
<!--        <editor v-model="form.content" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="图片地址" prop="imgUrl" >-->
<!--        <a-input v-model="form.imgUrl" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->

      <a-form-model-item label="图片" prop="imgUrl">
        <a-upload
          :customRequest='fileUploadRequest'
          list-type="picture-card"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          @change="handleChange">
          <img v-if="form.imgUrl" :src="form.imgUrl" alt="图片" style="height:104px;max-width:300px"/>

          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:702*300 </span>

      </a-form-model-item>


      <a-form-model-item label="是否启用" prop="useFlag" >
        <a-radio-group v-model="form.useFlag" button-style="solid">
          <a-radio-button v-for="(d, index) in useFlagOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>

    </a-form-model>

    <SelectModalGoodsForm ref="goodModalGoodForms" @ok="selectModalGoodsFormOk"></SelectModalGoodsForm>
  </a-modal>
</template>

<script>
import { addBanner, getBanner, updateBanner } from '@/api/banner/banner'
import { detail } from '@/api/mall/goods'
import Editor from '@/components/Editor'
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import SelectModalGoodsForm from "./SelectModalGoodsForm"

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    SelectModalGoodsForm
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        cateName: null,

        createTime: null,

        remark: null,

        type: null,

        title: null,

        content: null,

        imgUrl: '',

        linkType: null,

        link: null,

        useFlag: 1,

        sort: null

      },
      useFlagOptions:[{
        label: '启用',
        value: 1
      },
        {
          label: '禁用',
          value: 0
        }],
      // 1增加,2修改
      formType: 1,
      open: false,
      previewVisible: false,
      previewImage: '',
      uploadLoading: false,
      goodInfo: null,
      isShop:false,
      rules: {
        cateName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '位置类型 1-首页 2-分类不能为空', trigger: 'change' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        imgUrl: [
          { required: true, message: '图片地址不能为空', trigger: 'blur' }
        ],
        linkType: [
          { required: true, message: '链接类型 1.不跳转 2.外链 3.普通商品不能为空', trigger: 'change' }
        ],
        useFlag: [
          { required: true, message: '是否启用 1-启用 0-不启用不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '排序不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeLinkTypeSelect(vale) {
      console.log(vale)
      if(vale == 1) {
        this.form.link = null
      } else {
        if(vale == 2) {

        } else {

        }
      }
    },
    closeTag() {
      console.log('关闭了')
      this.goodInfo =null;
        this.form.link = null;
    },
    selectModalGoodsFormOk(goods) {
      this.form.link = goods.id;
      this.goodInfo = goods;
    },
    /**
     * 添加商品
     */
    addformGoodBtns() {
      if(this.form.type) {
        let rowData = {
          goodsType:null
        }
        if(this.form.type == 1 || this.form.type == 2) {
            //  嗨宝
          rowData.goodsType = 1
        } else {
          //  嗨豆
          rowData.goodsType = 0
        }
        if(this.form.linkType == 3) {
          this.$refs['goodModalGoodForms'].open(rowData)
        } else {
          this.$message.warning("请选择链接类型为商品")
        }
      } else {
        this.$message.warning("请选择展示位置")
      }

    },
    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.imgUrl = info.file.originFileObj.ossUrl;
      }
    },
    // 上传图片
    uploadImages({ fileList }) {
      console.log(fileList)
      this.form.imgUrl = fileList;
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true
      debugger
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        cateName: null,
        createTime: null,
        remark: null,
        type: null,
        title: null,
        content: null,
        imgUrl: '',
        linkType: null,
        link: null,
        useFlag: 1,
        sort: null
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      if(this.shopInfo && this.shopInfo.id) {
      //  店铺添加商品信息
        this.isShop = true;
        this.form.type = this.shopInfo.shopType == 1 ? "1" : "5";
      } else {
        //  平台添加商品信息
        this.isShop = false;
      }
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner({"id":id}).then(response => {
        this.form = response.data;

        if(response.data.linkType == 3) {
          detail({
            id:response.data.link
          }).then(result => {
            if(result.code == 20001) {
              this.goodInfo = result.data
            }
          })
        }
        if(this.shopInfo && this.shopInfo.id) {
          //  店铺添加商品信息
          this.isShop = true;
          this.form.type = this.shopInfo.shopType == 1 ? "1" : "3";
        } else {
          //  平台添加商品信息
          this.isShop = false;
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
