import request from '@/utils/request'


// 查询商城-店铺-审核列表
export function listShopApply(query) {
  return request({
    url: '/shop/shop-shop-apply/list',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-审核分页
export function pageShopApply(query) {
  return request({
    url: '/shop/shop-shop-apply/page',
    method: 'get',
    params: query
  })
}

// 查询商城-店铺-审核详细
export function getShopApply(data) {
  return request({
    url: '/shop/shop-shop-apply/detail',
    method: 'get',
    params: data
  })
}

// 新增商城-店铺-审核
export function addShopApply(data) {
  return request({
    url: '/shop/shop-shop-apply/add',
    method: 'post',
    data: data
  })
}

// 修改商城-店铺-审核
export function updateShopApply(data) {
  return request({
    url: '/shop/shop-shop-apply/edit',
    method: 'post',
    data: data
  })
}
// 修改商城-店铺-审核
export function auditShop(data) {
  return request({
    url: '/shop/shop-shop-apply/audit',
    method: 'post',
    data: data
  })
}

// 删除商城-店铺-审核
export function delShopApply(data) {
  return request({
    url: '/shop/shop-shop-apply/delete',
    method: 'post',
    data: data
  })
}
