import { render, staticRenderFns } from "./AuthDetails.vue?vue&type=template&id=28bcf6f9&scoped=true&xmlns%3Afont-size=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./AuthDetails.vue?vue&type=script&lang=js"
export * from "./AuthDetails.vue?vue&type=script&lang=js"
import style0 from "./AuthDetails.vue?vue&type=style&index=0&id=28bcf6f9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bcf6f9",
  null
  
)

export default component.exports