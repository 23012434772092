import { render, staticRenderFns } from "./SettlementDetails.vue?vue&type=template&id=122f0538&scoped=true&xmlns%3Afont-size=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./SettlementDetails.vue?vue&type=script&lang=js"
export * from "./SettlementDetails.vue?vue&type=script&lang=js"
import style0 from "./SettlementDetails.vue?vue&type=style&index=0&id=122f0538&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122f0538",
  null
  
)

export default component.exports