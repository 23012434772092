<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @cancel="onClose" cancelText="关闭" @ok="submitForm">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
<!--      <a-form-model-item label="分类名称" prop="cateName" >-->
<!--        <a-input v-model="form.cateName" placeholder="请输入分类名称" />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="等级id" prop="levelId" >-->
<!--        <a-input v-model="form.levelId" placeholder="请输入等级id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="等级名称" prop="leveName" >
        <a-input v-model="form.leveName" placeholder="请输入等级名称" />
      </a-form-model-item>
<!--      <a-form-model-item label="邀请人id" prop="topUserId" >-->
<!--        <a-input v-model="form.topUserId" placeholder="请输入邀请人id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="真实姓名" prop="realName" >
        <a-input v-model="form.realName" placeholder="请输入真实姓名" />
      </a-form-model-item>
      <a-form-model-item label="证件号码" prop="idCard" >
        <a-input v-model="form.idCard" placeholder="请输入证件号码" />
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="invitationCode" >
        <a-input v-model="form.invitationCode" :maxLength="8" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="isEnable">
        <a-radio-group v-model="form.isEnable" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <div class="bottom-control">-->
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
<!--      </div>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import { getAnchor, addAnchor, updateAnchor } from '@/api/live/anchor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        cateName: null,

        createTime: null,

        remark: null,

        levelId: null,

        leveName: null,

        topUserId: null,

        realName: null,

        idCard: null,

        invitationCode: null,
        isEnable:true
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      statusOptions:[{
        label: '启用',
        value: true
      },
        {
          label: '禁用',
          value: false
        }],
      rules: {
        cateName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '真实姓名不能为空', trigger: 'blur' }
        ],
        idCard: [
          { required: true, message: '证件号码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        cateName: null,
        createTime: null,
        remark: null,
        levelId: null,
        leveName: null,
        topUserId: null,
        realName: null,
        idCard: null,
        invitationCode: null,
        isEnable:true
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAnchor({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAnchor(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAnchor(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
