<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="状态" prop="applyState">
                <a-select v-model="queryParam.applyState" placeholder="请选择状态" allow-clear>
                  <a-select-option :value="1">待审核</a-select-option>
                  <a-select-option :value="2">已拒绝</a-select-option>
                  <a-select-option :value="4">待审核打款凭证</a-select-option>
                  <a-select-option :value="5">审核打款凭证失败</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:shop/apply:add']">-->
<!--          <a-icon type="plus"/>-->
<!--          新增-->
<!--        </a-button>-->
<!--        <a-button-->
<!--          type="primary"-->
<!--          :disabled="single"-->
<!--          @click="$refs.createForm.handleUpdate(undefined, ids)"-->
<!--          v-hasPermi="['shop:shop/apply:edit']">-->
<!--          <a-icon type="edit"/>-->
<!--          修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:shop/apply:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:shop/apply:export']">-->
<!--          <a-icon type="download"/>-->
<!--          导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <Details
        ref="detailsModules"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!--          <a-divider type="vertical" v-hasPermi="['shop:shop/apply:edit']" />-->
          <!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:shop/apply:edit']">-->
          <!--            <a-icon type="edit" />修改-->
          <!--          </a>-->
<!--          <a-divider type="vertical" v-if="record.applyState == 1 || record.applyState == 4"/>-->
          <a @click="auditShop(record)" v-if="record.applyState == 1 || record.applyState == 4">审核</a>
          <a-divider type="vertical" v-hasPermi="['shop:shop/apply:details']" v-if="record.applyState == 1 || record.applyState == 4" />
          <a @click="$refs.detailsModules.show(record)" v-hasPermi="['shop:shop/apply:details']">查看详情</a>
          <a-divider type="vertical" v-hasPermi="['shop:shop/apply:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['shop:shop/apply:remove']">
            <a-icon type="delete"/>删除
          </a>
        </span>
        <img
          style="width: 48px;height: 48px;"
          v-image-preview
          :src="record.shopLogo"
          slot="shopLogo"
          slot-scope="text, record">
        <img
          style="width: 48px;height: 48px;"
          v-image-preview
          :src="record.businessLicense"
          slot="businessLicense"
          slot-scope="text, record">
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <a-modal v-model="memoVisible" title="审核" ok-text="确认" cancel-text="取消" @ok="memoOk">
        <a-form-item label="审核状态">
          <a-select style="width: 50%;" placeholder="请选择一个审核状态"  v-model="applyState">
            <a-select-option v-if="this.applyState1 == 1" :value="3">申请信息审核通过</a-select-option>
            <a-select-option v-if="this.applyState1 == 1" :value="2">申请信息审核拒绝</a-select-option>
            <a-select-option v-if="this.applyState1 == 4" :value="6">打款信息审核通过</a-select-option>
            <a-select-option v-if="this.applyState1 == 4" :value="5">打款信息审核拒绝</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="applyState == 2 || applyState == 5" label="审核意见">
          <a-input v-model="applyMessage"></a-input>
        </a-form-item>
      </a-modal>

    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageShopApply, auditShop, delShopApply } from '@/api/shop/shopApply'
import CreateForm from './modules/CreateForm'
import Details from './modules/Details'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ShopApply',
  components: {
    CreateForm,
    Details
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        applyState: null,
        pageNum: 1,
        pageSize: 10,
        sortField:'createTime',
        sortOrder:'DESCEND'
      },
      columns: [
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺LOGO',
          dataIndex: 'shopLogo',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'shopLogo' }
        },
        {
          title: '营业执照',
          dataIndex: 'businessLicense',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'businessLicense' }
        },
        {
          title: '店铺所在省份名称',
          dataIndex: 'shopAreap',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '联系人姓名',
          dataIndex: 'legalPersonName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '联系人电话',
          dataIndex: 'legalPersonPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请状态',
          dataIndex: 'applyState',
          ellipsis: true,
          align: 'center',
          customRender: function (state) {
            if (state === '1') {
              return '待审核'
            } else if (state === '2') {
              return '已拒绝'
            } else if (state === '3') {
              return '待支付'
            } else if (state === '4') {
              return '待财务审核打款凭证'
            } else if (state === '5') {
              return '审核打款凭证失败'
            } else if (state === '6') {
              return '开店成功'
            }
          }
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请类型',
          dataIndex: 'applyType',
          ellipsis: true,
          align: 'center',
          customRender: function (state) {
            if (state === 1) {
              return '平台直营'
            } else if (state === 2) {
              return '商家自营'
            }
          }
        },
        {
          title: '行业',
          dataIndex: 'tradeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      memoVisible: false,
      applyState1: null,
      applyState: null,
      applyMessage: '',
      model: {}
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询商城-店铺-审核列表 */
    getList() {
      this.loading = true
      pageShopApply(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    auditShop(record) {
      debugger
      this.id = record.id
      this.applyState1 = record.applyState
      this.memoVisible = true
    },
    memoOk() {
      var applyState = this.applyState
      var applyMessage = this.applyMessage
      if (!applyState) {
        this.$message.warning('请选择一个审核状态')
        return
      }
      if ((applyState === 2 || applyState === 5) && !applyMessage) {
        this.$message.warning('请请输入拒绝原因')
        return
      }


      this.handleApply(this.id, applyState, applyMessage)
    },
    handleApply(id, applyState, applyMessage) {
      // debugger
      this.model.id = id
      this.model.applyState = applyState
      this.model.applyMessage = applyMessage
      const modelInfo = JSON.parse(JSON.stringify(this.model))
      auditShop(modelInfo).then((res) => {
        // debugger
        if (res.code === 20001) {
          this.$message.success(res.message)
          this.getList()
        } else {
          this.$message.warning(res.message)
        }
      })
      this.memoVisible = false
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        shopId: undefined,
        applyType: undefined,
        userId: undefined,
        contactsName: undefined,
        areaCode: undefined,
        contactsPhone: undefined,
        shopName: undefined,
        applyState: undefined,
        applyMessage: undefined,
        companyName: undefined,
        companyAreap: undefined,
        companyAreac: undefined,
        companyAreax: undefined,
        conpanyAddress: undefined,
        creditCode: undefined,
        businessLicense: undefined,
        businessScope: undefined,
        legalPersonName: undefined,
        legalPersonPhone: undefined,
        legalPersonIdCard: undefined,
        legalPersonIdCardFront: undefined,
        legalPersonIdCardReverse: undefined,
        bankType: undefined,
        bankRealName: undefined,
        bankNo: undefined,
        bankPhone: undefined,
        bankAccountName: undefined,
        bankName: undefined,
        bankAddress: undefined,
        shopAreap: undefined,
        shopAreapId: undefined,
        shopAreac: undefined,
        shopAreacId: undefined,
        shopAreax: undefined,
        shopAreaxId: undefined,
        shopAddressDetail: undefined,
        shopLogo: undefined,
        shopBackdrop: undefined,
        shopKeywords: undefined,
        tradeId: undefined,
        goodsName: undefined,
        enterId: undefined,
        shopDescription: undefined,
        kfPhone: undefined,
        openMoney: undefined,
        note: undefined,
        lng: undefined,
        lat: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delShopApply(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('shop/shop/apply/export', {
            ...that.queryParam
          }, `商城-店铺-审核_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
