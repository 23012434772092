<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @ok="submitForm"
           @cancel="onClose" cancelText="关闭">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="物流公司名称" prop="dvyName">
        <a-input v-model="form.dvyName" placeholder="请输入物流公司名称"/>
      </a-form-model-item>
      <a-form-model-item label="物流公司编号" prop="dvyNo">
        <a-input v-model="form.dvyNo" placeholder="请输入物流公司编号"/>
      </a-form-model-item>

      <a-form-model-item label="联系电话" prop="companyMobile">
        <a-input v-model="form.companyMobile" placeholder="请输入联系电话"/>
      </a-form-model-item>
      <a-form-model-item label="物流公司logo" prop="companyLogo">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.companyLogo" :src="form.companyLogo" alt="物流公司图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:800*800 </span>
      </a-form-model-item>

      <a-form-model-item label="排序" prop="sort">
        <a-input-number v-model="form.sort" :min="0" style="width: 100%"/>
      </a-form-model-item>
<!--      <a-form-model-item label="默认" prop="default">-->
<!--        <a-radio-group v-model="form.isDefault" button-style="solid">-->
<!--          <a-radio-button v-for="(d, index) in defaultOptions" :key="index" :value="d.value">-->
<!--            {{ d.label }}-->
<!--          </a-radio-button>-->
<!--        </a-radio-group>-->
<!--      </a-form-model-item>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import {addDelivery, getDelivery, updateDelivery} from '@/api/shop/delivery'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      defaultOptions: [{
        label: '是',
        value: 1
      },
        {
          label: '否',
          value: 0
        }],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        isDefault: 0,
        createTime: null,

        remark: null,

        dvyName: null,
        companyMobile: null,
        companyHomeUrl: null,

        queryUrl: null,

        dvyNo: null,

        dvyNoHd: null,

        companyLogo: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        dvyName: [{
          required: true, message: '物流公司名称不能为空', trigger: 'blur'
        }],
        dvyNo: [{
          required: true, message: '物流公司编号不能为空', trigger: 'blur'
        }],
        sort: [{
          required: true, message: '排序不能为空', trigger: 'blur'
        }],
        companyLogo: [{
          required: true, message: '物流公司logo不能为空', trigger: 'blur'
        }],
        companyMobile: [{
          required: true, message: '联系电话不能为空', trigger: 'blur'
        }]
      },
      uploadLoading: false
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.companyLogo = info.file.originFileObj.ossUrl;
      }
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if (file.size > 5 * 1024 * 1000) {
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        isDefault: 0,
        remark: null,
        dvyName: null,
        companyHomeUrl: null,
        queryUrl: null,
        dvyNo: null,
        dvyNoHd: null,
        companyLogo: null,
        companyMobile: null,
        sort:null
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDelivery({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDelivery(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDelivery(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
