<template xmlns:font-size="http://www.w3.org/1999/xhtml">
  <a-drawer
    :title="title"
    :width="577"
    @close="onClose"
    :visible="visible"
    :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
  >
    <a-card title="个人信息" :bordered="false">
      <div class="content-block" v-if="model.realName">
        <div>姓名：</div>
        <div>{{model.realName}}</div>
      </div>
      <div class="content-block" v-if="model.shopPhone">
        <div>手机号：</div>
        <div>{{model.shopPhone}}</div>
      </div>
      <div class="content-block" v-if="model.idCard">
        <div>身份证号：</div>
        <div>{{model.idCard}}</div>
      </div>
      <div class="content-block" v-if="model.idCardFront">
        <div>身份证正面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.idCardFront' alt='身份证正面' />
        </div>
      </div>
      <div class="content-block" v-if="model.idCardReverse" style="margin-top: 20px">
        <div>身份证反面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.idCardReverse' alt='身份证反面' />
        </div>
      </div>
    </a-card >

    <a-card title="公司信息" :bordered="false">
      <div class="content-block" v-if="model.companyName">
        <div>公司名称：</div>
        <div>{{model.companyName}}</div>
      </div>
      <div class="content-block">
        <div>联系地址：</div>
        <div>{{model.companyAreap}}{{model.companyAreac}}{{model.companyAreax}}{{model.companyAddress}}</div>
      </div>
      <div class="content-block">
        <div>公司地址：</div>
        <div>{{model.companyAreap}}{{model.companyAreac}}{{model.companyAreax}}{{model.companyAddress}}</div>
      </div>
      <div class="content-block" v-if="model.creditCode">
        <div>统一社会信用码：</div>
        <div>{{model.creditCode}}</div>
      </div>
      <div class="content-block" v-if="model.businessLicense">
        <div>营业执照/统一社会信用证代码证书：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.businessLicense' alt='营业执照电子版' />
        </div>
      </div>
      <div class="content-block" v-if="model.businessScope">
        <div>法定经营范围：</div>
        <div>{{model.businessScope}}</div>
      </div>
      <div class="content-block" v-if="model.legalPersonName">
        <div>法人姓名：</div>
        <div>{{model.legalPersonName}}</div>
      </div>
      <div class="content-block" v-if="model.legalPersonPhone">
        <div>法人手机：</div>
        <div>{{model.legalPersonPhone}}</div>
      </div>
      <div class="content-block" v-if="model.legalPersonIdCard">
        <div>法人身份证：</div>
        <div>{{model.legalPersonIdCard}}</div>
      </div>
      <div class="content-block" v-if="model.legalPersonIdCardFront">
        <div>法人身份证正面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 163px" :src='model.legalPersonIdCardFront' alt='法人身份证正面' />
        </div>
      </div>
      <div class="content-block" v-if="model.legalPersonIdCardReverse" style="margin-top: 20px">
        <div>法人身份证反面：</div>
        <div class="anty-img-wrap">
          <img  style="width: 259px;height: 163px" :src='model.legalPersonIdCardReverse' alt='法人身份证反面' />
        </div>
      </div>

    </a-card >
  </a-drawer>
</template>

<script>
import { getShopApply, addShopApply, updateShopApply } from '@/api/shop/shopApply'
export default {
  name: "AuthDetails",
  props: {
  },
  components: {
  },
  data() {
    return {
      title: "详情",
      visible: false,
      model: {},
      labelCol: {
        xs: {span: 24},
        sm: {span: 5},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
      uploadLoading: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      url: {
        imgerver: window._CONFIG['fileURL'],
      },
    }
  },
  created() {
  },
  methods: {
    show(record) {
      this.model = record.authenticationMessage;
      this.visible = true;
    },
    onClose() {
      this.visible = false
    },

  }
}
</script>

<style lang="less" scoped>
.content-block {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  line-height: 37px;
  flex-wrap: wrap;
}
</style>
