import request from '@/utils/request'


// 查询企业行业列表
export function listCompanyIndustry(query) {
  return request({
    url: '/user/company-industry/list',
    method: 'get',
    params: query
  })
}

// 查询企业行业分页
export function pageCompanyIndustry(query) {
  return request({
    url: '/user/company-industry/page',
    method: 'get',
    params: query
  })
}

// 查询企业行业详细
export function getCompanyIndustry(data) {
  return request({
    url: '/user/company-industry/detail',
    method: 'get',
    params: data
  })
}

// 新增企业行业
export function addCompanyIndustry(data) {
  return request({
    url: '/user/company-industry/add',
    method: 'post',
    data: data
  })
}

// 修改企业行业
export function updateCompanyIndustry(data) {
  return request({
    url: '/user/company-industry/edit',
    method: 'post',
    data: data
  })
}

// 删除企业行业
export function delCompanyIndustry(data) {
  return request({
    url: '/user/company-industry/delete',
    method: 'post',
    data: data
  })
}
