<template>
  <a-modal :title="formTitle" :width="800" :visible="open"
           @cancel="onClose" cancelText="关闭" @ok="submitForm">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="商家备注" prop="sellerMemo" >
        <a-input v-model="form.sellerMemo" placeholder="请输入备注" type="textarea" allow-clear />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {editSellerMemoOrder} from "@/api/order/order";

export default {
  name: "EditOrderRemarkForm",
  data() {
    return {
      formTitle: '备注',
      open: false,
      form: {
        id: null,
        sellerMemo: null
      },
      rules: {
        sellerMemo: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    reset() {

    },
    openModal(order) {
      this.form = JSON.parse(JSON.stringify(order))
      this.open = true
    },
    /**
     * ok
     */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          editSellerMemoOrder({
            id:this.form.id,
            sellerMemo:this.form.sellerMemo
          }).then(res => {
            if(res.code == 20001) {
              this.$message.success(
                '提交成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }
          })

        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>