<template>
  <a-modal :title="formTitle" :width="800" :visible="open"
           @cancel="onClose"  cancelText="关闭" @ok="submitForm">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="店铺类别名称" prop="categoryName" >
        <a-input v-model="form.categoryName" placeholder="请输入店铺类别名称" />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort">
        <a-input-number v-model="form.sort" :min="0" />
      </a-form-model-item>
<!--      <a-form-model-item label="最高发布数量" prop="highestNumber" >-->
<!--        <a-input v-model="form.highestNumber" placeholder="请输入最高发布数量" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="店铺是否实名 0否1是" prop="weatherSm" >-->
<!--        <a-input v-model="form.weatherSm" placeholder="请输入店铺是否实名 0否1是" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="店铺是否上传身份证 0否1是" prop="weatherSfz" >-->
<!--        <a-input v-model="form.weatherSfz" placeholder="请输入店铺是否上传身份证 0否1是" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="店铺是否要营业执照 0否1是" prop="weatherZz" >-->
<!--        <a-input v-model="form.weatherZz" placeholder="请输入店铺是否要营业执照 0否1是" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="店铺是否要保证金 0否1是" prop="weatherBzj" >-->
<!--        <a-input v-model="form.weatherBzj" placeholder="请输入店铺是否要保证金 0否1是" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="保证金金额" prop="moneyBzj" >-->
<!--        <a-input v-model="form.moneyBzj" placeholder="请输入保证金金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否默认类型 0否1是" prop="weatherMr" >-->
<!--        <a-input v-model="form.weatherMr" placeholder="请输入是否默认类型 0否1是" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="店铺资质" prop="shopQc" >-->
<!--        <a-input v-model="form.shopQc" placeholder="请输入店铺资质" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getShopCategory, addShopCategory, updateShopCategory } from '@/api/shop/shopCategory'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        // createTime: null,
        //
        // remark: null,

        categoryName: null,

        // highestNumber: null,
        //
        // weatherSm: null,
        //
        // weatherSfz: null,
        //
        // weatherZz: null,
        //
        // weatherBzj: null,
        //
        // moneyBzj: null,
        //
        // weatherMr: null,
        //
        // shopQc: null,

        sort:0

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        categoryName: [
          { required: true, message: '店铺类别名称不能为空', trigger: 'blur' }
        ],
        sort:[
          {required: true, message: '排序不能为空', trigger: 'blur' }
        ],
        highestNumber: [
          { required: true, message: '最高发布数量不能为空', trigger: 'blur' }
        ],
        weatherSm: [
          { required: true, message: '店铺是否实名 0否1是不能为空', trigger: 'blur' }
        ],
        weatherSfz: [
          { required: true, message: '店铺是否上传身份证 0否1是不能为空', trigger: 'blur' }
        ],
        weatherZz: [
          { required: true, message: '店铺是否要营业执照 0否1是不能为空', trigger: 'blur' }
        ],
        weatherBzj: [
          { required: true, message: '店铺是否要保证金 0否1是不能为空', trigger: 'blur' }
        ],
        moneyBzj: [
          { required: true, message: '保证金金额不能为空', trigger: 'blur' }
        ],
        weatherMr: [
          { required: true, message: '是否默认类型 0否1是不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        // createTime: null,
        // remark: null,
        categoryName: null,
        // highestNumber: null,
        // weatherSm: null,
        // weatherSfz: null,
        // weatherZz: null,
        // weatherBzj: null,
        // moneyBzj: null,
        // weatherMr: null,
        // shopQc: null
        sort: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopCategory({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShopCategory(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShopCategory(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
