<template>
  <a-modal :title="formTitle" :visible="visible" :footer="null" width="50%" @cancel="visible = false">
    <!--    <page-header-wrapper>-->
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="12" :sm="24">
              <a-form-item label="账单标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="账单标题" allow-clear/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="48">
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a-button type="danger" :disabled="multiple" style="margin-left: 8px"  @click="handleDelete">-->
<!--                      <a-icon type="delete" />-->
<!--                            删除-->
<!--                    </a-button>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['video:video/comment:add']">-->
        <!--            <a-icon type="plus" />新增-->
        <!--          </a-button>-->
        <!--          <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['video:video/comment:edit']">-->
        <!--            <a-icon type="edit" />修改-->
        <!--          </a-button>-->

                  <a-button type="primary" @click="handleExport" >
                    <a-icon type="download" />导出
                  </a-button>
        <!--          <table-setting-->
        <!--            :style="{float: 'right'}"-->
        <!--            :table-size.sync="tableSize"-->
        <!--            v-model="columns"-->
        <!--            :refresh-loading="loading"-->
        <!--            @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <!--      <create-form-->
      <!--        ref="createForm"-->
      <!--        @ok="getList"-->
      <!--      />-->
      <!-- 数据展示 -->
<!--      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"

        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a @click="handleDelete(record)">-->
<!--            <a-icon type="delete" />-->
<!--            删除-->
<!--          </a>-->
<!--        </span>-->
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!--    </page-header-wrapper>-->
  </a-modal>


</template>

<script>
import { tableMixin } from '@/store/table-mixin'
import {pageAccountBill} from "@/api/accountbill/accountbill";

export default {
  name: 'dynamicComment',
  components: {
    // CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      formTitle: '',
      list: [],
      visible:false,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId:null,
        title: null,
        moneyType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1) {
              return '红包支付'
            } else if (text == 2) {
              return '领取红包'
            } else if(text == 3) {
              return '转账支付'
            }else if(text == 4) {
              return '领取转账'
            }else if(text == 5) {
              return '提现'
            }else if(text == 6) {
              return '充值'
            }else if(text == 7) {
              return '红包退款'
            }else if(text == 8) {
              return '转账未领取退款'
            }else if(text == 9) {
              return '提现审核拒绝'
            }
          }
        },
        {
          title: '账单标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作前金额',
          dataIndex: 'beforeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变化金额',
          dataIndex: 'changeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作后金额',
          dataIndex: 'afterMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动类型',
          dataIndex: 'billType',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if (text == 1){
              return "收入"
            }else if (text == 2){
              return "消费"
            }
          }
        },
        {
          title: '是否冻结',
          dataIndex: 'isFreeze',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if(text == 1) {
              return '是'
            } else {
              return '否'
            }
          }
        },

        {
          title: '时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    open(rowData) {
      this.queryParam.userId = rowData.userId;
      this.getList();
      this.visible = true;

    },
    /** 查询短视频评论列表 */
    getList () {
      this.loading = true
      pageAccountBill(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam.title = null;
      this.queryParam.moneyType = null;
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return deleteDynamic(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-account-bill/export', {
            ...that.queryParam
          }, `用户流水_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
/deep/ .ant-card-body {
  padding: 0!important;
}
</style>
