<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="上级用户id" prop="topUserId" >
        <a-input v-model="form.topUserId" placeholder="请输入上级用户id" />
      </a-form-model-item>
      <a-form-model-item label="身份id" prop="rankId" >
        <a-input v-model="form.rankId" placeholder="请输入身份id" />
      </a-form-model-item>
      <a-form-model-item label="vipId" prop="vipId" >
        <a-input v-model="form.vipId" placeholder="请输入vipId" />
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="invitationCode" >
        <a-input v-model="form.invitationCode" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item label="创业编号" prop="businessCode" >
        <a-input v-model="form.businessCode" placeholder="请输入创业编号" />
      </a-form-model-item>
      <a-form-model-item label="股权" prop="stockRights" >
        <a-input v-model="form.stockRights" placeholder="请输入股权" />
      </a-form-model-item>
      <a-form-model-item label="分类名称" prop="cateName" >
        <a-input v-model="form.cateName" placeholder="请输入分类名称" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/distribution/user'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        topUserId: null,

        rankId: null,

        vipId: null,

        invitationCode: null,

        businessCode: null,

        stockRights: null,

        topStockRights: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        invitationCode: [
          { required: true, message: '邀请码不能为空', trigger: 'blur' }
        ],
        stockRights: [
          { required: true, message: '股权不能为空', trigger: 'blur' }
        ],
        cateName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        topUserId: null,
        rankId: null,
        vipId: null,
        invitationCode: null,
        businessCode: null,
        stockRights: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
