<template>
  <a-modal title="Title" :visible="visible" @cancel="visible = fasle">
    <crud ref="crud" :config="config"></crud>
  </a-modal>
</template>
<script>
import Crud from "@/components/crud/Crud.vue";

export default {
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          { field: 'nickname', name: '昵称' },
          { field: 'mobile', name: '手机号' },
          { field: 'content', name: '评论内容' },
        ],
        firstOnload:false,
        table: {
          api: '/video/video-video-comment/page',
          deleteApi: '/video/video-video-comment/delete',
          col: [
            { field: 'avatar', name: '头像', type: 'avatar' },
            { field: 'nickname', name: '昵称' },
            { field: 'mobile', name: '手机号' },


            { field: 'content', name: '评论内容' },

            { field: 'createTime', name: '评论时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '删除', event: 'inner-delete' }
              ]
            }
          ]
        },
      },
      visible: false
    }
  },
  created() {

  },
  methods: {
    open(rowData) {
      console.log(rowData)
      this.visible = true;
      console.log('进来了');
      // this.table.api = '/video/video-video-comment/page'
      this.$refs.crud.search({
        videoId:rowData.id
      });
    }
  }
}
</script>