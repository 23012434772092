<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:shop:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:shop:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:shop:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:shop:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <BasicDetails ref="basicDetails" />
      <AuthDetails ref="authDetails" />
      <SettlementDetails ref="settlementDetails" />
      <AccountDetails ref="accountDetails"  />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
<!--
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="shopCreateTime" slot-scope="text, record">
          {{ parseTime(record.shopCreateTime) }}
        </span>
        <span slot="shopEndTime" slot-scope="text, record">
          {{ parseTime(record.shopEndTime) }}
        </span>
        <span slot="memberDueTime" slot-scope="text, record">
          {{ parseTime(record.memberDueTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">

           <a @click="$refs.basicDetails.show(record)"  v-if="record.basicMessage">基本信息</a>
           <a-divider type="vertical" v-if="record.basicMessage" />
           <a @click="$refs.authDetails.show(record)" v-if="record.authenticationMessage">认证信息</a>
                     <a-divider type="vertical"  v-if="record.authenticationMessage"/>
           <a @click="$refs.settlementDetails.show(record)" v-if="record.settleMessage">结算信息</a>
                     <a-divider type="vertical" v-if="record.settleMessage" />
           <a @click="$refs.accountDetails.show(record)" v-if="record.accountMessage">账户信息</a>
          <a-divider type="vertical" />
<!--           <a @click="$refs.updateCommissionRatio.show(record)">修改抽佣</a>-->

          <!--          <a-divider type="vertical" v-hasPermi="['shop:shop:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />修改抽佣
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['shop:shop:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['shop:shop:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageShop, delShop } from '@/api/shop/shop'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import BasicDetails from "./modules/BasicDetails";
import AuthDetails from "./modules/AuthDetails";
import SettlementDetails from "./modules/SettlementDetails";
import AccountDetails from "./modules/AccountDetails";
export default {
  name: 'Shop',
  components: {
    CreateForm,
    BasicDetails,
    AuthDetails,
    SettlementDetails,
    AccountDetails
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopName: null,
        shopType: null,
        userId: null,
        shopAddress: null,
        shopState: null,
        shopCloseInfo: null,
        shopLogo: null,
        shopCover: null,
        shopKeywords: null,
        shopDescription: null,
        shopPhone: null,
        areaCode: null,
        shopCollect: null,
        shopAccount: null,
        settlementAccount: null,
        shopCreateTime: null,
        shopEndTime: null,
        shopScore: null,
        attentionNum: null,
        goodsDescribeScore: null,
        shopDescribeScore: null,
        logisticsScore: null,
        businessLicenceNumberElectronic: null,
        trademarkCertificate: null,
        authCertificate: null,
        shopAreap: null,
        shopAreapId: null,
        shopAreac: null,
        shopAreacId: null,
        shopAreax: null,
        shopAreaxId: null,
        shopQc: null,
        thatViews: null,
        kfAccount: null,
        alarmGoodsStock: null,
        alarmGoodsSkuStock: null,
        shopCategoryId: null,
        weatherBzj: null,
        moneyBzj: null,
        shopProtocol: null,
        businessLicense: null,
        chaopinNo: null,
        shopApplyType: null,
        platformRatio: null,
        isLogistics: null,
        isSelfTake: null,
        isStoreDelivery: null,
        shopEnclosure: null,
        lat: null,
        lng: null,
        memberFlag: null,
        memberDueTime: null,
        autoRenew: null,
        commissionRatio: null,
        agencyTax: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商户类别',
          dataIndex: 'shopType',
          ellipsis: true,
          align: 'center',
          customRender: function (state) {
            if (state == 1) {
              return '平台直营'
            } else if (state == 0) {
              return '商家自营'
            }
          }
        },
        {
          title: '商户账号',
          dataIndex: 'shopPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '主营行业',
          dataIndex: 'tradeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商户地址',
          dataIndex: 'shopAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '嗨豆商城抽佣',
          dataIndex: 'commissionRatio',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商家状态',
          dataIndex: 'shopState',
          ellipsis: true,
          align: 'center',
          customRender: function (shopState) {
            if (shopState == 1) {
              return '开启'
            } else if (shopState == 0) {
              return '关闭'
            } else if (shopState == 2) {
              return '审核中'
            }
          }
        },
        {
          title: '入驻时间',
          dataIndex: 'shopCreateTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '24%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询商城-店铺列表 */
    getList () {
      this.loading = true
     pageShop(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopName: undefined,
        shopType: undefined,
        userId: undefined,
        shopAddress: undefined,
        shopState: undefined,
        shopCloseInfo: undefined,
        shopLogo: undefined,
        shopCover: undefined,
        shopKeywords: undefined,
        shopDescription: undefined,
        shopPhone: undefined,
        areaCode: undefined,
        shopCollect: undefined,
        shopAccount: undefined,
        settlementAccount: undefined,
        shopCreateTime: undefined,
        shopEndTime: undefined,
        shopScore: undefined,
        attentionNum: undefined,
        goodsDescribeScore: undefined,
        shopDescribeScore: undefined,
        logisticsScore: undefined,
        businessLicenceNumberElectronic: undefined,
        trademarkCertificate: undefined,
        authCertificate: undefined,
        shopAreap: undefined,
        shopAreapId: undefined,
        shopAreac: undefined,
        shopAreacId: undefined,
        shopAreax: undefined,
        shopAreaxId: undefined,
        shopQc: undefined,
        thatViews: undefined,
        kfAccount: undefined,
        alarmGoodsStock: undefined,
        alarmGoodsSkuStock: undefined,
        shopCategoryId: undefined,
        weatherBzj: undefined,
        moneyBzj: undefined,
        shopProtocol: undefined,
        businessLicense: undefined,
        chaopinNo: undefined,
        shopApplyType: undefined,
        platformRatio: undefined,
        isLogistics: undefined,
        isSelfTake: undefined,
        isStoreDelivery: undefined,
        shopEnclosure: undefined,
        lat: undefined,
        lng: undefined,
        memberFlag: undefined,
        memberDueTime: undefined,
        autoRenew: undefined,
        commissionRatio: undefined,
        agencyTax: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delShop(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('shop/shop/export', {
            ...that.queryParam
          }, `商城-店铺_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
