<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="发布人用户id，外键" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入发布人用户id，外键" />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <a-input v-model="form.content" placeholder="请输入内容" />
      </a-form-model-item>
      <a-form-model-item label="视频路径" prop="videoUrl" >
        <a-input v-model="form.videoUrl" placeholder="请输入视频路径" />
      </a-form-model-item>
      <a-form-model-item label="图片路径, 当 type 为 0 时, 代表逗号分隔的多图, type 为 1 时, 代表视频封面" prop="imgUrl" >
        <a-input v-model="form.imgUrl" placeholder="请输入图片路径, 当 type 为 0 时, 代表逗号分隔的多图, type 为 1 时, 代表视频封面" />
      </a-form-model-item>
      <a-form-model-item label="类型, 0-纯文字, 1-图片, 2-视频" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="定位名称" prop="positionName" >
        <a-input v-model="form.positionName" placeholder="请输入定位名称" />
      </a-form-model-item>
      <a-form-model-item label="点赞数" prop="likeNum" >
        <a-input v-model="form.likeNum" placeholder="请输入点赞数" />
      </a-form-model-item>
      <a-form-model-item label="评论数" prop="commentNum" >
        <a-input v-model="form.commentNum" placeholder="请输入评论数" />
      </a-form-model-item>
      <a-form-model-item label="是否置顶, 置顶动态, 0-未置顶, 1-已置顶" prop="isTop" >
      </a-form-model-item>
      <a-form-model-item label="审核状态, 0-未审核, 1-已审核, 2-审核失败, 默认-0" prop="auditStatus" >
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDynamic, addDynamic, updateDynamic } from '@/api/dynamic/dynamic'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        content: null,

        videoUrl: null,

        imgUrl: null,

        type: null,

        positionName: null,

        likeNum: null,

        commentNum: null,

        isTop: null,

        auditStatus: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '发布人用户id，外键不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '类型, 0-纯文字, 1-图片, 2-视频不能为空', trigger: 'change' }
        ],
        likeNum: [
          { required: true, message: '点赞数不能为空', trigger: 'blur' }
        ],
        commentNum: [
          { required: true, message: '评论数不能为空', trigger: 'blur' }
        ],
        isTop: [
          { required: true, message: '是否置顶, 置顶动态, 0-未置顶, 1-已置顶不能为空', trigger: 'change' }
        ],
        auditStatus: [
          { required: true, message: '审核状态, 0-未审核, 1-已审核, 2-审核失败, 默认-0不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        userId: null,
        content: null,
        videoUrl: null,
        imgUrl: null,
        type: null,
        positionName: null,
        likeNum: null,
        commentNum: null,
        isTop: null,
        auditStatus: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDynamic({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDynamic(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDynamic(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
