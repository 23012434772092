import request from '@/utils/request'


// 查询创业会员列表
export function listVip(query) {
  return request({
    url: '/distribution/distribution-vip/list',
    method: 'get',
    params: query
  })
}

// 查询创业会员分页
export function pageVip(query) {
  return request({
    url: '/distribution/distribution-vip/page',
    method: 'get',
    params: query
  })
}

// 查询创业会员详细
export function getVip(data) {
  return request({
    url: '/distribution/distribution-vip/detail',
    method: 'get',
    params: data
  })
}

// 新增创业会员
export function addVip(data) {
  return request({
    url: '/distribution/distribution-vip/add',
    method: 'post',
    data: data
  })
}

// 修改创业会员
export function updateVip(data) {
  return request({
    url: '/distribution/distribution-vip/edit',
    method: 'post',
    data: data
  })
}

// 删除创业会员
export function delVip(data) {
  return request({
    url: '/distribution/distribution-vip/delete',
    method: 'post',
    data: data
  })
}



// 查询创业身份
export function getDistributionRankList(query) {
  return request({
    url: '/distribution/distribution-rank/list',
    method: 'get',
    params: query
  })
}
