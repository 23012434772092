<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @cancel="onClose" cancelText="关闭" @ok="submitForm">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">

      <a-form-model-item label="礼物名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入礼物名称" />
      </a-form-model-item>
      <a-form-model-item label="花费金豆数" prop="goldenBeans" >
        <a-input-number v-model="form.goldenBeans" :min="0" style="width: 100%;"/>
<!--        <a-input v-model="form.goldenBeans" type="number" placeholder="请输入花费金豆数" />-->
      </a-form-model-item>
<!--      <a-form-model-item label="图片地址" prop="imgUrl" >-->
<!--        <a-input v-model="form.imgUrl" placeholder="请输入图片地址" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="图片" prop="imgUrl">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <!--          {{baseimgerver}}-->
          <img v-if="form.imgUrl" :src="form.imgUrl" alt="图片" style="height:110px;max-width:110px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:110*110 </span>
      </a-form-model-item>
      <a-form-model-item label="特效图" prop="giftEffects" >

        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          @change="handleChangeGift"
           >
          <a v-if="form.giftEffects" :href="form.giftEffects" target="_blank" >{{form.giftEffects}}</a>
          <div v-else>
          <a-icon :type="uploadLoadingGift ? 'loading' : 'plus'"/>
                       <div class="ant-upload-text">上传</div>
            </div>
         </a-upload>
        <span style="color:#ff0000">图片限制小于50M,推荐规格:110*110 </span>

        <!--        <a-input v-model="form.giftEffects" placeholder="请输入特效地址" />-->
<!--        <a-form-model-item label="图片" prop="imgUrl">-->
<!--          <a-upload-->
<!--            listType="picture-card"-->
<!--            class="avatar-uploader"-->
<!--            :showUploadList="false"-->
<!--            :customRequest='fileUploadRequest'-->
<!--            :beforeUpload="beforeUpload"-->
<!--            @change="handleChange"-->
<!--          >-->
<!--            &lt;!&ndash;          {{baseimgerver}}&ndash;&gt;-->
<!--            <img v-if="form.imgUrl" :src="form.imgUrl" alt="图片" style="height:104px;max-width:300px"/>-->
<!--            <div v-else>-->
<!--              <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>-->
<!--              <div class="ant-upload-text">上传</div>-->
<!--            </div>-->
<!--          </a-upload>-->
<!--          <span style="color:#ff0000">图片限制小于5M,推荐规格:800*800 </span>-->
<!--        </a-form-model-item>-->
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input-number v-model="form.sort" :min="0" />
<!--        <a-input v-model="form.sort" placeholder="请输入排序, asc" />-->
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="isEnabled" >
<!--        <a-input v-model="form.isEnabled" placeholder="请输入是否启用, 0-禁用, 1-启用, 默认-1" />-->
        <a-radio-group v-model="form.isEnabled" button-style="solid">
          <a-radio-button v-for="(d, index) in enabledOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <div class="bottom-control">-->
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
<!--      </div>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import { getGift, addGift, updateGift } from '@/api/gift/gift'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      enabledOptions:[ {
        label: '启用',
        value: 1
      },
        {
          label: '禁用',
          value: 0
        }],
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        name: null,

        goldenBeans: null,

        imgUrl: null,

        giftEffects: null,

        sort: 0,

        isEnabled:1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '礼物名称不能为空', trigger: 'blur' }
        ],
        goldenBeans: [
          { required: true, message: '花费金豆数不能为空', trigger: 'blur' }
        ],
        imgUrl: [
          { required: true, message: '图片地址不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '排序, asc不能为空', trigger: 'blur' }
        ],
        // isEnabled: [
        //   { required: true, message: '是否启用', trigger: 'blur' }
        // ]
      },
      uploadLoading:false,
      uploadLoadingGift:false
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.imgUrl = info.file.originFileObj.ossUrl;
      }
    },
    handleChangeGift(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoadingGift = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoadingGift = false;
        // let index = info.file.originFileObj.name.lastIndexOf(".");
        // let fileType = info.file.originFileObj.name.substr(index + 1);
        // let indexIndex = info.file.originFileObj.ossUrl.lastIndexOf(".");
        // this.form.giftEffects = info.file.originFileObj.ossUrl.substr(0, indexIndex + 1) + fileType;
        this.form.giftEffects = info.file.originFileObj.ossUrl;
        console.log(this.form.giftEffects +'22222222')
        console.log(this.form.giftEffects)
      }
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          console.log('------res[0]')
          console.log(res[0])
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        name: null,
        goldenBeans: null,
        imgUrl: null,
        giftEffects: null,
        sort: 0,
        isEnabled: 1
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGift({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGift(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGift(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
