<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="cancel">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="APP类型" prop="appType" >
        <a-select v-model="form.appType">
          <a-select-option key="0">安卓</a-select-option>
          <a-select-option key="1">苹果</a-select-option>
          <a-select-option key="2">通用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="版本号名称" prop="versionName" >
        <a-input v-model="form.versionName" placeholder="请输入版本号名称" />
      </a-form-model-item>
      <a-form-model-item label="版本号" prop="versionCode" >
        <a-input v-model="form.versionCode" type="number" placeholder="请输入版本号" />
      </a-form-model-item>

      <a-form-model-item label="更新包大小" prop="upHeSize" >
        <a-input v-model="form.upHeSize" type="number" placeholder="请输入更新包大小" />
      </a-form-model-item>
      <a-form-model-item label="更新地址类型" prop="upUrlType" >
        <a-select v-model="form.upUrlType">
          <a-select-option key="0">内部</a-select-option>
          <a-select-option key="1">其它(应用市场)</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="其它(应用市场)应用 【内部使用文件库】" prop="upUrl" v-if="form.upUrlType == 1">
        <a-input v-model="form.upUrl" placeholder="请输入其它(应用市场)应用 【内部使用文件库】" />
      </a-form-model-item>

      <a-form-model-item label="更新类型" prop="upType" >
        <a-select v-model="form.upType">
          <a-select-option key="0">热更新</a-select-option>
          <a-select-option key="1">整包更新</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="升级方式" prop="compulsoryUp" >
        <a-select v-model="form.compulsoryUp">
          <a-select-option key="0">强制</a-select-option>
          <a-select-option key="1">可跳过</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="计划更新方式" prop="planUpType" >
        <a-select v-model="form.planUpType">
          <a-select-option key="0">及时更新</a-select-option>
          <a-select-option key="1">计划更新</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="计划更新时间" prop="planUpTime" v-if="form.planUpType == 1">
<!--        <a-input v-model="form.planUpTime" placeholder="请输入计划更新时间" />-->
        <a-date-picker @change="onChange" />
      </a-form-model-item>
      <a-form-model-item label="下载地址" prop="downloadUrl" >
<!--        <a-input v-model="form.downloadUrl" placeholder="请输入下载地址" />-->
        <a-upload
          :multiple="true"
          :file-list="fileList"
          :customRequest='fileUploadRequest'
          @change="handleChange"
        >
          <a-button> <a-icon type="upload" /> 上传 </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="更新说明" prop="upNotes" >
        <a-input v-model="form.upNotes" placeholder="请输入更新说明" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVersionInfo, addVersionInfo, updateVersionInfo } from '@/api/version/versionInfo'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        appType: null,

        upNotes: null,

        upHeSize: null,

        upUrlType: null,

        upUrl: null,

        versionName: null,

        versionCode: null,

        upType: null,

        compulsoryUp: null,

        planUpType: null,

        planUpTime: null,

        downloadUrl: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      fileList: [],
      open: false,
      rules: {
        appType: [
          { required: true, message: 'APP类型 : 0安卓 1苹果 2通用不能为空', trigger: 'change' }
        ],
        upUrlType: [
          { required: true, message: '更新地址类型: 0内部 1其它(应用市场)不能为空', trigger: 'change' }
        ],
        versionCode: [
          { required: true, message: '版本号不能为空', trigger: 'blur' }
        ],
        upType: [
          { required: true, message: '更新类型: 0热更新 1整包更新不能为空', trigger: 'change' }
        ],
        compulsoryUp: [
          { required: true, message: '强制升级否 0强制 1可跳过不能为空', trigger: 'change' }
        ],
        planUpType: [
          { required: true, message: '计划更新时间: 0及时更新 1计划更新不能为空', trigger: 'change' }
        ],
        planUpTime: [
          { required: true, message: '计划更新时间不能为空', trigger: 'change' }
        ],
        downloadUrl: [
          { required: true, message: '下载地址不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    handleChange(info) {
      // let fileList = [...info.fileList];
      // fileList = fileList.slice(-1);
      console.log('info')
      console.log(info)
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });

      this.fileList = fileList;
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {

        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.downloadUrl = info.file.originFileObj.ossUrl;
        this.fileList = [info.file.originFileObj]
      }
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    onChange(e,str) {
      console.log(e)
      console.log(str)
      this.form.planUpTime = new Date(str).getTime();
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        appType: null,
        upNotes: null,
        upHeSize: null,
        upUrlType: null,
        upUrl: null,
        versionName: null,
        versionCode: null,
        upType: null,
        compulsoryUp: null,
        planUpType: null,
        planUpTime: null,
        downloadUrl: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVersionInfo({"id":id}).then(response => {
        this.form = response.data;
        this.fileList = [{
          uid: '-1',
          name:response.data.downloadUrl,
          status: 'done',
          url: response.data.downloadUrl,

        }]
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVersionInfo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVersionInfo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
