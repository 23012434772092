
import {getOssConfig} from '@/api/system/upload.js'
import axios from 'axios'
import moment from 'moment'
import storage from "store";
import {ACCESS_TOKEN} from "@/store/mutation-types";

// 文件扩展名提取
export function fileType (fileName) {
  return fileName.substring(fileName.lastIndexOf('.') + 1)
}

/**
 * oss路径定义
 * @param file
 */
export function ossPath (file) {
  const folder = moment().format('YYYY-MM-DD')
  if (file.name) {
    return `admin/${folder}/${fileType(file.name)}/${file.name}`
  } else {
    return `admin/${folder}/html/${moment().format('YYYY-MM-DD-HH-mm-ss')}.html`
  }
}

export function ossUpload (file) {
  return new Promise((resolve, reject) => {
    getOssConfig().then((res) => {
      const { accessid, policy, signature,endpoint, fileName } = res.data
      const formData = new FormData()
      const key = ossPath(file)
      formData.append('key', key)
      formData.append('OSSAccessKeyId', accessid)
      formData.append('policy', policy)
      formData.append('signature', signature)
      formData.append('success_action_status', '200')
      formData.append('file', file)
      const token = storage.get(ACCESS_TOKEN)
      axios({
        url: endpoint,
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'attachment;filename=' + encodeURIComponent(fileName)
        },
        data: formData,
      }).then((res) => {
        const { status } = res
        if (status === 200) {
          const data = {
            url: `${endpoint}/${key}`,
            type: file.name ? fileType(file.name) : 'html'
          }
          resolve(data)
        } else {
          reject(res)
        }
      }).catch(err => {
        reject(err)
      })
    }).catch(err => {})
  })
}

