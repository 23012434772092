<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!--      条件筛选 start-->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="订单编号" prop="orderNo">
                <a-input v-model="queryParam.orderNo" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="订单状态" prop="orderStatus">
                <a-select v-model="queryParam.orderStatus" placeholder="请选择订单状态" @change="updateOrderStatus">
                  <a-select-option value="1">待付款</a-select-option>
                  <a-select-option value="2">待发货</a-select-option>
                  <a-select-option value="3">待签收</a-select-option>
                  <a-select-option value="5">已完成</a-select-option>
                  <a-select-option value="6">已关闭</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="商品名称">
                <a-input placeholder="请输入商品名称" v-model="queryParam.goodsName"></a-input>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="买家昵称">
                <a-input placeholder="请输入买家昵称" v-model="queryParam.userName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="成交时间">
                <!--                <a-input placeholder="请输入买家昵称" v-model="queryParam.userName"></a-input>-->
                <a-range-picker @change="onChangeRangePicker" v-model="allXuanzeRngTime" format="YYYY-MM-DD HH:mm:ss"/>
              </a-form-item>
            </a-col>

            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!--      条件筛选 end-->

      <!--    导出按钮 start  -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:order:export']">
          <a-icon type="download"/>
          导出
        </a-button>
      </div>
      <!--    导出按钮 end  -->

      <!--   tab 切换    -->
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="99" tab="近三个月订单"/>
        <a-tab-pane key="1" tab="待付款的订单"/>
        <a-tab-pane key="2" tab="待发货的订单"/>
        <a-tab-pane key="3" tab="已发货的订单"/>
        <a-tab-pane key="5" tab="已完成的订单"/>
        <a-tab-pane key="6" tab="已关闭的订单"/>
        <a-tab-pane key="98" tab="三个月前订单"/>
      </a-tabs>
      <!--    tab 切换  -->

      <!--   列表数据 start   -->
      <div>
        <a-spin :spinning="loading">
<!--          表头 start-->

          <table class="order-table order-table-hader">
            <colgroup>
              <col class="table-mod__col1">
              <col class="table-mod__col2">
              <col class="table-mod__col3">
              <col class="table-mod__col4">
              <col class="table-mod__col5">
              <col class="table-mod__col6">
              <col class="table-mod__col7">
              <col class="table-mod__col8">
            </colgroup>
            <tbody>
            <tr>
              <td>宝贝</td>
              <td>单价</td>
              <td>数量</td>
              <td>买家</td>
              <td>交易状态</td>
              <td>实收款</td>
              <td>操作</td>
            </tr>
            </tbody>
          </table>
          <!--          表头 end-->
<!--       表格数据 start   -->
          <div class="order-table-item" v-for="(order, index) in list" :key="index">
            <p class="order-goods-hader">
              <span>订单号:</span>
              <span>{{ order.orderNo }}</span>
              <span style="margin-left: 50px;">创建时间:</span>
              <span>{{ order.createTime }}</span>
            </p>

            <table class="order-table order-table-body">
              <colgroup>
                <col class="table-mod__col1">
                <col class="table-mod__col2">
                <col class="table-mod__col3">
                <col class="table-mod__col4">
                <col class="table-mod__col5">
                <col class="table-mod__col6">
                <col class="table-mod__col7">
              </colgroup>
              <tbody>
              <tr v-for="(orderGoods, index2) in order.shopOrderGoodsList" :key="index2">
                <td class="no-boder-right" style="padding-top: 16px;">
                  <div style="margin-left: 10px;width: 100%;">
                    <div style="float: left;">
                      <img style="width: 80px;height: 80px;" :src="orderGoods.goodsPicture"
                           preview="商品主图" />
                    </div>
                    <div style="float: left;margin-left: 10px;width: 73%;color: #3c3c3c;">
                      <div style="width: 100%;text-align: left;font-size: 14px;line-height: 16px;">
                        {{ orderGoods.goodsName }}
                      </div>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </td>
                <td class="no-boder-right">￥{{ orderGoods.price }}元<p v-if="order.orderBeans != 0.0">{{ orderGoods.beans }}嗨豆</p></td>
                <td class="no-boder-right">{{ orderGoods.num }}</td>
                <td :rowspan="order.shopOrderGoodsList.length" v-if="index2 == 0">{{ order.userName }}</td>

                <!--交易状态-->
                <td :rowspan="order.shopOrderGoodsList.length" v-if="index2 == 0">
                  <template v-if="order.orderStatus == 1">
                    <span>等待买家付款</span>
                  </template>
                  <template v-if="order.orderStatus == 2">
                    <span>买家已付款</span>
                  </template>
                  <template v-if="order.orderStatus == 3">
                    <span>卖家已发货</span>
                  </template>
                  <template v-if="order.orderStatus == 4">
                    <span>等待买家评价</span>
                  </template>
                  <template v-if="order.orderStatus == 5">
                    <span>交易成功</span>
                  </template>
                  <template v-if="order.orderStatus == 6">
                    <span>交易关闭</span>
                  </template>
                  <template v-if="order.orderStatus == 0">
                    <span>交易取消</span>
                  </template>
                  <p>
                    <a @click="handleInfo(order.id)">详情</a>
                  </p>
                </td>

                <td :rowspan="order.shopOrderGoodsList.length" v-if="index2 == 0">
                  <p style="font-weight: bold;">￥{{ order.payMoney }}元</p>

                  <p v-if="order.orderBeans != 0.0" style="font-weight: bold;">{{ order.orderBeans }}嗨豆</p>
<!--                  <p>(含快递:￥{{ order.shippingMoney - order.remissionShippingMoney }})</p>-->
                  <p v-if="order.shippingMoney">(含快递:￥{{ order.shippingMoney}})</p>
                  <a v-if="order.orderStatus >= 3" @click="orderShippingInfo(order.id)">查看物流</a>
                </td>

                <td :rowspan="order.shopOrderGoodsList.length" v-if="index2 == 0">
                  <template v-if="order.sellerMemo">
                    <span>{{ order.sellerMemo }}</span>
                    <a-divider type="vertical" />
                  </template>

                  <template v-if="order.orderStatus == 2 && shopInfo"  v-hasPermi="['order:order:fahuo']">
                    <a @click="getExpressNo(order)">发货</a>
                    <a-divider type="vertical" />
                  </template>

                  <template v-if="!order.sellerMemo && shopInfo" v-hasPermi="['order:order:fahuo']">
                    <a @click="orderRemark(order)">备注</a>
<!--                    <a-divider type="vertical" />-->
                  </template>
<!--                  &lt;!&ndash; 删除 &ndash;&gt;-->
<!--                  <a @click="handleDelete(order.id)" v-hasPermi="['order:order:remove']">-->
<!--                    删除-->
<!--                  </a>-->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
<!--       表格数据 end   -->
        </a-spin>
        <a-empty style="margin-top: 50px" v-if="list.length == 0 && !loading"/>
      </div>
      <!--    列表数据 end  -->

      <!--   分页 start   -->
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <!--      分页 end-->

      <EditOrderRemarkForm ref="EditOrderRemarkForm" @ok="getList"></EditOrderRemarkForm>
      <OrderInfoModal ref="OrderInfoModal"></OrderInfoModal>
      <OrderShippingInfoModal ref="orderShippingInfoModal"></OrderShippingInfoModal>
      <ShipMentsModal ref="shipMentsModal" @ok="getList"></ShipMentsModal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {tableMixin} from '@/store/table-mixin'
import moment from 'moment'
import EditOrderRemarkForm from "./modules/EditOrderRemarkForm"
import OrderInfoModal from "./modules/OrderInfoModal"
import OrderShippingInfoModal from './modules/OrderShippingInfoModal'
import ShipMentsModal from './modules/ShipMentsModal'
import {deleteOrder, pageOrder} from "@/api/order/order";

export default {
  name: "orderlistindex",
  mixins: [tableMixin],
  components: {
    EditOrderRemarkForm,
    OrderInfoModal,
    OrderShippingInfoModal,
    ShipMentsModal
  },
  data() {
    return {
      queryParam: {
        orderNo: null,
        orderStatus: null,
        goodsName: null,
        userName: null,
        rangeTime: null,
        pageNum: 1,
        pageSize: 10
      },
      allXuanzeRngTime: undefined,
      advanced: false,
      total: 0,
      activeKey: '99',
      loading: false,
      list: [],
      startCreateTime:'',
      endCreateTime:''
    }
  },
  created() {
   let startCreateTime = moment().subtract(3, "months").format("YYYY-MM-DD") + ' 00:00:00';
   let endCreateTime = moment().format("YYYY-MM-DD") + ' 23:59:59';
   // this.startCreateTime = moment().subtract(3, "months").format("YYYY-MM-DD") + ' 00:00:00';
   // this.endCreateTime = moment().format("YYYY-MM-DD") + ' 23:59:59';

    this.queryParam.rangeTime = startCreateTime + ' - ' + endCreateTime;
    this.getList()
  },
  computed:{
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  methods: {

    /**
     * 交易状态详情
     * @param id
     */
    handleInfo(id) {
      this.$refs['OrderInfoModal'].openModal(id)
    },

    /**
     * 发货
     */
    getExpressNo(order) {
      this.$refs['shipMentsModal'].openModal(order)
    },

    /**
     * 备注
     */
    orderRemark(order) {
      this.$refs['EditOrderRemarkForm'].openModal(order)
    },

    /** 删除按钮操作 */
    handleDelete (id) {
      var that = this

      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + id + '的数据',
        onOk () {
          return deleteOrder([{
            id:id
          }])
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },

    /**
     * 查看物流
     */
    orderShippingInfo(id) {
      this.$refs['orderShippingInfoModal'].info(id)
    },
    /**
     * 分页数据改变
     * @param current
     * @param pageSize
     */
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },

    /**
     * 改变数据
     * @param current
     * @param pageSize
     */
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },

    /**
     * 获取列表数据
     */
    getList() {
      this.loading = true
      pageOrder(this.queryParam).then(response => {
        if(response.code == 20001) {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        } else {
          this.$message.warning(response.msg)
        }
      })
    },
    changeTab(index) {
      var startCreateTime, endCreateTime;
      if (index == '99' || index == '98') {
        if (index == '99') {
          startCreateTime = moment().subtract(3, "months").format("YYYY-MM-DD") + ' 00:00:00'
          endCreateTime = moment().format("YYYY-MM-DD") + ' 23:59:59'
          this.queryParam.rangeTime = startCreateTime + ' - ' + endCreateTime
        }
        if (index == '98') {
          startCreateTime = ''
          endCreateTime = moment().subtract(3, "months").format("YYYY-MM-DD") + ' 23:59:59'
          this.queryParam.rangeTime = startCreateTime + ' - ' + endCreateTime
        }
        index = undefined;
      } else {
        this.queryParam.rangeTime = null
        this.allXuanzeRngTime = undefined
      }

      this.queryParam.orderStatus = index;
      this.getList()
    },
    /**
     * 区间选择
     * @param value
     */
    onChangeRangePicker(value , dateString) {
      console.log(value)
      console.log(dateString)
      if(value.length) {
        this.queryParam.rangeTime = dateString[0] + ' - ' + dateString[1];

      } else {
        this.queryParam.rangeTime = null
      }
      this.getList()
    },
    /**
     * 查询
     */
    handleQuery() {
      this.getList()
    },

    /**
     * 重置
     */
    resetQuery() {
      this.queryParam={
        orderNo: null,
          orderStatus: null,
          goodsName: null,
          userName: null,
          rangeTime: null,
          pageNum: 1,
          pageSize: 10
      }
      this.allXuanzeRngTime = null;
      // this.startCreateTime = null;
      // this.endCreateTime = null;
      // this.getList()
    },

    /**
     * 导出按钮
     */
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('shop/shop-order/export', {
            ...that.queryParam
          }, `订单_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },

    /**
     * 订单状态改变
     */
    updateOrderStatus(v) {
      this.queryParam.orderStatus = v;

    }
  }
}
</script>

<style scoped lang="less">
/*订单表格样式*/
.order-table {
  width: 100%;
  min-width: 998px;

  td {
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: top;
  }
}

.order-table-hader {
  background: #F9F9F9;
  border: 1px solid #ECECEC;
  margin-bottom: 5px;
  text-align: center;
}


.table-mod__col1 {
  width: 30% !important;
}

.table-mod__col2,
.table-mod__col3,
.table-mod__col4 {
  width: 8% !important;
}

.table-mod__col5,
.table-mod__col6,
.table-mod__col7 {
  width: 11% !important;
}

//表格数据样式部分

.order-table-item {
  width: 100%;
  min-width: 400px;
  margin-bottom: 5px;
  border: 1px solid #ECECEC;

  .order-goods-hader {
    padding-left: 30px;
    height: 44px;
    line-height: 44px;
    background: #eaf8ff;
    color: #3c3c3c;
    margin-bottom: 0px;

    span {
      font-size: 13px;
    }
  }
}

.order-table-item:hover {
  border: 1px solid #AED8FF;
}


.order-table-body {

  tr td {
    text-align: center;
    vertical-align: top;
    border-right: 1px solid #ececec;
    border-top: 1px solid #ececec;
  }

  tr td:first-child {
    text-align: left;
  }

  p {
    margin-bottom: 0px;
  }

  .no-boder-right {
    border-right: 0px;
  }

}
</style>