<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @cancel="onClose" cancelText="关闭" @ok="submitForm">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="平台" prop="platform" >
        <a-select v-model="form.platform" placeholder="请选择平台">
          <a-select-option value="1">
            安卓
          </a-select-option>
          <a-select-option value="2">
           ios
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="平台产品id" prop="iosProductId" v-if="form.platform == 2">
        <a-input v-model="form.iosProductId" placeholder="请输入平台产品id" />
      </a-form-model-item>

      <a-form-model-item label="价格" prop="price" >
        <a-input v-model="form.price" type="number" :min="0" placeholder="请输入价格" />
      </a-form-model-item>
      <a-form-model-item label="原价/划线价" prop="rawPrice" >
        <a-input v-model="form.rawPrice" type="number" :min="0" placeholder="请输入原价/划线价" />
      </a-form-model-item>
      <a-form-model-item label="获得VIP天数" prop="day" >
        <a-input v-model="form.day" type="number" :min="0" placeholder="请输入获得VIP天数" />
      </a-form-model-item>
      <a-form-model-item label="获得金豆数量" prop="goldenBeansCount" >
        <a-input v-model="form.goldenBeansCount" type="number" :min="0" placeholder="请输入获得金豆数量" />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input v-model="form.sort" type="number" :min="0" placeholder="请输入排序" />
      </a-form-model-item>
      <a-form-model-item label="是否显示推荐标签" prop="isRecommend" >
        <a-radio-group v-model="form.isRecommend" button-style="solid">
          <a-radio-button v-for="(d, index) in recommentOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否显示超值优惠标签" prop="isGreatDeals" >
        <a-radio-group v-model="form.isGreatDeals" button-style="solid">
          <a-radio-button v-for="(d, index) in greatDealsOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="isEnabled" >
        <a-radio-group v-model="form.isEnabled" button-style="solid">
          <a-radio-button v-for="(d, index) in enabledOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <div class="bottom-control">-->
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
<!--      </div>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import { getFriendCombo, addFriendCombo, updateFriendCombo } from '@/api/vip/friendCombo'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      recommentOptions:[{
        label: '显示',
        value: 1
      },
        {
          label: '不显示',
          value: 0
        }],
      greatDealsOptions:[{
        label: '显示',
        value: 1
      },
        {
          label: '不显示',
          value: 0
      }],
      enabledOptions:[{
        label: '启用',
        value: 1
      },
        {
          label: '禁用',
          value: 0
      }],
      // 表单参数
      form: {
        id: null,

        createTime: null,

        platform: null,

        iosProductId: null,

        name: null,

        price: null,

        rawPrice: null,

        day: null,

        goldenBeansCount: null,

        sort: null,

        isRecommend: 0,

        isGreatDeals: 0,

        isEnabled: 1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // remark: [
        //   { required: true, message: '备注不能为空', trigger: 'blur' }
        // ],
        platform: [
          { required: true, message: '平台, 1-安卓，2-ios, 默认-1不能为空', trigger: 'change' }
        ],
        iosProductId: [
          { required: true, message: 'ios平台产品id不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '价格, 单位: 分不能为空', trigger: 'blur' }
        ],
        rawPrice: [
          { required: true, message: '原价/划线价, 单位: 分不能为空', trigger: 'blur' }
        ],
        day: [
          { required: true, message: '获得VIP天数不能为空', trigger: 'blur' }
        ],
        goldenBeansCount: [
          { required: true, message: '获得金豆数量不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '排序, 排序不能为空', trigger: 'blur' }
        ],
        // isRecommend: [
        //   { required: true, message: '是否显示推荐标签, 0-不显示, 1-显示, 默认-0不能为空', trigger: 'blur' }
        // ],
        // isGreatDeals: [
        //   { required: true, message: '是否显示超值优惠标签, 0-不显示, 1-显示, 默认-0不能为空', trigger: 'blur' }
        // ],
        // isEnabled: [
        //   { required: true, message: '是否启用, 0-禁用, 1-启用, 默认-1不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        platform: null,
        iosProductId: null,
        name: null,
        price: null,
        rawPrice: null,
        day: null,
        goldenBeansCount: null,
        sort: null,
        isRecommend: 0,

        isGreatDeals: 0,

        isEnabled: 1
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      // let formData = JSON.parse(JSON.stringify(row))

      this.formType = 2
      const id = row ? row.id : ids
      getFriendCombo({"id":id}).then(response => {
        this.form = response.data
        this.form.platform = response.data.platform == 1 ? "1" : "2"
        this.form.isRecommend = response.data.isRecommend ? 1 : 0
        this.form.isEnabled = response.data.isEnabled ? 1 : 0
        this.form.isGreatDeals = response.data.isGreatDeals ? 1 : 0
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateFriendCombo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addFriendCombo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
