import request from '@/utils/request'


// 查询交友vip开通订单列表
export function listFriendOrder(query) {
  return request({
    url: '/user/vip-friend-order/list',
    method: 'get',
    params: query
  })
}

// 查询交友vip开通订单分页
export function pageFriendOrder(query) {
  return request({
    url: '/user/vip-friend-order/page',
    method: 'get',
    params: query
  })
}

// 查询交友vip开通订单详细
export function getFriendOrder(data) {
  return request({
    url: '/user/vip-friend-order/detail',
    method: 'get',
    params: data
  })
}

// 新增交友vip开通订单
export function addFriendOrder(data) {
  return request({
    url: '/user/vip-friend-order/add',
    method: 'post',
    data: data
  })
}

// 修改交友vip开通订单
export function updateFriendOrder(data) {
  return request({
    url: '/user/vip-friend-order/edit',
    method: 'post',
    data: data
  })
}

// 删除交友vip开通订单
export function delFriendOrder(data) {
  return request({
    url: '/user/vip-friend-order/delete',
    method: 'post',
    data: data
  })
}
