<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="物流公司名称" prop="dvyName">
                <a-input v-model="queryParam.dvyName" placeholder="请输入物流公司名称" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:delivery:add']">
          <a-icon type="plus"/>
          新增
        </a-button>
        <a-button type="primary" @click="handleSaveSort" v-hasPermi="['shop:delivery:add']">
          保存排序
        </a-button>
                <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:delivery:remove']">
                  <a-icon type="delete" />删除
                </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:delivery:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <!--        <table-setting-->
        <!--          :style="{float: 'right'}"-->
        <!--          :table-size.sync="tableSize"-->
        <!--          v-model="columns"-->
        <!--          :refresh-loading="loading"-->
        <!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <input slot="sort" slot-scope="text, record" :value="text" style="width: 100%;text-align: center;"
               class="ant-input" @change="onCellChange(record.id, $event)">
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.companyLogo" slot="companyLogo"
             slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['shop:delivery:edit']"/>-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:delivery:edit']">
<!--            <a-icon type="edit"/>-->
            编辑
          </a>
          <a-divider type="vertical" v-hasPermi="['shop:delivery:remove']"/>
                     <a-popconfirm title="确认删除吗?" @confirm="() => handleDelete(record)"
                                   v-hasPermi="['shop:delivery:remove']">

          <a>
            删除
          </a>
          </a-popconfirm>

        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {delDelivery, pageDelivery,saveSort} from '@/api/shop/delivery'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'

export default {
  name: 'Delivery',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        dvyName: null,
        // companyHomeUrl: null,
        // queryUrl: null,
        // dvyNo: null,
        // dvyNoHd: null,
        // companyLogo: null,
        pageNum: 1,
        pageSize: 10
      },
      changeSort:[],
      columns: [
        {
          title: '公司名称',
          dataIndex: 'dvyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司编号',
          dataIndex: 'dvyNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '联系电话',
          dataIndex: 'companyMobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          align: "center",
          dataIndex: 'sort',
          width: 100,
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '公司logo',
          dataIndex: 'companyLogo',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'companyLogo'}
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * TODO:保存排序
     */
    handleSaveSort: function () {
      const that = this;
      var arr = this.changeSort;
      if (arr.length < 1) {
        that.$message.warning("暂无数据");
        return;
      }
      saveSort(arr).then((res) => {
        if (res.code == 20001) {
          that.$message.success(res.message);
          this.getList();
        } else {
          that.$message.warning(res.message);
        }
      });
      //刷新table

    },
    /**
     *TODO: 修改哪个排序
     * @param id
     * @param e
     */
    onCellChange(id, e) {
      var sort = e.target.value;
      var changeSort = this.changeSort;
      var flag = true;
      changeSort.forEach((value) => {
        if (value['id'] == id) {
          flag = false;
          value['sort'] = sort;
          return true;
        }
      });
      if (flag) {
        changeSort.push({id: id, sort: sort});
      }
    },
    /** 查询商城-物流公司列表 */
    getList() {
      this.loading = true
      pageDelivery(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        dvyName: undefined,
        // companyHomeUrl: undefined,
        // queryUrl: undefined,
        // dvyNo: undefined,
        // dvyNoHd: undefined,
        // companyLogo: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      let data = []
      that.ids.map(id => data.push({"id": id}))
      delDelivery(data)
        .then(() => {
          that.onSelectChange([], [])
          that.getList()
          that.$message.success(
            '删除成功',
            3
          )
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('shop/delivery/export', {
            ...that.queryParam
          }, `商城-物流公司_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
