import request from '@/utils/request'


// 查询礼物列表
export function listGift(query) {
  return request({
    url: '/gift/gift-gift/list',
    method: 'get',
    params: query
  })
}
export function editEnable(data) {
  return request({
    url: '/gift/gift-gift/editEnable',
    method: 'post',
    data: data
  })
}

export function saveSort(data) {
  return request({
    url: '/gift/gift-gift/saveSort',
    method: 'post',
    data: data
  })
}
// 查询礼物分页
export function pageGift(query) {
  return request({
    url: '/gift/gift-gift/page',
    method: 'get',
    params: query
  })
}

// 查询礼物详细
export function getGift(data) {
  return request({
    url: '/gift/gift-gift/detail',
    method: 'get',
    params: data
  })
}

// 新增礼物
export function addGift(data) {
  return request({
    url: '/gift/gift-gift/add',
    method: 'post',
    data: data
  })
}

// 修改礼物
export function updateGift(data) {
  return request({
    url: '/gift/gift-gift/edit',
    method: 'post',
    data: data
  })
}

// 删除礼物
export function delGift(data) {
  return request({
    url: '/gift/gift-gift/delete',
    method: 'post',
    data: data
  })
}
