import request from '@/utils/request'


// 查询系统参数列表
export function listConfig(query) {
  return request({
    url: '/config/config/list',
    method: 'get',
    params: query
  })
}

// 查询系统参数分页
export function pageConfig(query) {
  return request({
    url: '/config/config/page',
    method: 'get',
    params: query
  })
}

// 查询系统参数详细
export function getConfig(data) {
  return request({
    url: '/config/config/detail',
    method: 'get',
    params: data
  })
}

// 新增系统参数
export function addConfig(data) {
  return request({
    url: '/config/config/add',
    method: 'post',
    data: data
  })
}

// 修改系统参数
export function updateConfig(data) {
  return request({
    url: '/config/config/edit',
    method: 'post',
    data: data
  })
}

// 删除系统参数
export function delConfig(data) {
  return request({
    url: '/config/config/delete',
    method: 'post',
    data: data
  })
}


export function getConfigByOrder(data) {
  return request({
    url: '/config/config/getConfigByOrder',
    method: 'get',
    params: data
  })
}

export function getConfigByPrice(data) {
  return request({
    url: '/config/config/getConfigByPrice',
    method: 'get',
    params: data
  })
}

export function getConfigByAccount(data) {
  return request({
    url: '/config/config/getConfigByAccount',
    method: 'get',
    params: data
  })
}

export function getConfigByDistribution(data) {
  return request({
    url: '/config/config/getConfigByDistribution',
    method: 'get',
    params: data
  })
}

export function getConfigByProfit(data) {
  return request({
    url: '/config/config/getConfigByProfit',
    method: 'get',
    params: data
  })
}

export function editConfigByOrder(data) {
  return request({
    url: '/config/config/editConfigByOrder',
    method: 'post',
    data: data
  })
}

export function editConfigByPrice(data) {
  return request({
    url: '/config/config/editConfigByPrice',
    method: 'post',
    data: data
  })
}

export function editConfigByAccount(data) {
  return request({
    url: '/config/config/editConfigByAccount',
    method: 'post',
    data: data
  })
}

export function editConfigByDistribution(data) {
  return request({
    url: '/config/config/editConfigByDistribution',
    method: 'post',
    data: data
  })
}

export function editConfigByProfit(data) {
  return request({
    url: '/config/config/editConfigByProfit',
    method: 'post',
    data: data
  })
}