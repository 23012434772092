<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="等级" prop="level" >
        <a-input v-model="form.level" placeholder="请输入等级" />
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="奖金限制" prop="bonusLimit" >
        <a-input-number :min="0" :step="1" :property="0" v-model="form.bonusLimit" placeholder="请输入奖金限制" />
      </a-form-model-item>
      <a-form-model-item label="奖励股权" prop="awardStockRights" >
        <a-input-number :min="0" :step="0.1" :property="3" v-model="form.awardStockRights" placeholder="请输入奖励股权" />
      </a-form-model-item>
      <a-form-model-item label="上级用户奖励股权" prop="topAwardStockRights" >
        <a-input-number :min="0" :step="0.1" :property="3" v-model="form.topAwardStockRights" placeholder="请输入上级用户奖励股权" />
      </a-form-model-item>
      <a-form-model-item label="上级用户奖励金额" prop="topBonus" >
        <a-input-number :min="0" :step="0.01" :property="2" v-model="form.topBonus" placeholder="请输入上级用户奖励金额" />
      </a-form-model-item>
      <a-form-model-item label="上级用户奖励嗨宝" prop="topBonus" >
        <a-input-number :min="0" :step="0.01" :property="2" v-model="form.topGam" placeholder="请输入上级用户奖励嗨宝" />
      </a-form-model-item>
      <a-form-model-item label="上级用户奖励嗨币" prop="topBonus" >
        <a-input-number :min="0" :step="0.01" :property="2" v-model="form.topGold" placeholder="请输入上级用户奖励嗨币" />
      </a-form-model-item>
      <a-form-model-item label="上级用户奖励金额分配天数" prop="bonusDays" >
        <a-input-number :min="0" :step="1"  v-model="form.bonusDays" placeholder="请输入上级用户奖励金额分配天数" />
      </a-form-model-item>
      <a-form-model-item label="升级限制个数" prop="numLimit" >
        <a-input-number :min="0" :step="1" :property="0" v-model="form.numLimit" placeholder="请输入升级限制个数" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRank, addRank, updateRank } from '@/api/distribution/rank'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        level: null,

        name: null,

        bonusLimit: null,

        awardStockRights: null,

        topAwardStockRights: null,

        topBonus: null,

        topGam: null,

        topGold: null,

        bonusDays: null,

        numLimit: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        level: [
          { required: true, message: '等级不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        bonusLimit: [
          { required: true, message: '奖金限制不能为空', trigger: 'blur' }
        ],
        awardStockRights: [
          { required: true, message: '奖励股权不能为空', trigger: 'blur' }
        ],
        topAwardStockRights: [
          { required: true, message: '上级用户奖励股权不能为空', trigger: 'blur' }
        ],
        topBonus: [
          { required: true, message: '上级用户奖励金额不能为空', trigger: 'blur' }
        ],
        topGam: [
          { required: true, message: '上级用户奖励嗨宝不能为空', trigger: 'blur' }
        ],
        topGold: [
          { required: true, message: '上级用户奖励嗨币不能为空', trigger: 'blur' }
        ],
        bonusDays: [
          { required: true, message: '上级用户奖励金额分配天数不能为空', trigger: 'blur' }
        ],
        numLimit: [
          { required: true, message: '升级限制个数不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        level: null,
        name: null,
        bonusLimit: null,
        awardStockRights: null,
        topAwardStockRights: null,
        topBonus: null,
        topGam: null,
        topGold: null,
        bonusDays: null,
        numLimit: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRank({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRank(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRank(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
