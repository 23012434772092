<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div v-show="pagetype === 1">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam.goodsName" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="所属分类">
<!--                  <a-input v-model="queryParam.category_id" placeholder="请输入" allow-clear />-->
                  <a-select :filterOption="false" placeholder="请输入分类名称" @search="cateGoryListData"  v-model="queryParam.categoryId" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in cateGoryListData" :key="d.id">{{ d.categoryName }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24" v-if="!shopInfo">
                <a-form-item label="所属店铺">
                  <!--                  <a-input v-model="queryParam.category_id" placeholder="请输入" allow-clear />-->
                  <a-select :filterOption="false" show-search placeholder="请输入店铺名称" @search="handleSearch" v-model="queryParam.shopId" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in shopData" :key="d.id">{{ d.shopName }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <template v-if="advanced">
                <a-col :md="8" :sm="24">
                  <a-form-item label="上架状态">
                    <a-select placeholder="请选择" v-model="queryParam.state" style="width: 100%" allow-clear>
                      <a-select-option :key="0">下架中</a-select-option>
                      <a-select-option :key="1">上架中</a-select-option>
                      <a-select-option :key="2">待审核</a-select-option>
                      <a-select-option :key="3">审核失败</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </template>
              <a-col :md="!advanced && 8 || 24" :sm="24">
                <span class="table-page-search-submitButtons"
                  :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <div class="table-operations">
          <a-button type="primary" @click="handleAdd" v-hasPermi="['shop:goods:add']" v-if="shopInfo">
            <a-icon type="plus" />新增
          </a-button>
<!--          <a-button type="primary" :disabled="single" @click="handleUpdate" v-hasPermi="['mall:goods:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a-button>-->
          <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['mall:goods:remove']">
            <a-icon type="delete" />删除
          </a-button>
<!--          <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"-->
<!--            :refresh-loading="loading" @refresh="getList" />-->


          <div class="ant-alert-info ant-alert-info" v-hasPermi="['mall:goods:remind']" v-if="shopList && shopInfo" style="float: right;padding: 5px 15px 5px 15px;">
            <a-icon type="bell" style="color: red"/>
            低库存提醒&nbsp;&nbsp;
            <span v-if="shopInfo && shopInfo.alarmGoodsStock == 0 && shopInfo.alarmGoodsSkuStock == 0">
              <a :disabled="shopStatus1" @click="showAlarmModal">开启</a>
            </span>
            <span v-if="shopInfo && (shopInfo.alarmGoodsStock != 0 || shopInfo.alarmGoodsSkuStock != 0)">
              <a :disabled="shopStatus1" @click="closeAlarm">关闭</a>
            </span>
          </div>
        </div>
      <stockAlarmModal ref="stockAlarmModal"></stockAlarmModal>
        <!-- 数据展示 -->
        <a-table
          :loading="loading" rowKey="id" :columns="columns" :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
          :customRow="customRow"
          :bordered="tableBordered">

<!--          <ImagePreview slot="picture" style="width:80px;height:80px;border:none;" slot-scope="text, record" :src="record.picture" />-->
          <img style="width: 48px;height: 48px;" v-image-preview :src="record.picture" slot="picture" slot-scope="text, record">
<!--          <el-image-->
<!--            :width="248"-->
<!--            slot-scope="text, record"-->
<!--            slot="picture"-->
<!--            :src="baseimgerver+'/'+record.picture"-->
<!--          />-->
          <span slot="goodsLabel" slot-scope="text, record">

          <div v-hasPermi="['mall:goods:add']">
            新品：
           <a-switch :disabled="shopStatus1" @click="updateGoodsState(record,2)" v-model="record.isNew">
             <a-icon type="check" slot="checkedChildren"/>
             <a-icon type="close" slot="unCheckedChildren"/>
           </a-switch>
          </div>

              <div v-hasPermi="['mall:goods:add']">
            热卖：
           <a-switch :disabled="shopStatus1" @click="updateGoodsState(record,4)" v-model="record.isHot">
             <a-icon type="check" slot="checkedChildren"/>
             <a-icon type="close" slot="unCheckedChildren"/>
           </a-switch>
          </div>
          <div v-hasPermi="['mall:goods:add']">
            推荐：
           <a-switch :disabled="shopStatus1" @click="updateGoodsState(record,3)" v-model="record.isRecommend">
             <a-icon type="check" slot="checkedChildren"/>
             <a-icon type="close" slot="unCheckedChildren"/>
           </a-switch>
          </div>

              <div v-hasPermi="['mall:goods:add']">
            精品：
           <a-switch :disabled="shopStatus1" @click="updateGoodsState(record,1)" v-model="record.isBoutique">
             <a-icon type="check" slot="checkedChildren"/>
             <a-icon type="close" slot="unCheckedChildren"/>
           </a-switch>
          </div>

              <div v-if="record.state == 1 || record.state == 0">
           上架：
           <a-switch @click="updateGoodsState(record,5)" v-model="record.state == 1">
             <a-icon type="check" slot="checkedChildren"/>
             <a-icon type="close" slot="unCheckedChildren"/>
           </a-switch>
          </div>
        </span>

          <span slot="realSales" slot-scope="text, record">
          <div v-hasPermi="['mall:goods:audit']" v-if="shopStatus1">
             <input :value="text" style="width: 100%;text-align: center;"
                    class="ant-input" :disabled="shopStatus1" @change="onSalesChange(record.id, $event)"/>
          </div>
          <div v-hasPermi="['mall:goods:add']" v-else>
              <input :value="text" style="width: 100%;text-align: center;"
                     class="ant-input" disabled @change="onSalesChange(record.id, $event)"/>
          </div>

        </span>

<!--          <span v-hasPermi="['mall:goods:audit']" slot="isHighSales" slot-scope="text, record">-->
<!--          <a-switch :disabled="shopStatus1" @click="updateGoodsState(record,5)" v-model="record.isHighSales == 1">-->
<!--             <a-icon type="check" slot="checkedChildren"/>-->
<!--             <a-icon type="close" slot="unCheckedChildren"/>-->
<!--           </a-switch>-->
<!--        </span>-->

<!--          <span  v-hasPermi="['mall:goods:add']" slot="niceGoodsRecommend" slot-scope="text, record">-->
<!--          <a-switch :disabled="shopStatus1" @click="updateGoodsState(record,6)" v-model="record.niceGoodsRecommend == 1">-->
<!--             <a-icon type="check" slot="checkedChildren"/>-->
<!--             <a-icon type="close" slot="unCheckedChildren"/>-->
<!--           </a-switch>-->
<!--        </span>-->

          <input slot="sort" slot-scope="text, record" :value="text" style="width: 100%;text-align: center;"
                 class="ant-input" :disabled="shopStatus1" @change="onCellChange(record.id, $event)"/>
          <span slot="operation" slot-scope="text, record">
            <a @click="openDetail(record)">详情</a>
                      <a-divider type="vertical"/>

              <a v-hasPermi="['mall:goods:edit']" :disabled="shopStatus1" v-if="record.state !=2" @click="handleUpdate(record, undefined)">编辑</a>

          <a-divider v-hasPermi="['mall:goods:remove']" v-if="record.state==3 ||record.state==0" type="vertical"/>
          <a-popconfirm  v-hasPermi="['mall:goods:remove']" title="确认删除吗?" @confirm="() => logicalDelete(record.id)" v-if="record.state==3 ||record.state==0">
            <a :disabled="shopStatus1">刪除</a>
          </a-popconfirm>

          <a-divider v-hasPermi="['mall:goods:audit']" v-if="record.state==2" type="vertical"/>
<!--          <a v-hasPermi="['mall:goods:audit']" v-if="record.state==2" @click="handleAudit(record)">审核</a>-->

<!--             <a-divider v-hasPermi="['mall:goods:audit']" type="vertical"/>-->
          <a v-hasPermi="['mall:goods:audit']" :disabled="!shopStatus1" v-if="record.state==2" @click="handleAudit(record,1)">审核通过</a>
                   <a-divider v-hasPermi="['mall:goods:audit']" v-if="record.state==2" type="vertical"/>
          <a v-hasPermi="['mall:goods:audit']" :disabled="!shopStatus1" v-if="record.state==2" @click="handleAudit(record,2)">审核驳回</a>
<!--          <a-divider  v-hasPermi="['mall:goods:add:evaluate']" type="vertical"/>-->
<!--          <a :disabled="shopStatus1" v-hasPermi="['mall:goods:add:evaluate']" @click="addEvaluate(record)">添加评价</a>-->

<!--          <a-divider v-hasPermi="['mall:goods:edit']" type="vertical"/>-->
<!--          <a :disabled="shopStatus1" v-hasPermi="['mall:goods:edit']" @click="copyGoods(record.id)">复制</a>-->
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
          :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange" @change="changeSize" />
      </div>
      <!-- 增加修改 -->
      <!-- <create-pform v-show="pagetype === 2" ref="CreatePform" :statusOptions="dict.type['sys_normal_disable']"
        @ok="getList" /> -->

      <auditGoods ref="auditGoods" @ok="getList()"></auditGoods>
      <AuditDetailGoods ref="AuditDetailGoods"/>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import CreatePform from './modules/CreatePform'
import AuditDetailGoods from './modules/AuditDetailGoods'
import { tableMixin } from '@/store/table-mixin'
import * as goods from '@/api/mall/goods'
import stockAlarmModal from "./modules/StockAlarmModal"
import {queryShopList, updateAlarmStock} from "@/api/shop/shop";
import auditGoods from './modules/AuditGoods'
import querystring from 'querystring';
import {getLevelList} from "@/api/mall/goodscate";
export default {
  name: 'Post',
  components: {
    CreatePform,
    stockAlarmModal,
    auditGoods,
    AuditDetailGoods
    // ImagePreview
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      baseimgerver : `${process.env.VUE_APP_BASE_API}`,

      pagetype: 1,  // 1商品列表 2新增编辑商品页面
      // tableBordered: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        goodsName:'',//商品名称
        categoryId:'',
        shopId: null,
        postCode: undefined,
        postName: undefined,
        state: undefined
      },
      columns: [
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          ellipsis: true,
          align: 'left',
          width: 200,
        },
        {
          title: '商品图片',
          align: "center",
          dataIndex: 'picture',
          width: 120,
          scopedSlots: {customRender: 'picture'}},

        {
          title: '店铺名称',
          align: "center",
          dataIndex: 'shopName'
        },
        {
          title: '是否自营',
          align: "center",
          dataIndex: 'goodsType',
          customRender: function (type) {
            if (type == 1) {
              return "平台自营";
            } else {
              return "商家自营";
            }
          }
        },
        {
          title: '商品分类',
          align: "center",
          dataIndex: 'categoryName'
        },
        {
          title: '标签',
          align: "center",
          scopedSlots: {customRender: 'goodsLabel'}
        },
        {
          title: '是否上架',
          align: "left",
          dataIndex: 'state',
          width: 100,
          customRender: function (value) {
            if (value == 1) {
              return "上架中";
            } else if(value == 2) {
              return "审核中";
            } else if(value == 3) {
              return "审核失败";
            } else {
              return "已下架";
            }
          }
        },
        {
          title: '真实销量',
          align: "left",
          dataIndex: 'realSales',
          width: 100,
          scopedSlots: {customRender: 'realSales'}
        },
        {
          title: '库存',
          align: "left",
          dataIndex: 'stock',
          width: 100,
        },
        // {
        //   title: '审核状态',
        //   align: "center",
        //   dataIndex: 'state',
        //   customRender: function (state) {
        //     if (state == 0 || state == 1) {
        //       return "无需审核"
        //     } else if (state == 2) {
        //       return "待审核"
        //     } else if (state == 3) {
        //       return "审核未通过"
        //     }
        //   }
        // },
        {
          title: '审核意见',
          align: 'center',
          dataIndex: 'auditMessage',
          customRender: function (v,r) {
            if (r.state == 3) {
              return v
            }
            return '-'
          }
        },
        {
          title: '排序',
          align: "center",
          dataIndex: 'sort',
          width: 100,
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '操作',
          dataIndex: 'operation',
          // width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      shopStatus1:false,
      changeSort:[],
      shopList:[{
        alarmGoodsStock:0
      }],
      shopData: [],
      cateGoryListData:[]
    }
  },
  filters: {
  },
  created() {
    this.shopStatus1 = (this.$store.getters.shopInfo && this.$store.getters.shopInfo.shopState == 1) ? false :true;
    this.getList()
    this.getShopListXinxi();
    this.getLevelList(null);
  },
  computed: {
    shopInfo: function () {
      return this.$store.getters.shopInfo;
    }
  },
  watch: {
  },
  methods: {
    openDetail(record) {
      this.$refs['AuditDetailGoods'].handleOpen(record)
    },
    customRow:function(record,index) {
      let corlor = ""
      if(this.shopInfo) {
        if(record.state == 1) {
          if((record.stock) <= this.shopInfo.alarmGoodsStock){
            corlor = "#AA5555";
          }
        }
      }

      let param = { style: { background: corlor } }
      return param;
    },
    getLevelList(value) {
      getLevelList({
        categoryName:value,
        level:3
      }).then(res => {
        this.cateGoryListData = res.data;
      })
    },
    handleSearch(value) {
      console.log(value)
      this.getShopListXinxi(value)
    },
    getShopListXinxi(shopName) {
      if(this.shopInfo) {

      } else {
        // const str = querystring.encode({
        //   code: 'utf-8',
        //   shopName: shopName,
        // });
        queryShopList({
          shopName:shopName
        }).then(res => {
          console.log('------------------')
          this.shopData = res.data
          console.log(this.shopData)
        })
      }
    },
    handleAudit(record,num) {
      if(num == 2) {
        this.$refs['auditGoods'].handleOpen(record)
      } else {
        let recordInfo = JSON.parse(JSON.stringify(record))
        recordInfo.state = 1
        goods.auditGoods(recordInfo).then(res => {
          if(res.code == 20001) {
            record.state = res.data.state
          } else {
            this.$message.warning(res.message)
          }
        })
      }
    },
    logicalDelete(id) {
      let that = this;
      // this.handleDelete()
      goods.del([id]).then(() => {
        that.onSelectChange([], [])
        that.getList()
        that.$message.success('删除成功', 3)
      })
    },

    showAlarmModal() {
      this.$refs['stockAlarmModal'].show(this.shopInfo)
    },

    closeAlarm() {
      let shopInfo = JSON.parse(JSON.stringify(this.shopInfo))
      shopInfo.alarmGoodsStock = 0
      shopInfo.alarmGoodsSkuStock = 0
      updateAlarmStock(shopInfo).then((res) => {
        if (res.success) {
          this.$message.success(res.message)
          this.$store.commit('SET_SHOP_INFO', res.data)
          console.log(this.$store.getters.shopInfo)
          // this.$emit('ok')
        } else {
          // this.shopInfo.alarmGoodsStock=this.oldAlarmGoodsStock
          // this.shopInfo.alarmGoodsSkuStock = this.oldAlarmGoodsSkuStock
          this.$message.warning(res.message);
        }
      }).finally(() => {
        // this.confirmLoading = false;
        // this.close();
      })
    },
    onCellChange(id, e) {
      var sort = e.target.value;
      var changeSort = this.changeSort;
      var flag = true;
      changeSort.forEach((value) => {
        if (value['id'] == id) {
          flag = false;
          value['sort'] = sort;
          return true;
        }
      });
      if (flag) {
        changeSort.push({id: id, sort: sort});
      }
    },
    //TODO:修改各个状态
    updateGoodsState(record, type) {
      var that = this;
      console.log(record.state)
      let formData = JSON.parse(JSON.stringify(record))
      debugger
      //更改狀態
      // if (type == 1) {
      //   // formData.isBoutique = (formData.isBoutique == 0) ? 1 : 0
      //   formData.isBoutique = (formData.isBoutique) ? false : true
      // } else if (type == 2) {
      //   formData.isNew = (formData.isNew) ? false : true
      // } else if (type == 3) {
      //   formData.isRecommend = (formData.isRecommend) ? false : true
      // } else if (type == 4) {
      //   formData.isHot = (formData.isHot) ? false : true
      // }else
      if (type == 5) {
        formData.state = (formData.state == 0) ? 1 : 0
      }
    console.log(formData);
      // let queryData = {}
      // if (type == 5){
      //   queryData = {
      //     id:formData.id,
      //     state:(formData.state == 0) ? 1 : 0
      //   }
      // }else {
      //   queryData = {
      //     id:formData.id,
      //     isBoutique:(formData.isBoutique),
      //     isNew: (formData.isNew),
      //     isRecommend:(formData.isRecommend),
      //     isHot: formData.isHot,
      //   }
      // }
   let queryData = {
     id:formData.id,
        isBoutique:(formData.isBoutique),
        isNew: (formData.isNew),
        isRecommend:(formData.isRecommend),
        isHot: formData.isHot,
        state:formData.state
      }
      // return;
      goods.updateGoodsState(queryData).then((res) => {
        if (res.code == 20001) {
          that.$message.success(res.message)
          that.$emit('ok');
        } else {
          if (type == 1) {
            record.isBoutique = (queryData.isBoutique) ?false : true
          } else if (type == 2) {
            record.isNew = (queryData.isNew == 0) ? false : true
          } else if (type == 3) {
            record.isRecommend = (queryData.isRecommend == 0) ? false : true
          } else if (type == 4) {
            record.isHot = (queryData.isHot == 0) ? false : true
          }else if (type == 5) {
            record.state = (queryData.state == 0) ? 1 : 0;
          }
         this.$message.warning(res.message)
        }
        that.getList();
      })
    },
    /**
     * TODO:改变销量
     * @param id
     * @param e
     */
    onSalesChange(id, e){
      console.log(id)
      console.log(e.target.value)
      var that = this;
      var params = {
        "id":id,
        "realSales":e.target.value
      }
      httpAction(this.url.changeSales, params, "post").then((res) => {
        if (res.success) {
          that.$message.success(res.message);
          this.changeSort = [];
        } else {
          that.$message.warning(res.message);
        }
      }).finally(() => {
        that.loadData();
      });
    },
    /** 查询商品列表 */
    getList() {
      this.loading = true
      goods.list(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        goodsName:'',//商品名称
        categoryId:'',
        shopId: null,
        postCode: undefined,
        postName: undefined,
        state: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(selectedRows)
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({
        path: '/mall/goodsForm',
        params:
        {
          id: undefined,
          formTitle: '添加商品'
        }
      })
    },
    /** 编辑商品信息 **/
    handleUpdate(row) {
      const id = row ? row.id : ''
      console.log('商品信息' + id)
      this.$router.push({
        path: '/mall/goodsForm',
        query:
        {
          id: id,
          formTitle: '修改商品'
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const id = this.ids
      console.log(id)
      console.log(this.ids)
      goods.del(id).then(() => {
        that.onSelectChange([], [])
        that.getList()
        that.$message.success('删除成功', 3)
      })
    },
    confirmHandleStatus(info) {
      console.log('info', info)
    },
    cancelHandleStatus() {

    },
  }
}
</script>
