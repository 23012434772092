<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否会员" prop="isVip">
                <a-select v-model="queryParam.isVip" placeholder="请选择是否会员">
                  <a-select-option value="1">
                    是
                  </a-select-option>
                  <a-select-option value="0">
                    否
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="是否交友会员" prop="isFriendVip">
                <a-select v-model="queryParam.isFriendVip" placeholder="请选择是否交友会员">
                  <a-select-option value="1">
                    是
                  </a-select-option>
                  <a-select-option value="0">
                    否
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="是否在线" prop="isOnline">-->
<!--                <a-select v-model="queryParam.isOnline" placeholder="请选择是否在线">-->
<!--                  <a-select-option value="1">-->
<!--                    是-->
<!--                  </a-select-option>-->
<!--                  <a-select-option value="0">-->
<!--                    否-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="会员等级" prop="vipGrade">
                <a-select v-model="queryParam.vipGrade" placeholder="请选择会员等级">
                  <a-select-option value="0">
                    普通用户
                  </a-select-option>
                  <a-select-option value="1">
                    黄金会员
                  </a-select-option>
                  <a-select-option value="2">
                    白金会员
                  </a-select-option>
                  <a-select-option value="3">
                    钻石会员
                  </a-select-option>
                  <a-select-option value="4">
                    至尊会员
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="是否实名认证" prop="isAuthentication">-->
<!--                <a-select v-model="queryParam.isAuthentication" placeholder="请选择是否实名认证">-->
<!--                  <a-select-option value="1">-->
<!--                    是-->
<!--                  </a-select-option>-->
<!--                  <a-select-option value="1">-->
<!--                    否-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="上级用户id，外键(user.id)" prop="topUserId">-->
<!--                <a-input v-model="queryParam.topUserId" placeholder="请输入上级用户id，外键(user.id)" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['userAdmin:userAdmin:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['userAdmin:userAdmin:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['userAdmin:userAdmin:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['userAdmin:userAdmin:export']">
          <a-icon type="download" />导出
        </a-button>
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
<!--      <dynamicModalTables ref="dynamicModalTables" />-->
<!--      <rechargeRecord ref="rechargeRecord"/>-->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="false"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.registerTime) }}
        </span>
        <span slot="lastOrderTime" slot-scope="text, record">
          {{ parseTime(record.lastOrderTime) }}
        </span>

        <a-switch slot="isOnline" slot-scope="text, record" @click="updateUserState(record)" v-model="record.isOnline">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>
<!--        <a slot="videoNumber" slot-scope="text, record">{{record.videoNumber}}</a>-->
<!--        <a slot="friendNumber" slot-scope="text, record">{{record.friendNumber}}</a>-->
<!--        <a slot="followedNumber" slot-scope="text, record">{{record.followedNumber}}</a>-->
<!--        <a slot="fansNumber" slot-scope="text, record">{{record.fansNumber}}</a>-->
<!--        <a slot="dynamic" slot-scope="text, record" @click.stop="lookDynamic(record)">查看动态</a>-->
<!--        <a slot="rechargeRecords" @click.stop="openRecord(record,1)" slot-scope="text, record">收入记录</a>-->
<!--        <a slot-scope="text,record" slot="goldenBeans" @click.stop="openRecord(record,2)" >消费记录</a>-->

<!--        <a-tag slot="type" slot-scope="text,record">{{record.type == 1 ? '主播' : record.type == 2 ? '商家' : '普通用户'}}</a-tag>-->
        <img  slot="avatar" slot-scope="text,record" style="width: 32px;height: 32px;border-radius: 50%" :src="record.avatar" v-image-preview/>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['userAdmin:userAdmin:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['userAdmin:userAdmin:edit']">-->
<!--&lt;!&ndash;            <a-icon type="edit" />&ndash;&gt;-->
<!--            编辑-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['userAdmin:userAdmin:remove']" />-->

            <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down"/></a>
            <a-menu slot="overlay">
              <a-menu-item>
                  <a @click="changeQiyong(record)" v-if="record.status == 1" v-hasPermi="['userAdmin:userAdmin:edit']">
            禁用
          </a>
             <a @click="changeQiyong(record)" v-else v-hasPermi="['userAdmin:userAdmin:edit']">
            启用
          </a>
              </a-menu-item>
               <a-menu-item>
                <a @click="chongzhiMima(record)">
            重置密码
          </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleDelete(record)" v-hasPermi="['userAdmin:userAdmin:remove']">
<!--            <a-icon type="delete" />-->
            删除
          </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>


        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageUserAdmin, listUserAdmin, delUserAdmin, updateOnline, queryVipPage} from '@/api/userAdmin/userAdmin'
import CreateForm from './modules/resetPwdModalForm'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'vipUserAdmin',
  components: {
    CreateForm,
    // dynamicModalTables,
    // rechargeRecord
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        mobile: null,
        passwd: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        isVip: null,
        isFriendVip: null,
        vipGrade: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [

        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: "avatar" }
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if(text == 1) {
              return '男'
            } else if(text == 2) {
              return '女'
            } else {
              return '保密'
            }
          }
        },
        {
          title: '是否创业会员',
          dataIndex: 'isVip',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if(text) {
              return '是'
            }  else {
              return '否'
            }
          }
        },
        {
          title: '是否交友会员',
          dataIndex: 'isFriendVip',
          ellipsis: true,
          align: 'center',
          customRender: function (text, record, index) {
            if(text) {
              return '是'
            }  else {
              return '否'
            }
          }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '密码',
        //   dataIndex: 'passwd',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '下单次数',
          dataIndex: 'orderNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最后下单时间',
          dataIndex: 'lastOrderTime',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'lastOrderTime' },
        },
        {
          title: '会员余额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '累计消费金额',
          dataIndex: 'balance',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'registerTime',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'createTime' },

        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    chongzhiMima(record) {
      this.$refs['createForm'].openModal(record.id)
    },
    openRecord(record, billType) {
      let queryData = {
        userId:record.id,
        billType: billType,
        moneyType: 3
      }
      this.$refs['rechargeRecord'].open(queryData)
    },

    lookDynamic(record) {
      console.log('点击了')
      this.$refs['dynamicModalTables'].open(record)
    },
    changeQiyong(record) {
      let recordParams = {
        id:record.id,
        status:record.status ? 0 : 1
      }
      updateOnline(recordParams).then(res => {
        if(res.code == 20001) {
          record.status ? 1 : 0
          record.status=  record.status ? 0 : 1

        } else {
          this.$message.warning(res.msg);
          record.status=  record.status ? 1 : 0
        }
      })
    },
    /**
     * 更新用户操作
     * @param record
     */
    updateUserState(record) {
      console.log('更新操作')
      let recordParams = {
        id:record.id,
        isOnline:record.isOnline ? 1 : 0
      }
      updateOnline(recordParams).then(res => {
        if(res.code == 20001) {

        } else {
          this.$message.warning(res.msg);
          record.isOnline ? 0 : 1
        }
      })
    },
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
      queryVipPage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: null,
        mobile: null,
        passwd: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        isVip: null,
        isFriendVip: null,
        vipGrade: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUserAdmin(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('userAdmin/userAdmin/export', {
            ...that.queryParam
          }, `会员信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
