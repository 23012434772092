import request from '@/utils/request'
const userApi = {
  list:'/shop/shop-goods-security/list'
}

// 查询分类列表
export function list (query) {
  return request({
    url: userApi.list,
    method: 'get',
    params: query
  })
}