import request from '@/utils/request'


// 查询企业用户列表
export function listCompanyUser(query) {
  return request({
    url: '/user/company-user/list',
    method: 'get',
    params: query
  })
}

// 查询企业用户分页
export function pageCompanyUser(query) {
  return request({
    url: '/user/company-user/page',
    method: 'get',
    params: query
  })
}

// 查询企业用户详细
export function getCompanyUser(data) {
  return request({
    url: '/user/company-user/detail',
    method: 'get',
    params: data
  })
}

// 新增企业用户
export function addCompanyUser(data) {
  return request({
    url: '/user/company-user/add',
    method: 'post',
    data: data
  })
}

// 修改企业用户
export function updateCompanyUser(data) {
  return request({
    url: '/user/company-user/edit',
    method: 'post',
    data: data
  })
}

// 删除企业用户
export function delCompanyUser(data) {
  return request({
    url: '/user/company-user/delete',
    method: 'post',
    data: data
  })
}
