<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="取消">

    <a-spin :spinning="confirmLoading">
      <div style="padding-left: 20%;">
        <a-row :gutter="24" >
          <div >
            <a-col >
              <a-checkbox v-model="checkbox1" ></a-checkbox>&nbsp;&nbsp;
              当销售中的任一商品库存小于&nbsp;&nbsp;
              <a-input-number v-model="alarmGoodsStock" />&nbsp;&nbsp;时，
            </a-col>
            <a-col>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              推送消息盒子通知。
            </a-col>
          </div>
        </a-row>
        <a-row :gutter="24" >
          <div>
            <a-col >
              <a-checkbox v-model="checkbox2" ></a-checkbox>&nbsp;&nbsp;
              当销售中的任一SKU库存小于&nbsp;&nbsp;
              <a-input-number v-model="alarmGoodsSkuStock" />&nbsp;&nbsp;时，
            </a-col>
            <a-col>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              推送消息盒子通知。
            </a-col>
          </div>
        </a-row>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
 import { updateAlarmStock } from "@/api/shop/shop"
  export default {
    name: "stockAlarmModal",
    components: {
    },
    data () {
      return {
        title:"操作",
        visible: false,
        model: {
        },
        confirmLoading: false,
        shopInfo:{},
        alarmGoodsStock:0,
        alarmGoodsSkuStock:0,
        oldAlarmGoodsStock:0,
        oldAlarmGoodsSkuStock:0,
        checkbox1:false,
        checkbox2:false,
        shopInfo:{},
        headers:{},
      }
    },
    methods: {

      close () {
        this.$emit('close');
        this.checkbox1=false;
        this.checkbox2=false;
        this.alarmGoodsStock=0;
        this.alarmGoodsSkuStock=0;
        this.visible = false;
      },
      handleOk () {
        var that = this;
        if(this.alarmGoodsStock == null){
          this.alarmGoodsStock = 0;
        }
        if(this.alarmGoodsSkuStock == null){
          this.alarmGoodsSkuStock = 0;
        }
        if(!this.checkbox1 && !this.checkbox2){
          alert("请至少选择一个提醒设置！");
          return;
        }
        if(!this.checkbox1){
          this.alarmGoodsStock = 0
        }
        if(!this.checkbox2){
          this.alarmGoodsSkuStock = 0
        }
        this.shopInfo.alarmGoodsStock = this.alarmGoodsStock
        this.shopInfo.alarmGoodsSkuStock = this.alarmGoodsSkuStock
        updateAlarmStock(this.shopInfo).then((res) => {
          if (res.success) {
            that.$message.success(res.message)
            this.$store.commit('SET_SHOP_INFO', res.data);
            console.log(this.$store.getters.shopInfo);
            that.$emit('ok')
          } else {
            that.shopInfo.alarmGoodsStock=that.oldAlarmGoodsStock
            that.shopInfo.alarmGoodsSkuStock = that.oldAlarmGoodsSkuStock
            that.$message.warning(res.message)
          }
        }).finally(() => {
          that.confirmLoading = false;
          that.close();
        })

      },
      handleCancel () {
        this.close()
      },
      show(shop){
        this.shopInfo=shop;
        this.alarmGoodsStock=shop.alarmGoodsStock;
        this.oldAlarmGoodsStock = shop.alarmGoodsStock;
        this.alarmGoodsSkuStock = shop.alarmGoodsSkuStock;
        this.oldAlarmGoodsStock = shop.alarmGoodsSkuStock;
        if(this.alarmGoodsStock > 0 ){
          this.checkbox1 = true;
        }
        if(this.alarmGoodsSkuStock > 0 ){
          this.checkbox2 = true;
        }
        this.visible = true;
      },
    }
  }
</script>

<style lang="less" scoped>

</style>