<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="平台" prop="level" >
        <a-select v-model="form.type" placeholder="请选择平台类型">
          <a-select-option :value="1">安卓</a-select-option>
          <a-select-option :value="2">IOS</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="级别" prop="level" >
        <a-input v-model="form.level" placeholder="请输入级别" />
      </a-form-model-item>
      <a-form-model-item label="级别名称" prop="levelName" >
        <a-input v-model="form.levelName" placeholder="请输入级别名称" />
      </a-form-model-item>
      <a-form-model-item label="金额" prop="money" >
        <a-input v-model="form.money" placeholder="请输入金额" />
      </a-form-model-item>


      <a-form-model-item label="奖励嗨宝" prop="money" >
        <a-input v-model="form.gemMoney" placeholder="请输入金额" />
      </a-form-model-item>
      <a-form-model-item label="奖励嗨币" prop="money" >
        <a-input v-model="form.goldMoney" placeholder="请输入金额" />
      </a-form-model-item>
      <a-form-model-item label="奖励股权" prop="money" >
        <a-input v-model="form.stockRights" placeholder="请输入金额" />
      </a-form-model-item>
      <a-form-model-item label="奖励上级股权" prop="money" >
        <a-input v-model="form.topStockRights" placeholder="请输入金额" />
      </a-form-model-item>
      <a-form-model-item label="奖励上级股权分红天数" prop="money" >
        <a-input v-model="form.bonusDays" placeholder="请输入金额" />
      </a-form-model-item>


      <a-form-model-item label="购买条件" prop="condition" >
        <a-select
          v-model="form.condition"
          showSearch
          placeholder="购买条件"
          style="width: 200px"
        >
          <a-select-option v-for="rank in rankList" :key="rank.level" :value="rank.level">
            {{ rank.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="可升至身份等级" prop="upgradeRank" >
        <a-select
          v-model="form.upgradeRank"
          showSearch
          placeholder="购买条件"
          style="width: 200px"
        >
          <a-select-option v-for="rank in rankList" :key="rank.level" :value="rank.level">
            {{ rank.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

     <!-- <a-form-model-item label="购买条件" prop="condition" >
        <a-input v-model="rankList" placeholder="请输入购买条件" />
      </a-form-model-item>-->
      <a-form-model-item label="说明" prop="explain" >
        <a-input v-model="form.explain" placeholder="请输入说明" />
      </a-form-model-item>
<!--      <a-form-model-item label="分类名称" prop="cateName" >-->
<!--        <a-input v-model="form.cateName" placeholder="请输入分类名称" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="背景图" prop="backgroundUrl">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <!--          {{baseimgerver}}-->
          <img v-if="form.backgroundUrl" :src="form.backgroundUrl" alt="图片" style="height:90px;max-width:224px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:686*280 </span>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVip, addVip, updateVip, getDistributionRankList } from '@/api/distribution/vip'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        level: null,

        levelName: null,
        backgroundUrl:null,
        money: null,

        condition: null,
        upgradeRank:null,
        explain: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        level: [
          { required: true, message: '级别不能为空', trigger: 'blur' }
        ],
        levelName: [
          { required: true, message: '级别名称不能为空', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '金额不能为空', trigger: 'blur' }
        ],
        condition: [
          { required: true, message: '购买条件不能为空', trigger: 'blur' }
        ],
        upgradeRank: [
          { required: true, message: '可升至身份等级不能为空', trigger: 'blur' }
        ],
        cateName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ]
      },

      rankList: [],
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.backgroundUrl = info.file.originFileObj.ossUrl;
      }
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          console.log('------res[0]')
          console.log(res[0])
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        level: null,
        levelName: null,
        backgroundUrl:null,

        money: null,
        condition: null,
        explain: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.getDistributionRank();
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVip({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      this.getDistributionRank();
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVip(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVip(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /** 查询创业身份  rank */
    getDistributionRank (row, ids) {
      getDistributionRankList().then(response => {
        this.rankList = response.data
      })
    },
  }
}
</script>
