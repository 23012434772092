import request from '@/utils/request'


// 查询短视频分类标签列表
export function listVideoTag(query) {
  return request({
    url: '/video/video-video-tag/list',
    method: 'get',
    params: query
  })
}

// 查询短视频分类标签分页
export function pageVideoTag(query) {
  return request({
    url: '/video/video-video-tag/page',
    method: 'get',
    params: query
  })
}

// 查询短视频分类标签详细
export function getVideoTag(data) {
  return request({
    url: '/video/video-video-tag/detail',
    method: 'get',
    params: data
  })
}

// 新增短视频分类标签
export function addVideoTag(data) {
  return request({
    url: '/video/video-video-tag/add',
    method: 'post',
    data: data
  })
}

// 修改短视频分类标签
export function updateVideoTag(data) {
  return request({
    url: '/video/video-video-tag/edit',
    method: 'post',
    data: data
  })
}

// 删除短视频分类标签
export function delVideoTag(data) {
  return request({
    url: '/video/video-video-tag/delete',
    method: 'post',
    data: data
  })
}
