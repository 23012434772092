<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @ok="submitForm"
           @cancel="onClose"  cancelText="关闭">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="品牌名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入品牌名称" />
      </a-form-model-item>
      <a-form-model-item label="品牌网址" prop="url" >
        <a-input v-model="form.url" placeholder="请输入品牌网址" />
      </a-form-model-item>
<!--      <a-form-model-item label="品牌英文名称" prop="englishName" >-->
<!--        <a-input v-model="form.englishName" placeholder="请输入品牌英文名称" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="品牌首字母" prop="initials" >-->
<!--        <a-input v-model="form.initials" placeholder="请输入品牌首字母" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="品牌logo" prop="logo" >
<!--        <a-input v-model="form.logo" placeholder="请输入品牌logo" />-->
<!--           :action="uploadAction"
          :headers="headers"-->
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"

          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <!--          {{baseimgerver}}-->
          <img v-if="form.logo" :src="form.logo" alt="品牌logo" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M,推荐规格:800*800 </span>
      </a-form-model-item>
      <a-form-model-item label="品牌简介" prop="description" >
        <a-input v-model="form.description" placeholder="请输入品牌简介" />
      </a-form-model-item>
<!--      <a-form-model-item label="品牌关键字" prop="keywords" >-->
<!--        <a-input v-model="form.keywords" placeholder="请输入品牌关键字" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="排序" prop="sort">
        <a-input-number v-model="form.sort" :min="0" style="width: 100%" />
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="isDeleted">

      <a-radio-group v-model="form.isDeleted" button-style="solid">
        <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">
          {{ d.label }}
        </a-radio-button>
      </a-radio-group>
      </a-form-model-item>
<!--      <div class="bottom-control">-->
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
<!--      </div>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import { getBrand, addBrand, updateBrand } from '@/api/shop/brand'
import {getToken} from "@/utils/auth";
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import {Log} from "@/components/crud/Log";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      statusOptions:[{
        label: '是',
        value: 0
      },
        {
          label: '否',
          value: 1
        }],
      // 表单参数
      form: {
        id: null,
        url:null,
        isDeleted: 0,
        remark: null,
        name: null,
        logo: null,
        description: null,
        sort:''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name:[{
          required:true,
          message:'品牌名称不能为空',
          trigger:'blur'
        }],
        logo:[{
          required:true,
          message:'品牌logo不能为空'
        }],
        sort:[{
          required:true,
          message:'sort不能为空'
        }]
      },
      fileUpload:`${process.env.VUE_APP_BASE_API}/upload/image`,
      headers:{},
      baseimgerver : `${process.env.VUE_APP_BASE_API}`,
      uploadLoading:false
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + getToken()
    }
  },
  computed: {
    uploadAction: function () {
      return this.fileUpload;
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    handleChange(info) { //上傳圖片回調
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.logo = info.file.originFileObj.ossUrl;
      }
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        url:null,
        isDeleted: 0,
        name: null,
        logo: null,
        description: null,
        sort:''
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBrand({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBrand(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBrand(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
