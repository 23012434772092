import request from '@/utils/request'


// 查询交友财富等级列表
export function listFriendWealth(query) {
  return request({
    url: '/user/friend-wealth-grade/list',
    method: 'get',
    params: query
  })
}

// 查询交友财富等级分页
export function pageFriendWealth(query) {
  return request({
    url: '/user/friend-wealth-grade/page',
    method: 'get',
    params: query
  })
}

// 查询交友财富等级详细
export function getFriendWealth(data) {
  return request({
    url: '/user/friend-wealth-grade/detail',
    method: 'get',
    params: data
  })
}

// 新增交友财富等级
export function addFriendWealth(data) {
  return request({
    url: '/user/friend-wealth-grade/add',
    method: 'post',
    data: data
  })
}

// 修改交友财富等级
export function updateFriendWealth(data) {
  return request({
    url: '/user/friend-wealth-grade/edit',
    method: 'post',
    data: data
  })
}

// 删除交友财富等级
export function delFriendWealth(data) {
  return request({
    url: '/user/friend-wealth-grade/delete',
    method: 'post',
    data: data
  })
}
