<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="规格名称">
                <a-input v-model="queryParam.specName" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
<!--            <template v-if="advanced">-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="状态">-->
<!--                  <a-select placeholder="请选择" v-model="queryParam.status" style="width: 100%" allow-clear>-->
<!--                    <a-select-option v-for="(d, index) in dict.type['sys_normal_disable']" :key="index"-->
<!--                      :value="d.value">{{ d.label }}</a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--            </template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'" />-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:goodsspec:add']">
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"-->
<!--          v-hasPermi="['system:articlecate:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:articlecate:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"-->
<!--          :refresh-loading="loading" @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :statusOptions="dict.type['sys_normal_disable']" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :pagination="false" :bordered="tableBordered">
        <span slot="specstr" slot-scope="text, record">
          <div v-for="(item, index) in record.specs" :key="index">{{ item.specstr }}</div>
        </span>

        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:goodsspec:edit']">
<!--            <a-icon type="edit" />-->
<!--            修改-->
            编辑
          </a>
          <a-divider type="vertical" v-hasPermi="['shop:goodsspec:delete']" />
          <a @click="handleDelete(record)" >
<!--            <a-icon type="delete" />-->
            删除
          </a>
<!--             <a-popconfirm title="确认删除吗?" @confirm="() => handleDelete(record)" v-hasPermi="['system:articlecate:remove']">-->
<!--                  <a>刪除</a>-->
<!--                </a-popconfirm>-->
<!--          <a @click="handleArticleList(record)" v-hasPermi="['system:articlecate:remove']">-->
<!--            <a-icon type="eye" />查看商品-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
// import * as article from '@/api/mall/article'
import * as goodsspec from '@/api/mall/goodsspec'
import GenInfoForm from '@/views/tool/gen/modules/GenInfoForm.vue'
import {delDelivery} from "@/api/shop/delivery";

export default {
  name: 'Post',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        specName: null,
        status: undefined
      },
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          customRender:function (text, record, index) {
           return index;
          }
        },
        {
          title: '规格名称',
          dataIndex: 'specName',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '是否可视',
          dataIndex: 'isVisible',
          ellipsis: true,
          align: 'center',
          customRender:function (text) {
            if(text){
              return "是";
            }else{
              return "否";
            }
          }
        },
        {
          title: '排序',
          align: "center",
          dataIndex: 'sort'
        },
        {
          title: '规格说明',
          align: "center",
          dataIndex: 'specDes'
        },
        // {
        //   title: '规格属性',
        //   dataIndex: 'specstr',
        //   scopedSlots: { customRender: 'specstr' },
        //   align: 'left'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询部门列表 */
    getList() {

      this.loading = true
      goodsspec.list(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        specName:null
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.postId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      // var that = this
      // const postIds = [row.id] || this.ids
      // goodsspec.del(postIds)
      //   .then(() => {
      //     that.onSelectChange([], [])
      //     that.getList()
      //     that.$message.success(
      //       '删除成功',
      //       3
      //     )
      //   })

      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return  goodsspec.del(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 查看当前分类下的 文章列表 **/
    handleArticleList(info) {
      this.$router.push({
        path: '/mall/mall/goods/index',
        query: { id: info.id }
      })
    }
  }
}
</script>
