<template>
  <a-modal :title="formTitle" :width="800" :visible="open" @ok="submitForm"
           @cancel="onClose"  cancelText="关闭">
<!--    <a-divider orientation="left">-->
<!--      <b>{{ formTitle }}</b>-->
<!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="${comment}" prop="orderId" >
        <a-input v-model="form.orderId" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="订单编号" prop="orderNo" >
        <a-input v-model="form.orderNo" placeholder="请输入订单编号" />
      </a-form-model-item>
      <a-form-model-item label="${comment}" prop="orderGoodsId" >
        <a-input v-model="form.orderGoodsId" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="${comment}" prop="goodsId" >
        <a-input v-model="form.goodsId" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="商品编号" prop="goodsCode" >
        <a-input v-model="form.goodsCode" placeholder="请输入商品编号" />
      </a-form-model-item>
      <a-form-model-item label="商品名称" prop="goodsName" >
        <a-input v-model="form.goodsName" placeholder="请输入商品名称" />
      </a-form-model-item>
      <a-form-model-item label="商品价格" prop="goodsPrice" >
        <a-input v-model="form.goodsPrice" placeholder="请输入商品价格" />
      </a-form-model-item>
      <a-form-model-item label="商品图片" prop="goodsImage" >
        <file-upload v-model="form.goodsImage" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="规格名称" prop="skuName" >
        <a-input v-model="form.skuName" placeholder="请输入规格名称" />
      </a-form-model-item>
      <a-form-model-item label="${comment}" prop="shopId" >
        <a-input v-model="form.shopId" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName" >
        <a-input v-model="form.shopName" placeholder="请输入店铺名称" />
      </a-form-model-item>
      <a-form-model-item label="是否自营  0：否 1：是" prop="goodsType" >
      </a-form-model-item>
      <a-form-model-item label="评价内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="评价图片" prop="image" >
        <file-upload v-model="form.image" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="解释内容" prop="explainFirst" >
        <a-input v-model="form.explainFirst" placeholder="请输入解释内容" />
      </a-form-model-item>
      <a-form-model-item label="评价人名称" prop="memberName" >
        <a-input v-model="form.memberName" placeholder="请输入评价人名称" />
      </a-form-model-item>
      <a-form-model-item label="点赞数量" prop="number" >
        <a-input v-model="form.number" placeholder="请输入点赞数量" />
      </a-form-model-item>
      <a-form-model-item label="${comment}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="头像" prop="avatar" >
        <a-input v-model="form.avatar" placeholder="请输入头像" />
      </a-form-model-item>
      <a-form-model-item label="0表示不是 1表示是匿名评价" prop="isAnonymous" >
        <a-input v-model="form.isAnonymous" placeholder="请输入0表示不是 1表示是匿名评价" />
      </a-form-model-item>
      <a-form-model-item label="评价星级" prop="evaluateScores" >
        <a-input v-model="form.evaluateScores" placeholder="请输入评价星级" />
      </a-form-model-item>
      <a-form-model-item label="物流服务" prop="qualityScores" >
        <a-input v-model="form.qualityScores" placeholder="请输入物流服务" />
      </a-form-model-item>
      <a-form-model-item label="服务评分" prop="serviceScores" >
        <a-input v-model="form.serviceScores" placeholder="请输入服务评分" />
      </a-form-model-item>
      <a-form-model-item label="追加评价内容" prop="againContent" >
        <editor v-model="form.againContent" />
      </a-form-model-item>
      <a-form-model-item label="追加评价图片" prop="againImage" >
        <file-upload v-model="form.againImage" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="追加解释内容" prop="againExplain" >
        <a-input v-model="form.againExplain" placeholder="请输入追加解释内容" />
      </a-form-model-item>
      <a-form-model-item label="1好评2中评3差评" prop="explainType" >
      </a-form-model-item>
      <a-form-model-item label="1显示 0隐藏" prop="isShow" >
        <a-input v-model="form.isShow" placeholder="请输入1显示 0隐藏" />
      </a-form-model-item>
      <a-form-model-item label="追加评价时间" prop="againAddtime" >
        <a-date-picker style="width: 100%" v-model="form.againAddtime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="评价视频" prop="videoUrl" >
        <a-input v-model="form.videoUrl" placeholder="请输入评价视频" />
      </a-form-model-item>
      <a-form-model-item label="评价类型.0:文字；1:图片；2:视频" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="视频封面图" prop="coverImage" >
        <file-upload v-model="form.coverImage" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="评价面向：0商品，1店铺" prop="faceTo" >
        <a-input v-model="form.faceTo" placeholder="请输入评价面向：0商品，1店铺" />
      </a-form-model-item>
      <a-form-model-item label="置顶：0、未置顶   1、置顶" prop="pushTop" >
        <a-input v-model="form.pushTop" placeholder="请输入置顶：0、未置顶   1、置顶" />
      </a-form-model-item>
      <a-form-model-item label="是否虚拟评价：0、普通用户   1、虚拟用户" prop="inventEvaluate" >
        <a-input v-model="form.inventEvaluate" placeholder="请输入是否虚拟评价：0、普通用户   1、虚拟用户" />
      </a-form-model-item>
      <a-form-model-item label="店主回复" prop="replyContent" >
        <editor v-model="form.replyContent" />
      </a-form-model-item>
      <a-form-model-item label="回复时间" prop="replyTime" >
        <a-date-picker style="width: 100%" v-model="form.replyTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="是否回复 0否 1是" prop="replyFlag" >
        <a-input v-model="form.replyFlag" placeholder="请输入是否回复 0否 1是" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getGoodsEvaluate, addGoodsEvaluate, updateGoodsEvaluate } from '@/api/shop/goodsEvaluate'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        orderId: null,

        orderNo: null,

        orderGoodsId: null,

        goodsId: null,

        goodsCode: null,

        goodsName: null,

        goodsPrice: null,

        goodsImage: null,

        skuName: null,

        shopId: null,

        shopName: null,

        goodsType: null,

        content: null,

        image: null,

        explainFirst: null,

        memberName: null,

        number: null,

        userId: null,

        avatar: null,

        isAnonymous: null,

        evaluateScores: null,

        qualityScores: null,

        serviceScores: null,

        againContent: null,

        againImage: null,

        againExplain: null,

        explainType: null,

        isShow: null,

        againAddtime: null,

        videoUrl: null,

        type: null,

        coverImage: null,

        faceTo: null,

        pushTop: null,

        inventEvaluate: null,

        replyContent: null,

        replyTime: null,

        replyFlag: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        orderNo: [
          { required: true, message: '订单编号不能为空', trigger: 'blur' }
        ],
        goodsCode: [
          { required: true, message: '商品编号不能为空', trigger: 'blur' }
        ],
        goodsName: [
          { required: true, message: '商品名称不能为空', trigger: 'blur' }
        ],
        goodsPrice: [
          { required: true, message: '商品价格不能为空', trigger: 'blur' }
        ],
        goodsImage: [
          { required: true, message: '商品图片不能为空', trigger: 'blur' }
        ],
        shopName: [
          { required: true, message: '店铺名称不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '评价内容不能为空', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '评价图片不能为空', trigger: 'blur' }
        ],
        memberName: [
          { required: true, message: '评价人名称不能为空', trigger: 'blur' }
        ],
        isAnonymous: [
          { required: true, message: '0表示不是 1表示是匿名评价不能为空', trigger: 'blur' }
        ],
        evaluateScores: [
          { required: true, message: '评价星级不能为空', trigger: 'blur' }
        ],
        qualityScores: [
          { required: true, message: '物流服务不能为空', trigger: 'blur' }
        ],
        serviceScores: [
          { required: true, message: '服务评分不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        orderId: null,
        orderNo: null,
        orderGoodsId: null,
        goodsId: null,
        goodsCode: null,
        goodsName: null,
        goodsPrice: null,
        goodsImage: null,
        skuName: null,
        shopId: null,
        shopName: null,
        goodsType: null,
        content: null,
        image: null,
        explainFirst: null,
        memberName: null,
        number: null,
        userId: null,
        avatar: null,
        isAnonymous: null,
        evaluateScores: null,
        qualityScores: null,
        serviceScores: null,
        againContent: null,
        againImage: null,
        againExplain: null,
        explainType: null,
        isShow: null,
        againAddtime: null,
        videoUrl: null,
        type: null,
        coverImage: null,
        faceTo: null,
        pushTop: null,
        inventEvaluate: null,
        replyContent: null,
        replyTime: null,
        replyFlag: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGoodsEvaluate({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGoodsEvaluate(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGoodsEvaluate(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
