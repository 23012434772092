<template>
  <a-modal :title="formTitle" :width="800" :visible="open"
           @cancel="onClose" cancelText="关闭"  :footer="null">
    <a-card :bordered="false">
      <!--订单状态步骤条 start-->
      <a-steps v-if="record.orderStatus!=0 && record.orderStatus!=6" :current="record.orderStatus"
               size="small">
        <a-step>
          <template slot="title">
            买家下单
          </template>
          <span slot="description">{{ record.createTime || '' }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            买家付款
          </template>
          <span slot="description">{{ record.payTime || '' }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            发货
          </template>
          <span slot="description">{{ record.consignTime || '' }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            买家确认收货
          </template>
          <span slot="description">{{ record.affirmTime || '' }}</span>
        </a-step>
        <a-step>
          <!-- <span slot="title">Finished</span> -->
          <template slot="title">
            交易完成
          </template>
          <span slot="description">{{ record.finishTime || '' }}</span>
        </a-step>
      </a-steps>
      <!--订单状态步骤条 end-->

      <div class="order-info">
        <table>
          <tr>
            <td style="width: 310px;vertical-align: top;background-color:#fbfbfb;">
              <a-card size="small" :bordered="false" title="订单信息"
                      :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                      :bodyStyle="{'background-color':'#fbfbfb'}">
                <table class="info-table">
                  <tr>
                    <td class="first">收货地址：</td>
                    <td class="data">
                      {{ record.receiverName }},
                      {{ record.receiverMobile }},
                      {{
                        record.receiverProvince + ' ' + record.receiverCity + ' ' +
                        record.receiverDistrict + ' ' + record.receiverAddress
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="first">买家留言：</td>
                    <td class="data">{{ record.buyerMessage || '-' }}</td>
                  </tr>
                  <tr>
                    <td class="first">订单编号：</td>
                    <td class="data">
                      {{ record.orderNo }}
                      <a-dropdown placement="bottomCenter">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                          更多
                          <a-icon type="down"/>
                        </a>

                        <div slot="overlay"
                             style="background-color: #FBFBFB;border: 1px solid #ddd;width: 250px;padding: 5px 10px;">
                          <table>
                            <tr>
                              <td>成交时间：</td>
                              <td>{{ record.createTime }}</td>
                            </tr>
                            <tr v-if="record.payTime">
                              <td>付款时间：</td>
                              <td>{{ record.payTime }}</td>
                            </tr>
                            <tr v-if="record.consignTime">
                              <td>发货时间：</td>
                              <td>{{ record.consignTime }}</td>
                            </tr>
                          </table>
                        </div>
                      </a-dropdown>
                    </td>
                  </tr>
                  <tr>
                    <td class="first">买家：</td>
                    <td class="data">{{ record.userName }}</td>
                  </tr>
                </table>
              </a-card>
            </td>

            <td style="border-left: 1px solid #ddd;">
              <a-card :bordered="false">
                <div class="detailPanel" style="margin:13px 10px;width: 100%;height: auto;">
                  <dl>
                    <dt>
                      <img
                        :src="record.orderStatus!=4 && record.orderStatus!=5?'/orderInfo/exclamation-point.png':'/orderInfo/success.png'">
                    </dt>
                    <dd>
                      <h3>订单状态:{{ record.orderStatus | statusFilter }}</h3>
                    </dd>
                  </dl>

                  <ul class="trade-detail-prompt">

                    <li v-if="record.orderStatus==0">
                      <span class="dotted-node">&nbsp;</span>
                      原因：{{ record.cancelOrderDescLabel }}
                    </li>

                    <li v-if="record.orderStatus==1">
                      <span class="dotted-node">&nbsp;</span>
                      买家还有<span style="color: red;font-weight: bold;"><count-down
                      :end-time="record.lastPayTime"/></span>;来付款,超时订单自动关闭
                    </li>

                    <li v-if="record.orderStatus==3">
                      <span class="dotted-node">&nbsp;</span>
                      买家还有<span style="color: red;font-weight: bold;"><count-down :end-time="endaffirmDate"/></span>;来确认收货,超时订单自动确认收货
                    </li>

                    <li v-if="record.orderStatus==3||record.orderStatus==4||record.orderStatus==5">
                      <span class="dotted-node">&nbsp;</span>
                    </li>
                  </ul>
                </div>
              </a-card>
            </td>
          </tr>
        </table>
      </div>
      <a-table
        style="margin-top:20px;"
        rowKey="id"
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        size="middle"
        bordered>
        <!--商品信息-->
        <span slot="goodsInfo" slot-scope="text, record">
            <div>
              <div style="float: left;">
                <img style="width: 50px;height: 50px;"
                     :src="record.goodsPicture"
                     preview="商品主图"
                />
              </div>
              <div style="float: left;margin-left: 10px;">
                <div class="ellipsis" style="width: 280px;text-align: left;">{{ record.goodsName }}</div>
                <div class="ellipsis"
                     style="font-size:12px;color:#acacac;width: 280px;text-align: left;">ID:{{ record.goodsId }} <template
                  v-if="record.goodsCode">编号:{{ record.goodsCode }}</template></div>
              </div>
              <div style="clear: both;"></div>
            </div>
        </span>
      </a-table>

      <div style="margin-top:20px;background-color: #f3f3f3;">
        <table class="order-money">
          <tr>
            <td class="om-c1">商品总价：</td>
            <td class="om-c1">￥{{ record.goodsMoney }}元</td>
            <td v-if="record.orderBeans != 0.0" class="om-c1">+{{ record.goodsBeans }}嗨豆</td>
          </tr>
          <tr>
            <td class="om-c1">运费（快递）：</td>
            <td class="om-c1">￥{{ record.shippingMoney }}</td>
          </tr>
          <tr>
            <td class="om-c2">订单总价：</td>
            <td class="om-c2">{{ record.goodsMoney + record.shippingMoney }}</td>
            <td v-if="record.orderBeans != 0.0" class="om-c2">+{{ record.orderBeans}}嗨豆</td>
          </tr>
<!--          <tr v-if="record.couponMoney>0">-->
<!--            <td class="om-c2">优惠券减免金额：</td>-->
<!--            <td class="om-c2">{{ this.record.couponMoney }}</td>-->
<!--          </tr>-->
<!--          <tr v-if="record.remissionShippingMoney>0">-->
<!--            <td class="om-c2">运费减免金额：</td>-->
<!--            <td class="om-c2">{{ this.record.remissionShippingMoney }}</td>-->
<!--          </tr>-->
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr v-if="record.orderStatus!=0">
            <td class="om-c2">{{ record.orderStatus >= 4 ? '实收款' : '应收款' }}：</td>
            <td class="om-c3">￥{{ record.payMoney }}</td>
          </tr>
        </table>
        <div style="clear: both;"></div>
      </div>
    </a-card>
  </a-modal>
</template>

<script>
import {detailOrder} from "@/api/order/order";
import {pageOrderList} from "@/api/order/ordergoods";
import moment from "moment"
import countDown from "@/components/Other/CountDown";

export default {
  name: "OrderInfoModal",
  components: {
    countDown
  },
  data() {
    return {
      open: false,
      formTitle: '订单详情',
      record: {},
      endaffirmDate:'',
      columns: [
        {
          title: '商品',
          align: "center",
          dataIndex: 'goodsName',
          width: 400,
          scopedSlots: {customRender: 'goodsInfo'},
        },
        {
          title: '单价',
          align: "center",
          dataIndex: 'price',
        },
        {
          title: '嗨豆',
          align: "center",
          dataIndex: 'beans',
        },
        {
          title: '数量',
          align: "center",
          dataIndex: 'num'
        },
        // {
        //   title: '优惠',
        //   align: "center",
        //   dataIndex: 'goodsMoney',
        //   customRender: function (t, r, index) {
        //     if (r.couponMoney > 0) {
        //       return "优惠券减免金额" + r.couponMoney;
        //     }
        //
        //     if (r.coinTotal > 0) {
        //       return "爱旅币减免金额" + r.coinTotal;
        //     }
        //     return '暂无优惠';
        //   }
        // },
        {
          title: '状态',
          align: "center",
          dataIndex: 'refundStatus',
          customRender: function (t, r, index) {
            if (t != 0) {
              switch (t) {
                case 1:
                  return "申请中";
                case 2:
                  return "已拒绝退款";
                case 3:
                  return "退款成功";
                case 5:
                  return "等待卖家处理退货申请";
                case 6:
                  return "等待买家发货";
                case 7:
                  return "等待卖家收货";
                case 8:
                  return "退款中";
              }
            }
            //订单状态1待付款 2待发货 3待收货 4待评价 5已完成 6已关闭 0已取消
            switch (r.orderStatus) {
              case 0:
                return "已取消";
              case 1:
                return "待付款";
              case 2:
                return "待发货";
              case 3:
                return "待收货";
              case 4:
                return "待评价";
              case 5:
                return "已完成";
              case 6:
                return "已关闭";
            }

          }
        }
      ],
      data: [],
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: '交易取消',
        1: '商品已拍下，等待买家付款',
        2: '买家已付款，等待商家发货',
        3: '商家已发货，等待买家确认',
        4: '交易成功',
        5: '已完成',
        6: '交易关闭'
      };
      return statusMap[status]
    }
  },
  methods:{
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },

    /**
     * 打开弹窗
     * @param id 订单id
     */
    openModal(id) {
      detailOrder({
        id:id
      }).then(response => {
        if(response.code == 20001) {
          this.record = response.data;
          pageOrderList({
            orderId:id
          }).then(res => {
            if(res.code == 20001) {
              this.data = res.data
              this.open = true
            } else {
              this.$message.warning(res.msg)
            }
          })

        } else {
          this.$message.warning(response.msg)
        }
      })
      this.getEndaffirmDate();
    },
    async getEndaffirmDate() {
      //发货时间
      var consignTime = this.record.consignTime;
      if (!consignTime) {
        return
      }
      //获取多少天后自动确认收货
      let names = "date";
      let res = await getAction("/config/config/queryByConfigGroup", {'configGroup': names})
      debugger
      if (res.success) {
        this.endaffirmDate = moment(consignTime).add(res.result.affirmDate, "day").format("YYYY-MM-DD HH:mm:ss");
      }
    },
    reset() {

    },
    /**
     * ok
     */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          /**
           * TODO:添加备注
           */
          this.open = false
          this.$emit('ok')
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}

.addressshow2 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.addressshow1 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    td {
      display: table-cell;
      vertical-align: top;
      font-size: 13px;
    }

    .first {
      width: 85px;
    }

    .data {
      width: 215px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        background: url(/img/orderInfo/dotted.png) 50% 50% no-repeat;
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>