import request from '@/utils/request'


// 查询创业vip开通订单列表
export function listVipOrder(query) {
  return request({
    url: '/distribution/distribution-vip-order/list',
    method: 'get',
    params: query
  })
}

// 查询创业vip开通订单分页
export function pageVipOrder(query) {
  return request({
    url: '/distribution/distribution-vip-order/page',
    method: 'get',
    params: query
  })
}

// 查询创业vip开通订单详细
export function getVipOrder(data) {
  return request({
    url: '/distribution/distribution-vip-order/detail',
    method: 'get',
    params: data
  })
}

// 新增创业vip开通订单
export function addVipOrder(data) {
  return request({
    url: '/distribution/distribution-vip-order/add',
    method: 'post',
    data: data
  })
}

// 修改创业vip开通订单
export function updateVipOrder(data) {
  return request({
    url: '/distribution/distribution-vip-order/edit',
    method: 'post',
    data: data
  })
}

// 删除创业vip开通订单
export function delVipOrder(data) {
  return request({
    url: '/distribution/distribution-vip-order/delete',
    method: 'post',
    data: data
  })
}
