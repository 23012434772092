import request from '@/utils/request'


// 查询主播列表
export function listAnchor(query) {
  return request({
    url: '/live/live-anchor/list',
    method: 'get',
    params: query
  })
}

// 查询主播分页
export function pageAnchor(query) {
  return request({
    url: '/live/live-anchor/page',
    method: 'get',
    params: query
  })
}

// 查询主播详细
export function getAnchor(data) {
  return request({
    url: '/live/live-anchor/detail',
    method: 'get',
    params: data
  })
}

// 新增主播
export function addAnchor(data) {
  return request({
    url: '/live/live-anchor/add',
    method: 'post',
    data: data
  })
}

// 修改主播
export function updateAnchor(data) {
  return request({
    url: '/live/live-anchor/edit',
    method: 'post',
    data: data
  })
}

// 删除主播
export function delAnchor(data) {
  return request({
    url: '/live/live-anchor/delete',
    method: 'post',
    data: data
  })
}
