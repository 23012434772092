<template xmlns:font-size="http://www.w3.org/1999/xhtml">
  <a-drawer
    :title="title"
    :width="577"
    @close="onClose"
    :visible="visible"
    :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
  >
    <a-card title="店铺申请详情" :bordered="false">
      <div class="content-block">
        <div>姓名：</div>
        <div>{{model.realName}}</div>
      </div>
      <div class="content-block">
        <div>手机号：</div>
        <div>{{model.mobile}}</div>
      </div>
      <div class="content-block">
        <div>身份证号：</div>
        <div>{{model.idCard}}</div>
      </div>
      <div class="content-block" v-if="model.idCardFront">
        <div>身份证正面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.idCardFront' alt='身份证正面' />
        </div>
      </div>
      <div class="content-block" v-if="model.idCardReverse" style="margin-top: 20px">
        <div>身份证反面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.idCardReverse' alt='身份证反面' />
        </div>
      </div>
      <div class="content-block">
        <div>公司名称：</div>
        <div>{{model.companyName}}</div>
      </div>
      <div class="content-block">
        <div>联系地址：</div>
        <div>{{model.companyAddress}}</div>
      </div>
      <div class="content-block">
        <div>公司地址：</div>
        <div>{{model.companyAddress}}</div>
      </div>
      <div class="content-block">
        <div>统一社会信用码：</div>
        <div>{{model.creditCode}}</div>
      </div>
      <div class="content-block">
        <div>营业执照/统一社会信用证代码证书：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.businessLicense' alt='营业执照电子版' />
        </div>
      </div>
      <div class="content-block">
        <div>法定经营范围：</div>
        <div>{{model.businessScope}}</div>
      </div>
      <div class="content-block">
        <div>法人姓名：</div>
        <div>{{model.legalPersonName}}</div>
      </div>
      <div class="content-block">
        <div>法人手机：</div>
        <div>{{model.legalPersonPhone}}</div>
      </div>
      <div class="content-block">
        <div>法人身份证：</div>
        <div>{{model.legalPersonIdCard}}</div>
      </div>
      <div class="content-block">
        <div>法人身份证正面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.legalPersonIdCardFront' alt='法人身份证正面' />
        </div>
      </div>
      <div class="content-block"  style="margin-top: 20px">
        <div>法人身份证反面：</div>
        <div class="anty-img-wrap">
          <img style="width: 259px;height: 161px" :src='model.legalPersonIdCardReverse' alt='法人身份证反面' />
        </div>
      </div>

      <div class="content-block">
        <div>商家名称：</div>
        <div>{{model.shopName}}</div>
      </div>
      <div class="content-block">
        <div>商家logo：</div>
        <div class="anty-img-wrap">
          <img style="width: 72px;height: 72px;" :src='model.shopLogo' alt='商家logo' />
        </div>
      </div>
      <div class="content-block" style="margin-top: 20px">
        <div>商家背景：</div>
        <div class="anty-img-wrap">
          <img style="width: 375px;height: 135px;" :src='model.shopBackdrop' alt='商家背景' />
        </div>
      </div>
      <div class="content-block">
        <div>省市区：</div>
        <div>{{model.shopAreap}}-{{model.shopAreac}}-{{model.shopAreax}}</div>
      </div>
      <div class="content-block">
        <div>详细地址：</div>
        <div>{{model.shopAddressDetail}}</div>
      </div>
<!--      <div class="content-block">-->
<!--        <div>地图选点：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->
      <div class="content-block">
        <div>联系人：</div>
        <div>{{model.realName}}</div>
      </div>
      <div class="content-block">
        <div>联系电话：</div>
        <div>{{model.mobile}}</div>
      </div>
      <div class="content-block">
        <div>客服电话：</div>
        <div>{{model.kfPhone}}</div>
      </div>
      <div class="content-block">
        <div>所属行业：</div>
        <div>{{model.tradeName}}</div>
      </div>
<!--      <div class="content-block">-->
<!--        <div>店铺套餐：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->
      <div class="content-block">
        <div>店铺类型：</div>
        <div>{{model.applyType | filterApplyType}}</div>
      </div>

      <div class="content-block">
        <div>客服电话：</div>
        <div>{{model.kfPhone}}</div>
      </div>
<!--      <div class="content-block">-->
<!--        <div>工作日：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->
<!--      <div class="content-block">-->
<!--        <div>营业时间：</div>-->
<!--        <div>{{model.updateTime}}</div>-->
<!--      </div>-->
      <div class="content-block">
        <div>结算类型：</div>
        <div>{{model.bankType | filterBankType}}</div>
      </div>
      <div class="content-block">
        <div>结算开户名：</div>
        <div>{{model.bankRealName}}</div>
      </div>
      <div class="content-block">
        <div>结算银行账号：</div>
        <div>{{model.bankNo}}</div>
      </div>
      <div class="content-block">
        <div>结算开户银行支行名称：</div>
        <div>{{model.bankName}}</div>
      </div>
      <div class="content-block">
        <div>结算开户银行所在地：</div>
        <div>{{model.bankAddress}}</div>
      </div>
      <div class="content-block">
        <div>打款金额：</div>
        <div>{{model.openMoney}}</div>
      </div>
      <div class="content-block">
        <div>打款截图：</div>
        <img v-if="model.screenshot" style="width: 259px;height: 161px" :src='model.screenshot' alt='打款截图' />
      </div>
      <div class="content-block">
        <div>打款备注：</div>
        <div>{{model.payNote}}</div>
      </div>
    </a-card >
  </a-drawer>
</template>

<script>
import { getShopApply, addShopApply, updateShopApply } from '@/api/shop/shopApply'
export default {
  name: "Details",
  props: {
  },
  components: {
  },
  data() {
    return {
      title: "详情",
      visible: false,
      model: {},
      labelCol: {
        xs: {span: 24},
        sm: {span: 5},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
      uploadLoading: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      url: {
        imgerver: window._CONFIG['fileURL'],
      },
    }
  },
  created() {
  },
  filters: {
    filterBankType(val) {
      if(val == 1) {
        return '借记卡'
      } else if(val == 2) {
        return '企业户'
      } else if(val == 3) {
        return '二类户'
      } else if(val == 4) {
        return '三类户'
      } else {
        return '暂无'
      }
    },
    filterApplyType(val) {
      if(val == 1) {
        return '平台直营'
      } else if(val == 2) {
        return '商家自营'
      }else {
        return '暂无'
      }
    }
  },
  methods: {
    show(record) {
      debugger
      this.form.resetFields();
      this.visible = true;
      this.model = record;
      this.$nextTick(() => {
      });
    },
    onClose() {
      this.visible = false
    },

  }
}
</script>

<style lang="less" scoped>
.content-block {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  line-height: 37px;
  flex-wrap: wrap;
}
</style>
