<template>

</template>

<script>
import useTagsViewStore from '@/store/modules/tagsView';
export default {
  name: "cateBlack" ,
  created() {
    this.$router.replace({ path:'/mall/goodscate' });
    // const obj = { path: "/mall/CateBlack" }
    // useTagsViewStore.mutations.DEL_OTHERS_CACHED_VIEWS({
    //   name:'cateBlack'
    // });
  }

}
</script>

<style scoped>

</style>