<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="ios平台产品id" prop="iosProductId">-->
<!--                <a-input v-model="queryParam.iosProductId" placeholder="请输入ios平台产品id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">

<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="价格, 单位: 分" prop="price">-->
<!--                  <a-input v-model="queryParam.price" placeholder="请输入价格, 单位: 分" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="原价/划线价, 单位: 分" prop="rawPrice">-->
<!--                  <a-input v-model="queryParam.rawPrice" placeholder="请输入原价/划线价, 单位: 分" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="获得VIP天数" prop="day">-->
<!--                  <a-input v-model="queryParam.day" placeholder="请输入获得VIP天数" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="获得金豆数量" prop="goldenBeansCount">-->
<!--                  <a-input v-model="queryParam.goldenBeansCount" placeholder="请输入获得金豆数量" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="排序, asc" prop="sort">-->
<!--                  <a-input v-model="queryParam.sort" placeholder="请输入排序, asc" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['vip:friend/combo:add']">
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['vip:friend/combo:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['vip:friend/combo:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['vip:friend/combo:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <a-switch slot="isEnabled" slot-scope="text, record" @click="updateGoodsState(record,1)" v-model="record.isEnabled">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>

        <a-switch slot="isRecommend" slot-scope="text, record" @click="updateGoodsState(record,2)" v-model="record.isRecommend">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>

        <a-switch slot="isGreatDeals" slot-scope="text, record" @click="updateGoodsState(record,3)" v-model="record.isGreatDeals">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['vip:friend/combo:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['vip:friend/combo:edit']">
<!--            <a-icon type="edit" />-->
           编辑
          </a>
          <a-divider type="vertical" v-hasPermi="['vip:friend/combo:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['vip:friend/combo:remove']">
<!--            <a-icon type="delete" />-->
            删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageFriendCombo, listFriendCombo, delFriendCombo, changeStatusVip} from '@/api/vip/friendCombo'
import CreateForm from './modules/CreateModalForm'
import { tableMixin } from '@/store/table-mixin'
import {saveFlag} from "@/api/banner/banner";

export default {
  name: 'FriendCombo',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        platform: null,
        iosProductId: null,
        name: null,
        price: null,
        rawPrice: null,
        day: null,
        goldenBeansCount: null,
        sort: null,
        isRecommend: null,
        isGreatDeals: null,
        isEnabled: null,
        pageNum: 1,
        pageSize: 10,
        sortField: 'sort',
        sortOrder: 'ASCEND'
      },
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          customRender: function (text, record, index) {
            return (index + 1)
          }
        },


        {
          title: '平台',
          dataIndex: 'platform',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if(text == 1) {
              return '安卓'
            } else {
              return 'ios'
            }
          }
        },
        // {
        //   title: 'ios平台产品id',
        //   dataIndex: 'iosProductId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '价格',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '原价/划线价',
          dataIndex: 'rawPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '获得VIP天数',
          dataIndex: 'day',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '获得金豆数量',
          dataIndex: 'goldenBeansCount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '推荐标签',
          dataIndex: 'isRecommend',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'isRecommend' }
        },
        {
          title: '超值优惠标签',
          dataIndex: 'isGreatDeals',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'isGreatDeals' }
        },
        {
          title: '是否启用',
          dataIndex: 'isEnabled',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'isEnabled' }
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '插入时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /**
     * 启用/禁用
     */
    updateGoodsState(record,type) {
      console.log(record)
      let value = 0
      if(type == 1) {
        value = record.isEnabled ? 1 : 0
      }
      if(type == 2) {
        value = record.isRecommend ? 1 : 0
      }
      if(type == 3) {
        value =  record.isGreatDeals ? 1 : 0
      }
      changeStatusVip({
        id:record.id,
        type:type,
        value:value
      }).then(res => {
        if(res.code == 20001) {

        } else {
          if(type == 1) {
            record.isEnabled = record.isEnabled ? false : true
          }
          if(type == 2) {
            record.isRecommend = record.isRecommend ? false : true
          }
          if(type == 3) {
            record.isGreatDeals =  record.isGreatDeals ? false : true
          }
          this.$message.warning(res.msg);
        }
      })
    },
    /** 查询交友会员套餐列表 */
    getList () {
      this.loading = true
     pageFriendCombo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        platform: undefined,
        iosProductId: undefined,
        name: undefined,
        price: undefined,
        rawPrice: undefined,
        day: undefined,
        goldenBeansCount: undefined,
        sort: undefined,
        isRecommend: undefined,
        isGreatDeals: undefined,
        isEnabled: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delFriendCombo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('vip/friend/combo/export', {
            ...that.queryParam
          }, `交友会员套餐_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
