<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户类型，0-普通用户，1-主播，2-商家" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="上级用户id，外键(user.id)" prop="topUserId" >
        <a-input v-model="form.topUserId" placeholder="请输入上级用户id，外键(user.id)" />
      </a-form-model-item>
      <a-form-model-item label="用户名/手机号，用于登录" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入用户名/手机号，用于登录" />
      </a-form-model-item>
      <a-form-model-item label="密码，md5" prop="passwd" >
        <a-input v-model="form.passwd" placeholder="请输入密码，md5" />
      </a-form-model-item>
      <a-form-model-item label="头像链接" prop="avatar" >
        <a-input v-model="form.avatar" placeholder="请输入头像链接" />
      </a-form-model-item>
      <a-form-model-item label="昵称" prop="nickname" >
        <a-input v-model="form.nickname" placeholder="请输入昵称" />
      </a-form-model-item>
      <a-form-model-item label="性别,0-保密 1-男 2-女" prop="sex" >
      </a-form-model-item>
      <a-form-model-item label="生日，格式1990-04-22" prop="birthday" >
      </a-form-model-item>
      <a-form-model-item label="身高" prop="height" >
        <a-input v-model="form.height" placeholder="请输入身高" />
      </a-form-model-item>
      <a-form-model-item label="体重" prop="weight" >
        <a-input v-model="form.weight" placeholder="请输入体重" />
      </a-form-model-item>
      <a-form-model-item label="所在城市" prop="city" >
        <a-input v-model="form.city" placeholder="请输入所在城市" />
      </a-form-model-item>
      <a-form-model-item label="所在城市是否保密 1-是 0-否" prop="isCitySecrecy" >
        <a-input v-model="form.isCitySecrecy" placeholder="请输入所在城市是否保密 1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="星座" prop="constellation" >
        <a-input v-model="form.constellation" placeholder="请输入星座" />
      </a-form-model-item>
      <a-form-model-item label="职业" prop="occupation" >
        <a-input v-model="form.occupation" placeholder="请输入职业" />
      </a-form-model-item>
      <a-form-model-item label="婚姻状态，0-保密，1-已婚，2-未婚，3-离异，4-丧偶" prop="maritalStatus" >
      </a-form-model-item>
      <a-form-model-item label="收入情况，0-保密，1-5万以下，2-5-10万，3-10-15万，4-15-20万，5-20-25万，6-50万以上" prop="income" >
        <a-input v-model="form.income" placeholder="请输入收入情况，0-保密，1-5万以下，2-5-10万，3-10-15万，4-15-20万，5-20-25万，6-50万以上" />
      </a-form-model-item>
      <a-form-model-item label="居住情况，0-暂不设置，1-接收婚前同居，2-接收婚后同居，3-保密" prop="dwellCondition" >
        <a-input v-model="form.dwellCondition" placeholder="请输入居住情况，0-暂不设置，1-接收婚前同居，2-接收婚后同居，3-保密" />
      </a-form-model-item>
      <a-form-model-item label="是否实名认证，1-是 0-否" prop="isAuthentication" >
        <a-input v-model="form.isAuthentication" placeholder="请输入是否实名认证，1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="是否真人认证，1-是 0-否" prop="isRealAuthentication" >
        <a-input v-model="form.isRealAuthentication" placeholder="请输入是否真人认证，1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="是否在线，1-是 0-否" prop="isOnline" >
        <a-input v-model="form.isOnline" placeholder="请输入是否在线，1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="是否会员，1-是 0-否" prop="isVip" >
        <a-input v-model="form.isVip" placeholder="请输入是否会员，1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="会员等级，0-普通用户，1-黄金会员，2-白金会员，3-钻石会员，4-至尊会员" prop="vipGrade" >
        <a-input v-model="form.vipGrade" placeholder="请输入会员等级，0-普通用户，1-黄金会员，2-白金会员，3-钻石会员，4-至尊会员" />
      </a-form-model-item>
      <a-form-model-item label="身份等级，0-普通用户，1-科员，2-科长，3-主任，4-经理，5-总经理，6-县级股东，7-地区股东，8-省级股东" prop="rankGrade" >
        <a-input v-model="form.rankGrade" placeholder="请输入身份等级，0-普通用户，1-科员，2-科长，3-主任，4-经理，5-总经理，6-县级股东，7-地区股东，8-省级股东" />
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="invitationCode" >
        <a-input v-model="form.invitationCode" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item label="用户个性签名" prop="userSign" >
        <a-input v-model="form.userSign" placeholder="请输入用户个性签名" />
      </a-form-model-item>
      <a-form-model-item label="是否推送 1-是 0-否" prop="isNotice" >
        <a-input v-model="form.isNotice" placeholder="请输入是否推送 1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email" >
        <a-input v-model="form.email" placeholder="请输入邮箱" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUserAdmin, addUserAdmin, updateUserAdmin } from '@/api/userAdmin/userAdmin'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        topUserId: null,

        mobile: null,

        passwd: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        height: null,

        weight: null,

        city: null,

        isCitySecrecy: null,

        constellation: null,

        occupation: null,

        maritalStatus: 0,

        income: null,

        dwellCondition: null,

        isAuthentication: null,

        isRealAuthentication: null,

        isOnline: null,

        isVip: null,

        vipGrade: null,

        rankGrade: null,

        invitationCode: null,

        userSign: null,

        isNotice: null,

        status: 0,

        createTime: null,

        remark: null,

        email: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '用户类型，0-普通用户，1-主播，2-商家不能为空', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '用户名/手机号，用于登录不能为空', trigger: 'blur' }
        ],
        passwd: [
          { required: true, message: '密码，md5不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '头像链接不能为空', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '昵称不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        isCitySecrecy: [
          { required: true, message: '所在城市是否保密 1-是 0-否不能为空', trigger: 'blur' }
        ],
        maritalStatus: [
          { required: true, message: '婚姻状态，0-保密，1-已婚，2-未婚，3-离异，4-丧偶不能为空', trigger: 'blur' }
        ],
        income: [
          { required: true, message: '收入情况，0-保密，1-5万以下，2-5-10万，3-10-15万，4-15-20万，5-20-25万，6-50万以上不能为空', trigger: 'blur' }
        ],
        dwellCondition: [
          { required: true, message: '居住情况，0-暂不设置，1-接收婚前同居，2-接收婚后同居，3-保密不能为空', trigger: 'blur' }
        ],
        isAuthentication: [
          { required: true, message: '是否实名认证，1-是 0-否不能为空', trigger: 'blur' }
        ],
        isRealAuthentication: [
          { required: true, message: '是否真人认证，1-是 0-否不能为空', trigger: 'blur' }
        ],
        isOnline: [
          { required: true, message: '是否在线，1-是 0-否不能为空', trigger: 'blur' }
        ],
        isVip: [
          { required: true, message: '是否会员，1-是 0-否不能为空', trigger: 'blur' }
        ],
        vipGrade: [
          { required: true, message: '会员等级，0-普通用户，1-黄金会员，2-白金会员，3-钻石会员，4-至尊会员不能为空', trigger: 'blur' }
        ],
        rankGrade: [
          { required: true, message: '身份等级，0-普通用户，1-科员，2-科长，3-主任，4-经理，5-总经理，6-县级股东，7-地区股东，8-省级股东不能为空', trigger: 'blur' }
        ],
        userSign: [
          { required: true, message: '用户个性签名不能为空', trigger: 'blur' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        topUserId: null,
        mobile: null,
        passwd: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        height: null,
        weight: null,
        city: null,
        isCitySecrecy: null,
        constellation: null,
        occupation: null,
        maritalStatus: 0,
        income: null,
        dwellCondition: null,
        isAuthentication: null,
        isRealAuthentication: null,
        isOnline: null,
        isVip: null,
        vipGrade: null,
        rankGrade: null,
        invitationCode: null,
        userSign: null,
        isNotice: null,
        status: 0,
        createTime: null,
        remark: null,
        email: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserAdmin({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUserAdmin(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUserAdmin(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
