<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="品牌名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入品牌名称" allow-clear/>
              </a-form-item>
            </a-col>
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="分类" prop="name">-->
            <!--                <a-input v-model="queryParam.name" placeholder="请输入品牌名称" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="状态" prop="isEnable">
                <a-select placeholder="请选择" v-model="queryParam.isEnable" style="width: 100%" allow-clear>
                  <a-select-option
                    :value="1">正常
                  </a-select-option>
                  <a-select-option
                    :value="0">禁用
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!--            <template v-if="advanced">-->
            <!--        -->
            <!--            </template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
                <!--                  {{ advanced ? '收起' : '展开' }}-->
                <!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
                <!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:brand:add']">
          <a-icon type="plus"/>
          新增
        </a-button>

        <a-button type="primary" @click="handleSaveSort" v-hasPermi="['shop:brand:add']">
          保存排序
        </a-button>
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:brand:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:brand:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:brand:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <!--        <table-setting-->
        <!--          :style="{float: 'right'}"-->
        <!--          :table-size.sync="tableSize"-->
        <!--          v-model="columns"-->
        <!--          :refresh-loading="loading"-->
        <!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.logo" slot="logo" slot-scope="text, record">
        <a slot="weburl" slot-scope="text, record" :href="record.url" target="_blank">{{ record.url }}</a>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <input slot="sort" slot-scope="text, record" :value="text" style="width: 100%;text-align: center;"
               class="ant-input" @change="onCellChange(record.id, $event)">

        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['shop:brand:edit']" />-->
          <!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:brand:edit']">-->
          <!--            <a-icon type="edit" />修改-->
          <!--          </a>-->
                               <a-popconfirm title="确认禁用吗?" @confirm="() => handleUpdateEnable(record)"
                                             v-hasPermi="['shop:brand:edit']" v-if="record.isEnable == 1">

            <a >
           禁用
          </a>
                                            </a-popconfirm>

                     <a-popconfirm title="确认启用吗?" @confirm="() => handleUpdateEnable(record)" v-hasPermi="['shop:brand:edit']"
                                   v-else>

            <a >
           启用
          </a>
           </a-popconfirm>

          <a-divider type="vertical" v-hasPermi="['shop:brand:remove']"/>
                    <a @click="handleDelete(record)" v-hasPermi="['shop:brand:remove']">
                      删除
                    </a>
<!--           <a-popconfirm title="确认删除吗?" @confirm="() => handleDelete(record)" v-hasPermi="['shop:brand:remove']">-->
<!--                  <a>刪除</a>-->
<!--                </a-popconfirm>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {delBrand, pageBrand, updateBrandEnable,saveSort} from '@/api/shop/brand'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {delFriendCombo} from "@/api/vip/friendCombo";
// import {saveSort} from "@/api/mall/goodscate";

export default {
  name: 'Brand',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        isEnable: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '品牌logo',
          dataIndex: 'logo',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'logo'}

        },
        {
          title: '品牌名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '网址',
          dataIndex: 'url',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'weburl'}
        },
        // {
        //   title: '分类名称',
        //   dataIndex: 'categoryName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '简介',
          dataIndex: 'description',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          align: "center",
          dataIndex: 'sort',
          width: 100,
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '状态',
          align: "center",
          dataIndex: 'isEnable',
          width: 100,
          customRender: function (value) {
            if (value == 0) {
              return "禁用";
            } else {
              return "启用";
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ],
      changeSort: []
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    //开启禁用操作
    handleUpdateEnable(record) {
      updateBrandEnable({
        id:record.id,
        isEnable:record.isEnable == 1 ? 0 : 1
      }).then(res => {
        if(res.code == 20001) {
          record.isEnable = record.isEnable == 1 ? 0 : 1;
        }
      })
    },

    /**
     * TODO:保存排序
     */
    handleSaveSort: function () {
      const that = this;
      var arr = this.changeSort;
      if (arr.length < 1) {
        that.$message.warning("暂无数据");
        return;
      }
      saveSort(arr).then((res) => {
        if (res.code == 20001) {
          that.$message.success(res.message);
          this.getList();
        } else {
          that.$message.warning(res.message);
        }
      });
    },
    /**
     * 修改哪个排序
     * @param id
     * @param e
     */
    onCellChange(id, e) {
      var sort = e.target.value;
      var changeSort = this.changeSort;
      var flag = true;
      changeSort.forEach((value) => {
        if (value['id'] == id) {
          flag = false;
          value['sort'] = sort;
          return true;
        }
      });
      if (flag) {
        changeSort.push({id: id, sort: sort});
      }
    },
    /** 查询商城-品牌列表 */
    getList() {
      this.loading = true
      pageBrand(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {

        name: null,
        isEnable: null,
        pageNum: 1,
        pageSize: 10

      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      // let data = []
      // that.ids.map(id => data.push({"id": id}))
      // delBrand(data)
      //   .then(() => {
      //     that.onSelectChange([], [])
      //     that.getList()
      //     that.$message.success(
      //       '删除成功',
      //       3
      //     )
      //   })

      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBrand(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('shop/brand/export', {
            ...that.queryParam
          }, `商城-品牌_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
