<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="等级名称" prop="levelName" >
        <a-input v-model="form.levelName" placeholder="请输入等级名称" />
      </a-form-model-item>
      <a-form-model-item label="达成等级需要的亲密度" prop="requiredIntimacy" >
        <a-input v-model="form.requiredIntimacy" placeholder="请输入达成等级需要的亲密度" />
      </a-form-model-item>
      <a-form-model-item label="达到等级后, 是否创建蜜月罐" prop="isHoneymoonJar" >
        <a-select v-model="form.isHoneymoonJar" placeholder="请输入达到等级后, 是否创建蜜月罐">
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="达到等级后, 是否可以确定婚恋关系" prop="isMarriageRelation" >
        <a-select v-model="form.isMarriageRelation" placeholder="请输入达到等级后, 是否可以确定婚恋关系">
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="等级描述" prop="description" >
        <a-input v-model="form.description" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getFriendIntimacyLevel, addFriendIntimacyLevel, updateFriendIntimacyLevel } from '@/api/user/friendIntimacyLevel'
import TagSelectOption from "@/components/TagSelect/TagSelectOption";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    TagSelectOption
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        levelName: null,

        requiredIntimacy: null,

        isHoneymoonJar: null,

        isMarriageRelation: null,

        description: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        levelName: [
          { required: true, message: '等级名称不能为空', trigger: 'blur' }
        ],
        requiredIntimacy: [
          { required: true, message: '达成等级需要的亲密度不能为空', trigger: 'blur' }
        ],
        isHoneymoonJar: [
          { required: true, message: '达到等级后, 是否创建蜜月罐', trigger: 'blur' }
        ],
        isMarriageRelation: [
          { required: true, message: '达到等级后, 是否可以确定婚恋关系', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '等级描述', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        levelName: null,
        requiredIntimacy: null,
        isHoneymoonJar: null,
        isMarriageRelation: null,
        description: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getFriendIntimacyLevel({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateFriendIntimacyLevel(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addFriendIntimacyLevel(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
