import request from '@/utils/request'
const userApi = {
  addMoreSpecValue: '/shop/shop-goods-spec-value/addMoreSpecValue',
  add: '/shop/shop-goods-spec-value/add',
  edit: '/shop/shop-goods-spec-value/edit',
  delete: '/shop/shop-goods-spec-value/delete',

}

// 增多个
export function addMoreSpecValue (data) {
  return request({
    url: userApi.addMoreSpecValue,
    method: 'post',
    data: data
  })
}

// 增多个
export function edit (data) {
  return request({
    url: userApi.edit,
    method: 'post',
    data: data
  })
}

export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}

export function deleteMore(data) {
  return request({
    url: userApi.delete,
    method: 'post',
    data: data
  })
}