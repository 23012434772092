<!-- 举报列表 -->
<template>
  <crud :config="config"></crud>
</template>

<script>
import Crud from '@/components/crud/Crud.vue'

export default {
  name: 'List',
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          { field: 'userNickname', name: '举报人', },
          { field: 'targetNickname', name: '被举报人', },
          {
            field: 'dataType', name: '举报对象', type: 'select', option: [
              { id: 1, name: '用户' },
              { id: 5, name: '群组' },
              { id: 2, name: '动态' },
              { id: 3, name: '直播' },
              { id: 4, name: '短视频' },

            ]
          },
          { field: 'reportType', name: '举报类型', type: 'select', api: '/report/report-type/list', nameMapping: 'title' },
          {
            field: 'auditStatus', name: '审核状态', type: 'select', option: [
              { id: 0, name: '待审核' },
              { id: 1, name: '已审核' },
              { id: 2, name: '已拒绝' }
            ]
          },

          { field: 'reportContent', name: '举报内容', show: false },

          { field: 'auditReason', name: '审核原因', show: false },
        ],
        button: [
          // { name: '导出', color: 'primary', icon: 'download', event: 'inner-export' }
          { name: '删除', color: 'danger', icon: 'delete', event: 'inner-delete', multiple: true },
        ],
        table: {
          api: '/report/report/page',
          deleteApi: '/report/report/delete',
          // exportApi: 'report/report/export',
          col: [
            { field: 'userAvatar', name: '举报人头像', type: 'avatar' },
            { field: 'userNickname', name: '举报人昵称' },

            { field: 'targetAvatar', name: '被举报人头像', type: 'avatar' },
            { field: 'targetNickname', name: '被举报人昵称' },
            {
              field: 'dataType', name: '举报对象', type: 'tag', tags: [
                { name: '未知' },
                { name: '用户' },
                { name: '动态' },
                { name: '直播' },
                { name: '短视频' },
                { name: '群组' }
              ]
            },
            { field: 'reportTypeName', name: '举报类型' },
            { field: 'reportImg', name: '举报图片', type: 'imgs' },
            { field: 'reportContent', name: '举报内容' },
            {
              field: 'auditStatus', name: '审核状态', type: 'tag', tags: [
                { name: '待审核' },
                { name: '已审核', color: 'green' },
                { name: '已拒绝', color: 'red' },
              ]
            },
            { field: 'auditReason', name: '审核原因' },
            { field: 'createTime', name: '创建时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '审核', event: 'inner-edit', checkShow: (rowData) => rowData.auditStatus == 0, icon: 'edit' },
                { name: '删除', event: 'inner-delete', icon: 'delete' }
              ]
            },
          ]
        },
        form: {
          editApi: '/report/report/edit',
          item: [
            {
              field: 'auditStatus', name: '审核状态', type: 'select', option: [
                { id: 1, name: '已审核' },
                { id: 2, name: '已拒绝' }
              ]
            },
            {
              field: 'auditReason', name: '审核原因', type: 'textarea'
            }
          ]
        }
      },

    }
  },
}
</script>