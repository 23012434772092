<template>
  <span>{{time}}</span>
</template>

<script>
    export default {
        name: "倒计时",
        props: {
            endTime: {//结束时间
                type: String,
                required: true
            },
            /**
             * 格式化为什么类型
             * 1：x天xx小时xx分xx秒
             * 后期还能扩展
             *
             */
            type: {
                type: String,
                default: '1'
            },
        },
        data() {
            return {
                time: ''
            }
        },
        created() {
            var type=this.type;
            if(type==1){
                this.changeData1();
            }
        },
        methods: {
            timeFormat(param) {
                return param < 10 ? '0' + param : param;
            },
            changeData1() {
                var that=this;
                var interval = setInterval(() => {
                    // 获取当前时间，同时得到活动结束时间数组
                    let newTime = new Date().getTime();
                    // 对结束时间进行处理渲染到页面
                    let endTime = new Date(that.endTime).getTime();
                    let obj = null;
                    // 如果活动未结束，对时间进行处理
                    if (endTime - newTime > 0) {
                        let time = (endTime - newTime) / 1000;
                        // 获取天、时、分、秒
                        let day = parseInt(time / (60 * 60 * 24));
                        let hou = parseInt(time % (60 * 60 * 24) / 3600);
                        let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                        let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                        obj = {
                            day: this.timeFormat(day),
                            hou: this.timeFormat(hou),
                            min: this.timeFormat(min),
                            sec: this.timeFormat(sec)
                        };
                    } else { // 活动已结束，全部设置为'00'
                        obj = {
                            day: '00',
                            hou: '00',
                            min: '00',
                            sec: '00'
                        };
                        clearInterval(interval);
                    }
                    var timeStr="";
                    if(obj.day!='00'){
                        timeStr+=obj.day + '天';
                    }
                    if(obj.hou!='00'){
                        timeStr+=obj.hou + '时';
                    }
                    if(obj.min!='00'){
                        timeStr+=obj.min + '分';
                    }
                    if(obj.sec!='00'){
                        timeStr+=obj.sec + '秒';
                    }
                    that.time = timeStr;
                }, 1000);
            }
        }
    }
</script>

<style scoped>
</style>